import { createSlice } from '@reduxjs/toolkit';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { dispatch } from '../store';
import {getAddressList} from "./address";

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    isGetPredefinedLoading: false,
    error: null,
    packageList: [],
    allPackageList: [],
    isOpenModal: false,
    defaultDimensionUnit: [],
};

const slice = createSlice({
    name: 'package',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        startGetPredefinedLoading(state) {
            state.isGetPredefinedLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET PACKAGES
        getPackagesSuccess(state, action) {
            state.isLoading = false;
            state.packageList = action.payload;

            // // FOR ADD PACKAGE && MODIFIED PACKAGE
            state.isOpenModal = false;
        },

        // GET PACKAGE DEFAULT DIMENSION UNIT
        getDimensionUnitSuccess(state, action) {
            state.isLoading = false;
            state.defaultDimensionUnit = action.payload;
        },

        // GET ALL PACKAGES
        getAllPackagesSuccess(state, action) {
            state.allPackageList = action.payload;
        },

        // MODIFY PACKAGE
        modifyPackagesSuccess(state, action) {
            const { id: packageId } = action.payload;

            const modifiedPackageList = state.packageList.package_data_list.map((_package) => {
                // if (isDefault) _package.is_default = false;

                if (_package.id === packageId) {
                    return {
                        ..._package,
                        ...action.payload,
                    };
                }

                return _package;
            });

            state.isLoading = false;
            state.packageList.package_data_list = modifiedPackageList;
            state.isOpenModal = false;
        },

        // DELETE PACKAGE
        deletePackageSuccess(state, action) {
            state.isLoading = false;
            state.packageList.package_data_list = state.packageList.package_data_list.filter((_package) => `${_package.id}` !== `${action.payload}`) || [];
        },

        // OPEN MODAL
        openModal(state) {
            state.isOpenModal = true;
        },

        // CLOSE MODAL
        closeModal(state) {
            state.isOpenModal = false;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal } = slice.actions;

// ----------------------------------------------------------------------

export function getPackageList() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(API_ENDPOINTS.packages.searchAllPackages);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getPackagesSuccess(data?.data ?? []));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getAllPackageList() {
    return async () => {
        dispatch(slice.actions.startGetPredefinedLoading());
        try {
            const response = await axios.get(API_ENDPOINTS.packages.searchPredefinedPackages);

            const { status, data } = response;

            if (status === 200) {
                const resultList = data?.data ?? [];

                // let count = 1;
                // const tempList = [{
                //     /* eslint-disable-next-line no-plusplus */
                //     pid: count++,
                //     nick_name: 'Custom Package',
                //     description: 'Any custom box or thick parcel',
                //     type: 'custom_package',
                //     code: 'PACKAGE',
                //     is_default: false,
                //     length: '',
                //     width: '',
                //     height: '',
                //     carrier: 'USER',
                //     isSubheader: false,
                // }];
                // const tempList = [];

                // resultList.forEach(r => {
                //     tempList.push({
                //         ...r,
                //         isSubheader: true,
                //     });
                //
                //     if (r.code === 'MY PACKAGES') {
                //         r?.predefined_packages.forEach(p => tempList.push({
                //             ...p,
                //             /* eslint-disable-next-line no-plusplus */
                //             pid: count++,
                //             isSubheader: false,
                //             type: 'my_package',
                //             code: 'PACKAGE',
                //             description: `${p.length} x ${p.width} x ${p.height} in`,
                //             is_default: p.is_default,
                //             carrier: 'USER',
                //         }));
                //     } else {
                //         r?.predefined_packages.forEach(p => tempList.push({
                //             ...p,
                //             /* eslint-disable-next-line no-plusplus */
                //             pid: count++,
                //             carrier: r.code,
                //             isSubheader: false,
                //             is_default: false,
                //         }));
                //     }
                // });

                setTimeout(() => dispatch(slice.actions.getAllPackagesSuccess(resultList)), 600);
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function addPackage(detail) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.packages.createPackage, detail);

            const { status, data } = response;

            if (status === 200 ) {
                dispatch(getPackageList());
                dispatch(getAllPackageList());
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function modifyPackage(detail) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.packages.editPackage, detail);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.modifyPackagesSuccess(detail));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function deletePackage(id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.packages.deletePackage, {
                package_id: id,
            });

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.deletePackageSuccess(id));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}
// ----------------------------------------------------------------------

export function getPackageDimensionUnit() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(API_ENDPOINTS.packages.getDimensionUnit);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getDimensionUnitSuccess(data?.data ?? []));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function modifyPackageUnit(detail) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.packages.editDimensionUnit, detail);

            const { status, data } = response;

            if (status === 200) {
                dispatch(getPackageDimensionUnit());
                // dispatch(slice.actions.getDimensionUnitSuccess(detail));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function markAsDefault(Id) {
    return async () => {
        dispatch(slice.actions.startLoading());

        try {
            const response = await axios.post(API_ENDPOINTS.packages.makeDefault, {
                package_id: Id
            });

            const { status, data } = response;

            if (status === 200) {
                dispatch(getPackageList());
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}
