import { m } from 'framer-motion';
import { useState, useCallback, useEffect } from 'react';
// @mui
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// _mock
// import {_notifications} from 'src/_mock';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { varHover } from 'src/components/animate';
// Api
import { getTotalUnRead as getTotalUnReadAPI } from 'src/api/task';
// redux
import { useSelector } from 'react-redux';
import { modifyProductInfoSuccess } from 'src/redux/slices/products';
import { useDispatch } from '../../../redux/store';
import { getTaskList, getUnreadTaskNum, markAllAsRead, markTasksAsRead } from '../../../redux/slices/task';
//
import { useLocales } from '../../../locales';
// import NotificationItem from './notification-item';
import CustomPopover, { usePopover } from '../../../components/custom-popover';
import TaskItem from './task-item';
import TaskDialog from '../../../sections/task/TaskDialog';

// ----------------------------------------------------------------------

export default function TasksPopover() {
    const dispatch = useDispatch();

    const popover = usePopover();

    const { translate } = useLocales();

    const smUp = useResponsive('up', 'sm');

    const { tasks, taskFilter, totalUnRead } = useSelector(({ task }) => task);

    const [taskDialog, setTaskDialog] = useState(null);

    useEffect(() => {
        dispatch(getTaskList({
            page_size: 3,
            page: 0,
            // read_status: 0,
        }, false));
        dispatch(getUnreadTaskNum());
    }, [dispatch, popover.open]);

    const handleMarkAllAsRead = () => {
        // const task_ids = tasks?.map(task => task.task_id_str);

        dispatch(markAllAsRead([]));
    };

    const handleOpenTaskDialog = () => {
        popover.onClose();

        setTaskDialog(
            <TaskDialog
                open={Boolean(true)}
                onClose={() => setTaskDialog(null)}
            />,
        );
    };

    const handleUpdateTasks = () => {
        const filter = {
            page_size: taskFilter.page_size || 5,
            page: taskFilter.page + 1 || 0,
        };
        dispatch(getTaskList(filter, true));
    };

    const renderHead = (
        <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
                {`${translate('task_1')}`}
            </Typography>

            {!!totalUnRead && (
                <Tooltip title="Mark all as read">
                    {/* <IconButton color="primary" onClick={handleMarkAllAsRead}> */}
                    {/*     <Iconify icon="eva:done-all-fill"/> */}
                    {/* </IconButton> */}
                    <Typography variant="link" onClick={handleMarkAllAsRead}>
                        {`${translate('task_2')}`}
                    </Typography>
                </Tooltip>
            )}

            {/* {!smUp && ( */}
            {/*     // <IconButton onClick={popover.onClose}> */}
            {/*     //     <Iconify icon="mingcute:close-line"/> */}
            {/*     // </IconButton> */}
            {/*     <Typography variant="link"> */}
            {/*         {`${translate('task_2')}`} */}
            {/*     </Typography> */}
            {/* )} */}
        </Stack>
    );

    const renderList = (
        <Scrollbar>
            <List disablePadding>
                {(tasks?.tasks || []).map((task) => (
                    <TaskItem key={task?.task_id_str} task={task} />
                ))}
            </List>
        </Scrollbar>
    );

    return (
        <>
            <IconButton
                component={m.button}
                whileTap="tap"
                whileHover="hover"
                variants={varHover(1.05)}
                color={popover.open ? 'inherit' : 'default'}
                onClick={popover.onOpen}
                sx={{
                    ...(popover.open && {
                        bgcolor: (theme) => theme.palette.action.selected,
                    }),
                }}
            >
                <Badge badgeContent={totalUnRead} color="error">
                    <Iconify icon="solar:bell-bing-bold-duotone" width={24} />
                </Badge>
            </IconButton>

            <CustomPopover
                open={popover.open}
                onClose={popover.onClose}
                sx={{ width: 320 }}
            >
                {renderHead}

                <Divider />

                {/* <Stack */}
                {/*     direction="row" */}
                {/*     alignItems="center" */}
                {/*     justifyContent="space-between" */}
                {/*     sx={{pl: 2.5, pr: 1}} */}
                {/* > */}
                {/*     {renderTabs} */}
                {/*     <IconButton onClick={handleMarkAllAsRead}> */}
                {/*         <Iconify icon="solar:settings-bold-duotone"/> */}
                {/*     </IconButton> */}
                {/* </Stack> */}

                <Divider />

                {renderList}

                <Box sx={{ p: 1 }}>
                    <Button fullWidth size="large" onClick={handleOpenTaskDialog}>
                        View All
                    </Button>
                </Box>
            </CustomPopover>
            {taskDialog}
        </>
    );
}
