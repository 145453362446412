// @mui
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import {useSnackbar} from "notistack";
// hooks
import {useBoolean} from 'src/hooks/use-boolean';
import {useResponsive} from 'src/hooks/use-responsive';
// components
import {useSettingsContext} from 'src/components/settings';
//
import { paths } from 'src/routes/paths';
import {useEffect} from "react";
import Main from './main';
import Header from './header';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';
import NavHorizontal from './nav-horizontal';
import axios from '../../utils/axios';
import {batch, useDispatch} from "../../redux/store";
import {getWarehouseList} from "../../redux/slices/warehouse";
import {getBinList} from "../../redux/slices/bin";
import {getUser} from "../../redux/slices/user";

// ----------------------------------------------------------------------

export default function DashboardLayout({children}) {
    const dispatch = useDispatch();

    const settings = useSettingsContext();

    const lgUp = useResponsive('up', 'lg');

    const { enqueueSnackbar } = useSnackbar();

    const nav = useBoolean();

    const isHorizontal = settings.themeLayout === 'horizontal';

    const isMini = settings.themeLayout === 'mini';

    const renderNavMini = <NavMini/>;

    const renderHorizontal = <NavHorizontal/>;

    const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse}/>;

    useEffect(() => batch(() => {
        dispatch(getUser());
    }), [dispatch]);

    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error?.code === 'auth.jwt.invalid') {
                localStorage.removeItem('accessToken');
                window.location.href = paths.auth.jwt.login;
            }
            if (error?.code !== 'payment_payment_methods_verify_fail' && error?.code !== 'payment_payment_methods_verify_first_fail') {
                enqueueSnackbar(error?.msg, { variant: 'error' });
            }
            return Promise.reject(error);
        })

    if (isHorizontal) {
        return (
            <>
                <Header onOpenNav={nav.onTrue}/>

                {lgUp ? renderHorizontal : renderNavVertical}

                <Main>{children}</Main>
            </>
        );
    }

    if (isMini) {
        return (
            <>
                <Header onOpenNav={nav.onTrue}/>

                <Box
                    sx={{
                        display: {lg: 'flex'},
                        minHeight: {lg: 1},
                    }}
                >
                    {lgUp ? renderNavMini : renderNavVertical}

                    <Main>{children}</Main>
                </Box>
            </>
        );
    }

    return (
        <>
            <Header onOpenNav={nav.onTrue}/>

            <Box
                sx={{
                    display: {lg: 'flex'},
                    minHeight: {lg: 1},
                }}
            >
                {renderNavVertical}

                <Main>{children}</Main>
            </Box>
        </>
    );
}

DashboardLayout.propTypes = {
    children: PropTypes.node,
};
