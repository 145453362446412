// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const cn = {
    demo: {
        title: `Chinese`,
        introduction: `Lorem Ipsum 只是印刷和排版行业的虚拟文本。 自 1500 年代以来，Lorem Ipsum 一直是行业的标准虚拟文本，当时一位不知名的印刷商采用了一种类型的厨房并将其加扰以制作类型样本书。 它不仅经历了五个世纪，而且经历了电子排版的飞跃，基本保持不变。 它在 1960 年代随着包含 Lorem Ipsum 段落的 Letraset 表的发布而流行起来，最近还随着 Aldus PageMaker 等桌面出版软件（包括 Lorem Ipsum 的版本）而普及.`,
    },
    docs: {
        hi: `你好`,
        description: `需要帮忙？\n 请查看我们的文档.`,
        documentation: `文件`,
    },
    app: `应用程序`,
    list: `列表`,
    edit: `编辑`,
    shop: `商店`,
    blog: `博客`,
    post: `邮政`,
    cards: `牌`,
    posts: `帖子`,
    create: `创建`,
    general: `一般的`,
    banking: `银行业`,
    booking: `预订`,
    profile: `轮廓`,
    product: `产品`,
    invoice: `发票`,
    details: `细节`,
    checkout: `退房`,
    calendar: `日历`,
    analytics: `分析`,
    management: `管理`,
    menu_level_1: `菜单级别 1`,
    menu_level_2: `菜单级别 2`,
    menu_level_3: `菜单级别 3`,
    item_disabled: `项目已禁用`,
    item_label: `项目标签`,
    item_caption: `项目标题`,
    description: `描述`,
    other_cases: `其他情况`,
    item_by_roles: `按角色逐项`,
    only_admin_can_see_this_item: `只有管理员可以看到这个项目`,
    upgrade_to_pro: "Upgrade to Pro",
    // ----------------------------------------------------------------------
    header_balance: `账户余额`,
    // ----------------------------------------------------------------------
    option_default: `全部`,
    // Settings Transaction Type
    option_recharge: `充值`,
    option_bank_account: `充值`,
    option_credit_card: `充值`,
    option_offline: `充值`,
    option_alipay: `充值`,
    option_wechatpay: `充值`,
    option_purchase: `运费付款`,
    option_void: `Void`,
    option_refund: `运费退款`,
    option_adjustment: `运费调整`,
    // option_zelle_status
    option_pending: `Pending`,
    option_declined: `Declined`,
    // option_credit_card: `Credit Card`,
    option_cancel: `Void`,
    // Settings Payment Type
    option_card: `信用卡`,
    option_balance: `账号余额`,
    // Shipment My Shipment Tracking
    option_created: `Label Created`,
    option_in_transit: `In Transit`,
    option_out_for_delivery: `Out for Delivery`,
    option_delivered: `Delivered / Pickup Ready`,
    option_unsuccessful: `Unsuccessful`,
    option_unknown: `Unknown`,
    // Shipment My Pickups Status
    option_confirmed: `已确认`,
    option_canceled: `已取消`,
    // Shipment Pickups Location
    option_front_door: `前门`,
    option_back_door: `后门`,
    option_side_door: `侧门`,
    option_knock_on_door_ring_bell: `敲门/按门铃`,
    option_mail_room: `收发室`,
    option_office: `办公室`,
    option_reception: `前台/接待处`,
    option_in_at_mail_box: `邮箱内/上面`,
    option_other: `其他（附加的必要说明-请用英文）`,
    // Package Type
    option_subheader_MY_PACKAGES: `我的包裹`,
    option_subheader_USPS: `USPS`,
    option_subheader_UPS: `UPS`,
    option_subheader_ONTRAC: `ONTRAC`,
    option_subheader_FedEx: `FEDEX`,
    option_custom_package_name: `自定义包裹`,
    option_custom_package_desc: `自定义任何包裹的尺寸`,
    // Billing Status
    option_current: `Current`,
    option_expired: `Expired`,
    option_changed: `Changed`,
    option_depleted: `Depleted`,
    // Order Shipping Area
    option_domestic: `境内邮寄`,
    option_international: `国际邮寄`,
    // Order Store Status
    option_order_awaiting_payment: `Awaiting Payment`,
    option_order_awaiting: `待发货`,
    option_order_shipped: `已发货`,
    option_order_canceled: `已取消`,
    option_order_cancelled: `已取消`,
    // ACH Business Type
    option_individual: `个人`,
    option_company: `公司`,
    // Order Date
    option_all_time: `全部`,
    option_today: `今天`,
    option_last24h: `过去24小时`,
    option_last7d: `过去7天`,
    option_last30d: `过去30天`,
    option_last45d: `过去45天`,
    option_this1m: `本月`,
    option_last1m: `上个月`,
    option_custom: `自定义`,
    // Label Size
    option_4x6: `4x6"`,
    option_4x8: `4x8"`,
    option_8_5x11_r: `8.5x11" - Right Side`,
    option_8_5x11_l: `8.5x11" - Left Side`,
    // Residential type
    option_residential: `住宅地址`,
    option_commercial: `商业地址`,
    // Label Printing Option
    option_ask: `每次询问`,
    option_label: `运单`,
    option_qr_code: `二维码`,
    // Started Shipping
    option_started_shipping_true: `Yes`,
    option_started_shipping_false: `No`,
    // ----------------------------------------------------------------------
    dashboard: `我的主页`,
    orders: `我的订单`,
    shipments: `包裹邮寄`,
    packing: `packing`,
    inventory: `inventory`,
    warehouse: `warehouse`,
    stores: `stores`,
    wallet: `wallet`,
    settings: `系统设置`,
    account: `账户设置`,
    company: `公司设置`,
    printer: `打印机设置`,
    documentation: `documentation`,
    shipping_rules: `shipping rules`,
    address_book: `address book`,
    carrier_accounts: `carrier accounts`,
    user: `用户`,


    billing: `付款设置`,
    items: `我的商品`,
    reports: `数据报告`,
    // account: `账户设置`,
    // integration: `渠道对接`,
    // configuration: `邮寄设置`,
    copied: `已复制`,
    logout: `退出账户`,
    // ----------------------------------------------------------------------
    auth_login_1: `登录ShipSaving`,
    auth_login_2: `请在下面输入您的登录信息。`,
    auth_login_3: `邮箱`,
    auth_login_4: `密码`,
    auth_login_5: `自动登录`,
    auth_login_6: `忘记密码?`,
    auth_login_7: `登录`,
    auth_login_8: `还没有账号？`,
    auth_login_9: `立即注册`,
    // ----------------------------------------------------------------------
    auth_signup_1: `创建您的账户`,
    auth_signup_2: `注册后立即自动获得运费折扣！`,
    auth_signup_3: `名字`,
    auth_signup_4: `公司`,
    auth_signup_5: `邮箱`,
    auth_signup_6: `电话`,
    auth_signup_7: `密码`,
    auth_signup_7_1: `密码必须在8-20个字母之间`,
    auth_signup_7_2: `至少包含1个小写字母、1个大写字母、1个数字或1个符号`,
    auth_signup_8: `How did you hear about ShipSaving?`,
    auth_signup_8_1: `Please specify`,
    auth_signup_9: `折扣码 (选填)`,
    auth_signup_9_1: `Please enter the referral code here to unlock a  30-day free trial`,
    auth_signup_10: `创建账户`,
    auth_signup_11: `创建账户即表示您同意ShipSaving的`,
    auth_signup_11_1: `条款`,
    auth_signup_11_2: `和`,
    auth_signup_11_3: `隐私政策`,
    auth_signup_11_4: `。`,
    auth_signup_11_5: `ShipSaving 禁止任何形式的欺诈活动。`,
    auth_signup_12: `您已经拥有一个账户？`,
    auth_signup_13: `登录`,
    auth_signup_14: `名`,
    auth_signup_15: `姓`,
    auth_signup_16_1: `预估每月发货量`,

    // no usage
    auth_signup_16: `设置您的发货地址`,
    auth_signup_17: `设置您的默认发货地址，以便计算运费和预约上门取件。`,
    auth_signup_18: `电话`,
    auth_signup_19: `地址栏1`,
    auth_signup_20: `地址栏2（选填）`,
    auth_signup_21: `城市`,
    auth_signup_22: `州`,
    auth_signup_23: `邮编`,

    // ----------------------------------------------------------------------
    auth_forgot_password_1: `忘记了您的密码？`,
    auth_forgot_password_2: `请输入与您的账户相关的电子邮件地址，我们将通过电子邮件向您发送一个链接来重置您的密码。`,
    auth_forgot_password_3: `邮箱`,
    auth_forgot_password_4: `重置密码`,
    auth_forgot_password_5: `返回`,
    // ----------------------------------------------------------------------
    auth_reset_password_1: `创建新密码`,
    auth_reset_password_2: `请为您的账号设置一个新的密码。`,
    auth_reset_password_3: `新密码`,
    auth_reset_password_4_1: `密码必须在8-20个字母之间`,
    auth_reset_password_4_2: `至少包含1个小写字母、1个大写字母、1个数字或1个符号`,
    auth_reset_password_4_3: `请不要与电子邮件@前的字母相同`,
    auth_reset_password_4_4: `请不要包含超过3个连续或重复的数字/字母`,
    auth_reset_password_5: `确认密码`,
    auth_reset_password_6: `重置密码`,
    auth_reset_password_7: `返回`,
    // ----------------------------------------------------------------------
    navbar_1_1: `升级至 `,
    navbar_1_2: `专业版`,
    navbar_2: `通过免费的WMS和其他强大的功能，让您的工作流程自动化。`,
    navbar_3: `了解更多`,
    navbar_4: `SHIPSAVING新闻中心`,
    navbar_5: `🎉 好消息！UPS邮寄服务已正式加入ShipSaving啦。今天开始发货，可以节省高达82% off的运费！`,
    navbar_6: `https://www.shipsaving.com/zh-cn/ups`,
    // ----------------------------------------------------------------------
    dashboard_1: `Quick Start`,
    dashboard_1_1_1: `Create a Shipment`,
    dashboard_1_1_2: `Inbound`,
    dashboard_1_1_3: `Outbound`,
    dashboard_1_1_4: `Scan Form`,
    dashboard_1_1_5: `Print APP`,
    dashboard_1_1_6: `API Manage`,
    dashboard_1_2_1_1: `Account Balance`,
    dashboard_1_2_1_2: `Add Funds`,
    dashboard_1_2_2: `Total Shipping Costs`,
    dashboard_1_2_3: `Total Rebates`,
    dashboard_2: `Tutorials`,
    dashboard_3: `Shipping Report`,
    dashboard_3_1: `个运单`,
    dashboard_3_2: `总计运单`,
    //
    reports_1_1: '一月',
    reports_1_2: '二月',
    reports_1_3: '三月',
    reports_1_4: '四月',
    reports_1_5: '五月',
    reports_1_6: '六月',
    reports_1_7: '七月',
    reports_1_8: '八月',
    reports_1_9: '九月',
    reports_1_10: '十月',
    reports_1_11: '十一月',
    reports_1_12: '十二月',
    //
    // dashboard_3_1_2: `使用ShipSaving运费计算器，快速查看最适合您的运输服务及预计送达时间。`,
    // dashboard_3_2_1: `开始邮寄`,
    // dashboard_3_2_2: `输入您的地址和包裹信息即可在线购买和打印运单，足不出户享受低至2折的运费费率。`,
    // dashboard_3_3_1: `连接店铺`,
    // dashboard_3_3_2: `实时同步您的店铺订单，轻松高效的一站式管理发货，省时省力省心。`,
    // dashboard_4_1_1: `最近邮寄`,
    // dashboard_4_1_2: `查看全部`,
    // dashboard_4_2_1: `邮寄ID`,
    // dashboard_4_2_2: `收件人`,
    // dashboard_4_2_3: `服务及运费`,
    // dashboard_4_2_4: `邮寄状态`,
    // dashboard_4_2_5: `订单回传`,
    // dashboard_5_1: `让我们一起开始吧`,
    // dashboard_5_2: `第一次使用ShipSaving？请参考以下面向所有新用户的邮寄入门视频指南。`,
    // dashboard_5_3_1: `ShipSaving平台介绍`,
    // dashboard_5_3_2: `如何通过ShipSaving邮寄包裹？`,
    // dashboard_5_3_3: `如何通过ShipSaving连接店铺？`,
    // dashboard_5_3_4: `如何设置您的默认发件地址？`,
    // dashboard_5_3_5: `如何设置您的默认发件包裹？`,
    // dashboard_5_3_6: `如何添加您的UPS账号到ShipSaving？`,
    // dashboard_5_4: `查看更多教程视频`,
    // dashboard_5_5: `来看看ShipSaving的邮寄小窍门吧！`,
    // dashboard_5_6: `教程。`,
    // dashboard_6_1: `您的ShipSaving账户暂时无法使用购买运单功能。请`,
    // // dashboard_6_2: `我的计划`,
    // dashboard_6_3: `添加/更新您的付款方式以解锁全部功能。`,
    // dashboard_6_4: `添加/更新付款方式`,
    // ----------------------------------------------------------------------
    quickrate_1: `快速测价`,
    quickrate_2_1: `美国境内邮寄`,
    quickrate_2_2: `国际包裹邮寄`,
    quickrate_3_1: `发件地址`,
    quickrate_3_1_1: `邮编`,
    quickrate_3_1_2: `国家`,
    quickrate_3_1_3: `城市`,
    quickrate_3_2: `收件地址`,
    quickrate_3_2_1: `邮编`,
    quickrate_3_2_2: `国家`,
    quickrate_3_2_3: `城市`,
    quickrate_4: `包裹类型`,
    quickrate_4_1: `默认`,
    quickrate_5: `尺寸 & 重量`,
    quickrate_5_1_1: `长（in）`,
    quickrate_5_1_2: `宽（in）`,
    quickrate_5_1_3: `高（in）`,
    quickrate_5_2: `重量`,
    quickrate_6: `查看运费`,
    quickrate_7: `抱歉，我们暂时无法为您查询运费。请确认以上信息均已填写完整，或直接联系我们的客服团队。`,
    quickrate_7_1: `您选择的包裹的邮寄服务仅在ShipSaving Standard计划中提供。`,
    quickrate_8_1: `最便宜`,
    quickrate_8_1_1: `个工作日`,
    quickrate_8_1_2: ` Retail`,
    quickrate_8_1_3: `无法追踪`,
    quickrate_8_1_4: `免费追踪`,
    quickrate_8_1_5: `此服务有寄送物品限制。`,
    quickrate_8_1_6: `了解更多`,
    quickrate_8_1_7: `通过USPS First-Class Mail邮寄Letters and Large Envelopes/Flats，即使运单未使用，也不支持退款。`,
    quickrate_8_1_8: `由于承运商限制，ShipSaving不为FedEx Ground Economy提供任何售后服务。`,
    quickrate_8_2: `其他服务`,
    quickrate_8_3: `查询结果`,
    quickrate_8_4: `开始邮寄`,
    quickrate_8_4_1: `以下的运费`,
    quickrate_8_4_2: `不包括关税、税费或清关代理费`,
    quickrate_8_4_3: `。请注意，ShipSaving是一个第三方运输平台，我们不负责清关流程。`,
    quickrate_8_5: `最高节省89% off`,
    quickrate_8_6: `最高节省76% off`,
    quickrate_8_7: `折扣基于您自己的承运商账号`,
    quickrate_8_8: `免除住宅和燃油附加费`,
    quickrate_9_1: `物品类型`,
    quickrate_9_2: `文件`,
    quickrate_9_3: `非文件`,
    quickrate_10_3: ` • 周六派送`,
    // ----------------------------------------------------------------------
    shipments_create_0: `返回上一页`,
    shipments_create_1: `第一步，创建新运单`,
    shipments_create_2: `收件地址`,
    shipments_create_2_0_1: `编辑地址`,
    shipments_create_2_0_2: `取消编辑地址`,
    shipments_create_2_1: `收件人姓名`,
    shipments_create_2_2: `公司（选填）`,
    shipments_create_2_3: `邮箱（选填）`,
    shipments_create_2_3_1: `*向收件人发送发货确认邮件。`,
    shipments_create_2_3_2: `查看模版`,
    shipments_create_2_3_3: `好的`,
    shipments_create_2_4: `电话（境内邮寄选填）`,
    shipments_create_2_5: `地址栏1`,
    shipments_create_2_6: `地址栏2（选填）`,
    shipments_create_2_7: `城市`,
    shipments_create_2_8: `州`,
    shipments_create_2_9: `邮编`,
    shipments_create_2_10: `国家`,
    shipments_create_2_11_1: `住宅地址`,
    shipments_create_2_11_2: `（如果收件地址类型是住宅，请勾选此框）`,
    shipments_create_2_12: `收件人名字`,
    shipments_create_2_13: `收件人姓氏`,
    shipments_create_3: `发件地址`,
    shipments_create_3_1_1: `不使用已设置的默认发件地址`,
    shipments_create_3_1_2: `使用新的发件地址`,
    shipments_create_3_1_3: `默认`,
    shipments_create_3_2: `发件人姓名`,
    shipments_create_3_2_1: `发件人名字`,
    shipments_create_3_2_2: `发件人姓氏`,
    shipments_create_3_3: `公司（选填）`,
    shipments_create_3_4: `邮箱（选填）`,
    shipments_create_3_5: `电话（境内邮寄选填）`,
    shipments_create_3_6: `地址栏1`,
    shipments_create_3_7: `地址栏2（选填）`,
    shipments_create_3_8: `城市`,
    shipments_create_3_9: `州`,
    shipments_create_3_10: `邮编`,
    shipments_create_3_11: `国家`,
    shipments_create_3_12_1: `保存此地址`,
    shipments_create_3_12_2: `（添加至我的默认发件地址）`,
    shipments_create_4: `海关表格`,
    shipments_create_4_1: `了解如何`,
    shipments_create_4_1_1: `正确填写海关表格`,
    shipments_create_4_2_0: `海关信息`,
    shipments_create_4_2: `添加物品`,
    shipments_create_4_2_1: `海关物品`,
    shipments_create_4_3: `签名人`,
    shipments_create_4_4: `物品类型`,
    shipments_create_4_4_1: `产品`,
    shipments_create_4_4_2: `文件`,
    shipments_create_4_4_3: `退回的产品`,
    shipments_create_4_4_4: `礼物`,
    shipments_create_4_4_5: `样品`,
    shipments_create_4_5: `如无法送达`,
    shipments_create_4_5_1: `送回给发件人`,
    shipments_create_4_5_2: `丢弃`,
    shipments_create_4_6: `EEl / PFC & Tax ID`,
    shipments_create_4_7: `EEL / PFC（选填）`,
    shipments_create_4_7_1: `无`,
    shipments_create_4_7_2: `豁免代码`,
    shipments_create_4_7_3: `ITN`,
    shipments_create_4_8: `豁免类型`,
    shipments_create_4_9: `ITN代码`,
    shipments_create_4_10: `Tax ID（选填）`,
    shipments_create_4_10_1: `无`,
    shipments_create_4_11: `号码`,
    shipments_create_4_12: `海关表格 #`,
    shipments_create_4_12_1: `移除`,
    shipments_create_4_13: `物品描述（请对物品进行描述，包括其用途和材质）`,
    shipments_create_4_14: `数量`,
    shipments_create_4_15: `物品单价（$）`,
    shipments_create_4_16: `海关关税编号 (寄往欧盟国家必填)`,
    shipments_create_4_17: `原产地`,
    shipments_create_4_18: `查询Harmonization号码`,
    shipments_create_5: `包裹信息`,
    shipments_create_5_1: `包裹`,
    // shipments_create_5_1_1: `自定义包装`,
    // shipments_create_5_1_2: `使用新包裹`,
    // shipments_create_5_1_3: `我的包裹`,
    shipments_create_5_1_4: `默认`,
    shipments_create_5_2: `尺寸 & 重量`,
    shipments_create_5_2_1: `长（in）`,
    shipments_create_5_2_2: `宽（in）`,
    shipments_create_5_2_3: `高（in）`,
    shipments_create_5_2_4: `重量`,
    shipments_create_5_3: `邮寄选项`,
    shipments_create_5_3_1: `运输保险`,
    shipments_create_5_3_1_1: `无保险`,
    shipments_create_5_3_1_2: `承运商保险`,
    shipments_create_5_3_1_3: `Shipsurance (每件包裹的运费险限额为$5,000)`,
    shipments_create_5_3_2: `包裹申报价值`,
    shipments_create_5_3_3: `签收选项`,
    shipments_create_5_3_3_1_1: `*请注意，只有当您在创建运单后的`,
    shipments_create_5_3_3_1_2: `24小时内`,
    shipments_create_5_3_3_1_3: `申请作废面单，您才能收到Shipsurance的退款。选用ShipSurance, 我同意并接受`,
    shipments_create_5_3_3_1_4: `Shipsurance terms and conditions`,
    shipments_create_5_3_3_1: `无需签收`,
    shipments_create_5_3_3_2: `需要签收`,
    shipments_create_5_3_3_3: `需要成年人签收`,
    shipments_create_5_3_3_4: `派送证明 (仅限UPS)`,
    shipments_create_5_4: `周六派送`,
    shipments_create_5_4_1: `（UPS增值服务 - 星期六快递送货）`,
    shipments_create_5_5: `包裹特殊处理`,
    shipments_create_5_5_1: `（包裹使用不规则的包装类型，如钢管、木箱、轮胎等）`,
    shipments_create_5_5_1_1: `内含危险品`,
    shipments_create_5_5_1_2: ` (这个包裹中包含危险品或危险材料 `,
    shipments_create_5_5_1_3: `了解更多`,
    shipments_create_5_5_1_4: `)`,
    shipments_create_5_5_2_0: `Return Label`,
    shipments_create_5_5_2: `Return Type`,
    shipments_create_5_5_3: `Print Return Label`,
    shipments_create_5_5_4: `Electronic Return Label`,
    shipments_create_5_5_5: `1 Pickup Attempt`,
    shipments_create_5_5_6: `3 Pickup Attempts`,
    shipments_create_5_5_7: `UPS Return Label Email`,
    shipments_create_5_6: `UPS碳中和运输`,
    shipments_create_5_6_1: `（香水、指甲油、发胶、干冰、锂电池、枪支、打火机、燃料等。`,
    shipments_create_5_6_2: '了解更多',
    shipments_create_5_6_3: `）`,
    shipments_create_5_7: `抱歉，我们暂时无法为您获取费率。请确认上述所有信息都已填写，如果需要进一步的帮助，请与我们联系。`,
    shipments_create_5_8: `查看运费`,
    shipments_create_6: `第二步，运单详情`,
    shipments_create_6_1: `返回上一步`,
    shipments_create_7_1: `发件地址`,
    shipments_create_7_2: `包裹详情`,
    shipments_create_7_2_1: `包裹类型`,
    shipments_create_7_2_2: `尺寸`,
    shipments_create_7_2_3: `重量`,
    shipments_create_7_3: `运单详情`,
    shipments_create_7_3_1: `运单尺寸`,
    shipments_create_7_4: `海关表格`,
    shipments_create_7_4_1: `Tax ID`,
    shipments_create_7_4_2: `EEl / PFC`,
    shipments_create_8: `选择邮寄服务`,
    shipments_create_8_1: `最便宜`,
    shipments_create_8_1_1: `个工作日`,
    shipments_create_8_1_2: ` Retail`,
    shipments_create_8_1_3: `无法追踪`,
    shipments_create_8_1_4: `免费追踪`,
    shipments_create_8_1_5: `此服务有寄送物品限制。`,
    shipments_create_8_1_6: `了解更多`,
    shipments_create_8_1_7: `通过USPS First-Class Mail邮寄Letters and Large Envelopes/Flats，即使运单未使用，也不支持退款。`,
    shipments_create_8_2: `更多服务`,
    shipments_create_8_3: `最热门`,
    shipments_create_8_3_1: `最热门 & 最便宜`,
    shipments_create_8_4_1: `最高节省89% off`,
    shipments_create_8_4_2: `最高节省76% off`,
    shipments_create_8_4_3: `折扣基于您自己的承运商账号`,
    shipments_create_9: `邮寄日期`,
    shipments_create_9_1: `🧐 Why can’t I see other shipping services? `,
    shipments_create_9_2: `了解更多`,
    shipments_create_10: `运费详情`,
    shipments_create_10_1: `邮寄运费`,
    shipments_create_10_1_1: `额外服务包含`,
    shipments_create_10_1_1_1: `运输保险`,
    shipments_create_10_1_2: `预计交付时间`,
    shipments_create_10_1_2_1: `个工作日`,
    shipments_create_10_2: `运输保险`,
    shipments_create_10_2_1: `Shipsurance`,
    shipments_create_10_3: `总价`,
    shipments_create_10_3_1: `这可能不是您的最终运费. `,
    shipments_create_10_3_2: `了解更多`,
    shipments_create_10_3_3: `运费通常情况下是由您的货物尺寸和重量决定的。`,
    shipments_create_10_3_3_1: `运费调整`,
    shipments_create_10_3_3_2: `是指由于您输入的货物信息不准确而生成了错误的运单，导致承运商需要重新调整运输信息以将包裹送到最终目的地，从而产生的额外费用。`,
    shipments_create_10_3_3_3: `在大多数情况下，承运商会仔细检查您的包裹的运单细节，以及实际发送的包裹，并对发现的任何不准确之处收取额外费用。`,
    shipments_create_10_3_3_4: `以下是运费调整的5个主要原因：`,
    shipments_create_10_3_3_5: `包裹重量不一致`,
    shipments_create_10_3_3_6: `包裹尺寸不一致`,
    shipments_create_10_3_3_7: `不匹配的发货地/目的地邮编`,
    shipments_create_10_3_3_8: `包裹类型不正确`,
    shipments_create_10_3_3_9: `服务类型不正确`,
    shipments_create_10_3_3_10: `如何避免运费调整?`,
    shipments_create_10_3_3_11: `请务必精确输入您的货物的包裹信息，这样您就可以得到更准确的运费从而生成运单，避免后续产生昂贵的运费调整。`,
    shipments_create_10_3_3_12: `承运商账单决定了您包裹的最终价格。`,
    shipments_create_10_3_3_13: ` ShipSaving将根据帐单情况，把运费调整的款项返还到您的帐户余额或从您的默认支付方式中收取额外费用。`,
    shipments_create_10_3_3_14: `确认`,
    shipments_create_11: `付款方式`,
    shipments_create_11_1_1: `运单购买支持混合支付模式。当您的账户余额不足时，ShipSaving会从您的默认信用卡中扣除剩余金额。最后的记录可以在您的`,
    shipments_create_11_1_2: `交易历史页面`,
    shipments_create_11_1_3: `上查看。`,
    shipments_create_11_2: `此运单的费用将会计入您自己的UPS账号`,
    shipments_create_11_3: `账户余额支付`,
    shipments_create_11_3_1: `（默认）`,
    shipments_create_11_3_2: `当前账户余额`,
    shipments_create_11_4: `信用卡支付`,
    shipments_create_11_4_1: `卡号后四位`,
    shipments_create_11_4_2: `添加信用卡`,
    shipments_create_11_4_3: `您还没有绑定信用卡，请绑定信用卡完成此交易。`,
    shipments_create_11_5: `总支付`,
    shipments_create_12_1: `保存草稿`,
    shipments_create_12_2: `保存草稿`,
    shipments_create_13_1: `取消购买`,
    shipments_create_13_2: `取消购买`,
    shipments_create_14: `确认支付`,
    shipments_create_15: `目前没有有效的银行卡可以完成支付, 请前往`,
    shipments_create_15_1: `付款设置`,
    shipments_create_15_2: `页面管理您的付款方式。`,
    shipments_create_16: `购买运单失败`,
    shipments_create_16_1: `在购买运单之前，请前往付款设置页面验证您的信用卡。`,
    shipments_create_16_2: `取消`,
    shipments_create_16_3: `前往验证`,
    shipments_create_17: `*选择此项仅限当您已成功连接了自己的UPS账户。`,
    shipments_create_18: `自定义运单文本`,
    shipments_create_18_0: `添加“`,
    shipments_create_18_0_1: `自定义运单文本`,
    shipments_create_18_0_2: `”功能让您可以直接在运单上打印自定义文本。您可以利用它来打印订单SKU而无需打印另一张拣货单。这些额外的文本便于您分配包裹。`,
    shipments_create_18_1: `#1 (选填)`,
    shipments_create_18_2: `#2 (选填)`,
    shipments_create_19_1: `您的账户余额不足, 请`,
    shipments_create_19_2: `充值`,
    shipments_create_19_3: `后再购买Label`,
    // ----------------------------------------------------------------------
    shipments_1: `包裹邮寄`,
    shipments_2_1: `我的邮寄`,
    shipments_2_2: `我的草稿`,
    shipments_2_3: `我的取件`,
    shipments_2_4: `SCAN Form`,
    shipments_3_1: `开始邮寄`,
    shipments_3_2: `预约取件`,
    shipments_3_2_1: `USPS`,
    shipments_3_2_2: `DHL Express`,
    shipments_3_2_3: `FedEx`,
    shipments_3_2_4: `UPS`,
    // Shipment My Shipment
    shipments_2_1_1: `店铺`,
    shipments_2_1_15: `运单类型`,
    shipments_2_1_2: `承运商`,
    shipments_2_1_3: `邮寄状态`,
    shipments_2_1_4_1: `邮寄ID，收件人，包裹追踪号...`,
    shipments_2_1_4_2: `查找`,
    shipments_2_1_5: `重置`,
    shipments_2_1_6_1: `排序:`,
    shipments_2_1_6_2: `最新优先`,
    shipments_2_1_6_3: `最早优先`,
    shipments_2_1_7: `批量打印`,
    shipments_2_1_8: `下载运单`,
    // shipments_2_1_9: `服务及运费`,
    shipments_2_1_10_pending: `处理中`,
    shipments_2_1_10_succeed: `成功`,
    shipments_2_1_10_failed: `失败`,
    shipments_2_1_11: `查看详情`,
    shipments_2_1_11_1: `等待退款`,
    shipments_2_1_11_2: `退款成功`,
    shipments_2_1_11_3: `退款失败`,
    shipments_2_1_12: `服务及运费不可用`,
    shipments_2_1_13_1: `天`,
    shipments_2_1_13_2: `天`,
    shipments_2_1_14: `追踪号码#`,
    // Shipment My Draft
    shipments_2_2_1: `承运商`,
    shipments_2_2_2_1: `草稿ID，收件人...`,
    // shipments_2_2_2_2: `查找`,
    shipments_2_2_3: `重置`,
    shipments_2_2_4_1: `排序:`,
    shipments_2_2_4_2: `最新优先`,
    shipments_2_2_4_3: `最早优先`,
    shipments_2_2_5: `草稿ID`,
    shipments_2_2_6: `收件人`,
    shipments_2_2_7: `服务及运费`,
    shipments_2_2_8: `上次编辑`,
    shipments_2_2_9: `查看运费`,
    shipments_2_2_9_1: `编辑草稿`,
    // Shipment My Pickups
    shipments_2_3_1: `状态`,
    shipments_2_3_2_1: `预约确认码`,
    shipments_2_3_3: `重置`,
    shipments_2_3_4_1: `排序:`,
    shipments_2_3_4_2: `最新取件日期优先`,
    shipments_2_3_4_3: `最早取件日期优先`,
    shipments_2_3_5: `取件日期`,
    shipments_2_3_6: `取件地点`,
    shipments_2_3_7: `数量`,
    shipments_2_3_8: `总重量`,
    shipments_2_3_8_1: `无数据`,
    shipments_2_3_9: `预约确认码`,
    shipments_2_3_10: `状态`,
    shipments_2_3_11: `完成`,
    shipments_2_3_12: `取消`,
    // Shipment SCAN Form
    shipments_2_4_1: `发件地址`,
    shipments_2_4_2: `重置`,
    shipments_2_4_3_1: `排序:`,
    shipments_2_4_3_2: `最新SCAN Form优先`,
    shipments_2_4_3_3: `最早SCAN Form优先`,
    shipments_2_4_4: '发件地址',
    shipments_2_4_5: '包裹数量',
    shipments_2_4_6: '邮寄日期',
    shipments_2_4_7: '创建时间',
    shipments_2_4_8: '打印SCAN Form',
    shipments_2_4_9: 'https://www.shipsaving.com/zhong-wen-blogs/why-you-need-scan-form',
    // ----------------------------------------------------------------------
    shipment_batch_label_download_1: `下载运单`,
    shipment_batch_label_download_2: `店铺`,
    shipment_batch_label_download_3: `运单尺寸`,
    shipment_batch_label_download_4: `日期`,
    shipment_batch_label_download_5: `确认下载`,
    shipment_batch_label_download_6: `其他`,
    shipment_batch_label_download_7: `起始日期`,
    shipment_batch_label_download_8: `结束日期`,
    // ----------------------------------------------------------------------
    shipment_viewDetails_0: `返回上一页`,
    shipment_viewDetails_1_1: `发件地址`,
    shipment_viewDetails_1_2: `包裹详情`,
    shipment_viewDetails_1_2_1: `包裹类型`,
    shipment_viewDetails_1_2_1_1: `我的包裹`,
    shipment_viewDetails_1_2_2: `尺寸`,
    shipment_viewDetails_1_2_3: `重量`,
    shipment_viewDetails_1_2_4: `Custom Print #1`,
    shipment_viewDetails_1_2_5: `Custom Print #2`,
    shipment_viewDetails_1_2_6: `保险`,
    shipment_viewDetails_1_3: `运单详情`,
    shipment_viewDetails_1_3_1: `运单尺寸`,
    shipment_viewDetails_1_3_1_1: `4x6”, 4x8” 或 8.5x11”`,
    shipment_viewDetails_1_3_1_2: `二维码`,
    shipment_viewDetails_2: `追踪邮寄信息`,
    shipment_viewDetails_2_1: `包裹投递`,
    shipment_viewDetails_2_2: `预约取件`,
    shipment_viewDetails_2_3: `追踪号码#`,
    shipment_viewDetails_2_4: `Master Tracking#`,
    shipment_viewDetails_3: `运费详情`,
    shipment_viewDetails_3_1: `邮寄运费`,
    shipment_viewDetails_3_1_1: `额外服务包含`,
    shipment_viewDetails_3_1_2: `预计交付时间`,
    shipment_viewDetails_3_1_2_1: `个工作日`,
    shipment_viewDetails_3_2: `总价`,
    shipment_viewDetails_4: `付款方式`,
    shipment_viewDetails_4_1: `支付类型`,
    shipment_viewDetails_4_1_1: `卡号后四位`,
    shipment_viewDetails_4_1_2: `账户余额`,
    shipment_viewDetails_4_2: `总支付`,
    shipment_viewDetails_4_3_1: `运单购买支持混合支付模式。当您的账户余额不足时，ShipSaving会从您的默认信用卡中扣除剩余金额。最后的记录可以在您的`,
    shipment_viewDetails_4_3_2: `交易历史页面`,
    shipment_viewDetails_4_3_3: `上查看。`,
    shipment_viewDetails_5: `付款历史记录`,
    shipment_viewDetails_10: `退货运单`,
    shipment_viewDetails_6: `重新打印运单`,
    shipment_viewDetails_7: `查看二维码`,
    shipment_viewDetails_8_1: `取消运单`,
    shipment_viewDetails_8_2: `等待退款`,
    shipment_viewDetails_8_3: `退款成功`,
    shipment_viewDetails_8_4: `退款失败`,
    shipment_viewDetails_8_5: `You cannot void this label or get a refund.`,
    shipment_viewDetails_9: `付款历史记录`,
    shipment_viewDetails_9_1: `服务及运费不可用`,
    shipment_viewDetails_9_2_1: `预计`,
    shipment_viewDetails_9_2_2: `天到达`,
    shipment_viewDetails_9_3: `无法预计`,
    shipment_viewDetails_9_4_1: `Adjusted your shipment`,
    shipment_viewDetails_9_4_2: `包裹退款`,
    shipment_viewDetails_9_5: `Note`,
    shipment_viewDetails_11: `复制运单`,
    // ----------------------------------------------------------------------
    shipment_scan_form_1: `SCAN Form`,
    shipment_scan_form_1_1: `了解更多`,
    shipment_scan_form_1_2: `所有需要邮寄的包裹，整合为一个条形码，让邮政工作人员可以一次性扫描并寄出。`,
    shipment_scan_form_2: `包裹信息`,
    shipment_scan_form_2_1: `无任何包裹可创建SCAN Form`,
    shipment_scan_form_2_2: `邮寄日期`,
    shipment_scan_form_2_3: `总包裹数量`,
    shipment_scan_form_2_4: `追踪号码#`,
    shipment_scan_form_3: `SCAN Form地址`,
    shipment_scan_form_3_1: `不使用已设置的默认发件地址`,
    shipment_scan_form_3_2: `使用新的发件地址`,
    shipment_scan_form_3_3_1: `名字`,
    shipment_scan_form_3_3_2: `姓名`,
    shipment_scan_form_3_3_3: `电话`,
    shipment_scan_form_3_3_4: `邮箱（选填）`,
    shipment_scan_form_4: `创建SCAN Form`,
    // ----------------------------------------------------------------------
    orders_1: `订单`,
    orders_2_1: `店铺`,
    orders_2_2: `承运商`,
    orders_17_1: `日期`,
    orders_3: `订单ID，收件人...`,
    // orders_3_1: `查找`,
    orders_4: `重置`,
    orders_5_1: `排序:`,
    orders_5_2: `最新优先`,
    orders_5_3: `最早优先`,
    orders_6_1: `订单信息`,
    orders_6_2: `收件人`,
    orders_6_3: `商品`,
    orders_6_4: `包裹信息`,
    orders_6_4_1_1: `包含`,
    orders_6_4_1_2: `个商品`,
    orders_6_4_2: `查看详情`,
    orders_6_4_3_1: ``,
    orders_6_4_3_2: `个合并订单`,
    orders_6_4_4: `包裹列表`,
    orders_6_4_5: `尺寸`,
    orders_6_4_6: `重量`,
    orders_6_4_7: `取消`,
    orders_6_4_8: `保存`,
    orders_6_4_9: `更新`,
    orders_6_4_10: `添加包裹信息`,
    orders_6_4_11: `个包裹信息`,
    orders_6_4_12: `包裹信息`,
    orders_6_4_13: `尺寸: `,
    orders_6_4_14: `重量: `,
    orders_6_5: `承运商服务`,
    orders_6_5_1: `费率不可用`,
    orders_6_5_2: `需添加包裹信息`,
    orders_6_5_3: `费率需刷新`,
    orders_6_5_4: `费率已过期`,
    orders_6_5_5: `个运单`,
    orders_6_5_6: `查看详情`,
    orders_6_5_7: `天到达`,
    orders_6_5_8: `运单信息`,
    orders_6_5_8_1: `作废运单`,
    orders_6_5_9: `邮寄服务`,
    orders_6_5_10: `需添加详细信息`,
    orders_6_6: `无额外信息`,
    orders_7_1: `总价:`,
    orders_7_2: `自定义包装`,
    orders_7_3: `包裹信息不可用`,
    orders_7_4: `服务及运费不可用`,
    orders_8_1: `查看运费`,
    orders_8_2: `查看详情`,
    orders_9: `添加您的店铺`,
    orders_10: `合并订单`,
    orders_11: `Scan`,
    orders_12: `添加订单`,
    orders_13_1: `全部`,
    orders_14_1: `待发货`,
    orders_15_1: `已发货`,
    orders_16_1: `已取消`,
    orders_18: `上传`,
    orders_18_1: `上传订单`,
    orders_18_2: `下载模板`,
    orders_18_4: `在此上传您的表格或`,
    orders_18_5: `从电脑中选择`,
    orders_18_6: `允许 *.xls, *.xlsx, *.xlsm, *.xlsb 格式`,
    orders_18_7: `取消`,
    orders_18_8: `上传`,
    orders_18_9: `下载`,
    orders_download_succeed: `成功`,
    orders_download_failed: `失败`,
    orders_19: `导出`,
    orders_19_1: `导出订单`,
    orders_19_2: `起始日期`,
    orders_19_3: `结束日期`,
    orders_19_4: `店铺`,
    orders_19_5: `状态`,
    orders_19_6: `Request Export Orders`,
    input_schema_order_1: `请输入整数`,
    input_schema_order_1_1: `请注意，输入内容不得超过10个字符`,
    orders_20: `筛选`,
    orders_21: `更多筛选`,
    orders_22: `订单`,
    orders_23: `订单号码`,
    orders_24: `产品名称`,
    orders_25: `产品数量`,
    orders_26: `发件地`,
    orders_27: `邮寄区域`,
    orders_28: `已合并订单`,
    orders_29: `待处理订单`,
    orders_30: `订单信息`,
    orders_31: `追踪号码`,
    orders_32: `收件人`,
    orders_33: `城市`,
    orders_34: `邮编`,
    orders_35: `查找`,
    orders_36: `清空`,
    orders_37: `查看订单`,
    orders_38: `系统信息`,
    orders_39_1: `更改状态`,
    orders_39_2: `打印`,
    orders_39_2_1: `运单`,
    orders_39_2_2: `二维码`,
    orders_39_2_3: `单据`,
    orders_39_3: `标记为待处理`,
    orders_39_3_1: `待处理`,
    orders_39_3_2: `取消待处理`,
    orders_39_4: `编辑订单`,
    orders_39_5: `删除订单`,
    orders_39_6: `创建退货运单`,
    orders_39_7: `作废运单`,
    // ----------------------------------------------------------------------
    order_viewDetails_0: `返回上一页`,
    order_viewDetails_1: `订单信息`,
    order_viewDetails_1_1: `店铺`,
    order_viewDetails_1_2: `ID`,
    order_viewDetails_1_3: `订单总价`,
    order_viewDetails_2: `订单商品`,
    order_viewDetails_3: `订单说明`,
    // ----------------------------------------------------------------------
    items_1: `我的商品`,
    items_2: `添加商品`,
    items_3: `商品名称，SKU`,
    items_4_1: `最新优先`,
    items_4_2: `最早优先`,
    items_5_1: `商品信息`,
    items_5_2: `店铺`,
    items_5_3: `SKU`,
    items_5_4: `价格`,
    items_5_5: `尺寸 (长 x 宽 x 高)`,
    items_5_6: `重量`,
    items_6: `删除`,
    items_7_1: `编辑`,
    items_7_2: `删除`,
    // ----------------------------------------------------------------------
    settings_transactions_0: `返回上一页`,
    settings_transactions_1: `付款历史记录`,
    settings_transactions_2: `付款类型`,
    settings_transactions_3: `付款年份`,
    settings_transactions_11: `包裹追踪号，邮寄ID`,
    settings_transactions_4: `重置`,
    settings_transactions_5_0: `导出`,
    settings_transactions_5: `导出表格`,
    settings_transactions_5_1: `Request Export Transaction History`,
    settings_transactions_6_1: `排序:`,
    settings_transactions_6_2: `最新优先`,
    settings_transactions_6_3: `最早优先`,
    settings_transactions_7_1: `付款方式`,
    settings_transactions_7_1_1: `卡号后四位`,
    settings_transactions_7_1_2: `银行账号后四位`,
    settings_transactions_7_1_5: `账号余额`,
    settings_transactions_7_2: `金额`,
    settings_transactions_7_3: `账号余额`,
    settings_transactions_7_4: `付款类型`,
    settings_transactions_7_5: `备注`,
    settings_transactions_7_6: `开始日期`,
    settings_transactions_7_7: `付款号`,
    settings_transactions_7_8: `邮寄ID`,
    settings_transactions_8_1: `查看发票`,
    settings_transactions_8_2: `查看详情`,
    settings_transactions_9: `* 如果您需要我们提供发票，请联系我们的财务团队。`,
    settings_transactions_10_1: `如果您需要发票，可以发送电子邮件至此邮箱：`,
    settings_transactions_10_2: `，并在邮件正文中注明以下信息：`,
    settings_transactions_10_3: `注册时的电子邮件`,
    settings_transactions_10_4: `发票名称或公司名字`,
    settings_transactions_10_5: `地址`,
    settings_transactions_10_6: `所需的时间段`,
    // ----------------------------------------------------------------------
    settings_billing_history_1: `返回上一页`,
    settings_billing_history_2: `历史账单`,
    settings_billing_history_3_1: `计划`,
    settings_billing_history_3_1_1: `卡号后四位`,
    settings_billing_history_3_1_2: `Bank Account in`,
    settings_billing_history_3_2: `状态`,
    settings_billing_history_3_3: `用量`,
    settings_billing_history_3_4: `金额`,
    settings_billing_history_3_5: `付款方式`,
    settings_billing_history_3_6: `开始日期 (PST/PDT)`,
    settings_billing_history_3_7: `结束日期 (PST/PDT)`,
    settings_billing_history_4: `查看发票`,
    // ----------------------------------------------------------------------
    settings_rebates_0: `返回上一页`,
    settings_rebates_1: `Rebates`,
    settings_rebates_2: `Date create`,
    settings_rebates_3: `Due date`,
    settings_rebates_4: `Tracking number`,
    settings_rebates_5: `重置`,
    settings_rebates_6_1: `排序:`,
    settings_rebates_6_2: `最新优先`,
    settings_rebates_6_3: `最早优先`,
    settings_rebates_7_1: `金额`,
    settings_rebates_7_2: `Rebates Balance`,
    settings_rebates_7_3: `Tracking number`,
    settings_rebates_7_4: `日期`,
    settings_rebates_8: `Redeem`,
    // ----------------------------------------------------------------------
    shipments_pickup_schedule_0: `返回上一页`,
    shipments_pickup_schedule_1: `预约取件`,
    shipments_pickup_schedule_2: `了解更多`,
    shipments_pickup_schedule_3_1: `您可以免费预约下一个投递日的USPS取件，如果USPS错过了您的取件时间，您可以直接去`,
    shipments_pickup_schedule_3_2: `当地的邮局`,
    shipments_pickup_schedule_3_3: `进行投递。`,
    shipments_pickup_schedule_4: `包裹信息`,
    shipments_pickup_schedule_4_1: `没有可供取件的包裹。`,
    shipments_pickup_schedule_5: `取件日期`,
    shipments_pickup_schedule_5_1: `下一个投递日`,
    shipments_pickup_schedule_6: `取件总数量`,
    shipments_pickup_schedule_7: `总重量预估`,
    shipments_pickup_schedule_8: `取件位置`,
    shipments_pickup_schedule_9: `添加地址`,
    shipments_pickup_schedule_10: `默认`,
    shipments_pickup_schedule_11: '包裹位置',
    shipments_pickup_schedule_12: `附加说明`,
    shipments_pickup_schedule_13: `确认取件`,
    shipments_pickup_schedule_14_1: `名字`,
    shipments_pickup_schedule_14_2: `姓名`,
    shipments_pickup_schedule_14_3: `电话`,
    shipments_pickup_schedule_14_4: `邮箱（选填）`,
    // ----------------------------------------------------------------------
    // // Reports
    // reports_0: `数据报告`,
    // reports_1: `数据报告`,
    // reports_11: `个订单`,
    // reports_1_1: `待发货`,
    // reports_1_2: `已发货`,
    // reports_1_3: `已取消`,
    // reports_1_4: `待处理`,
    // reports_2: `订单数据`,
    // reports_22: `账户余额`,
    // reports_2_1: `充值`,
    // reports_3: `运费花费`,
    // reports_3_1: `查看详情`,
    // reports_4: `总退款`,
    // reports_5: `运单数据`,
    // reports_5_1: `个运单`,
    // reports_6: `承运商服务`,
    // reports_7_1: '一月',
    // reports_7_2: '二月',
    // reports_7_3: '三月',
    // reports_7_4: '四月',
    // reports_7_5: '五月',
    // reports_7_6: '六月',
    // reports_7_7: '七月',
    // reports_7_8: '八月',
    // reports_7_9: '九月',
    // reports_7_10: '十月',
    // reports_7_11: '十一月',
    // reports_7_12: '十二月',
    // reports_8: `总计运单`,
    // reports_9: `自定义日期`,
    // reports_9_1: `开始日期`,
    // reports_9_2: `结束日期`,
    // reports_9_3: `取消`,
    // reports_9_4: `确认`,
    // ----------------------------------------------------------------------
    // Partner Tool
    partner_tool_1: `Partner Tool`,
    // ----------------------------------------------------------------------
    partner_tool_sales_report_1: `Sales Report`,
    partner_tool_sales_report_1_1: `Total Shipments`,
    partner_tool_sales_report_1_2: `Total Volume`,
    // ----------------------------------------------------------------------
    partner_tool_client_1: `Client`,
    partner_tool_client_1_1: `Started Shipping`,
    partner_tool_client_1_2: `Name, Email`,
    partner_tool_client_1_3: `Newest Signup Date`,
    partner_tool_client_1_4: `Oldest Signup Date`,
    partner_tool_client_1_5: `Newest Purchase Date`,
    partner_tool_client_1_6: `Oldest Purchase Date`,
    partner_tool_client_2_1: `Name`,
    partner_tool_client_2_2: `Signup Date`,
    partner_tool_client_2_3: `Started Shipping`,
    partner_tool_client_2_4: `Total Volume`,
    partner_tool_client_2_5: `Total Shipments`,
    partner_tool_client_2_6: `Last Purchase Date`,
    partner_tool_client_2_7: `View Details`,
    // ----------------------------------------------------------------------
    settings_account_1: `账户设置`,
    // Account General
    // settings_account_1_1: `常用设置`,
    settings_account_1_0: `Account ID`,
    settings_account_1_0_1: `Account Status`,
    settings_account_1_0_2: `Minimum Balance`,
    settings_account_1_0_3: `Label Fee`,

    settings_account_1_1_1: `我的头像`,
    settings_account_1_1_1_1: `上传新头像`,
    settings_account_1_1_1_2: `允许 *.jpeg, *.jpg, *.png, *.gif 格式，尺寸不得超过1.0 MB`,
    settings_account_1_1_1_3: `文件超出允许的最大尺寸限制：1MB。`,
    settings_account_1_1_1_4: `上传成功!`,
    settings_account_1_1_1_5: `更新成功!`,
    settings_account_1_1_2: `账户信息`,
    settings_account_1_1_2_1: `姓名`,
    settings_account_1_1_2_2: `仓库`,
    settings_account_1_1_2_3: `邮箱`,
    settings_account_1_1_2_4: `Time Zone`,
    settings_account_1_1_2_5: `保存`,
    settings_account_1_1_2_6: `我的头像`,
    settings_account_1_1_2_7: `角色:`,
    settings_account_1_1_2_8: `名:`,
    settings_account_1_1_2_9: `姓:`,
    // settings_account_1_1_2_5: `公司（选填）`,
    settings_account_1_1_3: `修改密码`,
    settings_account_1_1_3_1: `旧密码`,
    settings_account_1_1_3_2: `新密码`,
    settings_account_1_1_3_3: `再次输入新密码`,
    settings_account_1_1_3_4: `保存`,
    // Company Page
    settings_account_2: `COMPANY`,
    settings_account_2_1: `Company Information`,
    settings_account_2_1_1: `Company Name`,
    settings_account_2_1_2: `Phone`,
    settings_account_2_1_3: `Email`,
    settings_account_2_1_4: `Street 1`,
    settings_account_2_1_5: `Street 2 (optional)`,
    settings_account_2_1_6: `City`,
    settings_account_2_1_7: `Zip`,
    settings_account_2_1_8: `State`,
    settings_account_2_1_9: `Country`,
    settings_account_2_2: `API Manager`,
    settings_account_2_2_1: `Download API Document`,
    settings_account_2_2_2: `Renew API Token`,
    settings_account_2_3: `Automation Setting`,
    settings_account_2_3_1: `Assign Warehouse Policy`,
    settings_account_2_3_2: `Outbound Policy`,
    settings_account_2_3_3: `Upload Tracking Policy`,
    settings_account_2_3_4: `Insurance Threshold`,
    settings_account_2_3_5: `Signature Threshold`,
    settings_account_2_3_6: `Default Customs Contents`,
    settings_account_2_3_7: `Default Customs if Undeliverable`,
    settings_account_2_3_8: `Remember Insurance Value`,
    settings_account_2_3_9: `Print Store Name on Label`,
    settings_account_2_3_10: `Print Title on Label`,
    settings_account_2_3_11: `Print Bin Info on Label`,
    settings_account_2_3_12: `Hold Orders with Address Problems`,
    settings_account_2_3_13: `Hold Orders with Product Bin not Assigned`,
    settings_account_2_3_14: `Hold Orders with Item Product not Matched`,
    settings_account_2_3_15: `Remember Signature Value`,
    settings_account_2_3_16: `Print Order Number on Label`,
    settings_account_2_3_17: `Print SKU on Label`,
    settings_account_2_3_18: `Automatically Correct Address`,


    // old
    settings_account_1_1_4: `MANAGE COMPANY`,
    settings_account_1_1_4_1: `公司名字`,
    settings_account_1_1_4_2: `电话`,
    settings_account_1_1_4_3: `邮件`,
    settings_account_1_1_4_10: `保存`,
    // Account Billing
    settings_account_1_2: `付款设置`,
    settings_account_1_2_1: `账户余额`,
    settings_account_1_2_1_1: `余额提现`,
    settings_account_1_2_1_2: `Rebate`,
    settings_account_1_2_1_3: `充值`,
    settings_account_1_2_2: `付款方式`,
    settings_account_1_2_2_1_1: `添加付款方式`,
    settings_account_1_2_2_1_2: `添加付款方式`,
    settings_account_1_2_2_10: `您还没有添加任何付款方式。\n请添加付款方式完成购买。最多只允许添加2个付款方式。`,
    settings_account_1_2_2_2: `编辑此付款方式`,
    settings_account_1_2_2_3: `删除此付款方式`,
    settings_account_1_2_2_4: `卡号后四位`,
    settings_account_1_2_2_5: `有效期`,
    settings_account_1_2_2_6: `验证我的付款方式`,
    settings_account_1_2_2_7: `验证过期/失败`,
    settings_account_1_2_2_8: `默认`,
    settings_account_1_2_2_8_1: `A default payment method is the payment method that will be used for all created payments going forward.`,
    settings_account_1_2_2_9_0: `未验证`,
    settings_account_1_2_2_9: `已验证`,
    settings_account_1_2_2_9_1: `The verified credit card can be used to buy labels, add funds to balance, and pay your monthly plan.\n\nThe verified ACH account can be used to buy labels and add funds to balance.`,
    settings_account_1_2_2_11: `Subscribe`,
    settings_account_1_2_2_11_1: `This card is used to pay for your monthly plan.`,
    settings_account_1_2_3: `付款历史记录`,
    settings_account_1_2_3_1: `查看全部`,
    settings_account_1_2_3_2: `您目前没有任何交易记录。`,
    // Account Plan
    // settings_account_1_3: `计划设置`,
    settings_account_1_3_1: `我的计划`,
    settings_account_1_3_2: `账单信息`,
    settings_account_1_3_3: `关闭账户`,
    // settings_account_1_3_2_1_1: `免费`,
    // settings_account_1_3_2_1_2: ` days left in trial`,
    // settings_account_1_3_2_1_3: ` hours left in trial`,
    // settings_account_1_3_2_1_4: ` minutes left in trial`,
    // settings_account_1_3_2_1_5: `Last minute left in trial`,
    // settings_account_1_3_2_2_1: `可用单量：`,
    // settings_account_1_3_2_2_2: `无上限`,
    // settings_account_1_3_3_1_1: `Starter计划无需月费，是帮助您削减成本的完美选择：`,
    // settings_account_1_3_3_1_2_1: `选择Standard计划，您将获得Starter计划的所有功能，`,
    // settings_account_1_3_3_1_2_2: `外加`,
    // settings_account_1_3_3_1: `随时随地购买&打印折扣运单😌`,
    // settings_account_1_3_3_2_1: `享受USPS, UPS, DHL Express官方折扣费率，`,
    // settings_account_1_3_3_2_2: `节省运费高达89% off`,
    // settings_account_1_3_3_3_1: `使用`,
    // settings_account_1_3_3_3_2: `智能地址自动填充`,
    // settings_account_1_3_3_3_3: `功能，避免手动录入错误`,
    // settings_account_1_3_3_4: `一键轻松连接您的店铺平台 🛒 , 实现订单自动同步`,
    // settings_account_1_3_3_5_1: `支持添加您自己的承运商账号，`,
    // settings_account_1_3_3_5_2: `实时对比运费`,
    // settings_account_1_3_3_5_3: ``,
    // settings_account_1_3_3_6: `为国际包裹在线填写报关信息，省时省力`,
    // settings_account_1_3_3_7: `✍️ 提供多种客服方式：帮助中心，邮件，在线聊天`,
    // settings_account_1_3_3_8_1: `📑 创建和打印`,
    // settings_account_1_3_3_8_2: `USPS SCAN Form`,
    // settings_account_1_3_3_9_1: `根据常用的订单商品和包装组合，创建`,
    // settings_account_1_3_3_9_2: `运输规则`,
    // settings_account_1_3_3_9_3: ``,
    // settings_account_1_3_3_10_1: `高级订单管理功能`,
    // settings_account_1_3_3_10_2: `: 合并订单，更改订单状态等等`,
    // settings_account_1_3_3_11_1: `支持为您的店铺手动创建单独运单或`,
    // settings_account_1_3_3_11_2: `通过Excel批量导入订单`,
    // settings_account_1_3_3_11_3: ``,
    // settings_account_1_3_3_12_1: ``,
    // settings_account_1_3_3_12_2: `一票多件功能`,
    // settings_account_1_3_3_12_3: `：多个包裹需要运送到同一收货地址`,
    // settings_account_1_3_3_13_1: `邀请子用户加入您的团队并编辑`,
    // settings_account_1_3_3_13_2: `操作权限 👀`,
    // settings_account_1_3_3_14_1: ``,
    // settings_account_1_3_3_14_2: `Standard版采用预充值模式，支持使用`,
    // // settings_account_1_3_3_15_1: `More payment methods`,
    // settings_account_1_3_3_15_2: `银行卡，Zelle`,
    // settings_account_1_3_3_16_1: `可在📊`,
    // settings_account_1_3_3_16_2: `数据报告`,
    // settings_account_1_3_3_16_3: `页面查看您的店铺以及邮寄花费表现的全面分析`,
    // settings_account_1_3_3_17_1: `适合个人及初创公司邮寄需求`,
    // settings_account_1_3_3_17_2: `我通常每个月邮寄`,
    // settings_account_1_3_3_17_3: `个包裹`,
    // settings_account_1_3_3_18_1: `Free`,
    // settings_account_1_3_3_18_2: `/mo`,
    // settings_account_1_3_3_19_1: `Downgrade`,
    // settings_account_1_3_3_19_2: `Subscription`,
    // settings_account_1_3_3_19_3: `选择计划`,
    // settings_account_1_3_3_19_4: `更改计划`,
    // settings_account_1_3_3_20: `了解更多`,
    // settings_account_1_3_3_21: `升级成为ShipSaving Pro`,
    // settings_account_1_3_3_22: `简化库存管理流程，加强物流，快速推进您的业务增长。`,
    // settings_account_1_3_3_23: `了解更多`,
    // settings_account_1_3_3_23_1: `升级成为ShipSaving Pro`,
    // settings_account_1_3_3_23_2: `成为Pro版本的用户，我们将助您轻松管理庞大的订单和库存，优化您的物流运营，保障您的业务快速稳健地增长。`,
    // settings_account_1_3_3_23_3: `强大的仓储管理系统 (WMS)`,
    // settings_account_1_3_3_23_4: `与电商平台集成（例如，亚马逊）`,
    // settings_account_1_3_3_23_5: `多渠道物流`,
    // settings_account_1_3_3_23_6: `专属一对一客户服务`,
    // settings_account_1_3_3_23_7: `灵活的API接入`,
    // settings_account_1_3_3_23_8: `关闭`,
    // settings_account_1_3_3_23_9: `立即联系我们`,
    // settings_account_1_3_3_24_1: `连接`,
    // settings_account_1_3_3_24_2: `自己的承运商账号`,
    // settings_account_1_3_3_24_3: `并使用您已协商的费率`,
    // settings_account_1_3_5_1: `账单信息`,
    // settings_account_1_3_5_1_1: `查看历史账单`,
    // settings_account_1_3_5_1_2: `您目前没有任何账单记录`,
    // settings_account_1_3_5_1_3: `计划续订日期`,
    // settings_account_1_3_5_1_4: `续订收费金额`,
    // settings_account_1_3_5_2: `付款方式`,
    // settings_account_1_3_5_2_1: `默认`,
    // Account User
    settings_account_1_4: `用户设置`,
    settings_account_1_4_1_1: `管理用户`,
    settings_account_1_4_1_2: `添加用户`,
    settings_account_1_4_2_1: `名字`,
    settings_account_1_4_2_2: `邮箱`,
    settings_account_1_4_2_2_1: `重发邀请`,
    settings_account_1_4_2_5: `店铺`,
    settings_account_1_4_2_5_1: `全部店铺`,
    settings_account_1_4_2_6: `状态`,
    settings_account_1_4_2_6_INACTIVATE: `未激活`,
    settings_account_1_4_2_6_ACTIVATE: `已激活`,
    // Account Notifications
    settings_account_1_5: `Notifications`,
    settings_account_1_5_1: `ACTIVITY`,
    // ----------------------------------------------------------------------
    settings_Integration_1: `渠道对接`,
    // Account Integration Stores
    settings_Integration_2_1: `店铺`,
    settings_Integration_3: `管理店铺`,
    settings_Integration_4: `添加您的店铺`,
    settings_Integration_5: `啊哦，您还没有添加店铺...`,
    settings_Integration_6: `编辑`,
    settings_Integration_7: `重新授权`,
    settings_Integration_8: `删除`,
    settings_Integration_9_1: `店铺昵称`,
    settings_Integration_9_2: `店铺`,
    settings_Integration_9_3: `发件地址`,
    settings_Integration_9_4: `Carrier Accounts`,
    settings_Integration_9_5: `有效期`,
    settings_Integration_10: `授权有效期`,
    settings_Integration_10_1: `长期`,
    settings_Integration_10_2: `Expired`,
    settings_Integration_11: `Upgrade to Pro`,
    settings_Integration_12: `Delete Store`,
    // Account Integration Carriers
    settings_Integration_2_2: `承运商`,
    settings_Integration_2_3: `管理承运商账号`,
    settings_Integration_2_2_3: `承运商`,
    settings_Integration_2_4_1: `现在创建一个`,
    settings_Integration_2_4_2: `UPS折扣账号`,
    settings_Integration_2_4_3: `！！`,
    settings_Integration_2_5: `添加您的承运商账号`,
    // Account Integration API
    settings_Integration_2_17: `API`,
    // - ShipSaving Accounts
    settings_Integration_2_6: `ShipSaving账号`,
    settings_Integration_2_6_1: `您的账号`,
    settings_Integration_2_7_1: `折扣高达89% off`,
    settings_Integration_2_7_2: `轻松预约上门取件`,
    settings_Integration_2_7_3: `运单可自动生成二维码`,
    settings_Integration_2_7_4: `了解更多`,
    settings_Integration_2_7_5: `USPS服务类别`,
    settings_Integration_2_8: `激活账号`,
    settings_Integration_2_9: `未激活账号`,
    settings_Integration_2_10: `编辑`,
    settings_Integration_2_11_1: `折扣高达76% off`,
    settings_Integration_2_11_2: `轻松预约上门取件`,
    settings_Integration_2_11_3: `自动填充海关表格`,
    settings_Integration_2_11_4: `DHL Express服务类别`,
    // settings_Integration_2_11: `激活账号`,
    // settings_Integration_2_12: `编辑`,
    settings_Integration_2_12: `+ 添加支付账号`,
    // - Your Accounts
    settings_Integration_2_13: `您的账号`,
    settings_Integration_2_14_1: `折扣高达62% off`,
    settings_Integration_2_14_2: `减免Ground包裹常见附加费`,
    settings_Integration_2_14_3: `支持包裹投递到`,
    settings_Integration_2_14_4: `Access to your own pre-negotiated rates`,
    settings_Integration_2_14_5: `85,000+服务站`,
    settings_Integration_2_14_6: `UPS服务类别`,
    // settings_Integration_2_15: `激活账号`,
    settings_Integration_2_16: `编辑`,
    settings_Integration_2_18: `Bill Account`,
    settings_Integration_2_19: `删除`,
    // ShipSaving Accounts
    settings_Integration_2_20_1: `使用账户自带的运费折扣`,
    settings_Integration_2_20_2: `多家承运商运费对比`,
    settings_Integration_2_20_3: `UPS直接为您提供账单`,
    // FEDEX DEFAULT
    settings_Integration_2_21_1: `获得美国境内折扣运费`,
    settings_Integration_2_21_2: `免除住宅和燃油附加费`,
    settings_Integration_2_21_3: `无运输数量限制`,
    // ----------------------------------------------------------------------
    settings_configuration_1: `邮寄设置`,
    settings_configuration_2_1: `我的地址`,
    settings_configuration_2_1_1: `管理地址`,
    settings_configuration_2_1_2: `添加发件地址`,
    settings_configuration_2_1_3: `您还没有添加寄件地址。`,
    settings_configuration_2_1_4: `默认`,
    settings_configuration_2_1_5: `编辑`,
    settings_configuration_2_1_6: `删除`,
    settings_configuration_2_2: `我的包裹`,
    settings_configuration_2_2_1: `管理我的包裹`,
    settings_configuration_2_2_2: `添加包裹`,
    settings_configuration_2_2_3: `包裹名字`,
    settings_configuration_2_2_4: `尺寸（长 x 高 x 宽）`,
    settings_configuration_2_2_5: `包裹类型`,
    settings_configuration_2_2_5_1: `自定义包裹`,
    settings_configuration_2_2_6: `编辑`,
    settings_configuration_2_2_7: `删除`,
    settings_configuration_1_1: `寄件地址`,
    settings_configuration_1_1_1: `管理寄件地址`,
    settings_configuration_1_1_2: `+ 添加寄件地址`,
    settings_configuration_1_1_3: `地址类型`,
    settings_configuration_1_1_4: `姓名，电话`,
    settings_configuration_1_1_5: `最新日期`,
    settings_configuration_1_1_6: `最早日期`,
    settings_configuration_1_1_7: `姓名`,
    settings_configuration_1_1_8: `联系方式`,
    settings_configuration_1_1_9: `地址`,
    settings_configuration_1_1_10: `住宅地址`,
    settings_configuration_1_1_11: `编辑`,
    settings_configuration_1_1_12: `删除`,
    settings_configuration_1_2: `收件地址`,
    settings_configuration_1_2_1: `管理收件地址`,
    settings_configuration_1_2_2: `+ 添加收件地址`,
    settings_configuration_1_3: `邮寄规则`,
    settings_configuration_1_3_1: `管理邮寄规则`,
    settings_configuration_1_3_2: `添加邮寄规则`,
    settings_configuration_1_3_3: `商品信息`,
    settings_configuration_1_3_3_1: `包含`,
    settings_configuration_1_3_3_2: `个商品`,
    settings_configuration_1_3_3_3: `查看详情`,
    settings_configuration_1_3_4: `包裹类型`,
    settings_configuration_1_3_4_1: `自定义包裹`,
    settings_configuration_1_3_5: `尺寸 (长 x 宽 x 高)`,
    settings_configuration_1_3_6: `重量`,
    settings_configuration_1_3_7: `编辑`,
    settings_configuration_1_3_8: `删除`,
    settings_configuration_1_4: `自动化`,
    settings_configuration_1_4_1: `打印设置`,
    settings_configuration_1_4_1_1: `默认运单尺寸`,
    settings_configuration_1_4_1_2: `默认运单打印方式（用于USPS）`,
    settings_configuration_1_4_2: `包裹邮寄设置`,
    settings_configuration_1_4_2_1: `默认海关物品类型`,
    settings_configuration_1_4_2_2: `默认如海关物品无法送达`,
    settings_configuration_1_4_2_3: `重量单位`,
    settings_configuration_1_4_3: `其他选项`,
    settings_configuration_1_4_3_1: `有地址问题的订单标记为待处理`,
    settings_configuration_1_5: `保存更改`,
    // ----------------------------------------------------------------------
    notifications_1: `通知`,
    notifications_2: `全部标记已读`,
    notifications_3: `查看全部`,
    notifications_4: `您还没有消息通知`,
    notifications_5_1: `系统通知`,
    notifications_5_2: `承运商通知`,
    notifications_5_3: `消息`,
    // ----------------------------------------------------------------------
    payment_complete_1: `Payment Completed`,
    payment_complete_2: `Your add funds request has been successfully and securely processed. The amount has been added to your ShipSaving account balance.`,
    payment_complete_3_1: `Check Transaction History`,
    payment_complete_3_2: `Go to Dashboard`,
    // ----------------------------------------------------------------------
    dialog_auth_send_email_0: `验证邮件已发送`,
    dialog_auth_send_email_1: `请检查您的电子邮件并按照指示激活您的账户。如果您没有收到，可以重新发送。`,
    dialog_auth_send_email_2: `好`,
    dialog_auth_send_email_3: `没有收到？请查看您的垃圾信箱或`,
    dialog_auth_send_email_4: `重新发送电子邮件`,
    // ----------------------------------------------------------------------
    dialog_auth_reset_confirmed_1: `恭喜！您的账号已经激活啦！`,
    dialog_auth_reset_confirmed_2: `Thank you for verifying your email address. Your registration is now complete, and you can enjoy all the features of ShipSaving.`,
    dialog_auth_reset_confirmed_3: `继续访问ShipSaving`,
    // ----------------------------------------------------------------------
    dialog_auth_reset_expired_1: `电子邮件的验证链接已经过期`,
    dialog_auth_reset_expired_2: `We're sorry, but the verification link you used has expired. Please click the button below to resend a new verification link to your email.`,
    dialog_auth_reset_expired_3: `重新发送验证邮件`,
    // ----------------------------------------------------------------------
    dialog_auth_locked_account_1: `账号保护`,
    dialog_auth_locked_account_2: `由于我们检测到您的账号有不寻常的活动，已将其暂时锁定。请联系客服以获得帮助。`,
    dialog_auth_locked_account_3: `联系我们`,
    // ----------------------------------------------------------------------
    dialog_buy_label_locked_1: `Unable to Buy Labels`,
    dialog_buy_label_locked_2_1: `Your ShipSaving account is temporarily unable to buy labels due to the unsuccessful payment of your monthly subscription fee at `,
    dialog_buy_label_locked_2_2: `. Please go to your `,
    dialog_buy_label_locked_2_3: `Billing page`,
    dialog_buy_label_locked_2_4: ` and update your payment method.`,
    dialog_buy_label_locked_3_1: `Your ShipSaving account is temporarily unable to buy labels due to the unsuccessful payment of your adjustment fee. Please `,
    dialog_buy_label_locked_3_2: `contact us`,
    dialog_buy_label_locked_3_3: ` to resolve. `,
    dialog_buy_label_locked_4_1: `Please `,
    dialog_buy_label_locked_4_2: `contact us`,
    dialog_buy_label_locked_4_3: ` to resolve. `,
    dialog_buy_label_locked_5: `OK`,
    // ----------------------------------------------------------------------
    dialog_first_time_tutorial_1_1: `欢迎来到ShipSaving`,
    dialog_first_time_tutorial_2_1: `当然，您也可以戴着口罩在邮局排长队等待寄包裹，或者通过ShipSaving免费易操作的系统在家轻松寄件，`,
    dialog_first_time_tutorial_2_2: ` 完全免费。`,
    dialog_first_time_tutorial_3_1: `更简单省钱的邮寄方式`,
    dialog_first_time_tutorial_4_1: `通过ShipSaving邮寄包裹只需简单两步，即可获得USPS, UPS和DHL Express折扣运费费率`,
    dialog_first_time_tutorial_4_2: `低至2折`,
    dialog_first_time_tutorial_4_3: `，完全免费。`,
    dialog_first_time_tutorial_5_1: `支持多种运单打印形式`,
    dialog_first_time_tutorial_6_1: `运单购买成功后立即打印。您也可以选择生成`,
    dialog_first_time_tutorial_6_2: `二维码`,
    dialog_first_time_tutorial_6_3: `，USPS工作人员会通过扫码直接帮您在邮局免费打印运单。`,
    dialog_first_time_tutorial_7: `下一步`,
    dialog_first_time_tutorial_8: `上一步`,
    dialog_first_time_tutorial_9: `略过`,
    dialog_first_time_tutorial_10: `完成`,
    // ----------------------------------------------------------------------
    dialog_standard_tutorial_1_1: `立即开始免费试用`,
    dialog_standard_tutorial_1_2: `开始`,
    dialog_standard_tutorial_1_3: `欢迎使用ShipSaving`,
    dialog_standard_tutorial_1_4: `不论您是`,
    dialog_standard_tutorial_1_5: `个人`,
    dialog_standard_tutorial_1_6: `或者`,
    dialog_standard_tutorial_1_7: `企业`,
    dialog_standard_tutorial_1_8: `邮寄，我们都可以为您提供最佳运输解决方案。`,
    dialog_standard_tutorial_2_1: `设置您的发件地址`,
    dialog_standard_tutorial_2_2: `在ShipSaving中添加您的默认发件地址，即可开始创建运单并获得运费折扣。`,
    dialog_standard_tutorial_2_3: `发件地址`,
    dialog_standard_tutorial_2_4: `跳过`,
    dialog_standard_tutorial_2_5: `下一步`,
    dialog_standard_tutorial_2_6: `同步管理所有订单`,
    dialog_standard_tutorial_2_7: `不需切换销售平台，支持`,
    dialog_standard_tutorial_2_8: `同步所有渠道订单`,
    dialog_standard_tutorial_2_9: `，快速生成运单。`,
    dialog_standard_tutorial_3_1: `设置您的邮寄选项`,
    dialog_standard_tutorial_3_2: `请告诉我们更多有关您邮寄偏好的信息，以便我们更好地简化您的运输流程。`,
    dialog_standard_tutorial_3_3: `邮寄设置`,
    dialog_standard_tutorial_3_4: `默认运单尺寸`,
    dialog_standard_tutorial_3_5: `默认重量单位`,
    dialog_standard_tutorial_3_6: `包裹设置`,
    dialog_standard_tutorial_3_7: `完成`,
    dialog_standard_tutorial_3_8: `立即享受折扣运单`,
    dialog_standard_tutorial_3_9: `简单两步，即可获得USPS、UPS、FedEx和DHL Express的运费最佳折扣，`,
    dialog_standard_tutorial_3_10: `最高可节省89%。`,
    // ----------------------------------------------------------------------
    dialog_select_payment_type_1_1: 'ACH',
    dialog_select_payment_type_1_2_1: '无手续费',
    dialog_select_payment_type_1_2_2_1: '最低交易金额：',
    dialog_select_payment_type_1_2_2_2: '$1000',
    dialog_select_payment_type_1_2_3_1: '处理时间：',
    dialog_select_payment_type_1_2_3_2: '5-7个工作日',
    dialog_select_payment_type_1_3: '添加账号',
    dialog_select_payment_type_2_1: '美国银行卡',
    dialog_select_payment_type_2_2_1: '收取3%手续费',
    dialog_select_payment_type_2_2_2_1: '最低交易金额:',
    dialog_select_payment_type_2_2_2_2: '$50',
    dialog_select_payment_type_2_2_3_1: '处理时间:',
    dialog_select_payment_type_2_2_3_2: '及时受理',
    dialog_select_payment_type_2_3: '添加银行卡',
    dialog_select_payment_type_3_1: '无手续费',
    dialog_select_payment_type_3_1_1: '$500',
    dialog_select_payment_type_3_1_2: '1个工作日',
    // ----------------------------------------------------------------------
    dialog_redemption_account_balance_1: `账户余额提现`,
    dialog_redemption_account_balance_2: `提现申请必须在购买最后一个运单后的180天内完成。请注意，信用卡交易收取3%的服务费。退款将在7-10个工作日内处理并退回到您的原始信用卡。请填写完整的表格并发邮件至`,
    dialog_redemption_account_balance_3_1: `下载申请表格`,
    // ----------------------------------------------------------------------
    dialog_add_bank_account_1: `添加ACH账号`,
    dialog_add_bank_account_1_1: `ACH无需支付任何额外手续费`,
    dialog_add_bank_account_1_2_1: `Follow our `,
    dialog_add_bank_account_1_2_2: `payment methods guide`,
    dialog_add_bank_account_1_2_3: ` to set up your ACH account.`,
    dialog_add_bank_account_1_2_4: `ACH的付款交易需要4-5个工作日的处理时间。因此，用ACH充值可能需要长达7个工作日才能在您的ShipSaving账户余额中显示。`,
    dialog_add_bank_account_1_2_5: `请勿在短时间内重复付款，这可能会导致交易失败。`,
    dialog_add_bank_account_1_3: `账户类型`,
    dialog_add_bank_account_1_4: `Account Number`,
    dialog_add_bank_account_1_5: `Routing Number`,
    dialog_add_bank_account_1_6: `发票信息`,
    dialog_add_bank_account_1_6_1: `姓名`,
    dialog_add_bank_account_1_6_2: `地址栏1`,
    dialog_add_bank_account_1_6_3: `地址栏2（选填）`,
    dialog_add_bank_account_1_6_4: `城市`,
    dialog_add_bank_account_1_6_5: `州`,
    dialog_add_bank_account_1_6_6: `邮编`,
    dialog_add_bank_account_1_7: `保存为默认付款方式`,
    dialog_add_bank_account_1_8: `取消`,
    dialog_add_bank_account_1_9: `添加`,
    dialog_add_bank_account_2: `编辑ACH账号`,
    dialog_add_bank_account_2_1: `保存`,
    // ----------------------------------------------------------------------
    dialog_add_card_1: `添加银行卡`,
    dialog_add_card_1_0: `请填写以下表格来添加借记卡或信用卡作为您的付款方式。`,
    dialog_add_card_1_1: `银行卡信息`,
    dialog_add_card_1_2: `姓名`,
    dialog_add_card_1_3: `银行卡号`,
    dialog_add_card_1_3_1: `我们接受以下美国银行卡`,
    dialog_add_card_1_4: `有效期(月)`,
    dialog_add_card_1_5: `有效期(年)`,
    dialog_add_card_1_6: `CVV代码`,
    dialog_add_card_1_7: `账单地址`,
    dialog_add_card_1_8: `地址栏1`,
    dialog_add_card_1_9: `地址栏2（选填）`,
    dialog_add_card_1_10: `城市`,
    dialog_add_card_1_11: `州`,
    dialog_add_card_1_12: `邮编`,
    dialog_add_card_1_13: `保存为默认付款方式`,
    dialog_add_card_1_14: `取消`,
    dialog_add_card_1_15: `添加`,
    dialog_add_card_2: `编辑信用卡`,
    dialog_add_card_2_1: `保存`,
    // ----------------------------------------------------------------------
    dialog_delete_card_1: `删除支付方式`,
    dialog_delete_card_2: `您确定要删除这张银行卡吗？此操作不可撤销。`,
    dialog_delete_card_3: `取消`,
    dialog_delete_card_4: `确认`,
    // ----------------------------------------------------------------------
    dialog_recharge_1: `充值`,
    dialog_recharge_1_1: `支持多种支付方式，选择一个为您的ShipSaving账户进行充值。`,
    dialog_recharge_1_2_1: `付款方式`,
    dialog_recharge_1_2_1_1: `ACH`,
    dialog_recharge_1_2_1_2: `银行卡`,
    dialog_recharge_1_2_1_3: `Zelle`,
    dialog_recharge_1_2_1_3_1: `请完成第一笔充值以解锁新的充值方式 - Zelle`,
    dialog_recharge_1_2_1_4: `微信支付`,
    dialog_recharge_1_2_1_5: `支付宝`,
    dialog_recharge_1_2_2: `付款信息`,
    dialog_recharge_1_2_2_1: `卡号后四位`,
    dialog_recharge_1_2_2_2: `账号后四位`,
    dialog_recharge_1_2_2_3: `默认`,
    dialog_recharge_1_2_3: `充值金额`,
    dialog_recharge_1_2_4: `取消`,
    dialog_recharge_1_2_5: `确认充值`,
    // ----------------------------------------------------------------------
    dialog_recharge_by_zelle_1: `Zelle充值信息`,
    dialog_recharge_by_zelle_1_0: `查看当前的充值进度`,
    dialog_recharge_by_zelle_1_1: `账号信息：626-295-3252`,
    dialog_recharge_by_zelle_1_2: `公司名称: Help Half Plus Inc.`,
    dialog_recharge_by_zelle_1_3: `转账成功后，`,
    dialog_recharge_by_zelle_1_4: `请通过上传转账截图帮助我们识别您的交易。`,
    dialog_recharge_by_zelle_1_5: `您的交易将在1个工作日内得到处理。（工作时间：周一至周五，上午9点至下午6点，洛杉矶时间）`,
    dialog_recharge_by_zelle_2: `金额（最低$1000）`,
    dialog_recharge_by_zelle_3: `交易信息`,
    dialog_recharge_by_zelle_3_1: `姓名或公司名`,
    dialog_recharge_by_zelle_3_2_1: `交易确认码`,
    dialog_recharge_by_zelle_3_2_2: `* 如果您没有此号码，可以不用填写`,
    dialog_recharge_by_zelle_5_1: `在此上传您的截图或`,
    dialog_recharge_by_zelle_5_2: `从电脑中选择`,
    dialog_recharge_by_zelle_5_3: `允许 *.jpeg, *.jpg, *.png, *.gif 格式，尺寸不得超过5.0 MB`,
    // ----------------------------------------------------------------------
    settings_zelle_1: `Zelle充值历史`,
    settings_zelle_2: `状态`,
    // ----------------------------------------------------------------------
    dialog_recharge_result_alipay_1: `支付宝充值`,
    dialog_recharge_result_alipay_2: `请跟随操作步骤完成支付宝付款。`,
    dialog_recharge_result_alipay_3: `确认`,
    // ----------------------------------------------------------------------
    dialog_recharge_result_wechatpay_1: `微信充值`,
    dialog_recharge_result_wechatpay_2: `请跟随操作步骤完成微信付款。`,
    dialog_recharge_result_wechatpay_3: `确认`,
    // ----------------------------------------------------------------------
    dialog_recharge_result_zelle_1: `上传成功`,
    dialog_recharge_result_zelle_2_1: `您的交易需要`,
    dialog_recharge_result_zelle_2_2: `1个工作日`,
    dialog_recharge_result_zelle_2_3: `的处理时间。（工作时间：洛杉矶时间上午9点至下午六点）`,
    dialog_recharge_result_zelle_2_4: `您也可以`,
    dialog_recharge_result_zelle_2_5: `查看当前的充值进度`,
    dialog_recharge_result_zelle_3: `确认`,
    // ----------------------------------------------------------------------
    dialog_recharge_result_ach_1: `Add Funds by ACH`,
    dialog_recharge_result_ach_2_1: `Your transaction will be processed up to `,
    dialog_recharge_result_ach_2_2: `7个工作日`,
    dialog_recharge_result_ach_2_3: `的处理时间。（工作时间：洛杉矶时间上午9点至下午六点）`,
    dialog_recharge_result_ach_3: `确认`,
    // ----------------------------------------------------------------------
    dialog_recharge_result_1: `充值成功`,
    dialog_recharge_result_2_1: `您的账户已充入`,
    dialog_recharge_result_2_2: ``,
    dialog_recharge_result_2_3: `。继续使用ShipSaving创建您的折扣运单吧！`,
    dialog_recharge_result_2_3_1: ` 🎉 现已`,
    dialog_recharge_result_2_3_1_1: `成功解锁Zelle`,
    dialog_recharge_result_2_3_1_2: `作为您的新充值方式选项。`,
    dialog_recharge_result_3: `确认`,
    // ----------------------------------------------------------------------
    dialog_zelle_annotation_1_1: `打开您的Zelle网上银行或手机应用程序。点击`,
    dialog_zelle_annotation_1_2: `Activity`,
    dialog_zelle_annotation_1_3: `，查看待处理或过去的交易信息，您将会找到交易确认号码。😊`,
    // ----------------------------------------------------------------------
    dialog_downgrade_plan_1: `Switch to Starter Plan`,
    dialog_downgrade_plan_2: `I understand that by switching to Starter (free plan), `,
    dialog_downgrade_plan_3: `I cannot access`,
    dialog_downgrade_plan_4: ` the SCAN form, advanced order and item management, report, user permission, and add funds to my balance. Any previously configured shipping rules will not be able to be recovered.`,
    dialog_downgrade_plan_5: `I HAVE READ AND AGREE TO THE INFORMATION ABOVE.`,
    dialog_downgrade_plan_6: `Cancel`,
    dialog_downgrade_plan_7: `Confirm to Switch`,
    // ----------------------------------------------------------------------
    dialog_add_user_1: `添加用户`,
    dialog_add_user_5: `通过添加用户，让他们有访问你账号的权限。`,
    dialog_add_user_1_1: `用户信息`,
    dialog_add_user_1_1_1: `名字`,
    dialog_add_user_1_1_2: `姓名`,
    dialog_add_user_1_1_3: `邮箱`,
    dialog_add_user_1_1_4: `店铺`,
    dialog_add_user_1_2: `用户权限`,
    dialog_add_user_2: `编辑用户`,
    dialog_add_user_2_shipments: `运单权限`,
    dialog_add_user_2_orders: `订单权限`,
    dialog_add_user_2_items: `商品权限`,
    dialog_add_user_2_reports: `数据报告`,
    dialog_add_user_2_account: `账户权限`,
    dialog_add_user_2_integration: `渠道对接`,
    dialog_add_user_2_configuration: `邮寄设置权限`,
    dialog_add_user_2_shipments_my_shipments: `我的邮寄 & 草稿`,
    dialog_add_user_2_shipments_scan_form: `SCAN Form`,
    dialog_add_user_2_shipments_pick_up: `上门取件`,
    dialog_add_user_2_shipments_buy_label: `购买运单`,
    dialog_add_user_2_shipments_reprint_label: `重印运单`,
    dialog_add_user_2_shipments_batch_print: `批量打印`,
    dialog_add_user_2_shipments_void_label: `作废运单`,
    dialog_add_user_2_shipments_scan_form_add: `添加`,
    dialog_add_user_2_shipments_scan_form_print: `打印`,
    dialog_add_user_2_shipments_pick_up_add: `预约`,
    dialog_add_user_2_shipments_pick_up_cancel: `取消预约`,
    dialog_add_user_2_order_combin_order: `合并订单`,
    dialog_add_user_2_order_add: `添加订单`,
    dialog_add_user_2_order_import: `上传订单`,
    dialog_add_user_2_order_export: `导出订单`,
    dialog_add_user_2_order_filter: `筛选订单`,
    dialog_add_user_2_order_rate: `查看运费`,
    dialog_add_user_2_order_change_status: `更改订单状态`,
    dialog_add_user_2_order_hold: `标记为待处理`,
    dialog_add_user_2_order_return_label: `创建退货运单`,
    dialog_add_user_2_order_void_label: `作废运单`,
    dialog_add_user_2_order_download_label: `下载运单`,
    dialog_add_user_2_item_add: `添加商品`,
    dialog_add_user_2_item_delete: `删除商品`,
    dialog_add_user_2_item_modify: `编辑商品`,
    dialog_add_user_2_reports_info: `数据信息`,
    dialog_add_user_2_account_billing: `付款设置`,
    dialog_add_user_2_account_plan: `账户计划`,
    dialog_add_user_2_account_user: `用户设置`,
    dialog_add_user_2_account_billing_recharge: `充值`,
    dialog_add_user_2_account_billing_bank_account: `银行账户`,
    dialog_add_user_2_account_billing_card: `添加支付方式`,
    dialog_add_user_2_account_billing_transaction: `付款信息`,
    dialog_add_user_2_account_billing_transaction_export: `导出表格`,
    dialog_add_user_2_account_billing_transaction_invoice: `账单`,
    dialog_add_user_2_account_plan_info: `计划详情`,
    dialog_add_user_2_account_user_modify: `编辑用户`,
    dialog_add_user_2_integration_store: `店铺`,
    dialog_add_user_2_integration_carrier: `承运商`,
    dialog_add_user_2_integration_store_modify: `编辑店铺`,
    dialog_add_user_2_integration_carrier_modify: `编辑账号`,
    dialog_add_user_2_configuration_ship_from: `发件地址`,
    dialog_add_user_2_configuration_ship_to: `收件地址`,
    dialog_add_user_2_configuration_package: `我的包裹`,
    dialog_add_user_2_configuration_shipping_rule: `运输规则`,
    dialog_add_user_2_configuration_automation: `自动化设置`,
    dialog_add_user_2_configuration_ship_from_modify: `编辑`,
    dialog_add_user_2_configuration_ship_to_modify: `编辑`,
    dialog_add_user_2_configuration_package_modify: `编辑`,
    dialog_add_user_2_configuration_shipping_rule_modify: `编辑`,
    dialog_add_user_2_usps: `USPS`,
    dialog_add_user_3: `取消`,
    dialog_add_user_4_1: `确认添加`,
    dialog_add_user_4_2: `更新`,
    // ----------------------------------------------------------------------
    dialog_combine_order_1: `合并订单`,
    dialog_combine_order_1_1: `合并同一收件人的所有订单，将它们整合为一个包裹进行邮寄。`,
    dialog_combine_order_2: `确认合并`,
    dialog_combine_order_3: `您目前没有可用于合并的订单。`,
    dialog_combine_order_4: `好`,
    // ----------------------------------------------------------------------
    dialog_new_edit_order_1_1: `添加订单`,
    dialog_new_edit_order_1_2: `编辑订单`,
    dialog_new_edit_order_2: `为您的自定义店铺手动填写新的订单。`,
    dialog_new_edit_order_3: `店铺`,
    dialog_new_edit_order_3_1: `添加自定义店铺`,
    dialog_new_edit_order_3_1_1: `无自定义店铺`,
    dialog_new_edit_order_3_2: `收件人`,
    dialog_new_edit_order_3_2_1: `不适用已默认的发件地址`,
    dialog_new_edit_order_3_2_2: `使用新的发件地址`,
    dialog_new_edit_order_3_3: `住宅地址`,
    dialog_new_edit_order_4: `商品`,
    dialog_new_edit_order_4_1: `添加商品`,
    dialog_new_edit_order_4_2: `商品`,
    dialog_new_edit_order_4_3: `搜索商品SKU & 名称`,
    dialog_new_edit_order_4_4: `数量`,
    dialog_new_edit_order_5: `订单总价`,
    dialog_new_edit_order_5_1: `订单价格`,
    dialog_new_edit_order_5_2: `税 (选填)`,
    dialog_new_edit_order_5_3: `运费 (选填)`,
    dialog_new_edit_order_6: `取消`,
    dialog_new_edit_order_7: `添加订单`,
    dialog_new_edit_order_8: `保存订单`,
    // ----------------------------------------------------------------------
    dialog_pro_plan_1: `升级账户至专业版`,
    dialog_pro_plan_2: `通过此次升级，您将获得ShipSaving for Starter的所有功能，以及：`,
    dialog_pro_plan_3_1: `自动化打单发货流程`,
    dialog_pro_plan_3_2: `批量合并和拆分订单，预设发货规则...`,
    dialog_pro_plan_3_3: `WMS仓库及库存管理系统`,
    dialog_pro_plan_3_4: `多仓管理，拣货智能扫描，让发货更轻松...`,
    dialog_pro_plan_3_5: `支持团队多用户多角色注册和登录`,
    dialog_pro_plan_3_6: `用户权限分配，将店铺分配给特定用户...`,
    dialog_pro_plan_4: `联系我们`,
    dialog_pro_plan_5_1: `立即升级以获得ShipSaving的`,
    dialog_pro_plan_5_2: `所有高级功能`,
    dialog_pro_plan_5_3: `。`,
    // ----------------------------------------------------------------------
    dialog_connect_store_1: `添加您的店铺`,
    dialog_connect_store_2: `选择您的店铺，自动连接和同步订单`,
    dialog_connect_store_3: `在ShipSaving解锁更多店铺对接权限`,
    dialog_connect_store_4: `升级至专业版`,
    dialog_connect_store_5: `查看所有平台`,
    dialog_connect_store_6: `https://www.shipsaving.com/zh-cn/partners`,
    dialog_connect_store_ecommerce_platforms: `电商平台`,
    dialog_connect_store_marketplaces: `综合市场`,
    dialog_connect_store_order_and_inventory_management: `订单/仓储管理`,
    dialog_connect_store_customized_store: `自定义店铺`,
    // ----------------------------------------------------------------------
    dialog_add_store_1: `返回`,
    dialog_add_store_1_1: `对接ShipSaving以提升销售额。`,
    dialog_add_store_1_2: `对接指南`,
    dialog_add_store_2: `昵称`,
    dialog_add_store_3: `发件地址`,
    dialog_add_store_4: `设置`,
    dialog_add_store_5: `Upload tracking numbers to your store`,
    dialog_add_store_6: `Send tracking notification email to customer via ShipSaving`,
    dialog_add_store_7: `取消`,
    dialog_add_store_8: `保存`,
    dialog_add_store_9: `添加`,
    dialog_add_store_10: `对接通行证`,
    dialog_add_store_notify_customer: `买家发货通知`,
    dialog_add_store_auto_email_tracking: `向客户发送发货确认邮件`,
    dialog_add_store_notify_sales_channel: `电商平台发货通知`,
    dialog_add_store_11_1: `店铺昵称不得超过60个字符`,
    dialog_add_store_12: `Print Additional Info on Labels`,
    dialog_add_store_12_1: `Information #1`,
    dialog_add_store_12_2: `Information #2`,
    // ----------------------------------------------------------------------
    dialog_select_carrier_1: `添加承运商`,
    dialog_select_carrier_2: `选择一个承运商以获得运费折扣`,
    dialog_select_carrier_3: `解锁更多承运商账号对接权限`,
    dialog_select_carrier_4: `升级至专业版`,
    // ----------------------------------------------------------------------
    dialog_bill_account_1: `Bill Account`,
    dialog_bill_account_1_1: `Select a carrier to get a shipping discount`,
    dialog_bill_account_2: `Bill Accounts`,
    dialog_bill_account_3: `Add bill account`,
    dialog_bill_account_4: `Delete Account`,
    dialog_bill_account_5_1: `Nickname`,
    dialog_bill_account_5_2: `Account Number`,
    dialog_bill_account_5_3: `Zip Code`,
    dialog_bill_account_5_4: `Country`,
    dialog_bill_account_6: `取消`,
    dialog_bill_account_7: `Save Change`,
    // ----------------------------------------------------------------------
    dialog_bill_account_delete_1: `Are you sure you want to delete this account ?`,
    dialog_bill_account_delete_2: `You are about to delete your bill account. This action cannot be undone, and you will no longer get UPS rates at ShipSaving.`,
    dialog_bill_account_delete_3: `取消`,
    dialog_bill_account_delete_4: `Delete Account`,
    // ----------------------------------------------------------------------
    dialog_add_ups_carrier_1: `添加UPS账号`,
    dialog_add_ups_carrier_1_1: `请注意，如果您想将自己的UPS账户连接到ShipSaving平台，您将`,
    dialog_add_ups_carrier_1_2: `永久无法使用ShipSaving提供的UPS折扣。`,
    dialog_add_ups_carrier_2: `命名（选填）`,
    dialog_add_ups_carrier_3: `联系人信息`,
    dialog_add_ups_carrier_4: `姓名`,
    dialog_add_ups_carrier_5: `职位`,
    dialog_add_ups_carrier_6: `公司`,
    dialog_add_ups_carrier_7: `公司官网链接`,
    dialog_add_ups_carrier_8: `邮箱`,
    dialog_add_ups_carrier_9: `电话`,
    dialog_add_ups_carrier_10: `账单地址`,
    dialog_add_ups_carrier_11: `街道1`,
    dialog_add_ups_carrier_12: `街道2（选填）`,
    dialog_add_ups_carrier_13: `城市`,
    dialog_add_ups_carrier_14: `州`,
    dialog_add_ups_carrier_15: `邮编`,
    dialog_add_ups_carrier_16: `国家`,
    dialog_add_ups_carrier_17: `账户信息`,
    dialog_add_ups_carrier_18: `Account Number`,
    dialog_add_ups_carrier_19: `Account Zip`,
    dialog_add_ups_carrier_20: `国家`,
    dialog_add_ups_carrier_21: `账单信息`,
    dialog_add_ups_carrier_22: `发票日期`,
    dialog_add_ups_carrier_23: `发票号码`,
    dialog_add_ups_carrier_24: `Control ID`,
    dialog_add_ups_carrier_25: `发票金额`,
    dialog_add_ups_carrier_26: `我在过去90天内收到过一张账单`,
    dialog_add_ups_carrier_27_1: `勾选此条款后，代表您同意`,
    dialog_add_ups_carrier_27_2: `UPS Technology Agreement`,
    dialog_add_ups_carrier_27_3: `。`,
    dialog_add_ups_carrier_28_1: `为了方便验证，请提供有效发票的信息，有效发票需要符合以下标准`,
    dialog_add_ups_carrier_28_1_1: `属于近期3张发票的其中之一。`,
    dialog_add_ups_carrier_28_1_2: `美国和加拿大不能超过90天。`,
    dialog_add_ups_carrier_28_1_3: `所有其他国家不能超过45天。`,
    dialog_add_ups_carrier_28_2: `这些发票信息可以在您的UPS账户的“My UPS”中找到。请正确输入账单上的发票号码。`,
    dialog_add_ups_carrier_29: `取消`,
    dialog_add_ups_carrier_30: `添加承运商`,
    dialog_add_ups_carrier_31: `Carrier Integration Guide`,
    // ----------------------------------------------------------------------
    dialog_add_fedex_carrier_1: `Fedex Account Number`,
    dialog_add_fedex_carrier_2: `FedEx Ground® Economy Endorsement`,
    dialog_add_fedex_carrier_3: `FedEx Ground® Economy Hub ID`,
    dialog_add_fedex_carrier_4: `Ground® Economy Endorsement`,
    dialog_add_fedex_carrier_5: `By checking this box, you agree to the `,
    dialog_add_fedex_carrier_6: `FedEx EULA Agreement`,
    dialog_add_fedex_carrier_7: `FedEx service marks are owned by Federal Express Corporation and used by permission.`,
    // ----------------------------------------------------------------------
    dialog_confirm_connect_ups_carrier_1: `您的UPS账号成功对接！`,
    dialog_confirm_connect_ups_carrier_2: `请注意，您在ShipSaving的付款，包括运单购买和运费调整，将计入您自己的UPS账号。`,
    dialog_confirm_connect_ups_carrier_3: `好`,
    // ----------------------------------------------------------------------
    dialog_failed_connect_ups_carrier_1: `啊哦！出错了......`,
    dialog_failed_connect_ups_carrier_2: `我们在连接您的UPS账号时遇到了问题，请稍后再试。如果需要任何进一步的帮助，请与我们联系。`,
    dialog_failed_connect_ups_carrier_3: `重新尝试`,
    // ----------------------------------------------------------------------
    dialog_delete_ups_carrier_1: '您确定要删除此账号吗？',
    dialog_delete_ups_carrier_2: '您即将删除您的UPS账号。此操作无法撤销，您将无法再在ShipSaving获得UPS的价格。',
    dialog_delete_ups_carrier_3: '取消',
    dialog_delete_ups_carrier_4: '确认删除',
    // ----------------------------------------------------------------------
    dialog_upgrade_plan_1: '添加您的承运商账号',
    dialog_upgrade_plan_2: '您可以连接自己的承运商，并通过升级您的现有计划至Standard版本，以便在ShipSaving中轻松查看您的运费。',
    dialog_upgrade_plan_3: '取消',
    dialog_upgrade_plan_4: '了解更多',
    dialog_upgrade_plan_5: `准备好升级了吗？`,
    dialog_upgrade_plan_6: `亲爱的用户，您选择的包裹所支持的邮寄服务仅适用于我们的Standard计划。为了保证您可以顺利解锁UPS更多服务，我们建议您升级。`,
    dialog_upgrade_plan_7: ``,
    dialog_upgrade_plan_8: `升级并享受14天免费试用`,
    // ----------------------------------------------------------------------
    dialog_add_ship_from_address_1: `添加新地址`,
    dialog_add_ship_from_address_1_1: `寄件地址是指您的包裹将被取走，并进行发货的地址。`,
    dialog_add_ship_from_address_1_2_1: `名字`,
    dialog_add_ship_from_address_1_2_2: `姓氏`,
    dialog_add_ship_from_address_1_2_3: `姓名`,
    dialog_add_ship_from_address_1_3: `公司（选填）`,
    dialog_add_ship_from_address_1_4: `邮箱（选填）`,
    dialog_add_ship_from_address_1_5: `电话`,
    dialog_add_ship_from_address_1_6: `保存为默认发件地址`,
    dialog_add_ship_from_address_1_7: `住宅地址`,
    dialog_add_ship_from_address_1_8: `取消`,
    dialog_add_ship_from_address_1_9: `添加`,
    dialog_add_ship_from_address_2: `编辑地址`,
    dialog_add_ship_from_address_2_1: `保存`,
    // ----------------------------------------------------------------------
    dialog_add_address_1: `添加新地址`,
    dialog_add_address_1_1: `收件地址是指您的包裹将被送达的地点。`,
    dialog_add_address_1_2_1: `名字`,
    dialog_add_address_1_2_2: `姓氏`,
    dialog_add_address_1_2_3: `姓名`,
    dialog_add_address_1_3: `公司（选填）`,
    dialog_add_address_1_4: `邮箱（选填）`,
    dialog_add_address_1_5: `电话`,
    dialog_add_address_1_6: `保存为默认发件地址`,
    dialog_add_address_1_7: `住宅地址`,
    dialog_add_address_1_8: `取消`,
    dialog_add_address_1_9: `添加`,
    dialog_add_address_2: `编辑地址`,
    dialog_add_address_2_1: `保存`,
    // ----------------------------------------------------------------------
    dialog_delete_address_1: '您确定要删除此地址吗？',
    dialog_delete_address_2: '此地址将无法再使用，但不会影响之前购买的运单。',
    dialog_delete_address_3: '取消',
    dialog_delete_address_4: ' 确认删除',
    // ----------------------------------------------------------------------
    dialog_delete_item_1: 'Are you sure you want to delete this item ?',
    dialog_delete_item_2: 'Please note that deleting the item will cause the item image to disappear from the order list. Are you sure you want to continue?',
    dialog_delete_item_3: '取消',
    dialog_delete_item_4: '删除',
    // ----------------------------------------------------------------------
    dialog_add_package_1: `添加新包裹`,
    dialog_add_package_1_1: `您可以添加常用的包裹信息，以方便下次邮寄。`,
    dialog_add_package_1_2: `包裹信息`,
    dialog_add_package_1_4: `尺寸`,
    dialog_add_package_1_5: `长（in）`,
    dialog_add_package_1_6: `宽（in）`,
    dialog_add_package_1_7: `高（in）`,
    dialog_add_package_1_8: `包裹命名`,
    dialog_add_package_1_9: `昵称`,
    dialog_add_package_1_10: `保存为购买运单时的默认包装`,
    dialog_add_package_1_11: `取消`,
    dialog_add_package_1_12: `添加包裹`,
    dialog_add_package_2: `编辑包裹`,
    dialog_add_package_2_1: `保存包裹`,
    // ----------------------------------------------------------------------
    dialog_delete_package_1: '您确定要删除此包裹信息吗？',
    dialog_delete_package_2: '此包裹信息将无法再使用，但不会影响之前购买的运单。',
    dialog_delete_package_3: '取消',
    dialog_delete_package_4: '确认删除',
    // ----------------------------------------------------------------------
    dialog_add_shipping_rule_1: `添加邮寄规则`,
    dialog_add_shipping_rule_1_1: `邮寄规则为您节省时间和精力，在ShipSaving内实现订单自动化处理。`,
    dialog_add_shipping_rule_1_2: `店铺`,
    dialog_add_shipping_rule_1_2_1: `选择店铺`,
    dialog_add_shipping_rule_1_2_2: `无自定义店铺`,
    dialog_add_shipping_rule_1_3: `包裹信息`,
    dialog_add_shipping_rule_1_4: `其他选项`,
    dialog_add_shipping_rule_1_5: `取消`,
    dialog_add_shipping_rule_1_6: `添加邮寄规则`,
    dialog_add_shipping_rule_2: `编辑邮寄规则`,
    dialog_add_shipping_rule_2_1: `保存邮寄规则`,
    // ----------------------------------------------------------------------
    dialog_pickup_schedule_confirm_1: `您的预约已确认！`,
    dialog_pickup_schedule_confirm_2: `预约确认码`,
    dialog_pickup_schedule_confirm_3: `取件时间`,
    dialog_pickup_schedule_confirm_4: `请将您要被取走的包裹贴好运单，在取件日时留在您安排的取件位置。`,
    dialog_pickup_schedule_confirm_5: `确定`,
    // ----------------------------------------------------------------------
    dialog_cancel_pickup_schedule_1: `您是否确定取消此次取件？`,
    dialog_cancel_pickup_schedule_2: `您即将取消USPS的上门取件请求，请注意，此请求一旦确认将无法撤销。我们会向您发送一封包含更改确认的电子邮件。`,
    dialog_cancel_pickup_schedule_3: `取消`,
    dialog_cancel_pickup_schedule_4: `继续`,
    // ----------------------------------------------------------------------
    dialog_get_shipments_label_1_1: `有打印机？`,
    dialog_get_shipments_label_1_2: `如果您附近有打印机，可以立即打印您的运单，并将运单贴在包裹或信封上。`,
    dialog_get_shipments_label_1_3: `打印运单`,
    dialog_get_shipments_label_2_1: `没有打印机？`,
    dialog_get_shipments_label_2_2: `将您的运单二维码与包裹一起带到邮局。USPS将在柜台扫码并帮您打印运单。`,
    dialog_get_shipments_label_2_3: `获取二维码`,
    // ----------------------------------------------------------------------
    dialog_custom_form_desc_1: `海关表格填写指南`,
    dialog_custom_form_desc_2: `请为包裹中的所有物品提供详细描述。包括每件物品的全面概述，详细说明它的用途和它的材质。`,
    dialog_custom_form_desc_3: `对物品进行清晰准确的描述`,
    dialog_custom_form_desc_4: `是确保您的包裹顺利通过海关的最好方法。`,
    dialog_custom_form_desc_5: ``,
    dialog_custom_form_desc_6: `提供每件物品的准确单价`,
    dialog_custom_form_desc_7: `。海关当局会检查您是否有任何被低估价值的物品，并收取正确的金额，可能会对漏报的物品增加额外的手续费。`,
    dialog_custom_form_desc_8: `打印并将运单和所有相关文件（发票、海关表格等）贴在外包装上`,
    dialog_custom_form_desc_9: `。确保使用透明胶带（或把它放在附在包裹上的塑料信封里），以便可以清晰阅读。`,
    // ----------------------------------------------------------------------
    dialog_download_label_1_1: `下载运单`,
    dialog_download_label_1_2: `分享运单链接`,
    dialog_download_label_2_1: `包裹投递`,
    dialog_download_label_2_2: `预约取件`,
    // ----------------------------------------------------------------------
    dialog_ups_return_pickup_1: `UPS退货运单 - Pickup Attempt(s)`,
    dialog_ups_return_pickup_2: `UPS将为您打印退货运单，并尝试收取您的货件。如果无法取回货件，UPS将把退货运单留在您的取件地点。`,
    // ----------------------------------------------------------------------
    dialog_multi_packages_return_1: `重新填写包裹信息`,
    dialog_multi_packages_return_2: `一票多件的包裹不支持生成退货运单. 如果您想继续，之前的包裹信息将会被清空，需要重新填写。`,
    dialog_multi_packages_return_3: `取消`,
    dialog_multi_packages_return_4: `继续`,
    // ----------------------------------------------------------------------
    dialog_gwt_qr_code_1: `请在您的手机上保存此二维码。确认邮件也已发送到您的注册邮件地址。`,
    dialog_gwt_qr_code_2: `保存二维码`,
    dialog_gwt_qr_code_3_1: `如果您想自行打印运单，可以进入USPS.com进行运单打印。\n转到`,
    dialog_gwt_qr_code_3_2: `USPS Label Broker`,
    // ----------------------------------------------------------------------
    dialog_shipment_rates_desc_1: `Media Mail`,
    dialog_shipment_rates_desc_1_1: `您可以通过Media Mail寄送`,
    dialog_shipment_rates_desc_1_2: `书籍、录像带、DVD、CD、乐谱和其他录音物品`,
    dialog_shipment_rates_desc_1_3: `，总重量不能超过70磅。`,
    dialog_shipment_rates_desc_1_4: `请注意，USPS有权打开并检查您的包裹，以检查您选择的邮寄服务是否与寄送物品相符。因此，`,
    dialog_shipment_rates_desc_1_5: `您不应该通过Media Mail邮寄广告（附有书籍的公告除外）或其他非媒体材料`,
    dialog_shipment_rates_desc_1_6: `，因为您将面临包裹因邮资不足被退回的风险。`,
    dialog_shipment_rates_desc_2: `Library Mail`,
    dialog_shipment_rates_desc_2_1: `Library Mail是`,
    dialog_shipment_rates_desc_2_2: `图书馆、学术机构、博物馆、非营利组织和其他类似组织之间`,
    dialog_shipment_rates_desc_2_3: `相互寄送借阅物品的一种服务方式。`,
    dialog_shipment_rates_desc_2_4: `正如Media Mail一样，`,
    dialog_shipment_rates_desc_2_5: `通过Library Mail寄送的物品不能包含广告或其他非媒体材料。`,
    dialog_shipment_rates_desc_2_6: `物品需要7至10天才能到达目的地。`,
    dialog_shipment_rates_desc_3: `好`,
    // ----------------------------------------------------------------------
    dialog_shipment_rates_fedex_desc_1: `递送时间`,
    dialog_shipment_rates_fedex_desc_1_1: `通常情况下，交付需要2至7个工作日，这取决于从包裹的出发地到目的地的距离。美国本土48个州以外的地区，预计时间会更长。`,
    dialog_shipment_rates_fedex_desc_2: `包裹的最大尺寸和重量限制`,
    dialog_shipment_rates_fedex_desc_2_1: `包裹最大限重为70磅，长度加周长为130英寸。理想的包裹重量为1-10磅。`,
    dialog_shipment_rates_fedex_desc_3: `例外情况`,
    dialog_shipment_rates_fedex_desc_3_1: `以下服务选项不适用于FedEx Ground Economy：`,
    // ----------------------------------------------------------------------
    dialog_shipment_no_service_desc_1: `Check out Why the Following Services are not Available`,
    // ----------------------------------------------------------------------
    dialog_usps_hazardous_dialog_1: `此包裹是否含有危险品或危险物料吗？`,
    dialog_usps_hazardous_dialog_2: `请注意，USPS最新生效的`,
    dialog_usps_hazardous_dialog_3: `USPS Publication 52 (Pub 52)`,
    dialog_usps_hazardous_dialog_4: `公告将影响您的货物运输。危险物品必须进行单独打包运送。`,
    dialog_usps_hazardous_dialog_5: `危险物料包括电池、干冰、易燃液体、喷雾剂、气囊、弹药、烟火、汽油、打火机、锂电池、火柴、指甲油、油漆、香水等等。`,
    dialog_usps_hazardous_dialog_6: `查看教程`,
    dialog_usps_hazardous_dialog_7: `了解如何为国内货物包装、标签和运送HAZMAT（危险物料）。`,
    dialog_usps_hazardous_dialog_8: `是，含有危险品`,
    dialog_usps_hazardous_dialog_9: `否，不含危险品`,
    // ----------------------------------------------------------------------
    dialog_usps_hazamat_dialog_1: `通过USPS邮寄危险品`,
    dialog_usps_hazamat_dialog_2: `请注意只有USPS提供邮寄危险品的服务选项，其他承运商则不适用。`,
    dialog_usps_hazamat_dialog_3: `USPS Publication 52 (Pub 52)`,
    dialog_usps_hazamat_dialog_4: `的最新公告将会影响您的货物运输。`,
    dialog_usps_hazamat_dialog_5: `勾选此框将只显示适用的USPS服务。`,
    dialog_usps_hazamat_dialog_6: `对于美国境内运输危险品，USPS仅提供地面运输。因此，将您的包裹指定为“危险物品”将导致 `,
    dialog_usps_hazamat_dialog_7: `Priority Mail和Priority Mail Express服务无法使用。`,
    dialog_usps_hazamat_dialog_8: `查看教程`,
    dialog_usps_hazamat_dialog_9: `，了解如何包装，标签和运输国内货物的危险物品。`,
    // ----------------------------------------------------------------------
    dialog_validate_address_1: `缺少部分地址`,
    dialog_validate_address_1_1: `您输入的地址USPS不支持预约取件，我们建议您再次进行检查。`,
    dialog_validate_address_2: `地址推荐`,
    dialog_validate_address_2_1: `我们建议您使用校正后的地址，您也可以忽略这条提示。\n注意：该地址USPS不支持预约取件。`,
    dialog_validate_address_3: `更新地址`,
    dialog_validate_address_4: `保存地址`,
    // ----------------------------------------------------------------------
    dialog_disconnect_store_1: `Are you sure you want to delete this account ?`,
    dialog_disconnect_store_2: `此渠道的订单会保留，但您一旦确认，将不会再产生订单同步。`,
    dialog_disconnect_store_3: `取消`,
    dialog_disconnect_store_4: `确认断开`,
    // ----------------------------------------------------------------------
    dialog_verify_payment_method_notification_1: '请验证您的付款方式',
    dialog_verify_payment_method_notification_2: '信用卡安全对我们来说非常重要，这就是为什么我们从您的卡中收取',
    dialog_verify_payment_method_notification_3: '两笔临时金额（每笔低于1美元）以验证您的身份',
    dialog_verify_payment_method_notification_4: '。请前往您的网上银行查看并输入准确的金额进行验证。💙',
    dialog_verify_payment_method_notification_5: '现在验证',
    dialog_verify_payment_method_notification_6: '取消',
    dialog_verify_payment_method_notification_7: '保存此运单信息为草稿（当您成功添加银行卡后，前往包裹邮寄 - 我的草稿页面即可继续编辑此运单）',
    // ----------------------------------------------------------------------
    dialog_verify_payment_method_0: `如何验证银行卡?`,
    dialog_verify_payment_method_0_1: `为了保证您的支付安全,`,
    dialog_verify_payment_method_0_2: ` ShipSaving已向您的银行卡发送了两笔临时验证金额，`,
    dialog_verify_payment_method_0_3: `请参考以下步骤进行验证:`,
    dialog_verify_payment_method_0_4: `1. 登录您的网上银行`,
    dialog_verify_payment_method_0_5: `2. 在付款记录中找到两笔金额`,
    dialog_verify_payment_method_0_6: `3. 在下方输入框准确的填写入两笔金额`,
    dialog_verify_payment_method_1: `验证付款方式`,
    dialog_verify_payment_method_2: `请正确输入银行对账单上ShipSaving的两笔小额款项，以验证此卡。然后您就可以开始开启邮寄旅程啦！😊`,
    dialog_verify_payment_method_3_1: ` 如果您使用的是`,
    dialog_verify_payment_method_3_2: `American Express银行卡`,
    dialog_verify_payment_method_3_3: `，只需要输入小数点后两位的金额。`,
    dialog_verify_payment_method_4: `取消`,
    dialog_verify_payment_method_5: `确认`,
    // ----------------------------------------------------------------------
    dialog_success_connect_store_1: `Congratulations, your store is connected!`,
    dialog_success_connect_store_2: `Please go to Integration - Stores page if you’d like to manage your store setting. Or contact us for any further assistance.`,
    dialog_success_connect_store_3: `OK`,
    // ----------------------------------------------------------------------
    dialog_failed_connect_store_1_1: `Oops, something went`,
    dialog_failed_connect_store_1_2: `wrong!`,
    dialog_failed_connect_store_2: `Your request is failed, please go to Integration - Stores page to connect your store again. Or contact us for any further assistance.`,
    dialog_failed_connect_store_3: `Try Again`,
    // ----------------------------------------------------------------------
    dialog_void_label_1: `取消运单`,
    dialog_void_label_2_1_1: `此运单的退款请求将被提交给USPS。USPS最多需要30天的时间来确保该运单未被使用，然后退款才会返还至您的ShipSaving账号余额。`,
    dialog_void_label_2_1_2: `如果该运单已被扫描，USPS将忽略您的请求，并且不会收到退款。`,
    dialog_void_label_2_2_1: `此运单的退款请求将被提交给UPS。如果该运单已经被扫描，UPS将忽略您的请求，并且不会收到退款。`,
    dialog_void_label_2_2_2_1: `如果想要了解更多信息，请`,
    dialog_void_label_2_2_2_2: `登录`,
    dialog_void_label_2_2_2_3: `您的UPS账户。`,
    dialog_void_label_2_3_1: `此运单的退款请求将被提交给DHL Express。退款将在1小时内返还到您的ShipSaving账号余额。`,
    dialog_void_label_2_3_2: `如果该运单已经被扫描，DHL Express将忽略您的请求，并且不会收到退款。`,
    dialog_void_label_2_4_1: `此运单已提交退款请求，退款将在1小时内返还至您的ShipSaving账号余额。`,
    dialog_void_label_2_4_2: `如果该运单已经被扫描，您的退款请求将会被忽略，并且不会收到退款。`,
    dialog_void_label_2_5_1: `您可以在购买运单之日起15天内申请取消运单并退款。`,
    dialog_void_label_2_5_2: `此运单的退款请求将被提交给FedEx。FedEx最多需要3天的时间来确保该运单未被使用，然后退款才会返还至您的ShipSaving账号余额。如果该运单已被扫描，FedEx将忽略您的请求，并且不会收到退款。`,
    dialog_void_label_3: `您确认继续吗？`,
    dialog_void_label_4: `取消`,
    dialog_void_label_5: `确认`,
    // ----------------------------------------------------------------------
    dialog_void_template_1: `取消运单`,
    dialog_void_template_2: `您确认继续吗？`,
    dialog_void_template_3_1: `如果想要了解更多信息，请`,
    dialog_void_template_3_2: `登录`,
    dialog_void_template_4_1: `取消`,
    dialog_void_template_4_2: `确认`,
    dialog_void_ups_1: `UPS`,
    dialog_void_ups_2_1: `您可以在购买运单之日起23天内申请取消运单并退款。`,
    dialog_void_ups_2_2: `此运单的退款请求将被提交给UPS。UPS最多需要3天的时间来确保该运单未被使用，然后退款才会返还至您的ShipSaving账号余额。如果该运单已被扫描，UPS将忽略您的请求，并且不会收到退款。`,
    dialog_void_ups_3_1: `此运单的退款请求将被提交给UPS。如果该运单已经被扫描，UPS将忽略您的请求，并且不会收到退款。`,
    dialog_void_ups_3_2: `您的UPS账户。`,
    dialog_void_fedex_1: `FEDEX`,
    dialog_void_fedex_2_1: `您可以在购买运单之日起15天内申请取消运单并退款。`,
    dialog_void_fedex_2_2: `此运单的退款请求将被提交给FedEx。FedEx最多需要3天的时间来确保该运单未被使用，然后退款才会返还至您的ShipSaving账号余额。如果该运单已被扫描，FedEx将忽略您的请求，并且不会收到退款。`,
    dialog_void_usps_1: `USPS`,
    dialog_void_usps_2_1: `您可以在购买运单之日起23天内申请取消运单并退款。`,
    dialog_void_usps_2_2: `此运单的退款请求将被提交给USPS。USPS最多需要30天的时间来确保该运单未被使用，然后退款才会返还至您的ShipSaving账号余额。如果该运单已被扫描，USPS将忽略您的请求，并且不会收到退款。`,
    dialog_void_dhl_express_1: `DHL Express`,
    dialog_void_dhl_express_2_1: `您可以在购买运单之日起23天内申请取消运单并退款。`,
    dialog_void_dhl_express_2_2: `此运单的退款请求将被提交给DHL。DHL最多需要3天的时间来确保该运单未被使用，然后退款才会返还至您的ShipSaving账号余额。如果该运单已被扫描，DHL将忽略您的请求，并且不会收到退款。`,
    dialog_void_dhl_express_3_1: `此运单的退款请求将被提交给DHL Express。如果该运单已经被扫描，DHL Express将忽略您的请求，并且不会收到退款。`,
    dialog_void_dhl_express_3_2: `您的DHL Express账户。`,
    dialog_void_dhl_ecommerce_1: `DHL eCommerce`,
    dialog_void_dhl_ecommerce_3_1: `此运单的退款请求将被提交给DHL eCOMMERCE 。如果该运单已经被扫描，DHL eCOMMERCE 将忽略您的请求，并且不会收到退款。`,
    dialog_void_dhl_ecommerce_3_2: `您的DHL eCOMMERCE 账户。`,
    dialog_void_ontrac_1: `OnTrac`,
    dialog_void_ontrac_3_1: `此运单的退款请求将被提交给OnTrac。如果该运单已经被扫描，OnTrac将忽略您的请求，并且不会收到退款。`,
    dialog_void_ontrac_3_2: `您的OnTrac账户。`,

    // ----------------------------------------------------------------------
    dialog_pickup_schedule_learn_more_1: `上门取件是什么？`,
    dialog_pickup_schedule_learn_more_1_1_1: `上门取件允许您在下一个投递日或指定日期安排取件，在安排取件之前，`,
    dialog_pickup_schedule_learn_more_1_1_2: `邮件必须已付运费`,
    dialog_pickup_schedule_learn_more_1_1_3: `。另外，USPS 的上门取件并不限制要取件的包裹总数量。😄`,
    dialog_pickup_schedule_learn_more_1_2_1: `当您所在地区的邮递员送件时，他们会顺便免费取走您的邮件。但是，如果您预约了取件的具体时间，这将会产生额外的费用。如果您的取件需要安排`,
    dialog_pickup_schedule_learn_more_1_2_2: `特定的日期和时间`,
    dialog_pickup_schedule_learn_more_1_2_3: `，您可以直接向`,
    dialog_pickup_schedule_learn_more_1_2_4: `USPS`,
    dialog_pickup_schedule_learn_more_1_2_5: `支付25美元的费用。`,
    dialog_pickup_schedule_learn_more_2: `当您安排取件时应该注意什么？`,
    dialog_pickup_schedule_learn_more_2_1_1: `Priority Mail Express，Priority Mail 和国际递送`,
    dialog_pickup_schedule_learn_more_2_1_2: `有资格享有免费上门取件的服务。其他邮递服务，比如`,
    dialog_pickup_schedule_learn_more_2_1_3: `First-Class Mail，💿 Media Mail 和 Parcel Select Ground`,
    dialog_pickup_schedule_learn_more_2_1_4: `需要和上述其中一个邮递服务一起取件，才可以被领取。`,
    dialog_pickup_schedule_learn_more_2_2_1: `您可以在`,
    dialog_pickup_schedule_learn_more_2_2_2: `周一至周六安排取件`,
    dialog_pickup_schedule_learn_more_2_2_3: `，直到取件当天的`,
    dialog_pickup_schedule_learn_more_2_2_4: `中部时间凌晨 2:00 🕑 前`,
    dialog_pickup_schedule_learn_more_2_2_5: `，都可以进行预约。康涅狄格州凌晨2:00 之后，不提供当日取件服务。如果取消取件请求，请在`,
    dialog_pickup_schedule_learn_more_2_2_6: `取货当天上午 2:00之前`,
    dialog_pickup_schedule_learn_more_2_2_7: `进行。`,
    dialog_pickup_schedule_learn_more_3: `取件的限制`,
    dialog_pickup_schedule_learn_more_3_1_1: `单个物品重量不能超过`,
    dialog_pickup_schedule_learn_more_3_1_2: `70磅`,
    dialog_pickup_schedule_learn_more_3_1_3: `，或总长度和周长超过`,
    dialog_pickup_schedule_learn_more_3_1_4: `130英寸。📦 重量超过10盎司或厚度超过1/2英寸、贴有邮票的邮件`,
    dialog_pickup_schedule_learn_more_3_1_5: `您必须去邮局进行投递，出示给邮局柜台的员工。`,
    dialog_pickup_schedule_learn_more_4: `在您安排取件之后...`,
    dialog_pickup_schedule_learn_more_4_1_1: `成功安排取件后，您将收到一封 📧 `,
    dialog_pickup_schedule_learn_more_4_1_2: `确认取件的电子邮件`,
    dialog_pickup_schedule_learn_more_4_1_3: `，其中包含有您取件信息的详细概述和预约确认号码。`,
    dialog_pickup_schedule_learn_more_5: `如果USPS没有取件，该怎么办？`,
    dialog_pickup_schedule_learn_more_5_1_1: `首先，您需要了解，`,
    dialog_pickup_schedule_learn_more_5_1_2: `所有的取件都是通过 USPS 安排的`,
    dialog_pickup_schedule_learn_more_5_1_3: `，如果邮递员没有取走您的邮件，我们建议您可以再次预约或者直接去 👉 `,
    dialog_pickup_schedule_learn_more_5_1_4: `USPS邮局`,
    dialog_pickup_schedule_learn_more_5_1_5: `投递。`,
    dialog_pickup_schedule_learn_more_6_1: `😉 如果您还想了解更多关于上门取件服务的信息。可以访问`,
    dialog_pickup_schedule_learn_more_6_2: `USPS官网`,
    dialog_pickup_schedule_learn_more_6_3: `查询更多内容。`,
    dialog_pickup_schedule_learn_more_7: `好`,
    // ----------------------------------------------------------------------
    dialog_notifications_1: `通知`,
    dialog_notifications_2_1: `全部`,
    dialog_notifications_2_2: `未读`,
    dialog_notifications_3: `查看更多`,
    dialog_notifications_4: `您还没有消息通知`,
    // ----------------------------------------------------------------------
    dialog_item_1_1: `添加商品`,
    dialog_item_1_2: `编辑商品`,
    dialog_item_2: `您可以为您的自定义店铺添加一个或多个商品。`,
    dialog_item_3: `商品信息`,
    dialog_item_3_1: `自定义店铺`,
    dialog_item_3_1_1: `添加自定义店铺`,
    dialog_item_3_2: `商品名称`,
    dialog_item_3_3: `SKU`,
    dialog_item_3_4: `数量`,
    dialog_item_3_5: `价格`,
    dialog_item_3_6: `图片链接`,
    dialog_item_4: `尺寸 & 重量`,
    dialog_item_4_1: `长`,
    dialog_item_4_2: `宽`,
    dialog_item_4_3: `高`,
    dialog_item_4_4: `in`,
    dialog_item_4_5: `重量`,
    dialog_item_4_6: `lb`,
    dialog_item_4_7: `oz`,
    dialog_item_5: `更新信息（选填）`,
    dialog_item_5_1: `海关关税编号`,
    dialog_item_5_2: `原产地`,
    dialog_item_5_3: `样式`,
    dialog_item_5_4: `颜色`,
    dialog_item_5_5: `尺寸`,
    dialog_item_6: `取消`,
    dialog_item_7_1: `添加`,
    dialog_item_7_2: `保存`,
    // ----------------------------------------------------------------------
    dialog_ups_accept_1_1: `购买您的第一个UPS运单`,
    dialog_ups_accept_1_1_1: `确认您的发货地址`,
    dialog_ups_accept_1_1_2: `*请注意，该地址将是您UPS账户的默认退货地址，请谨慎填写。`,
    dialog_ups_accept_2_1: `祝贺您! 🎉您离购买您的第一个UPS运单只有一步之遥。为了继续，请同意并接受`,
    dialog_ups_accept_2_2: `Terms and Conditions`,
    dialog_ups_accept_2_3: `UPS Prohibited Articles`,
    dialog_ups_accept_2_4: `以及`,
    dialog_ups_accept_2_5: `UPS Technology Agreement`,
    dialog_ups_accept_3_1: `同意并购买`,
    dialog_ups_accept_3_2: `取消`,
    // ----------------------------------------------------------------------
    response_service_level_desc_FIRST_CLASS_MAIL: `经济实惠且简便的发送信封和轻量级包裹的方式，最大邮寄限重为15.99盎司`,
    response_service_level_desc_PRIORITY_MAIL: `USPS最热门的邮寄选项，适合邮寄70磅以下的包裹，可以使用USPS统一邮资包裹或您自己的包装箱`,
    response_service_level_desc_PRIORITY_MAIL_EXPRESS: `文件或包裹会在一到两天内送达（包括星期日和节假日）`,
    response_service_level_desc_MEDIA_MAIL: `USPS针对邮寄书籍、录像带、DVD、CD、印刷音乐和其他录音的专属渠道`,
    response_service_level_desc_LIBRARY_MAIL: `图书馆、学术机构、博物馆、非营利组织和类似组织可以将借阅的物品相互发送`,
    response_service_level_desc_PARCEL_SELECT_GROUND: `适合邮寄大件或重件包裹以极低的费率提供地面递送服务`,
    response_service_level_desc_FIRST_CLASS_INTERNATIONAL: `一种经济实惠的方式，可以将邮件（包括信件、账单和明信片）以及重量不超过4磅的小包裹发送到国际目的地`,
    response_service_level_desc_PRIORITY_MAIL_INTERNATIONAL: `是一种将重达70磅以内的邮件和包裹发送到世界各地目的地的快捷方式。货件通常在 6-10 天内到达目的地`,
    response_service_level_desc_PRIORITY_MAIL_EXPRESS_INTERNATIONAL: `发送的包裹将在 3-5 个工作日内到达目的地地址`,
    response_service_level_desc_GROUND: `价格经济实惠的陆运选择，可让您的包裹在1-5个工作日送达`,
    response_service_level_desc_STANDARD: `保证经济标准的地面运输，非常适合来往美国的不太紧急的物品运输（阿拉斯加和夏威夷除外）`,
    response_service_level_desc_SAVER: `国际快递运输，保证在一天结束时送达；下个工作日结束前可送至加拿大；二天的工作日送达墨西哥和世界主要大都市地区`,
    response_service_level_desc_WORLDWIDE_EXPRESS: `运送国际货运到世界各地，准时送达和退款保证，提供内部清关服务；最多3次交付尝试`,
    response_service_level_desc_WORLDWIDE_EXPRESS_PLUS: `提供准时送达或退款保证，是依靠内部清关的门到门运输服务；最多3次交付尝试`,
    response_service_level_desc_WORLDWIDE_EXPEDITED: `加急运输是针对较紧急的国际小包装货物的快速；保证全天的快速交货或退款`,
    response_service_level_desc_NEXT_DAY_AIR: `是保证第二天交货的送货服务，UPS次日空运10am-12pm提供限时服务退款保障`,
    response_service_level_desc_NEXT_DAY_AIR_SAVER: `最实惠的第二天送货服务，下午3:00或下午4:30之前送达大多数商业目的地或一天结束之前到达居住地点`,
    response_service_level_desc_NEXT_DAY_AIR_EARLY: `包裹将在第二天早晨8点之前送达`,
    response_service_level_desc_SECOND_DAY_AIR: `对于不需要隔夜运送的包裹，保证第二天送达。是无需隔夜服务的货运的经济选择`,
    response_service_level_desc_SECOND_DAY_AIR_AM: `包裹将在第二个工作日上午10:30或下午12:00前到达大多数商业目的地。(不适用于住宅地址)`,
    response_service_level_desc_THIRD_DAY_SELECT: `保证在三个工作日内交货；可运送对时效不太敏感的物品；适用于美国48个邻近地区的包裹`,
    response_service_level_desc_SMART_POST: `具有成本效益的运输选项，适合在美国境内运输重量低于70磅的包裹`,
    response_service_level_desc_SUREPOST_LESS_1LB: `是一种为您的非紧急包裹提供的经济邮寄服务`,
    response_service_level_desc_SUREPOST_1LB_GREATER: `是一种为您的非紧急包裹提供的经济邮寄服务`,
    response_service_level_desc_DHL_EXPRESS_WORLDWIDE: `交付国际文件最快最安全的方式之一，信封重量需小于1磅`,
    response_service_level_desc_DHL_EXPRESS_WORLDWIDE_NONDOC: `交付国际货件最快最安全的方式之一，包裹最大限重70公斤/150磅，最大尺寸120x80x80 厘米（47.2x31.5x31.5英寸）`,
    response_service_level_desc_DHL_EXPRESS_1200: `在下一个可能的交货日中午12点前送达，支持退款保证政策。`,
    response_service_level_desc_DHL_EXPRESS_1200_NONDOC: `在下一个可能的交货日中午12点前送达，支持退款保证政策。`,
    response_service_level_desc_DHL_EXPRESS_ENVELOPE: `使用DHL Express Envelope，您可以确保文件不受海关程序的限制。`,
    response_service_level_desc_DHL_MEDICAL_EXPRESS: `专为运输制药和医疗行业对时间和温度敏感的货物而开发。`,
    response_service_level_desc_DHL_MEDICAL_EXPRESS_NODOC: `专为运输制药和医疗行业对时间和温度敏感的货物而开发。`,
    response_service_level_desc_DHL_PARCEL_EXPEDITED_MAX: ``,
    response_service_level_desc_DHL_PACKET_INTERNATIONAL: `Access to your own pre-negotiated rates.`,
    response_service_level_desc_DHL_PARCEL_INTERNATIONAL_STANDARD: `Access to your own pre-negotiated rates.`,
    response_service_level_desc_DHL_PARCEL_INTERNATIONAL_DIRECT: `Access to your own pre-negotiated rates.`,
    response_service_level_desc_ONTRAC_SUNRISE: `OnTrac提供Sunrise, Sunrise Gold, Ground & Palletized Freight服务以满足您的定制运输需求。`,
    response_service_level_desc_ONTRAC_SUNRISE_GOLD: `OnTrac提供Sunrise, Sunrise Gold, Ground & Palletized Freight服务以满足您的定制运输需求。`,
    response_service_level_desc_ONTRAC_PALLETIZED_FREIGHT: `OnTrac提供Sunrise, Sunrise Gold, Ground & Palletized Freight服务以满足您的定制运输需求。`,
    response_service_level_desc_ONTRAC_GROUND: `OnTrac提供Sunrise, Sunrise Gold, Ground & Palletized Freight服务以满足您的定制运输需求。`,
    response_service_level_desc_ONTRAC_SAME_DAY: `OnTrac提供Sunrise, Sunrise Gold, Ground & Palletized Freight服务以满足您的定制运输需求。`,
    response_service_level_desc_FEDEX_INTERNATIONAL_CONNECT_PLUS: `适用于重量不超过70磅、长度加周长不超过130英寸且不含危险品的货物`,
    response_service_level_desc_USPS_GROUND_ADVANTAGE: `一种经济实惠且可靠的方式，可让您的包裹在2-5个工作日内送达`,
    // ----------------------------------------------------------------------
    form_street1: `地址栏 1`,
    form_street2: `地址栏 2（选填）`,
    form_city: `城市`,
    form_zipcode: `邮编`,
    form_state: `州`,
    form_country: `国家`,
    // ----------------------------------------------------------------------
    input_schema_login_form_1_1: `请输入有效的电子邮件地址`,
    input_schema_login_form_1_2: `请输入电子邮件`,
    input_schema_login_form_2: `请输入密码`,
    // ----------------------------------------------------------------------
    input_schema_register_form_1: `请输入名字`,
    input_schema_register_form_2: `请输入姓氏`,
    input_schema_register_form_3_1: `请输入有效的电子邮件地址`,
    input_schema_register_form_3_2: `请输入电子邮件`,
    input_schema_register_form_4_1: `密码必须至少有8个字母`,
    input_schema_register_form_4_2: `密码不能超过20个字母`,
    input_schema_register_form_4_3: `密码必须包含1个小写字母、1个大写字母、1个数字或1个符号`,
    input_schema_register_form_4_4: `请不要与电子邮件@前的字母相同`,
    input_schema_register_form_4_5: `请不要包含超过3个连续或重复的数字/字母`,
    input_schema_register_form_4_6: `请输入密码`,
    input_schema_register_form_6: `密码不匹配`,
    input_schema_register_form_5: `您必须同意ShipSaving的条款和隐私政策才能创建账号`,
    input_schema_register_form_7: `Plan required`,
    // ----------------------------------------------------------------------
    input_schema_reset_password_form_1_1: `请输入有效的电子邮件地址`,
    input_schema_reset_password_form_1_2: `请输入电子邮件`,
    input_schema_reset_password_form_2: `邮件已发送。`,
    // ----------------------------------------------------------------------
    input_schema_account_update_form_1: `请输入名字`,
    input_schema_account_update_form_1_1: `名字必须在20个字符以下`,
    input_schema_account_update_form_2: `请输入姓氏`,
    input_schema_account_update_form_2_1: `姓氏必须在20个字符以下`,
    // ----------------------------------------------------------------------
    input_schema_account_change_password_form_1: `请输入旧密码`,
    input_schema_account_change_password_form_2_1: `请输入新密码`,
    input_schema_account_change_password_form_2_2: `密码必须至少有8个字母`,
    input_schema_account_change_password_form_2_3: `密码不能超过20个字母`,
    input_schema_account_change_password_form_2_4: `密码必须包含1个小写字母、1个大写字母、1个数字或1个符号`,
    input_schema_account_change_password_form_2_5: `请不要与电子邮件@前的字母相同`,
    input_schema_account_change_password_form_2_6: `请不要包含超过3个连续或重复的数字/字母`,
    input_schema_account_change_password_form_2_7: `新密码请不要与旧密码相同`,
    input_schema_account_change_password_form_3: `密码不匹配`,
    // ----------------------------------------------------------------------
    input_schema_account_manage_company_1: `请输入公司名字`,
    input_schema_account_manage_company_2: `请输入电话`,
    input_schema_account_manage_company_3: `请输入邮件`,
    input_schema_account_manage_company_4: `请输入地址`,
    input_schema_account_manage_company_5: `请输入城市`,
    input_schema_account_manage_company_6: `请输入邮编`,
    input_schema_account_manage_company_7: `请输入州`,
    input_schema_account_manage_company_8: `请输入国家`,
    // ----------------------------------------------------------------------
    input_schema_bank_account_form_1: `请选择账户类型`,
    input_schema_bank_account_form_2: `请输入Account Number`,
    input_schema_bank_account_form_3: `请输入Routing Number`,
    input_schema_bank_account_form_4: `请输入姓名`,
    input_schema_bank_account_form_5: `请填写地址1`,
    input_schema_bank_account_form_6: `请输入城市`,
    input_schema_bank_account_form_7: `请输入州`,
    input_schema_bank_account_form_8: `请输入邮编`,
    input_schema_bank_account_form_9: `您必须同意ShipSaving的条款和隐私政策才能创建账号`,
    // ----------------------------------------------------------------------
    input_schema_card_form_1: `请输入姓名`,
    input_schema_card_form_1_1: `姓名栏不支持输入数字或特殊字符`,
    input_schema_card_form_2: `请输入银行卡卡号`,
    input_schema_card_form_2_1: `卡号长度最多为16位数`,
    input_schema_card_form_2_2: `卡号只能包含数字`,
    input_schema_card_form_3: `请输入到期月份`,
    input_schema_card_form_4: `请输入到期年份`,
    input_schema_card_form_5: `请输入CVV`,
    input_schema_card_form_6: `请填写地址1`,
    input_schema_card_form_7: `请输入城市`,
    input_schema_card_form_8: `请输入州`,
    input_schema_card_form_9: `请输入邮编`,
    input_schema_card_form_10: `您必须同意ShipSaving的条款和隐私政策才能创建账号`,
    // ----------------------------------------------------------------------
    input_schema_recharge_form_1: `Amount is required`,
    input_schema_recharge_form_1_1: `The minimum amount is $500`,
    input_schema_recharge_form_2: `Payment Method is required`,
    input_schema_recharge_form_3: `Name or company name is required`,
    input_schema_recharge_form_4: `Street 1 is required`,
    input_schema_recharge_form_5: `Screenshot is required`,
    // ----------------------------------------------------------------------
    input_schema_card_verify_form_1_1: `金额会在0.01至0.99之间`,
    input_schema_card_verify_form_1_2: `金额只允许小数点后的2位数字`,
    input_schema_card_verify_form_1_3: `请输入金额`,
    input_schema_card_verify_form_1_4: `两笔验证金额不能为相同值`,
    // ----------------------------------------------------------------------
    input_schema_address_form_1: `请输入名字`,
    input_schema_address_form_1_1: `First Name must be under 50 characters`,
    input_schema_address_form_2: `请输入姓氏`,
    input_schema_address_form_2_1: `Last Name must be under 50 characters`,
    input_schema_address_form_3: `请输入电话`,
    input_schema_address_form_3_1: `Phone must be under 20 characters`,
    input_schema_address_form_4: `请输入地址`,
    input_schema_address_form_5: `请输入城市`,
    input_schema_address_form_6: `请输入州`,
    input_schema_address_form_7: `请输入邮编`,
    input_schema_address_form_8: `请输入国家`,
    input_schema_address_form_9: `Warehouse Name is required`,
    input_schema_address_form_10: `Email must be a valid email address`,
    input_schema_address_form_10_1: `Email must be under 50 characters`,
    input_schema_address_form_11: `Company Name must be under 50 characters`,
    input_schema_address_form_12: `Only alphabet, punctuation and numbers are allowed`,
    // ----------------------------------------------------------------------
    input_schema_package_form_1_1: `昵称必须在20个字符以下`,
    input_schema_package_form_1_2: `请输入昵称`,
    input_schema_package_form_2_1: `最小长度为6"`,
    input_schema_package_form_2_2: `承运商限制最大长度+腰围（宽度*2+高度*2）是165英寸，但您的包装箱是`,
    input_schema_package_form_2_3: `请输入尺寸长度`,
    input_schema_package_form_3_1: `最小宽度为3"`,
    input_schema_package_form_3_2: `承运商限制最大长度+腰围（宽度*2+高度*2）是165英寸，但您的包装箱是`,
    input_schema_package_form_3_3: `请输入尺寸宽度`,
    input_schema_package_form_4_1: `最小高度为0.25"`,
    input_schema_package_form_4_2: `承运商限制最大长度+腰围（宽度*2+高度*2）是165英寸，但您的包装箱是`,
    input_schema_package_form_4_3: `请输入尺寸高度`,
    // ----------------------------------------------------------------------
    input_schema_bill_account_form_1: `Nickname is required`,
    input_schema_bill_account_form_2: `Account Number is required`,
    input_schema_bill_account_form_3: `Zip Code is required`,
    input_schema_bill_account_form_4: `Country is required`,
    // ----------------------------------------------------------------------
    input_schema_add_store_form_1_1: `Store nickname must be under 50 characters`,
    input_schema_add_store_form_1_2: `Nickname is required`,
    input_schema_add_store_form_2: `Ship From is required`,
    // ----------------------------------------------------------------------
    input_schema_add_carrier_form_1_1: `昵称必须在20个字符以下`,
    input_schema_add_carrier_form_1_2: `请输入昵称`,
    input_schema_add_carrier_form_2: `请输入联系人姓名`,
    input_schema_add_carrier_form_3: `请输入职位`,
    input_schema_add_carrier_form_4: `请输入公司名字`,
    input_schema_add_carrier_form_5: `请输入公司官网链接`,
    input_schema_add_carrier_form_6: `请输入公司邮件`,
    input_schema_add_carrier_form_7: `请输入公司电话`,
    input_schema_add_carrier_form_8: `请填写街道1`,
    input_schema_add_carrier_form_9: `请输入城市`,
    input_schema_add_carrier_form_10: `请输入州`,
    input_schema_add_carrier_form_11: `请输入邮编`,
    input_schema_add_carrier_form_12: `请输入国家`,
    input_schema_add_carrier_form_13: `请输入Account Number`,
    input_schema_add_carrier_form_14: `请输入Account Zipcode`,
    input_schema_add_carrier_form_15: `请输入国家`,
    input_schema_add_carrier_form_16: `请输入发票日期`,
    input_schema_add_carrier_form_17: `请输入发票号码`,
    input_schema_add_carrier_form_18: `请输入Control ID`,
    input_schema_add_carrier_form_19: `请输入发票金额`,
    // ----------------------------------------------------------------------
    input_schema_edit_carrier_form_1_1: `昵称必须在20个字符以下`,
    input_schema_edit_carrier_form_1_2: `请输入昵称`,
    // ----------------------------------------------------------------------
    input_schema_store_form_1_2: `请输入姓名`,
    // ----------------------------------------------------------------------
    input_schema_add_edit_order_form_1: `请选择店铺`,
    input_schema_add_edit_order_form_2: `请输入名字`,
    input_schema_add_edit_order_form_3: `请输入电话`,
    input_schema_add_edit_order_form_4: `请输入地址栏1`,
    input_schema_add_edit_order_form_5: `请输入城市`,
    input_schema_add_edit_order_form_6: `请输入邮编`,
    input_schema_add_edit_order_form_7: `请输入邮编`,
    input_schema_add_edit_order_form_8: `请输入国家`,
    input_schema_add_edit_order_form_9: `商品无效，请同时填写SKU和数量`,
    input_schema_add_edit_order_form_10: `总价格请大于0`,
    input_schema_add_edit_order_form_11: `税值请大于0`,
    input_schema_add_edit_order_form_12: `运费请大于0`,
    // ----------------------------------------------------------------------
    input_schema_ship_form_1: `请输入姓名`,
    input_schema_ship_form_1_1: `请输入名字`,
    input_schema_ship_form_1_2: `请输入姓氏`,
    input_schema_ship_form_2: `请输入地址栏1`,
    input_schema_ship_form_3: `请输入城市`,
    input_schema_ship_form_4: `请输入州`,
    input_schema_ship_form_5: `请输入邮编`,
    input_schema_ship_form_6: `请输入国家`,
    input_schema_ship_form_7_1: `国际包裹请输入电话`,
    input_schema_ship_form_7_2: `保存发件地址时，请输入电话`,
    input_schema_ship_form_8_1_1: `最小限重为0.01 lb`,
    input_schema_ship_form_8_1_1_1: `The maximum weight for letter is 0.21 lb`,
    input_schema_ship_form_8_1_2: `最大限重为150 lb`,
    input_schema_ship_form_8_1_3: `请输入重量`,
    input_schema_ship_form_8_2_1: `最小限重为0.16 oz`,
    input_schema_ship_form_8_2_2: `最大限重为15.99 oz`,
    input_schema_ship_form_8_2_2_1: `The maximum weight for letter is 3.5 oz`,
    input_schema_ship_form_8_2_3: `请输入重量`,
    input_schema_ship_form_8_3_1: `最小限重为0.01 kg`,
    input_schema_ship_form_8_3_2: `最大限重为68.03 kg`,
    input_schema_ship_form_8_3_2_1: `The maximum weight for letter is 0.09 kg`,
    input_schema_ship_form_8_3_3: `请输入重量`,
    input_schema_ship_form_8_4_1: `最小限重为4.54 g`,
    input_schema_ship_form_8_4_2: `最大限重为999.99 g`,
    input_schema_ship_form_8_4_2_1: `The maximum weight for letter is 97.5 g`,
    input_schema_ship_form_8_4_3: `请输入重量`,
    input_schema_ship_form_8_5: `最大包裹申报价值为5000`,
    input_schema_ship_form_9_1: `最小长度为6"`,
    input_schema_ship_form_9_2: `请输入尺寸长度`,
    input_schema_ship_form_9_3: `承运商限制最大长度+腰围（宽度*2+高度*2）是165英寸，但您的包装箱是`,
    input_schema_ship_form_10_1: `最小限宽为3"`,
    input_schema_ship_form_10_2: `请输入尺寸宽度`,
    input_schema_ship_form_10_3: `承运商限制最大长度+腰围（宽度*2+高度*2）是165英寸，但您的包装箱是`,
    input_schema_ship_form_11_1: `最小限高为0.25"`,
    input_schema_ship_form_11_2: `请输入尺寸高度`,
    input_schema_ship_form_11_3: `承运商限制最大长度+腰围（宽度*2+高度*2）是165英寸，但您的包装箱是`,
    input_schema_ship_form_12_1: `请填写描述`,
    input_schema_ship_form_12_2: `请输入国家`,
    input_schema_ship_form_12_3_1: `数量必须超过1`,
    input_schema_ship_form_12_3_2: `请输入数量`,
    input_schema_ship_form_12_4_1: `金额必须大于0.01`,
    input_schema_ship_form_12_4_2: `请输入金额`,
    input_schema_ship_form_13: `地址必须在35个字符以下，请在地址栏2输入剩余信息`,
    input_schema_ship_form_14: `地址必须在35个字符以下`,
    input_schema_ship_form_15_1: `自定义打印#1必须在20个字符以下`,
    input_schema_ship_form_15_2: `自定义打印#2必须在20个字符以下`,
    // ----------------------------------------------------------------------
    input_schema_pickup_schedule_form_1: `说明不能超过75个字母`,
    // ----------------------------------------------------------------------
    input_schema_calculator_domestic_form_1: `请输入邮编`,
    input_schema_calculator_domestic_form_2: `请输入邮编`,
    input_schema_calculator_domestic_form_3_1_1: `最小限重为0.01 lb`,
    input_schema_calculator_domestic_form_3_1_2: `最大限重为150 lb`,
    input_schema_calculator_domestic_form_3_1_3: `请输入重量`,
    input_schema_calculator_domestic_form_3_2_1: `最小限重为0.16 oz`,
    input_schema_calculator_domestic_form_3_2_2: `最大限重为15.99 oz`,
    input_schema_calculator_domestic_form_3_2_3: `请输入重量`,
    input_schema_calculator_domestic_form_3_3_1: `最小限重为0.01 kg`,
    input_schema_calculator_domestic_form_3_3_2: `最大限重为68.03 kg`,
    input_schema_calculator_domestic_form_3_3_3: `请输入重量`,
    input_schema_calculator_domestic_form_3_4_1: `最小限重为4.54 g`,
    input_schema_calculator_domestic_form_3_4_2: `最大限重为999.99 g`,
    input_schema_calculator_domestic_form_3_4_2_1: `The maximum weight for letter is 0.21 lb`,
    input_schema_calculator_domestic_form_3_4_3: `请输入重量`,
    input_schema_calculator_domestic_form_4_1: `最小长度为6"`,
    input_schema_calculator_domestic_form_4_2: `请输入尺寸长度`,
    input_schema_calculator_domestic_form_4_3: `承运商限制最大长度+腰围（宽度*2+高度*2）是165英寸，但您的包装箱是`,
    input_schema_calculator_domestic_form_5_1: `最小限宽为3"`,
    input_schema_calculator_domestic_form_5_2: `请输入尺寸宽度`,
    input_schema_calculator_domestic_form_5_3: `承运商限制最大长度+腰围（宽度*2+高度*2）是165英寸，但您的包装箱是`,
    input_schema_calculator_domestic_form_6_1: `最小限高为0.25"`,
    input_schema_calculator_domestic_form_6_2: `请输入尺寸高度`,
    input_schema_calculator_domestic_form_6_3: `承运商限制最大长度+腰围（宽度*2+高度*2）是165英寸，但您的包装箱是`,
    // ----------------------------------------------------------------------
    input_schema_calculator_international_form_1: `请输入国家`,
    input_schema_calculator_international_form_2: `请输入国家`,
    input_schema_calculator_international_form_3: `请输入城市`,
    input_schema_calculator_international_form_4: `请输入城市`,
    input_schema_calculator_international_form_5: `请输入邮编`,
    input_schema_calculator_international_form_6: `请输入邮编`,
    input_schema_calculator_international_form_7_1_1: `最小限重为0.01 lb`,
    input_schema_calculator_international_form_7_1_2: `最大限重为150 lb`,
    input_schema_calculator_international_form_7_1_3: `请输入重量`,
    input_schema_calculator_international_form_7_2_1: `最小限重为0.16 oz`,
    input_schema_calculator_international_form_7_2_2: `最大限重为15.99 oz`,
    input_schema_calculator_international_form_7_2_3: `请输入重量`,
    input_schema_calculator_international_form_7_3_1: `最小限重为0.01 kg`,
    input_schema_calculator_international_form_7_3_2: `最大限重为68.03 kg`,
    input_schema_calculator_international_form_7_3_3: `请输入重量`,
    input_schema_calculator_international_form_7_4_1: `最小限重为4.54 g`,
    input_schema_calculator_international_form_7_4_2: `最大限重为999.99 g`,
    input_schema_calculator_international_form_7_4_3: `请输入重量`,
    input_schema_calculator_international_form_8_1: `最小长度为6"`,
    input_schema_calculator_international_form_8_2: `请输入尺寸长度`,
    input_schema_calculator_international_form_8_3: `承运商限制最大长度+腰围（宽度*2+高度*2）是165英寸，但您的包装箱是`,
    input_schema_calculator_international_form_9_1: `最小限宽为3"`,
    input_schema_calculator_international_form_9_2: `请输入尺寸宽度`,
    input_schema_calculator_international_form_9_3: `承运商限制最大长度+腰围（宽度*2+高度*2）是165英寸，但您的包装箱是`,
    input_schema_calculator_international_form_10_1: `最小限高为0.25"`,
    input_schema_calculator_international_form_10_2: `请输入尺寸高度`,
    input_schema_calculator_international_form_10_3: `承运商限制最大长度+腰围（宽度*2+高度*2）是165英寸，但您的包装箱是`,
    // ----------------------------------------------------------------------
    input_schema_order_import_form_1: `请选择店铺`,
    input_schema_order_import_form_2: `请选择文件`,
    // ----------------------------------------------------------------------
    input_schema_order_export_form_1: `请选择起始日期`,
    input_schema_order_export_form_2: `请选择结束日期`,
    input_schema_order_export_form_3: `请选择店铺`,
    input_schema_order_export_form_4: `请选择状态`,
    input_schema_order_export_form_5: `下载该文件时出现错误，请重新尝试。`,
    // ----------------------------------------------------------------------
    input_schema_transaction_export_form_1: `请选择付款类型`,
    input_schema_transaction_export_form_2: `请选择付款年份`,
    // ----------------------------------------------------------------------
    input_schema_shipment_batch_label_download_form_1: `请选择店铺`,
    input_schema_shipment_batch_label_download_form_2: `请选择运单尺寸`,
    input_schema_shipment_batch_label_download_form_3: `请选择日期`,
    input_schema_shipment_batch_label_download_form_4: `下载该文件时出现错误，请重新尝试。`,
    input_schema_shipment_batch_label_download_form_5: `请选择起始日期`,
    input_schema_shipment_batch_label_download_form_6: `请选择结束日期`,
    // ----------------------------------------------------------------------
    input_schema_item_form_1: `Store is required`,
    input_schema_item_form_2_1: `Item Name max Length is 512`,
    input_schema_item_form_2_2: `Item Name is required`,
    input_schema_item_form_3_1: `SKU max Length is 128`,
    input_schema_item_form_3_2: `SKU is required`,
    input_schema_item_form_4: `Price no less than 0`,
    input_schema_item_form_5: `Thumbnails max Length is 191`,
    input_schema_item_form_6: `Length no less than`,
    input_schema_item_form_7: `Width no less than`,
    input_schema_item_form_8: `Height no less than`,
    input_schema_item_form_9_1: `lb no less than`,
    input_schema_item_form_9_2: `lb max Length is 150`,
    input_schema_item_form_10_1: `oz no less than`,
    input_schema_item_form_10_2: `oz max Length is 15`,
    // ----------------------------------------------------------------------
    com_shipsaving_auth_pwd_incorrect: `您输入的邮箱或密码不正确，请重试。`,
    com_shipsaving_company_type: `This user is not belong to new client. Please contact your account admin.`,
    com_shipsaving_user_active_fail: `Your user account is fail to active, please contact us at support@shipsaving.com.`,
    com_shipsaving_user_no_exist: `This user does not exist. Please check that you have typed correctly.`,
    com_shipsaving_email_registered: `此邮箱已被注册，请尝试直接登录。`,
    com_shipsaving_mail_send: `An error occurred while sending mail, please verify your email settings.`,
    com_shipsaving_email_exist: `The email address entered already exists in the system.`,
    com_shipsaving_user_active_url_expired: `It looks like your URL has expired, please request a new one!`,
    com_shipsaving_user_reset_pwd_fail: `密码重置失败`,
    com_shipsaving_user_api_token_fail: `请联系主用户获取此API Token。`,
    customer_company_no_exist: `公司不存在`,
    com_shipsaving_transaction_invoive_fail: `无法获取发票，请稍后再试。`,
    com_shipsaving_shipments_not_found: `未找到符合搜索的包裹订单信息，请重新尝试或联系我们以获取帮助。`,
    com_shipsaving_label_share_code_not_found: `在本服务器上没有找到此URL。`,
    com_shipsaving_shipments_overdue: `You cannot void this shipment because the time limit for void has been exceeded.`,
    com_shipsaving_shipments_voied: `This shipment has already been voided.`,
    com_shipsaving_shipment_invalid_shipment_id: `The shipment id is not exist, need to get rate again!`,
    com_shipsaving_shipment_invalid_rate_id: `请点击查询运费获取最新报价。`,
    com_shipsaving_shipment_qrcode_nosupport: `此承运商不支持运单二维码。`,
    com_shipsaving_shipment_created: `此包裹订单已经完成创建。`,
    com_shipsaving_shipment_create_fail: `在创建包裹订单时发生了错误，请稍后再试，或通过support@shipsaving.com与我们联系。`,
    com_shipsaving_shipment_create_fail_2: `The label has already been purchased for the order and cannot be purchased again`,
    com_shipsaving_shipment_create_lock: `Due to the payment failure when we charged you for the shipping adjustment fee, we temporarily disabled your \\"Buy a Label\\" feature. "`,
    com_shipsaving_shipment_charge_fail: `We were unable to process your payment. Please add a new payment method to your account.`,
    com_shipsaving_shipment_invalid_payment_method: `Your payment method is not valid. Please check your entries carefully and try again.`,
    com_shipsaving_shipments_preview_label_fail_: `Oops! There was a problem previewing this label.`,
    com_shipsaving_shipments_preview_qrcode_fail_: `Oops! There was a problem previewing this qrcode.`,
    com_shipsaving_shipments_create_fail1: `由于您的账户余额不足，无法`,
    com_shipsaving_shipment_rate_fail: `The company plan is free plan, can't get multiple package price at  one rate`,
    com_shipsaving_task_no_exist: `the task not exist`,
    com_shipsaving_task_download_file_error: `There was an error downloading the file.`,
    com_shipsaving_store_auth_fail: `This store authorization is already exist.`,
    com_shipsaving_store_no_support: `This Ecommerce platform is not supported in ShipSaving system.`,
    com_shipsaving_api_param_error: `Request parameter error.`,
    com_shipsaving_store_no_exist: `This store  is not exist.`,
    com_shipsaving_store_not_customized: `This store is not customized.`,
    com_shipsaving_store_platform_no_match: `Platform type is not matched with the modified store.`,
    com_shipsaving_carrier_account_no_support: `This Carrier is not supported in ShipSaving system.`,
    com_shipsaving_carrier_account_auth_no_exist: `This account authorization not exist.`,
    com_shipsaving_carrier_account_auth_duplicate: `Please note same carrier account can only be added once to your ShipSaving account. `,
    com_shipsaving_carrier_account_edit_fail: `This account can't modify`,
    com_shipsaving_carrier_account_no_exist: `This account is not exist.`,
    com_shipsaving_carrier_account_bill_account_no_support: `This account is not supported for add bill account.`,
    com_shipsaving_carrier_account_no_match: `Carrier account not match.`,
    com_shipsaving_carrier_account_auth_fail: `This bill account is not exist.`,
    com_shipsaving_payment_method_delete_fail: `Sorry, you cannot delete this credit card. Please keep at least one credit card on file to ensure you can pay properly when additional charges are applied to your shipment.`,
    com_shipsaving_payment_method_delete_fail1: `Sorry, you cannot delete this credit card. the card is plan fee payment method`,
    com_shipsaving_payment_method_delete_fail2: `您无法删除此张银行卡，因为它是您的默认付款方式。`,
    com_shipsaving_warehouse_no_exist: `This warehouse data is not exist in the system.`,
    com_shipsaving_warehouse_delete_error: `You cannot delete this data because it is a default Ship From address.`,
    com_shipsaving_address_no_exist: `This address data is not exist in the system.`,
    com_shipsaving_address_delete_error: `You cannot delete this data because it is a default Ship To address.`,
    com_shipsaving_package_delete_error: `You cannot delete this data because it is a default package.`,
    com_shipsaving_package_no_exist: `Package is not exist in the system.`,
    com_shipsaving_payment_methods_no_exist: `payment methods not exist`,
    com_shipsaving_payment_methods_delete_fail: `payment methods is primary,can't delete`,
    com_shipsaving_payment_methods_no_verify: `payment methods not verify`,
    com_shipsaving_paymentmethods_creat: `fail to creat payment methods`,
    com_shipsaving_paymentmethods_creat_fail: `fail to creat payment methods,Must be a U.S. card`,
    com_shipsaving_paymentmethods_creat_fail_2: `fail to creat payment methods, no more 2 payment methods`,
    com_shipsaving_payment_methods_creat_bank_account_fail: `fail to creat bank account by boa`,
    com_shipsaving_paymentmethods_modify: `fail to modify payment methods`,
    com_shipsaving_payment_methods_verify_fail: `fail to payment methods verify`,
    com_shipsaving_payment_methods_charge_fail: `fail to charge`,
    com_shipsaving_paymentmethods_check_all: `You have exceeded a maximum of adding five (5) payment methods in total. Please contact customer service via support@shipsaving.com for further actions.`,
    com_shipsaving_paymentmethods_check_not_del: `You have exceeded a maximum of adding two (2) payment methods on either debit/credit card or bank account. Please contact customer service via support@shipsaving.com for further actions`,
    com_shipsaving_paymentmethods_check_exit: `The debit/credit card or bank account you just added has already been added by another company. If this was not you, please contact customer service via support@shipsaving.com immediately.`,
    com_shipsaving_paymentmethods_verify_fail: `verify error more than 3 times, verify failed`,
    com_shipsaving_payment_methods_add_fail: `payment methods add fail more than 5 times, try again later please!`,
    com_shipsaving_payment_methods_add_service_stop: `Please be notified a service maintenance will be conducted on Friday 10/07/2022 until further notice. We sincerely apologize for any inconvenience.`,
    account_redeem_no_rebate: `Not any rebate balance`,
    payment_charge_no_exist: `charge not exist`,
    com_shipsaving_pickup_no_exist: `The pickup not exist`,
    com_shipsaving_pickup_canceled: `The pickup canceled!`,
    com_shipsaving_pickup_creat_fail: `Total package weight must be greater than 1lb`,
    com_shipsaving_webhook_unauthorized: `The request unauthorized`,
    com_shipsaving_order_no_exist: `The order not exist`,
    com_shipsaving_order_status_error: `The order status not awaiting`,
    com_shipsaving_order_no_warehouse: `The order not have warehouse`,
    com_shipsaving_combin_order_status_error: `one of orders status not awaiting`,
    com_shipsaving_order_delete_error1: `The order is not  customized store order,can't delete`,
    com_shipsaving_order_delete_error2: `The order have shipment data ,can't delete`,
    com_shipsaving_order_import_fail: `import orders fail`,
    order_import_order_template_version_error: `orders import_template version error`,
    order_import_order_template_download_fail: `import_order_template download fail`,
    com_shipsaving_item_no_exist: `This item is not exist in the system.`,
    com_shipsaving_item_store_no_support: `Item can only be added to your customized store`,
    com_shipsaving_item_store_no_support_1: `Item can't  be delete ,it not  customized store item`,
    com_shipsaving_item_sku_duplicate: `Item with the same sku has been added before`,
    com_shipsaving_shipping_rule_no_exist: `This shipping rule is not exist in the system.`,
    com_shipsaving_shipping_rule_no_items: `No item selected for this shipping rule`,
    com_shipsaving_shipping_rule_item_no_exist: `Item(s) not exist in this store`,
    com_shipsaving_shipping_rule_item_quantity_error: `Item(s) quantity must be greater than 0`,
    com_shipsaving_shipping_rule_item_combination_exist: `The permutation of item(s) has already been added before`,
    com_shipsaving_picking_list_order_exist: `order have picking list,check please`,
    com_shipsaving_picking_list_order_no_awaiting: `order status is not awaiting,check please`,
    com_shipsaving_picking_list_order_no_same_warehouse: `orders status is not belong same warehouse`,
    com_shipsaving_shipments_download_scan_form_fail_: `啊哦😳SCAN Form无法下载，请重新尝试。`,
    com_shipsaving_plan_change_fail_: `Current plan is choosed`,
    com_shipsaving_plan_change_fail_1_: `Your plan can only be changed once every 30 days.`,
    com_shipsaving_plan_payment_method_error_: `The primary & secondary payment method is same.`,
    com_shipsaving_plan_primary_payment_method_error_: `The primary payment method must be a card.`,
    com_shipsaving_plan_primary_payment_method_not_exist_: `The primary payment method is not exist in the system.`,
    com_shipsaving_plan_secondary_payment_method_error_: `The secondary payment method must be a card.`,
    com_shipsaving_plan_secondary_payment_method_not_exist_: `The secondary payment method is not exist in the system.`,
    order_task_request_fail: `order task operation fail`,
    default_error_msg: `Something went wrong`,
    payment_paymentmethods_blacklist: `由于此账单地址之前存在异常行为已被封锁。请联系我们的客服团队support@shipsaving.com以获得进一步帮助。`,
    com_shipsaving_payment_methods_add_fail_blacklist: `系统在请求添加您的银行卡时发生异常错误，为了确保您的账户安全，请联系我们的客服团队。`,
    com_shipsaving_shipment_rate_name_over_length: `姓名长度加在一起不可超过35个字符。`,
    com_shipsaving_shipment_rate_name_all_spaces: `名字和姓氏不能全部为空格，请重新输入。`,
    com_shipsaving_default_error: `很抱歉，系统遇到了未知错误。请稍后再试或联系客服支持。`,
};

export default cn;
