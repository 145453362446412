import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';
import axios, {API_ENDPOINTS} from "../../utils/axios";

const initialState = {
    isLoading: false,
    error: null,
    invoiceList: null,
    pickingList: null,
    packingSlip: null,
};

const slice = createSlice({
    name: 'documentation',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state, action) {
            state.isLoading = action.payload;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET INVOICE SUCCESS
        getInvoiceSuccess(state, action) {
            state.isLoading = false;
            state.invoiceList = action.payload;
        },

        // GET PICKING SUCCESS
        getPickingSuccess(state, action) {
            state.isLoading = false;
            state.pickingList = action.payload;
        },

        // GET PACKING SUCCESS
        getPackingSlipSuccess(state, action) {
            state.isLoading = false;
            state.packingSlip = action.payload;
        },

        // UPDATE INVOICE SUCCESS
        updateInvoiceSuccess(state, action) {
            state.isLoading = false;
            state.invoiceList = {
                ...state.invoiceList,
                ...action.payload,
            }
        },

        // UPDATE PICKING LIST SUCCESS
        updatePickingListSuccess(state, action) {
            state.isLoading = false;
            state.pickingList = {
                ...state.pickingList,
                ...action.payload,
            }
        },

        // UPDATE PACKING SLIP SUCCESS
        updatePackingSlipSuccess(state, action) {
            state.isLoading = false;
            state.packingSlip = {
                ...state.packingSlip,
                ...action.payload,
            }
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getInvoice() {
    return async () => {
        dispatch(slice.actions.startLoading(true));
        try {
            const response = await axios.get(API_ENDPOINTS.settings.invoice);
            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getInvoiceSuccess(data?.data ?? null));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function modifyInvoice(detail) {
    return async () => {
        try {
            const response = await axios.post(API_ENDPOINTS.settings.updateInvoice, detail);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.updateInvoiceSuccess(data.data ?? null));
            }
            return response;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function getPickingList() {
    return async () => {
        dispatch(slice.actions.startLoading(true));
        try {
            const response = await axios.get(API_ENDPOINTS.settings.pickingList);
            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getPickingSuccess(data?.data ?? null));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function modifyPickingList(detail) {
    return async () => {
        try {
            const response = await axios.post(API_ENDPOINTS.settings.updatePickingList, detail);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.updatePickingListSuccess(data.data ?? null));
            }
            return response;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function getPackingSlip() {
    return async () => {
        dispatch(slice.actions.startLoading(true));
        try {
            const response = await axios.get(API_ENDPOINTS.settings.packingSlip);
            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getPackingSlipSuccess(data?.data ?? null));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function modifyPackingSlip(detail) {
    return async () => {
        try {
            const response = await axios.post(API_ENDPOINTS.settings.updatePackingSlip, detail);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.updatePackingSlipSuccess(data.data ?? null));
            }
            return response;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}