// @mui
import {styled} from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton from '@mui/material/ListItemButton';
// theme
import {paper} from 'src/theme/css';
//
import {HEADER} from '../../../config-layout';

// ----------------------------------------------------------------------

export const ListItem = styled(ListItemButton, {
    shouldForwardProp: (prop) =>
        prop !== 'active' && prop !== 'open' && prop !== 'offsetTop' && prop !== 'subItem',
})(({active, open, offsetTop, subItem, theme}) => {
    const dotActive = {
        content: '""',
        borderRadius: '50%',
        position: 'absolute',
        width: 6,
        height: 6,
        left: -14,
        opacity: 0.48,
        backgroundColor: 'currentColor',
    };

    return {
        ...theme.typography.subtitle2,
        padding: 0,
        height: '100%',
        color: theme.palette.text.primary,
        transition: theme.transitions.create(['opacity'], {
            duration: theme.transitions.duration.shorter,
        }),
        '&:hover': {
            opacity: 0.48,
            backgroundColor: 'transparent',
        },
        // Sub item
        ...(subItem && {
            ...theme.typography.body2,
            color: theme.palette.text.secondary,
        }),
        // offsetTop
        ...(offsetTop && {
            color: theme.palette.text.primary,
        }),
        // Active
        ...(active && {
            color: theme.palette.primary.main,
            '&::before': dotActive,
        }),
        // Active sub item
        ...(active &&
            subItem && {
                ...theme.typography.subtitle2,
                color: theme.palette.text.primary,
                '&::before': {
                    ...dotActive,
                    color: theme.palette.primary.main,
                },
            }),
        // Open
        ...(open && {
            opacity: 0.48,
        }),
    };
});

// ----------------------------------------------------------------------

export const StyledMenu = styled(Paper)(({theme}) => ({
    ...paper({theme}),
    left: 0,
    right: 0,
    margin: 'auto',
    position: 'fixed',
    zIndex: theme.zIndex.modal,
    padding: theme.spacing(5, 1, 1, 3),
    maxWidth: theme.breakpoints.values.lg,
    top: HEADER.H_DESKTOP_OFFSET,
    boxShadow: theme.customShadows.dropdown,
    borderRadius: theme.shape.borderRadius * 2,
}));

// ----------------------------------------------------------------------

export const StyledSubheader = styled(ListSubheader)(({theme}) => ({
    ...theme.typography.overline,
    padding: 0,
    fontSize: 11,
    color: theme.palette.text.primary,
}));
