import {useEffect, useState} from "react";
// @mui
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
// utils
import {fToNow} from 'src/utils/format-time';
// components
import Label from 'src/components/label';
import FileThumbnail from 'src/components/file-thumbnail';
// redux
import { useDispatch } from 'src/redux/store';
import { getUnreadTaskNum, updateTaskStatus } from 'src/redux/slices/task';
import axios, { API_ENDPOINTS } from "../../../utils/axios";
import { markTaskAsRead as markTaskAsReadAPI } from '../../../api/task';
import {downloadURL} from "../../../utils/handleTask";
import TaskDialog from "../../../sections/task/TaskDialog";
import ErrorDialog from "../../../sections/task/ErrorDialog";

// ----------------------------------------------------------------------

export default function TaskItem({task}) {
    const dispatch = useDispatch();

    const renderAvatar = (
        <ListItemAvatar>
            {task.avatarUrl ? (
                <Avatar src={task.avatarUrl} sx={{bgcolor: 'background.neutral'}}/>
            ) : (
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        // width: 40,
                        // height: 40,
                        bgcolor: 'background.neutral',
                    }}
                >
                    <Box
                        component="img"
                        src="/assets/icon/system/ic_upload_xls.svg"
                        // sx={{width: 24, height: 24}}
                    />
                </Stack>
            )}
        </ListItemAvatar>
    );

    const [progress, setProgress] = useState(task.progress);
    const [taskStatus, setTaskStatus] = useState("Unknown Status");
    const [errorDialog, setErrorDialog] = useState(null);
    //
    // const getStatusMessage = (code) => {
    //     switch(code) {
    //         case 1: return '${progress}%';
    //         case 2: return 'Failed';
    //         case 3: return 'Imported';
    //         default: return 'Unknown Status';
    //     }
    // };

    // // eslint-disable-next-line consistent-return
    // useEffect(() => {
    //     if(task?.task_status === 1) {
    //         setTaskStatus('Failed');
    //     } else {
    //         const fetchProgress = async () => {
    //             try {
    //                 const result = await axios.get(API_ENDPOINTS.tasks.info, { params: { task_ids: task?.task_id } });
    //                 const { status, data } = result;
    //
    //                 if( status === 200 ) {
    //                     const taskData = data?.data?.mapKey;
    //                     console.log(taskData);
    //                     if (taskData) {
    //                         console.log("success");
    //                         console.log(taskData?.progress);
    //                         // setProgress(taskData.progress);
    //                         setTaskStatus(`${taskData.progress}%`);
    //                         if(taskData.progress === 100) {
    //                             console.log("finished");
    //                             setTaskStatus(taskData?.task_type);
    //                         }
    //                     }
    //
    //
    //                 }
    //             } catch (error) {
    //                 console.error('Error fetching task progress:', error);
    //                 // setTaskStatus('Error fetching progress');
    //             }
    //         };
    //
    //         const interval = setInterval(() => {
    //             if(task?.task_status === 0) {
    //                 fetchProgress();
    //             }
    //         }, 1000);
    //
    //         return () => clearInterval(interval);
    //     }
    // }, [task?.task_status])

    const handleMarkAsRead = async () => {
        if (!task.is_read) {
            try {
                const id = task.task_id_str;

                const response = await markTaskAsReadAPI([id]);

                const { status, data } = response;

                if (status === 200) {
                    // dispatch(updateTaskStatus(id));
                    dispatch(getUnreadTaskNum());
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleDownload = async (filePath) => {
        downloadURL(filePath);
    };

    useEffect(() => {
        // Function to fetch task progress
        const fetchProgress = async () => {
            try {
                const result = await axios.get(API_ENDPOINTS.tasks.info, { params: { task_ids: task?.task_id_str } });
                const { status, data } = result;

                if (status === 200) {
                    const taskData = data?.data?.mapKey;
                    if (taskData) {
                        console.log("Task data fetched successfully:", taskData);
                        setTaskStatus(`${taskData.progress}%`); // Update progress
                        if (taskData.progress === 100) {
                            setTaskStatus(taskData?.task_type); // Update status when task is complete
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching task progress:', error);
                setTaskStatus('Error fetching progress'); // Update status on error
            }
        };

        let interval;

        // Checking if task status is 'Failed'
        if (task?.task_status === 1) {
            setTaskStatus('Failed');
        } else if (task?.task_status === 0) { // Ensure task is in progress
            interval = setInterval(fetchProgress, 1000); // Set interval to fetch progress
        } else if (task?.task_status === 2) {
            if(task?.export_file_url !== "") {
                setTaskStatus(
                    <Link onClick={() => handleDownload(task?.export_file_url)}>Download</Link>
                )
            } else {
                setTaskStatus("Imported");
            }
        }

        // Return a cleanup function
        return () => {
            if (interval) {
                clearInterval(interval); // Clear interval on cleanup
            }
        };
    }, [task?.task_status]);

    function getOriginalFileName(fileName) {
        const parts = fileName.split('_');
        return parts.length > 2 ? parts[2] : fileName;
    }

    const handleOpenErrorDialog = (errors) => {
        // setTaskDialog(null);
        setErrorDialog(
            <ErrorDialog
                open={Boolean(true)}
                onClose={() => setErrorDialog(null)}
                errors={errors}
            />
        );
    }

    // useEffect(() => {
    //     const fetchProgress = async () => {
    //         try {
    //             const result = await axios.get(API_ENDPOINTS.tasks.info, { params: { task_ids: task?.task_id } });
    //             const { status, data } = result;
    //
    //             if( status === 200 ) {
    //                 const taskData = data?.data?.mapKey;
    //                 if (taskData) {
    //                     console.log("success");
    //                     // setProgress(taskData.progress);
    //                     setTaskStatus(`${taskData.progress}%`);
    //                     if(taskData.progress === 100) {
    //                         setTaskStatus(taskData?.task_type);
    //                     }
    //                 }
    //
    //
    //             }
    //         } catch (error) {
    //             console.error('Error fetching task progress:', error);
    //             // setTaskStatus('Error fetching progress');
    //         }
    //     };
    //
    //     const interval = setInterval(() => {
    //        if(task?.task_status === 0) {
    //            fetchProgress();
    //        }
    //     }, 1000);
    //
    //     return () => clearInterval(interval);
    // }, [task?.progress, ]);


    const renderText = (
        <ListItemText
            disableTypography
            primary={
                <Stack
                    direction="row"
                    justifyContent="space-between"
                >
                    <Box>
                        {getOriginalFileName(task.import_file_name)}
                    </Box>
                    <Box>
                        {taskStatus}
                    </Box>
                </Stack>
            }
            secondary={
                <Stack
                    direction="column"
                    // alignItems="center"
                    sx={{typography: 'caption', color: 'text.disabled'}}
                    divider={
                        <Box
                            sx={{width: 2, height: 2, bgcolor: 'currentColor', mx: 0.5, borderRadius: '50%'}}
                        />
                    }
                >
                    <Box sx={{ width: '100%', mr: 1 }}>
                        <LinearProgress
                            variant="determinate"
                            value={task?.task_status ? 100 : task?.progress} // If error, fill the bar, otherwise use value prop
                            sx={{
                                height: '10px', // Set the height of the progress bar
                                '& .MuiLinearProgress-bar': {
                                    backgroundColor: task?.task_status === 1 ? 'red' : 'primary.main', // Set bar color to red if error, otherwise primary
                                },
                                '& .MuiLinearProgress-root': {
                                    backgroundColor: task?.task_status === 1 ? '#FFD2D2' : 'grey.300', // Light red or grey background for the bar
                                },
                            }}
                        />
                    </Box>
                    {task?.is_import_template_err && (
                        <Box>
                            <Typography color="error.main">{task?.err_msg}</Typography>
                        </Box>
                    )}
                    {task?.import_errs !== null && (
                        <Box>
                            <Typography color="error.main">This file format error！<Link onClick={() => handleOpenErrorDialog(task?.import_errs)} color="error.main">Read more...</Link></Typography>
                        </Box>
                    )}
                    <Box sx={{display:'flex', justifyContent:'space-between'}}>
                        <Box>{task.biz_type}</Box>
                        <Box>{fToNow(task.create_timestamp)}</Box>
                    </Box>
                </Stack>
            }
        />
    );

    const renderUnReadBadge = task.isUnRead && (
        <Box
            sx={{
                top: 26,
                width: 8,
                height: 8,
                right: 20,
                borderRadius: '50%',
                bgcolor: 'info.main',
                position: 'absolute',
            }}
        />
    );

    const friendAction = (
        <Stack spacing={1} direction="row" sx={{mt: 1.5}}>
            <Button size="small" variant="contained">
                Accept
            </Button>
            <Button size="small" variant="outlined">
                Decline
            </Button>
        </Stack>
    );

    const projectAction = (
        <Stack alignItems="flex-start">
            <Box
                sx={{
                    p: 1.5,
                    my: 1.5,
                    borderRadius: 1.5,
                    color: 'text.secondary',
                    bgcolor: 'background.neutral',
                }}
            >
                {reader(
                    `<p><strong>@Jaydon Frankie</strong> feedback by asking questions or just leave a note of appreciation.</p>`
                )}
            </Box>

            <Button size="small" variant="contained">
                Reply
            </Button>
        </Stack>
    );

    const fileAction = (
        <Stack
            spacing={1}
            direction="row"
            sx={{
                pl: 1,
                p: 1.5,
                mt: 1.5,
                borderRadius: 1.5,
                bgcolor: 'background.neutral',
            }}
        >
            <FileThumbnail
                file="http://localhost:8080/httpsdesign-suriname-2015.mp3"
                sx={{width: 40, height: 40}}
            />

            <Stack spacing={1} direction={{xs: 'column', sm: 'row'}} flexGrow={1} sx={{minWidth: 0}}>
                <ListItemText
                    disableTypography
                    primary={
                        <Typography variant="subtitle2" component="div" sx={{color: 'text.secondary'}} noWrap>
                            design-suriname-2015.mp3
                        </Typography>
                    }
                    secondary={
                        <Stack
                            direction="row"
                            alignItems="center"
                            sx={{typography: 'caption', color: 'text.disabled'}}
                            divider={
                                <Box
                                    sx={{
                                        mx: 0.5,
                                        width: 2,
                                        height: 2,
                                        borderRadius: '50%',
                                        bgcolor: 'currentColor',
                                    }}
                                />
                            }
                        >
                            <span>2.3 GB</span>
                            <span>30 min ago</span>
                        </Stack>
                    }
                />

                <Button size="small" variant="outlined">
                    Download
                </Button>
            </Stack>
        </Stack>
    );

    const tagsAction = (
        <Stack direction="row" spacing={0.75} flexWrap="wrap" sx={{mt: 1.5}}>
            <Label variant="outlined" color="info">
                Design
            </Label>
            <Label variant="outlined" color="warning">
                Dashboard
            </Label>
            <Label variant="outlined">Design system</Label>
        </Stack>
    );

    const paymentAction = (
        <Stack direction="row" spacing={1} sx={{mt: 1.5}}>
            <Button size="small" variant="contained">
                Pay
            </Button>
            <Button size="small" variant="outlined">
                Decline
            </Button>
        </Stack>
    );

    return (
        <>
            <ListItemButton
                disableRipple
                sx={{
                    p: 2.5,
                    alignItems: 'center',
                    borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
                }}
                onClick={handleMarkAsRead}
            >
                {renderUnReadBadge}

                {renderAvatar}

                <Stack sx={{flexGrow: 1, overflow: 'hidden', wordBreak:'break-all'}}>
                    {renderText}
                    {/* {task.type === 'friend' && friendAction} */}
                    {/* {task.type === 'project' && projectAction} */}
                    {/* {task.type === 'file' && fileAction} */}
                    {/* {task.type === 'tags' && tagsAction} */}
                    {/* {task.type === 'payment' && paymentAction} */}
                </Stack>
            </ListItemButton>
            {errorDialog}
        </>
    );
}

TaskItem.propTypes = {
    task: PropTypes.object,
};

// ----------------------------------------------------------------------

function reader(data) {
    return (
        <Box
            dangerouslySetInnerHTML={{__html: data}}
            sx={{
                mb: 0.5,
                '& p': {typography: 'body2', m: 0},
                '& a': {color: 'inherit', textDecoration: 'none'},
                '& strong': {typography: 'subtitle2'},
            }}
        />
    );
}
