import { createSlice } from '@reduxjs/toolkit';
// utils
import axios, { API_ENDPOINTS } from "../../utils/axios";
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    plansList: [],
    hasTrial: null,
    plan: null,
};

const slice = createSlice({
    name: 'plan',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        stopLoading(state) {
            state.isLoading = false;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET PLANS
        getPlansSuccess(state, action) {
            state.isLoading = false;
            state.plansList = action.payload;
        },

        // GET PLAN
        getPlanSuccess(state, action) {
            state.isLoading = false;
            state.plan = action.payload;
        },

        checkHasTriaPlanSuccess(state, action) {
            state.isLoading = false;
            state.hasTrial = action.payload;
        },
    },
});


// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getPlanList() {
    return async () => {
        try {
            const response = await axios.get(API_ENDPOINTS.plan.getList);

            const { status, data } = response;
            console.log(data);
            if (status === 200 && data?.code === "ok") {
                dispatch(slice.actions.getPlansSuccess(data?.data ?? []));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function checkHasTrialPlan() {
    return async () => {
        try {
            const response = await axios.get(API_ENDPOINTS.plan.hasTrial);

            const { status, data } = response;

            if (status === 200 && data?.code === "ok") {
                dispatch(slice.actions.checkHasTriaPlanSuccess(data?.data ?? false));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getCurrentPlan() {
    return async () => {
        try {
            const response = await axios.get(API_ENDPOINTS.plan.getDetail);

            const { status, data } = response;

            if (status === 200 && data?.code === "ok") {
                dispatch(slice.actions.getPlanSuccess(data?.data ?? []));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function bindPlan(detail) {
    return async () => {
        dispatch(slice.actions.startLoading());

        try {
            const response = await axios.post(API_ENDPOINTS.plan.bind, detail);

            const { status, data } = response;

            if (status === 200 && data?.code === "ok") {
                dispatch(getCurrentPlan(detail?.company_id));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        } finally {
            dispatch(slice.actions.stopLoading());
        }
    };
}

// ----------------------------------------------------------------------

export function changePlan(detail) {
    return async () => {
        dispatch(slice.actions.startLoading());

        try {
            const response = await axios.post(API_ENDPOINTS.plan.update, detail);

            const { status, data } = response;

            if (status === 200 && data?.code === "ok") {
                dispatch(getCurrentPlan(detail?.company_id));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        } finally {
            dispatch(slice.actions.stopLoading());
        }
    };
}
