import PropTypes from 'prop-types';
import {useEffect, useReducer, useCallback, useMemo} from 'react';
// utils
import axios, {API_ENDPOINTS} from 'src/utils/axios';
import {AuthContext} from './auth-context';
import {isValidToken, setSession} from './utils';
import {persistor, store} from "../../../redux/store";
import {useLocales} from "../../../locales";
import {getUser} from "../../../redux/slices/user";

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
    user: null,
    company: null,
    loading: true,
    permissions: [],
};

const reducer = (state, action) => {
    if (action.type === 'INITIAL') {
        return {
            loading: false,
            user: action.payload.user,
            permissions: action.payload.permissions,
        };
    }
    if (action.type === 'LOGIN') {
        return {
            ...state,
            user: action.payload.user,
            permissions: action.payload.permissions,
        };
    }
    if (action.type === 'REGISTER') {
        return {
            ...state,
            // user: action.payload.user,
        };
    }
    if (action.type === 'LOGOUT') {
        return {
            ...state,
            user: null,
            permissions: [],
        };
    }
    if (action.type === 'GET_USER') {
        const { user } = action.payload;

        return {
            ...state,
            user: {
                ...state.user,
                ...user,
            },
        };
    }
    if (action.type === 'MODIFY_USER') {
        const { user, company } = action.payload;

        return {
            ...state,
            user: {
                ...state.user,
                ...user,
            },
            // company: {
            //     ...state.company,
            //     ...company,
            // },
        };
    }

    return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({children}) {
    const {currentLang} = useLocales();

    const [state, dispatch] = useReducer(reducer, initialState);

    const initialize = useCallback(async () => {
        try {
            const accessToken = localStorage.getItem(STORAGE_KEY);

            if (accessToken && isValidToken(accessToken)) {
                setSession(accessToken);

                // const response = await axios.get(API_ENDPOINTS.auth.me);
                // const {user} = response.data;
                const user = JSON.parse(localStorage.getItem('user'));
                const permissions = JSON.parse(localStorage.getItem('permissions'));

                dispatch({
                    type: 'INITIAL',
                    payload: {
                        user,
                        permissions,
                    },
                });
            } else {
                dispatch({
                    type: 'INITIAL',
                    payload: {
                        user: null,
                        permissions: [],
                    },
                });
            }
        } catch (error) {
            // console.error(error);
            dispatch({
                type: 'INITIAL',
                payload: {
                    user: null,
                    permissions: [],
                },
            });
        }
    }, []);

    useEffect(() => {
        initialize();
    }, [initialize]);

    // const headers = useMemo(() => ({
    //     'lang': currentLang.value,
    // }), [currentLang.value]);

    // LOGIN
    const login = useCallback(async (email, password) => {
        const data = {
            email,
            password,
            // is_remember,
        };

        const response = await axios.post(API_ENDPOINTS.auth.login, data);

        if( response.status === 200) {
            const accessToken = response.data.data.jwt;
            const user = response.data.data.jwt_payload;
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.removeItem('email-recovery');
            setSession(accessToken);

            // const responseA = await axios.get(API_ENDPOINTS.auth.permission);
            // const {status, data:dataA} = responseA;
            // if(status === 200) {
            //     const permissions = dataA.data;
            //     localStorage.setItem('permissions', JSON.stringify(permissions));

            //     dispatch({
            //         type: 'LOGIN',
            //         payload: {
            //             user,
            //             permissions,
            //         },
            //     });

            //     // return permissions;
            // }

            // localStorage.setItem('user', JSON.stringify(user));
            // localStorage.setItem('isLocked', JSON.stringify(locked));

            dispatch({
                type: 'LOGIN',
                payload: {
                    user,
                },
            });
        }
        // else {
        //     const {accessToken, user} = response.data;
        //     setSession(accessToken);
        //     dispatch({
        //         type: 'LOGIN',
        //         payload: {
        //             user,
        //         },
        //     });
        // }

    }, []);

    // VerifyRecaptcha
    const verifyRecaptcha = (token) => axios.post(API_ENDPOINTS.auth.verifyRecaptcha, {
            content: token
    });

    // REGISTER
    const register = useCallback(async (first_name, last_name, email, password, estimate_monthly_shipments) => {
        const data = {
            first_name,
            last_name,
            email,
            password,
            estimate_monthly_shipments,
            // isRead,
        };

        const response = await axios.post(API_ENDPOINTS.auth.register, data);

        const { status } = response;

        // const {accessToken, user} = response.data;
        // sessionStorage.setItem(STORAGE_KEY, accessToken);

        dispatch({
            type: 'REGISTER',
            // payload: {
            //     user,
            // },
        });
        return status === 200;
    }, []);

    // LOGOUT
    const logout = useCallback(async () => {
        const accessToken = localStorage.getItem(STORAGE_KEY);

        const response = await axios.post(API_ENDPOINTS.auth.logout);

        if(response.status === 200) {
            setSession(null);
            dispatch({
                type: 'LOGOUT',
            });

            localStorage.removeItem('user');
            localStorage.removeItem('permissions');
            localStorage.clear();

            persistor.purge().then(null);

            store.dispatch({type: "USER_LOGOUT"});
        }

    }, []);

    // Forgot Password
    const forgotPassword = useCallback(async (detail) => {

        const response = await axios.post(API_ENDPOINTS.auth.forgotPassword, detail);

        const {status, data} = response;

        return status === 200;

    }, []);

    const resetPassword = useCallback(async (detail) => {

        const response = await axios.post(API_ENDPOINTS.auth.resetPassword, detail);

        const {status, data} = response;

        return status === 200;

    },[]);

    // Resned Email
    const resendEmail = useCallback(async (email) => {
        const response = await axios.post(API_ENDPOINTS.auth.resendEmail, {email});

        const {status, data} = response;

        return status === 200;
    }, []);

    const activeUser = useCallback(async (detail) => {
            const response = await axios.post(API_ENDPOINTS.auth.activeUser, detail);

            const {status, data} = response;
            if (status === 200) {
                const accessToken = data.data?.jwt;
                const user = data.data?.jwt_payload;
                localStorage.removeItem('email-recovery');
                localStorage.setItem('user', JSON.stringify(user));
                if(accessToken) {
                    setSession(accessToken);
                }

                dispatch({
                    type: 'LOGIN',
                    payload: {
                        user,
                    },
                });
                return true;
            }
            return false;


    },[]);

    const checkPermission = (key, permissions) => {
        if (key === 'default') return true;

        // eslint-disable-next-line no-nested-ternary
        const tempPermissions = (permissions && permissions.length > 0) ? permissions : (state.permissions && state.permissions.length > 0) ? state.permissions : [];

        const result = tempPermissions.findIndex(_permission => _permission === key);

        return result > -1;
    };


    const modifyUser = useCallback(async (detail) => {
        const response = await axios.post(API_ENDPOINTS.settings.updateAccount, detail);

        const { status, data } = response;

        if (status === 200) {
            dispatch({
                type: 'MODIFY_USER',
                payload: {
                    user: {
                        name: detail?.name ?? '',
                        warehouse_id: detail?.warehouse ?? '',
                        timezone: detail?.timezone ?? '',
                    },
                    // company: {
                    //     // company: detail?.company_name ?? '',
                    // },
                },
            });
        }

        return response;
    },[]);

    const modifyCompany = useCallback(async (detail) => {
        const response = await axios.post(API_ENDPOINTS.settings.updateCompany, detail);

        const { status, data } = response;

        // if (status === 200) {
        //     dispatch({
        //         type: 'MODIFY_',
        //         payload: {
        //             user: {
        //                 name: detail?.name ?? '',
        //                 warehouse_id: detail?.warehouse ?? '',
        //                 timezone: detail?.timezone ?? '',
        //             },
        //             // company: {
        //             //     // company: detail?.company_name ?? '',
        //             // },
        //         },
        //     });
        // }

        return response;
    },[]);

    // ----------------------------------------------------------------------

    const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

    const status = state.loading ? 'loading' : checkAuthenticated;

    const memoizedValue = useMemo(
        () => ({
            user: state.user,
            permissions: state.permissions,
            method: 'jwt',
            loading: status === 'loading',
            authenticated: status === 'authenticated',
            unauthenticated: status === 'unauthenticated',
            //
            login,
            register,
            logout,
            forgotPassword,
            resetPassword,
            resendEmail,
            activeUser,
            verifyRecaptcha,
            checkPermission,
            modifyUser,
            modifyCompany,
        }),
        [login, logout, register, forgotPassword, resetPassword, resendEmail, activeUser, verifyRecaptcha, checkPermission, modifyUser, modifyCompany, state.user, status],
    );

    return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
    children: PropTypes.node,
};
