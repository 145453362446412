import {lazy} from 'react';
import {Outlet} from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
import SimpleLayout from 'src/layouts/simple';
import CompactLayout from 'src/layouts/compact';

// ----------------------------------------------------------------------

export const HomePage = lazy(() => import('src/pages/home'));
// export const HomePage = lazy(() => import('src/pages/auth/jwt/login'));
const Page500 = lazy(() => import('src/pages/500'));
const Page403 = lazy(() => import('src/pages/403'));
const Page404 = lazy(() => import('src/pages/404'));
const MaintenancePage = lazy(() => import('src/pages/maintenance'));
// ----------------------------------------------------------------------

export const mainRoutes = [
    {
        element: (
            <MainLayout>
                <Outlet/>
            </MainLayout>
        ),
    },
    {
        element: (
            <SimpleLayout>
                <Outlet/>
            </SimpleLayout>
        ),
    },
    {
        element: (
            <CompactLayout>
                <Outlet/>
            </CompactLayout>
        ),
        children: [
            {path: 'maintenance', element: <MaintenancePage/>},
            {path: '500', element: <Page500/>},
            {path: '404', element: <Page404/>},
            {path: '403', element: <Page403/>},
        ],
    },
];
