import {memo} from 'react';
// @mui
import {Box} from '@mui/material';
import Image from "../../components/image";

// ----------------------------------------------------------------------

function PackageIllustration({...other}) {
    return (
        <Box {...other}>
            <Image src="/assets/illustration/illustration_scan.gif"
                   alt='illustration_packages'
                   sx={{
                       '& .wrapper': {display: 'block !important'},
                       '& img': {objectFit: 'contain'},
                       overflow: 'auto',
                       lineHeight: `1.1`
                   }}
            />
        </Box>
    );
}

export default memo(PackageIllustration);
