// ----------------------------------------------------------------------

export function remToPx(value) {
    return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value) {
    return `${value / 16}rem`;
}

export function responsiveFontSizes({ sm, md, lg }) {
    return {
        '@media (min-width:600px)': {
            fontSize: pxToRem(sm),
        },
        '@media (min-width:900px)': {
            fontSize: pxToRem(md),
        },
        '@media (min-width:1200px)': {
            fontSize: pxToRem(lg),
        },
    };
}

const primaryFont = 'Roboto, sans-serif'; // Google Font
// const secondaryFont = 'CircularStd, sans-serif'; // Local Font

// ----------------------------------------------------------------------

export const typography = {
    fontFamily: primaryFont,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    h1: {
        fontWeight: 700,
        lineHeight: pxToRem(36),
        fontSize: pxToRem(24),
        letterSpacing: 0,
        // ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
    },
    h2: {
        fontWeight: 700,
        lineHeight: pxToRem(34),
        fontSize: pxToRem(22),
        // ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 }),
    },
    h3: {
        fontWeight: 500,
        lineHeight: pxToRem(24),
        fontSize: pxToRem(16),

        // ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 }),
    },
    h4: {
        fontWeight: 500,
        lineHeight: pxToRem(24),
        fontSize: pxToRem(16),
        textTransform: 'uppercase',
        // ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
    },
    h5: {
        fontWeight: 500,
        lineHeight: pxToRem(20),
        fontSize: pxToRem(12),
        letterSpacing: 0.5,
        textTransform: 'uppercase',
        // ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 }),
    },
    h6: {
        fontWeight: 500,
        lineHeight: pxToRem(18),
        fontSize: pxToRem(12),
        // ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
    },
    subtitle1: {
        fontWeight: 500,
        lineHeight: pxToRem(24),
        fontSize: pxToRem(16),
    },
    subtitle2: {
        fontWeight: 500,
        lineHeight: pxToRem(24),
        fontSize: pxToRem(16),
    },
    body1: {
        fontWeight: 400,
        lineHeight: pxToRem(22),
        fontSize: pxToRem(14),
    },
    body2: {
        fontWeight: 500,
        lineHeight: pxToRem(22),
        fontSize: pxToRem(14),
        // textTransform: 'capitalize',
    },
    caption: {
        fontWeight: 400,
        lineHeight: pxToRem(20),
        fontSize: pxToRem(12),
    },
    caption2: {
        fontWeight: 600,
        lineHeight: pxToRem(18),
        fontSize: pxToRem(11),
    },
    overline: {
        fontWeight: 500,
        lineHeight: pxToRem(20),
        fontSize: pxToRem(12),
        // textTransform: 'uppercase',
        // letterSpacing: 0.5,
    },
    button: {
        fontWeight: 700,
        lineHeight: pxToRem(22),
        fontSize: pxToRem(14),
        textTransform: 'capitalize',
    },
};
