// utils
import { filterEmptyValues } from 'src/redux/slices/products';
import axios, { API_ENDPOINTS } from '../utils/axios';

const getTotalUnRead = (filter) => axios.get(API_ENDPOINTS.tasks.query, {
    params: {
        page_size: 1,
        page: 0,
        read_status: 0,
    },
});

const markTaskAsRead = (id) => axios.post(API_ENDPOINTS.tasks.clearUnRead, {
    task_ids: id,
});

const exportRebate = (detail) => axios.post(API_ENDPOINTS.tasks.exportRebate, detail);

const exportTransaction = (detail) => axios.post(API_ENDPOINTS.tasks.exportTransaction,
    filterEmptyValues(detail),
);

export {
    getTotalUnRead,
    markTaskAsRead,
    exportRebate,
    exportTransaction,
};
