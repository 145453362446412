// ----------------------------------------------------------------------

export const HEADER = {
    H_MOBILE: 60,
    H_DESKTOP: 60,
    H_DESKTOP_OFFSET: 60,
};

export const NAV = {
    W_VERTICAL: 240,
    W_MINI: 88,
};
