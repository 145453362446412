import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Stack, Container, Typography } from '@mui/material';
// guards
// import PermissionGuard from '../guards/PermissionGuard';
// routes
import PropTypes from 'prop-types';
import { paths } from '../../../routes/paths';
// hooks
import { useLocales } from '../../../locales';
// components
import Page from '../../../components/Page';
import Image from '../../../components/image';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 600,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    padding: theme.spacing(24, 0),
}));


// ----------------------------------------------------------------------
PaymentComplete.propTypes = {
    status: PropTypes.string,
};
export default function PaymentComplete({ status }) {
    const { translate } = useLocales();

    return (
        <Page
            title="Payment Successfully Processed | ShipSaving"
            meta={
                <meta name="description"
                      content="Congratulations! Your payment has been successfully processed on ShipSaving. Enjoy a seamless and secure transaction experience." />
            }
        >
            <Container>
                <ContentStyle sx={{ textAlign: 'center' }}>
                    <Stack spacing={3.75} alignItems="center">
                        <Stack spacing={2.5} alignItems="center">
                            <Image
                                src={status === 'success' ? '/assets/image/system/success.svg' : '/assets/image/system/failed.svg'}
                                alt={status}
                                sx={{ width: '120px', height: '120px', '& img': { objectFit: 'contain' } }}
                                disabledEffect />

                            <Stack spacing={1.25}>
                                <Typography variant="h1">
                                    {status === 'success' ? `${translate('payment_complete_1')}` : `${translate('payment_failed_1')}`}
                                </Typography>

                                <Typography
                                    variant="body1">{status === 'success' ? `${translate('payment_complete_2')}` : `${translate('payment_failed_2')}`}</Typography>
                            </Stack>
                        </Stack>

                        {/* <PermissionGuard */}
                        {/*     permission="account.billing.transaction" */}
                        {/*     replacement={ */}
                        {/*         <Button to={paths.dashboard.root} variant="contained" component={RouterLink} */}
                        {/*                 sx={{ width: 'fit-content' }}> */}
                        {/*             {`${translate('payment_complete_3_2')}`} */}
                        {/*         </Button> */}
                        {/*     } */}
                        {/* > */}
                        <Button to={paths.dashboard.wallet.root} variant="contained" component={RouterLink}
                                sx={{ width: 'fit-content' }}>
                            {`${translate('payment_complete_3_1')}`}
                        </Button>
                        {/* </PermissionGuard> */}
                    </Stack>
                </ContentStyle>
            </Container>
        </Page>
    );
}
