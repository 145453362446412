import { Stack, Typography } from '@mui/material';

export const STORE_LIST = [
    {
        platform: 'shopify',
        name: 'Shopify',
        bgColor: '#292929',
        image: '/assets/image/stores/logo/shopify.svg',
        link: 'Shopify',
        auth: "oauth",
        readme: '',
        credentials: [],
        options: [
            {
                label: 'Send tracking notification to customer via Shopify',
                value: 'send_tracking_notification_via_shopify',
                name: 'send_tracking_notification_via_shopify',
                type: 'checkbox',
            },
            // {
            //     label: 'Upload tracking numbers to you store',
            //     value: '',
            //     name: '',
            //     type: 'checkbox',
            // },
        ],
    },
    {
        platform: 'woocommerce',
        name: 'Woocommerce',
        bgColor: '#9C5E90',
        image: '/assets/image/stores/logo/woocommerce.svg',
        link: 'WooCommerce',
        readme:
            <>
                <Typography variant="caption">
                    <span style={{ color: '#D32B2B' }}>*</span>
                    ShipSaving currently only supports the order status listed below.
                    If any orders with status are not included in the list below,
                    ShipSaving cannot synchronize those orders into the platform.
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    If an order with an "awaiting" status is changed into any status that is not
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    included, the "awaiting" status will remain the same. Users need to change the status manually.
                </Typography>
                <Stack>
                    <Typography variant="caption">Supported Status:</Typography>
                    <Typography variant="caption">
                        pending，processing，on-hold，completed，cancelled，refunded，failed，trash
                    </Typography>
                </Stack>
            </>,
        credentials: [
            {
                label: 'Store URL',
                value: 'woocommerce_url',
                name: 'url',
                type: 'input',
                placeholder: 'https://example.com',
            },
            {
                label: 'Consumer Key',
                value: 'woocommerce_consumer_key',
                name: 'consumerKey',
                type: 'input',
            },
            {
                label: 'Consumer Secret',
                value: 'woocommerce_consumer_secret',
                name: 'consumerSecret',
                type: 'input',
            },
        ],
        options: [
            {
                label: 'Shipping Plugin',
                value: 'shipping_plugin',
                name: 'shippingPlugin',
                type: 'select',
                arr: [
                    {
                        name: 'Advanced Shipment Tracking',
                        value: 'advanced_shipment_tracking',
                    },
                ],
            },
            // {
            //     label: 'Auto Email Tracking',
            //     value: 'auto_email_tracking',
            //     name: 'AutoEmailTracking',
            //     type: 'checkbox',
            // },
        ],
    },
    {
        platform: 'squarespace',
        name: 'Squarespace',
        bgColor: '#292929',
        image: '/assets/image/stores/logo/squarespace.svg',
        link: 'SquareSpace',
        readme: '',
        credentials: [
            {
                label: 'Api Key',
                value: 'squarespace_api_key',
                name: 'apiKey',
                type: 'input',
            },
        ],
        options: [],
    },
    {
        platform: 'bigcommerce',
        name: 'Bigcommerce',
        bgColor: '#34313F',
        image: '/assets/image/stores/logo/bigcommerce.svg',
        link: 'BigCommerce',
        readme: '',
        credentials: [
            {
                label: 'Store Hash',
                value: 'bigcommerce_store_hash',
                name: 'storeHash',
                type: 'input',
            },
            {
                label: 'Access Token',
                value: 'bigcommerce_access_token',
                name: 'accessToken',
                type: 'input',
            },
        ],
        options: [
            // {
            //     label: 'Auto Email Tracking',
            //     value: 'auto_email_tracking',
            //     name: 'AutoEmailTracking',
            //     type: 'checkbox',
            // },
        ],
    },
    {
        platform: 'tiktokshop',
        name: 'Tiktok Shop',
        bgColor: '#292929',
        image: '/assets/image/stores/logo/tiktokshop.svg',
        link: 'Tiktok',
        auth: "oauth",
        readme: '',
        options: [
            {
                label: 'Download TikTok Shop shipping order',
                value: 'download_shipping_order',
                name: 'download_shipping_order',
                type: 'checkbox',
            },
        ],
    },
    {
        platform: 'wix',
        name: 'Wix',
        bgColor: '#000',
        image: '/assets/image/stores/logo/wix.svg',
        link: 'Wix',
        auth: "oauth",
        readme: '',
        credentials: [],
        options: [],
    },
    {
        platform: 'magento2',
        name: 'Magento 2',
        bgColor: '#F04A11',
        image: '/assets/image/stores/logo/magento2.svg',
        link: 'Magento-2',
        readme: '',
        credentials: [
            {
                label: 'Store URL',
                value: 'magento2_url',
                name: 'url',
                type: 'input',
                placeholder: 'https://example.com',
            },
            {
                label: 'Access Token',
                value: 'magento2_access_token',
                name: 'accessToken',
                type: 'input',
            },
        ],
        options: [
            {
                label: 'Notify Customer',
                value: 'notify_customer',
                name: 'notifyCustomer',
                type: 'checkbox',
            },
            // {
            //     label: 'Auto Email Tracking',
            //     value: 'auto_email_tracking',
            //     name: 'AutoEmailTracking',
            //     type: 'checkbox',
            // },
        ],
    },
    {
        platform: 'shoplazza',
        name: 'Shoplazza',
        bgColor: '#FF0000',
        image: '/assets/image/stores/logo/shoplazza.svg',
        link: 'Shoplazza',
        readme: '',
        credentials: [
            {
                label: 'Store Name',
                value: 'shoplazza_store_name',
                name: 'storeName',
                type: 'input',
            },
        ],
        options: [],
    },
    {
        platform: 'shein',
        name: 'Shein',
        bgColor: '#000000',
        image: '/assets/image/stores/logo/shein.svg',
        link: 'Shein',
        readme: '',
        credentials: [],
        options: [],
    },
    {
        platform: 'temu',
        name: 'TEMU',
        bgColor: '#FB7701',
        image: '/assets/image/stores/logo/temu.svg',
        link: 'TEMU',
        readme: '',
        credentials: [],
        options: [],
    },
    {
        platform: 'allvalue',
        name: 'AllValue',
        bgColor: '#1D4BEA',
        image: '/assets/image/stores/logo/allvalue.svg',
        link: 'AllValue',
        readme: '',
        credentials: [],
        options: [],
    },
    {
        platform: 'ecwid',
        name: 'Ecwid',
        bgColor: '#F8DE4B',
        image: '/assets/image/stores/logo/ecwid.svg',
        link: 'Ecwid',
        readme: '',
        credentials: [],
        options: [],
    },
    {
        platform: 'amazon',
        name: 'Amazon',
        bgColor: '#202A36',
        image: '/assets/image/stores/logo/amazon.svg',
        link: 'Amazon',
        readme: '',
        credentials: [
            {
                label: 'MarketPlace',
                value: 'amazon_sp_marketplace_id',
                name: 'marketplace',
                type: 'select',
                arr: [
                    {
                        name: 'US - United States',
                        value: 'us',
                    },
                    {
                        name: 'CA - Canada',
                        value: 'ca',
                    },
                    {
                        name: 'MX - Mexico',
                        value: 'mx',
                    },
                ],
            },
        ],
        options: [],
    },
    {
        platform: 'etsy',
        name: 'Etsy',
        bgColor: '#F27224',
        image: '/assets/image/stores/logo/etsy.svg',
        link: 'Etsy',
        readme:
            <>
                <Stack>
                    <Typography variant="caption">
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        The term 'Etsy' is a trademark of Etsy, Inc. This application uses the Etsy API but is not
                        endorsed or certified by Etsy, Inc.
                    </Typography>
                </Stack>
                <Typography variant="caption">
                    <span style={{ color: '#D32B2B' }}>*</span>
                    ShipSaving cannot identify when Etsy orders are cancelled or refunded. If an Etsy order is cancelled
                    or refunded, we recommend you adjust the order in ShipSaving as well.
                </Typography>
            </>,
        credentials: [],
        options: [
            // {
            //     label: 'Auto Email Tracking',
            //     value: 'auto_email_tracking',
            //     name: 'AutoEmailTracking',
            //     type: 'checkbox',
            // },
        ],
    },
    {
        platform: 'walmart-marketplace',
        name: 'Walmart Marketplace',
        bgColor: '#007DC3',
        image: '/assets/image/stores/logo/walmart_marketplace.svg',
        link: 'Walmart-Marketplace',
        readme: '',
        credentials: [
            {
                label: 'Client Id',
                value: 'walmart_marketplace_client_id',
                name: 'clientId',
                type: 'input',
            },
            {
                label: 'Client Secret',
                value: 'walmart_marketplace_client_secret',
                name: 'clientSecret',
                type: 'input',
            },
        ],
        options: [],
    },
    {
        platform: 'walmart-dsv',
        name: 'Walmart DSV',
        bgColor: '#007DC3',
        image: '/assets/image/stores/logo/walmart_dsv.svg',
        link: 'Walmart-DSV',
        readme: '',
        credentials: [
            {
                label: 'Consumer Id',
                value: 'walmart_dsv_consumer_id',
                name: 'consumerId',
                type: 'input',
            },
            {
                label: 'Private Key',
                value: 'walmart_dsv_private_key',
                name: 'privateKey',
                type: 'input',
            },
            {
                label: 'Channel Type',
                value: 'walmart_dsv_channel_type',
                name: 'channelType',
                type: 'input',
            },
            {
                label: 'Ship Node',
                value: 'walmart_dsv_ship_node',
                name: 'shipNode',
                type: 'input',
            },
        ],
        options: [],
    },
    {
        platform: 'ebay',
        name: 'eBay',
        bgColor: '#292929',
        image: '/assets/image/stores/logo/ebay.svg',
        link: 'eBay',
        readme: '',
        credentials: [],
        options: [
            // {
            //     label: 'Auto Email Tracking',
            //     value: 'auto_email_tracking',
            //     name: 'AutoEmailTracking',
            //     type: 'checkbox',
            // },
        ],
    },
    {
        platform: 'newegg-marketplace',
        name: 'Newegg',
        bgColor: '#385F95',
        image: '/assets/image/stores/logo/newegg.svg',
        link: 'Newegg',
        readme: '',
        credentials: [
            {
                label: 'Seller Id',
                value: 'newegg_marketplace_seller_id',
                name: 'sellerId',
                type: 'input',
            },
            {
                label: 'Secret Key',
                value: 'newegg_marketplace_secret_key',
                name: 'secretKey',
                type: 'input',
            },
        ],
        options: [],
    },
    {
        platform: 'wish',
        name: 'Wish',
        bgColor: '#3FE06F',
        image: '/assets/image/stores/logo/wish.svg',
        link: 'Wish',
        readme: '',
        credentials: [],
        options: [],
    },
    {
        platform: 'houzz',
        name: 'Houzz',
        bgColor: '#1F1C1D',
        image: '/assets/image/stores/logo/houzz.svg',
        link: 'Houzz',
        readme: '',
        credentials: [
            {
                label: 'Token',
                value: 'houzz_token',
                name: 'token',
                type: 'input',
            },
            {
                label: 'Username',
                value: 'houzz_username',
                name: 'username',
                type: 'input',
            },
            {
                label: 'App Id',
                value: 'houzz_app_id',
                name: 'appId',
                type: 'input',
            },
        ],
        options: [
            {
                label: 'Notify Customer',
                value: 'notify_customer',
                name: 'notifyCustomer',
                type: 'checkbox',
            },
            {
                label: 'Notify Sales Channel',
                value: 'notify_sales_channel',
                name: 'notifySalesChannel',
                type: 'checkbox',
            },
        ],
    },
    {
        platform: 'reverb',
        name: 'Reverb',
        bgColor: '#FC841E',
        image: '/assets/image/stores/logo/reverb.svg',
        link: 'Reverb',
        readme: '',
        credentials: [
            {
                label: 'Access Token',
                value: 'reverb_access_token',
                name: 'accessToken',
                type: 'input',
            },
        ],
        options: [],
    },
    {
        platform: 'yamibuy',
        name: 'Yamibuy',
        bgColor: '#FF7373',
        image: '/assets/image/stores/logo/yamibuy.svg',
        link: 'Yami',
        readme: '',
        credentials: [
            {
                label: 'App Key',
                value: 'yamibuy_app_key',
                name: 'appKey',
                type: 'input',
            },
            {
                label: 'App Secret',
                value: 'yamibuy_app_secret',
                name: 'appSecret',
                type: 'input',
            },
        ],
        options: [
            // {
            //     label: 'Auto Email Tracking',
            //     value: 'auto_email_tracking',
            //     name: 'AutoEmailTracking',
            //     type: 'checkbox',
            // },
        ],
    },
    {
        platform: 'weee',
        name: 'Weee',
        bgColor: '#1195CB',
        image: '/assets/image/stores/logo/weee.svg',
        link: 'Weee',
        readme: '',
        credentials: [
            {
                label: 'Client ID',
                value: 'weee_client_id',
                name: 'clientID',
                type: 'input',
            },
            {
                label: 'Client Secret',
                value: 'weee_client_secret',
                name: 'clientSecret',
                type: 'input',
            },
        ],
        options: [],
    },
    {
        platform: 'wayfair',
        name: 'Wayfair',
        bgColor: '#7F187F',
        image: '/assets/image/stores/logo/wayfair.svg',
        link: 'WAYFAIR',
        readme: '',
        credentials: [
            {
                label: 'Client Id',
                value: 'wayfair_client_id',
                name: 'clientId',
                type: 'input',
            },
            {
                label: 'Client Secret',
                value: 'wayfair_client_secret',
                name: 'clientSecret',
                type: 'input',
            },
        ],
        options: [],
    },
    {
        platform: 'faire',
        name: 'FAIRE',
        bgColor: '#FFFFFF',
        image: '/assets/image/stores/logo/faire.svg',
        link: 'FAIRE',
        readme: '',
        credentials: [],
        options: [],
    },
    // {
    //     platform: 'groupon-goods',
    //     name: 'Groupon',
    //     bgColor: '#53A318',
    //     image: '/assets/image/stores/logo/groupon.svg',
    //     link: 'Groupon',
    //     readme: '',
    //     credentials: [
    //         {
    //             label: 'Token',
    //             value: 'groupon_goods_token',
    //             name: 'token',
    //             type: 'input',
    //         },
    //         {
    //             label: 'Supplier Id',
    //             value: 'groupon_goods_supplier_id',
    //             name: 'supplierId',
    //             type: 'input',
    //         },
    //     ],
    //     options: [],
    // },
    // {
    //     platform: 'ship-station',
    //     name: 'ShipStation',
    //     bgColor: '#292929',
    //     image: '/assets/image/stores/logo/shipstation.svg',
    //     link: 'ShipStation',
    //     readme: '',
    //     credentials: [
    //         {
    //             label: 'Api Key',
    //             value: 'ship_station_api_key',
    //             name: 'apiKey',
    //             type: 'input',
    //         },
    //         {
    //             label: 'Api Secret',
    //             value: 'ship_station_api_secret',
    //             name: 'apiSecret',
    //             type: 'input',
    //         },
    //     ],
    //     options: [
    //         {
    //             label: 'Notify Customer',
    //             value: 'notify_customer',
    //             name: 'notifyCustomer',
    //             type: 'checkbox',
    //         },
    //         {
    //             label: 'Notify Sales Channel',
    //             value: 'notify_sales_channel',
    //             name: 'notifySalesChannel',
    //             type: 'checkbox',
    //         },
    //         // {
    //         //     label: 'Auto Email Tracking',
    //         //     value: 'auto_email_tracking',
    //         //     name: 'AutoEmailTracking',
    //         //     type: 'checkbox',
    //         // },
    //     ],
    // },
    // {
    //     platform: 'seller-cloud',
    //     name: '',
    //     bgColor: '#292929',
    //     image: '/assets/image/stores/logo/seller-cloud.svg',
    //     link: 'Sellercloud',
    //     readme: '',
    //     credentials: [
    //         {
    //             label: 'Server Id',
    //             value: 'seller_cloud_server_id',
    //             name: 'serverId',
    //             type: 'input',
    //         },
    //         {
    //             label: 'Username',
    //             value: 'seller_cloud_username',
    //             name: 'username',
    //             type: 'input',
    //         },
    //         {
    //             label: 'Password',
    //             value: 'seller_cloud_password',
    //             name: 'password',
    //             type: 'input',
    //         },
    //     ],
    //     options: [],
    // },
    // {
    //     platform: 'oracle-ns',
    //     name: '',
    //     bgColor: '#282422',
    //     image: '/assets/image/stores/logo/oracle-ns.svg',
    //     link: 'Oracle-NetSuite',
    //     readme: '',
    //     credentials: [
    //         {
    //             label: 'Account Id',
    //             value: 'net_suite_account_id',
    //             name: 'accountId',
    //             type: 'input',
    //         },
    //         {
    //             label: 'Consumer Key',
    //             value: 'net_suite_consumer_key',
    //             name: 'consumerKey',
    //             type: 'input',
    //         },
    //         {
    //             label: 'Consumer Secret',
    //             value: 'net_suite_consumer_secret',
    //             name: 'consumerSecret',
    //             type: 'input',
    //         },
    //         {
    //             label: 'Token Id',
    //             value: 'net_suite_token_id',
    //             name: 'tokenId',
    //             type: 'input',
    //         },
    //         {
    //             label: 'Token Secret',
    //             value: 'net_suite_token_secret',
    //             name: 'tokenSecret',
    //             type: 'input',
    //         },
    //     ],
    //     options: [],
    // },
    // {
    //     platform: 'eccang',
    //     name: 'Eccang',
    //     bgColor: '#2468F2',
    //     image: '/assets/image/stores/logo/eccang.svg',
    //     link: 'Eccang',
    //     readme: '',
    //     credentials: [
    //         {
    //             label: 'EB Domain',
    //             value: 'eccang_eb_domain',
    //             name: 'EBDomain',
    //             placeholder: 'xxx-eb.eccang.com',
    //             type: 'input',
    //         },
    //         {
    //             label: 'WMS Domain',
    //             value: 'eccang_wms_domain',
    //             name: 'WMSDomain',
    //             placeholder: 'xxx.eccang.com',
    //             type: 'input',
    //         },
    //         {
    //             label: 'Username',
    //             value: 'eccang_userName',
    //             name: 'username',
    //             type: 'input',
    //         },
    //         {
    //             label: 'Password',
    //             value: 'eccang_userPass',
    //             name: 'password',
    //             type: 'input',
    //         },
    //         {
    //             label: 'Shipping Method',
    //             value: 'eccang_shipping_method',
    //             name: 'shippingMethod',
    //             type: 'input',
    //         },
    //     ],
    //     options: [],
    // },
    // {
    //     platform: 'jushuitan',
    //     name: '',
    //     bgColor: '#0580FF',
    //     image: '/assets/image/stores/logo/jushuitan.svg',
    //     link: 'Jushuitan',
    //     readme: '',
    //     credentials: [],
    //     options: [],
    // },
    // {
    //     platform: 'customized',
    //     name: 'Customized',
    //     bgColor: '#2C6CD2',
    //     image: '/assets/image/stores/logo/customized.svg',
    //     link: '',
    //     readme: '',
    //     credentials: [],
    //     options: [
    //         // {
    //         //     label: 'Auto Email Tracking',
    //         //     value: 'auto_email_tracking',
    //         //     name: 'AutoEmailTracking',
    //         //     type: 'checkbox',
    //         // },
    //     ],
    // },
];

export const CONNECT_STORE_LIST = [
    {
        name: 'Ecommerce Platforms',
        bgColor: '#292929',
        data: [
            {
                label: 'shopify',
                image: '/assets/image/stores/list/shopify.svg',
            },
            {
                label: 'woocommerce',
                image: '/assets/image/stores/list/woocommerce.svg',
            },
            {
                label: 'bigcommerce',
                image: '/assets/image/stores/list/bigcommerce.svg',
            },
            {
                label: 'tiktokshop',
                image: '/assets/image/stores/list/tiktokshop.svg',
            },
            {
                label: 'allvalue',
                image: '/assets/image/stores/list/allvalue.svg',
            },
        ],
    },
    {
        name: 'Marketplaces',
        bgColor: '#292929',
        data: [
            {
                label: 'etsy',
                image: '/assets/image/stores/list/etsy.svg',
            },
            {
                label: 'walmart-marketplace',
                image: '/assets/image/stores/list/walmart-marketplace.svg',
            },
            {
                label: 'walmart-dsv',
                image: '/assets/image/stores/list/walmart-dsv.svg',
            },
            {
                label: 'ebay',
                image: '/assets/image/stores/list/ebay.svg',
            },
            {
                label: 'newegg-marketplace',
                image: '/assets/image/stores/list/newegg-marketplace.svg',
            },
            {
                label: 'yamibuy',
                image: '/assets/image/stores/list/yamibuy.svg',
            },
            {
                label: 'wayfair',
                image: '/assets/image/stores/list/wayfair.svg',
            },
        ],
    },
];

export const CONNECT_STORE_LIST_PRO = [
    {
        name: 'Ecommerce Platforms',
        bgColor: '#292929',
        data: [
            {
                label: 'shopify',
                image: '/assets/image/stores/list/shopify.svg',
            },
            {
                label: 'woocommerce',
                image: '/assets/image/stores/list/woocommerce.svg',
            },
            {
                label: 'squarespace',
                image: '/assets/image/stores/list/squarespace.svg',
            },
            {
                label: 'bigcommerce',
                image: '/assets/image/stores/list/bigcommerce.svg',
            },
            {
                label: 'tiktokshop',
                image: '/assets/image/stores/list/tiktokshop.svg',
            },
            {
                label: 'wix',
                image: '/assets/image/stores/list/wix.svg',
            },
            {
                label: 'magento2',
                image: '/assets/image/stores/list/magento2.svg',
            },
            {
                label: 'shoplazza',
                image: '/assets/image/stores/list/shoplazza.svg',
            },
            {
                label: 'shein',
                image: '/assets/image/stores/list/shein.svg',
            },

            {
                label: 'temu',
                image: '/assets/image/stores/list/temu.svg',
            },
            {
                label: 'allvalue',
                image: '/assets/image/stores/list/allvalue.svg',
            },
            {
                label: 'ecwid',
                image: '/assets/image/stores/list/ecwid.svg',
            },
        ],
    },
    {
        name: 'Marketplaces',
        bgColor: '#292929',
        data: [
            {
                label: 'etsy',
                image: '/assets/image/stores/list/etsy.svg',
            },
            {
                label: 'walmart-marketplace',
                image: '/assets/image/stores/list/walmart-marketplace.svg',
            },
            {
                label: 'walmart-dsv',
                image: '/assets/image/stores/list/walmart-dsv.svg',
            },
            {
                label: 'ebay',
                image: '/assets/image/stores/list/ebay.svg',
            },
            {
                label: 'newegg-marketplace',
                image: '/assets/image/stores/list/newegg-marketplace.svg',
            },
            {
                label: 'reverb',
                image: '/assets/image/stores/list/reverb.svg',
            },
            {
                label: 'wish',
                image: '/assets/image/stores/list/wish.svg',
            },
            {
                label: 'houzz',
                image: '/assets/image/stores/list/houzz.svg',
            },
            {
                label: 'faire',
                image: '/assets/image/stores/list/faire.svg',
            },
            {
                label: 'yamibuy',
                image: '/assets/image/stores/list/yamibuy.svg',
            },
            {
                label: 'weee',
                image: '/assets/image/stores/list/weee.svg',
            },
            {
                label: 'wayfair',
                image: '/assets/image/stores/list/wayfair.svg',
            },

        ],
    },
];
