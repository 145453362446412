import {createSlice} from '@reduxjs/toolkit';
// utils
import axios, {API_ENDPOINTS} from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    userList: [],
    user: [],
    company: [],
    companyType: '',
};

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET USER
        getUserSuccess(state, action) {
            state.isLoading = false;
            state.user = action.payload;
            state.error = null
        },

        // GET COMPANY
        getCompanySuccess(state, action) {
            state.isLoading = false;
            state.company = action.payload;
            state.error = null
        },

        // GET USERS
        getUsersSuccess(state, action) {
            state.isLoading = false;
            state.userList = action.payload;
        },

        // ACTIVE / DE-ACTIVE USERS
        changeUserStatusSuccess(state, action) {
            const { id, isActive } = action.payload;
            const modifiedUserList = state.userList.map((_user) => {
                if (_user.id === id) {
                    _user.status = isActive ? 'ACTIVATE' : 'INACTIVATE';
                }

                return _user;
            });

            state.isLoading = false;
            state.userList = modifiedUserList;
        },

        // MODIFY USER
        modifyUserSuccess(state, action) {
            const { id: userId, first_name: firstName, last_name: lastName, email } = action.payload;

            const modifiedUserList = state.userList.map((_user) => {
                if (_user.id === userId) {
                    _user.first_name = firstName;
                    _user.last_name = lastName;
                    _user.email = email;
                }

                return _user;
            });

            state.isLoading = false;
            state.userList = modifiedUserList;
        },

        // DELETE ADDRESS
        deleteUserSuccess(state, action) {
            state.isLoading = false;
            state.userList = state.userList.filter((_user) => `${_user.id}` !== `${action.payload}`) || [];
        },

        // UPDATE COMPANY
        updateCompanySuccess(state, action) {
            state.company = action.payload;
        },

        // GET COMPANY TYPE
        getCompanyTypeSuccess(state, action) {
            state.companyType = action.payload;
        },

    },
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getUser() {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(API_ENDPOINTS.settings.accountInfo);
            dispatch(slice.actions.getUserSuccess(response.data.data))
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
};

export const avatarUpload = (file) => {
    const formData = new FormData();
    formData.append('file', file);

    return axios.post(API_ENDPOINTS.settings.avatar, formData);
};

// ----------------------------------------------------------------------

export function getCompanyType() {
    return async (dispatch) => {
        try {
            const response = await axios.get(API_ENDPOINTS.user.getCompanyType);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getCompanyTypeSuccess(data.data?.type ?? ''));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

// ----------------------------------------------------------------------

export function modifyCompanyType(type) {
    return async (dispatch) => {
        try {
            const response = await axios.post(API_ENDPOINTS.user.updateCompanyType, { type });

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getCompanyTypeSuccess(data.data?.type ?? ''));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

// ----------------------------------------------------------------------

export function getUserList() {
    return async (dispatch) => {
        try {
            const response = await axios.get(API_ENDPOINTS.user.searchUser);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getUsersSuccess(data?.data || []));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function addUser(detail) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.user.addUser, detail);

            const { status, data } = response;

            if (status === 200) {
                dispatch(getUserList());
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function modifyUser(detail) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.user.editUser, detail);

            const { status, data } = response;

            if (status === 200) {
                // dispatch(slice.actions.modifyUserSuccess(detail));
                dispatch(getUserList());
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function changeUserStatus(id, isActive) {
    return async (dispatch) => {
        try {
            const response = await axios.post(API_ENDPOINTS.user.activateUser, {
                id,
                status: isActive ? 'ACTIVATE' : 'INACTIVATE',
            });

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.changeUserStatusSuccess({ id, isActive }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function deleteUser(id) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.user.deleteUser, {
                id,
            });

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.deleteUserSuccess(id));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function getCompany() {
    // const url = `${API_ENDPOINTS.settings.company}?company_id=${Id}`
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(API_ENDPOINTS.settings.company);
            dispatch(slice.actions.getCompanySuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

// ----------------------------------------------------------------------

export function modifyCompany(detail) {
    return async (dispatch) => {
        try {
            const response = await axios.post(API_ENDPOINTS.settings.updateCompany, detail);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.updateCompanySuccess(data.data ?? null));
            }

            return response;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
};

// ----------------------------------------------------------------------

export const companyAvatarUpload = (file) => {
    const formData = new FormData();
    formData.append('file', file);

    return axios.post(API_ENDPOINTS.settings.updateCompanyLogo, formData);
};
