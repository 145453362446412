import {createSlice} from '@reduxjs/toolkit';
// utils
import axios, {API_ENDPOINTS} from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    warehouseList: [],
    warehouseFilter: {
        residential: 'all',
        keyword: '',
        sort: 'desc',
        page: 0,
        rowsPerPage: 5,
        total: 0,
    },
    warehouseCountryList: [],
    isOpenModal: false,
};

const slice = createSlice({
    name: 'warehouse',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET WAREHOUSES
        getWarehouseListSuccess(state, action) {
            state.isLoading = false;
            state.warehouseList = action.payload;

            // // FOR ADD WAREHOUSE && MODIFIED WAREHOUSE
            // state.isOpenModal = false;
            // state.isOpenValidateModal = false;
        },

        // GET SPECIFIC COUNTRY WAREHOUSES
        getWarehouseCountryListSuccess(state, action) {
            state.isLoading = false;
            state.warehouseCountryList = action.payload;
        },

        // UPDATE WAREHOUSE LIST FILTER
        updateWarehouseListFilterSuccess(state, action) {
            state.warehouseFilter = {
                ...state.warehouseFilter,
                ...action.payload,
            };
        },

        // MODIFY WAREHOUSE
        modifyWarehouseSuccess(state) {
            state.isLoading = false;
        },

        // DELETE WAREHOUSE
        deleteWarehouseSuccess(state, action) {
            state.isLoading = false;
            state.warehouseList = state.warehouseList.filter((_warehouse) => `${_warehouse.warehouse_id}` !== `${action.payload}`) || [];
        },

        // OPEN MODAL
        openModal(state) {
            state.isOpenModal = true;
        },

        // CLOSE MODAL
        closeModal(state) {
            state.isOpenModal = false;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal } = slice.actions;

// ----------------------------------------------------------------------

export function getWarehouseList(filter) {
    return async (dispatch, getState) => {
        try {
            const response = await axios.get(API_ENDPOINTS.warehouse.warehouses);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getWarehouseListSuccess(data?.data ?? []));
                // dispatch(slice.actions.updateWarehouseListFilterSuccess({
                //     total: data?.total || 0,
                //     rowsPerPage: data?.per_page || 5,
                // }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// // ----------------------------------------------------------------------
//
// export function updateWarehouseListFilter(filter) {
//     return () => {
//         dispatch(slice.actions.updateWarehouseListFilterSuccess({
//             residential: filter?.residential || 'all',
//             keyword: filter?.keyword || '',
//             sort: filter?.sort || 'desc',
//             page: filter?.page || 0,
//             rowsPerPage: filter?.rowsPerPage || 5,
//             total: filter?.total || 0,
//         }));
//     };
// }
//
// // ----------------------------------------------------------------------
//
// export function resetWarehouseFilter() {
//     return async () => {
//         const defaultFilter = {
//             residential: 'all',
//             keyword: '',
//             sort: 'desc',
//             page: 0,
//             rowsPerPage: 5,
//             total: 0,
//         };
//
//         dispatch(slice.actions.updateWarehouseListFilterSuccess({ ...defaultFilter }));
//
//         return defaultFilter;
//     };
// }
//
// // ----------------------------------------------------------------------
//
// export function getWarehouseCountryList(countries) {
//     return async () => {
//         try {
//             const response = await getWarehouseListAPI(null);
//
//             const { status, data } = response;
//
//             if (status === 200) {
//                 const result = [];
//
//                 data.forEach((w) => {
//                     if (countries.includes(w.country.toLowerCase())) {
//                         result.push(w);
//                     }
//                 });
//
//                 dispatch(slice.actions.getWarehouseCountryListSuccess(result));
//             }
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }
//
// // ----------------------------------------------------------------------
//
export function addWarehouse(detail) {
    return async (dispatch, getState) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.warehouse.createWarehouse, detail);

            const { status, data } = response;

            if (status === 200) {
                dispatch(getWarehouseList());
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function modifyWarehouse(detail) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.warehouse.updateWarehouse, detail);

            const { status, data } = response;

            if (status === 200) {
                dispatch(getWarehouseList());
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function deleteWarehouse(id) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.warehouse.deleteWarehouse, {'warehouse_id':id});

            const { status, data } = response;

            if (status === 200 ) {
                dispatch(slice.actions.deleteWarehouseSuccess(id));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}
