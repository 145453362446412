// @mui
import { Stack, Typography, Link } from '@mui/material';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
import { PackageIllustration } from 'src/assets/illustrations';

// ----------------------------------------------------------------------

export default function NavDocs() {
    const { user } = useMockedUser();

    const { translate } = useLocales();

    return (
        <Stack spacing={5} sx={{ px: 2.5, pb: 5, mt: 10, width: 1, textAlign: 'left', display: 'block' }}>
            <Stack alignItems='flex-start' sx={{ backgroundColor: '#F4F4F4', borderRadius: '8px', px: 2, py: '22px' }}>
                <Stack zIndex={1}>
                    <Stack spacing={0.675}>
                        <Typography variant="body2" noWrap>
                            {`${translate('navbar_4')}`}
                        </Typography>

                        <Typography variant="caption" color='text.disabled' maxWidth='200px'>
                            {`${translate('navbar_5')}`}
                        </Typography>
                    </Stack>

                    <Link variant="caption" underline='always' color="#4568E5" target='_blank' href={`${translate('navbar_6')}`}>
                        {`${translate('navbar_3')}`}
                    </Link>
                </Stack>

                {/* <PackageIllustration sx={{ width: '180px', aspectRatio: '15/13', mt: '-20px' }}/> */}
                <PackageIllustration sx={{ width: '200px', mt: '-30px', ml: 'auto' }}/>
            </Stack>

            {/* <Button variant="text" sx={{ fontWeight: 500 }} onClick={handleLogout}> */}
            {/*     {`${translate('logout')}`} */}
            {/* </Button> */}
        </Stack>
    );
}
