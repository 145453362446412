import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import userReducer from './slices/user';
import planReducer from './slices/plan';
import warehouseReducer from './slices/warehouse';
import documentationReducer from './slices/documentation';
import productsReducer from './slices/products';
import addressReducer from './slices/address';
import binReducer from './slices/bin';
import taskReducer from './slices/task';
import carrierReducer from './slices/carrier';
import storeReducer from './slices/store';
import itemReducer from './slices/item';
import paymentReducer from './slices/payment';
import permissionReducer from './slices/permission';
import shippingRuleReducer from './slices/shippingRule';
import packageReducer from './slices/package';
import orderReducer from './slices/order';
import automationReducer from './slices/automation';

// ----------------------------------------------------------------------

export const rootPersistConfig = {
    key: 'root',
    storage,
    keyPrefix: 'redux-',
    whitelist: [],
};

const productPersistConfig = {
    key: 'product',
    storage,
    keyPrefix: 'redux-',
    whitelist: ['checkout'],
};

export const rootReducer = combineReducers({
    // product: persistReducer(productPersistConfig, productReducer),
    user: userReducer,
    plan: planReducer,
    warehouse: warehouseReducer,
    documentation: documentationReducer,
    address: addressReducer,
    products: productsReducer,
    bin: binReducer,
    task: taskReducer,
    carrier: carrierReducer,
    store: storeReducer,
    item: itemReducer,
    payment: paymentReducer,
    permission: permissionReducer,
    shippingRule: shippingRuleReducer,
    package: packageReducer,
    order: orderReducer,
    automation: automationReducer,
});
