// @mui
import {enUS, frFR, zhCN, viVN, arSA} from '@mui/material/locale';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
    {
        label: 'English(USA)',
        value: 'en',
        systemValue: enUS,
        icon: '/assets/icon/language/ic_language_us.svg',
    },
    // {
    //   label: 'French',
    //   value: 'fr',
    //   systemValue: frFR,
    //   icon: 'flagpack:fr',
    // },
    // {
    //   label: 'Vietnamese',
    //   value: 'vi',
    //   systemValue: viVN,
    //   icon: 'flagpack:vn',
    // },
    {
        label: '简体中文',
        value: 'cn',
        systemValue: zhCN,
        icon: '/assets/icon/language/ic_language_cn.svg',
    },
    // {
    //   label: 'Arabic',
    //   value: 'ar',
    //   systemValue: arSA,
    //   icon: 'flagpack:sa',
    // },
];

export const defaultLang = allLangs[0]; // English

// GET MORE COUNTRY FLAGS
// https://icon-sets.iconify.design/flagpack/
// https://www.dropbox.com/sh/nec1vwswr9lqbh9/AAB9ufC8iccxvtWi3rzZvndLa?dl=0
