import { Suspense, lazy } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import RedirectToPayment from 'src/routes/sections/RedirectToPayment';
import { PATH_AFTER_REGISTER } from '../../config-global';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
// ORDERS
const OrdersListPage = lazy(() => import('src/pages/dashboard/orders/orders'));
const OrdersAwaitingListPage = lazy(() => import('src/pages/dashboard/orders/awaiting'));
const OrdersHoldListPage = lazy(() => import('src/pages/dashboard/orders/hold'));
const OrdersCanceledListPage = lazy(() => import('src/pages/dashboard/orders/canceled'));
const OrdersShippedListPage = lazy(() => import('src/pages/dashboard/orders/shipped'));
// ORDER
// const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
// const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
// INVOICE
// const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
// const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/details'));
// const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
// const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));
// INVENTORY
const InventoryPage = lazy(() => import('src/pages/dashboard/inventory/inventory'));
const InventoryProcessPage = lazy(() => import('src/pages/dashboard/inventory/process'));
const InventoryHistoryPage = lazy(() => import('src/pages/dashboard/inventory/history'));
// WAREHOUSE
const WarehousePage = lazy(() => import('src/pages/dashboard/warehouse/warehouse'));
const BinsPage = lazy(() => import('src/pages/dashboard/warehouse/bins'));
const ProductsPage = lazy(() => import('src/pages/dashboard/warehouse/products'));
// STORES
const StoresAuthorized = lazy(() => import('src/pages/dashboard/stores/authorized'));
const StoresInstall = lazy(() => import('src/pages/dashboard/stores/install'));
// WALLET
const PaymentPage = lazy(() => import('src/pages/dashboard/wallet/payment'));
const TransactionPage = lazy(() => import('src/pages/dashboard/wallet/transaction'));
const RebatePage = lazy(() => import('src/pages/dashboard/wallet/rebate'));
const RechargePage = lazy(() => import('src/pages/dashboard/wallet/fund'));
// SETTINGS
const SettingsAccountPage = lazy(() => import('src/pages/dashboard/settings/account'));
const SettingsAutomationPage = lazy(() => import('src/pages/dashboard/settings/automation'));
// const SettingsPrinterPage = lazy(() => import('src/pages/dashboard/settings/printer'));
// const SettingsDocumentationPage = lazy(() => import('src/pages/dashboard/settings/documentation'));
const AddressBookPage = lazy(() => import('src/pages/dashboard/settings/address-book'));
const IntegrationPage = lazy(() => import('src/pages/dashboard/settings/integration'));
const BrandingPage = lazy(() => import('src/pages/dashboard/settings/branding'));
// ADDRESS-BOOK
// const AddressBookPage = lazy(() => import('src/pages/dashboard/address/address'));
// CARRIERS
const CarrierPage = lazy(() => import('src/pages/dashboard/carrier/CarrierAccounts'));
// USER
const UserPage = lazy(() => import('src/pages/dashboard/user/user'));
// SHIPPING RULE
const ShippingRulesPage = lazy(() => import('src/pages/dashboard/shippingRule/shippingRule'));
// const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
// const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
// const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
// const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
// const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
// const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
// FILE MANAGER
// const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// TEST RENDER PAGE BY ROLE
// const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
    {
        path: '/',
        element: (
            <AuthGuard>
                <DashboardLayout>
                    <Suspense fallback={<LoadingScreen/>}>
                        <Outlet/>
                    </Suspense>
                </DashboardLayout>
            </AuthGuard>
        ),
        children: [
            // { element: <IndexPage />, index: true },
            { element: <Navigate to={PATH_AFTER_REGISTER} replace/>, index: true },
            { path: 'dashboard', element: <IndexPage/> },
            {
                path: 'orders',
                children: [
                    { element: <OrdersListPage/>, index: true },
                    { path: 'awaiting', element: <OrdersAwaitingListPage/> },
                    { path: 'hold', element: <OrdersHoldListPage/> },
                    { path: 'canceled', element: <OrdersCanceledListPage/> },
                    { path: 'shipped', element: <OrdersShippedListPage/> },
                ],
            },
            {
                path: 'inventory',
                children: [
                    { element: <InventoryPage/>, index: true },
                    { path: 'process', element: <InventoryProcessPage/> },
                    { path: 'history', element: <InventoryHistoryPage/> },
                ],
            },
            {
                path: 'warehouse',
                children: [
                    { element: <WarehousePage/>, index: true },
                    { path: 'bins', element: <BinsPage/> },
                    { path: 'products', element: <ProductsPage/> },
                ],
            },
            {
                path: 'stores',
                children: [
                    { path: ':platform/install', element: <StoresInstall/> },
                    { path: ':platform/authorized', element: <StoresAuthorized/> },
                ],
            },
            {
                path: 'payments',
                children: [
                    { element: <PaymentPage/>, index: true },
                    { path: 'transactions', element: <TransactionPage/> },
                    { path: 'rebate', element: <RebatePage/> },
                    { path: 'funds', element: <RechargePage/> },
                ],
            },
            {
                path: 'settings',
                children: [
                    { path: 'account', element: <SettingsAccountPage/> },
                    // { path: 'company', element: <SettingsCompanyPage /> },
                    // { path: 'printer', element: <SettingsPrinterPage /> },
                    // { path: 'documentation', element: <SettingsDocumentationPage /> },
                    { path: 'address-book', element: <AddressBookPage/> },
                    { path: 'integration', element: <IntegrationPage/> },
                    { path: 'automation', element: <SettingsAutomationPage/> },
                    { path: 'shipping-rules', element: <ShippingRulesPage/> },
                    { path: 'branding', element: <BrandingPage/> }
                ],
            },
            { path: 'shipping-rules', element: <ShippingRulesPage/> },
            // { path: 'address-book', element: <AddressBookPage /> },
            { path: 'user', element: <UserPage/> },
            { path: 'carrier-accounts', element: <CarrierPage/> },
            { path: 'others', element: <RedirectToPayment/> },
            // {
            //     path: 'user',
            //     children: [
            //         {element: <UserProfilePage/>, index: true},
            //         {path: 'profile', element: <UserProfilePage/>},
            //         {path: 'cards', element: <UserCardsPage/>},
            //         {path: 'list', element: <UserListPage/>},
            //         {path: 'new', element: <UserCreatePage/>},
            //         {path: ':id/edit', element: <UserEditPage/>},
            //         {path: 'account', element: <UserAccountPage/>},
            //     ],
            // },
            // {
            //     path: 'order',
            //     children: [
            //         { element: <OrderListPage/>, index: true },
            //         { path: 'list', element: <OrderListPage/> },
            //         { path: ':id', element: <OrderDetailsPage/> },
            //     ],
            // },
            // {
            //     path: 'invoice',
            //     children: [
            //         { element: <InvoiceListPage />, index: true },
            //         { path: 'list', element: <InvoiceListPage /> },
            //         { path: ':id', element: <InvoiceDetailsPage /> },
            //         { path: ':id/edit', element: <InvoiceEditPage /> },
            //         { path: 'new', element: <InvoiceCreatePage /> },
            //     ],
            // },
            // { path: 'file-manager', element: <FileManagerPage /> },
            // { path: 'permission', element: <PermissionDeniedPage /> },
            { path: '/dashboard/blank', element: <BlankPage/> },
        ],
    },
    // {
    //   element: (
    //       <CompactLayout>
    //         <Outlet/>
    //       </CompactLayout>
    //   ),
    //   children: [
    //     { path: 'coming-soon', element: <ComingSoonPage/> },
    //     { path: 'maintenance', element: <MaintenancePage/> },
    //     { path: '500', element: <Page500/> },
    //     { path: '404', element: <Page404/> },
    //     { path: '403', element: <Page403/> },
    //   ],
    // },
];
