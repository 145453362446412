import {Navigate, useRoutes, Outlet} from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
// config
// import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import {mainRoutes, HomePage} from './main';
import {authRoutes} from './auth';
import {dashboardRoutes} from './dashboard';
import {componentsRoutes} from './components'
import AuthClassicLayout from "../../layouts/auth/classic";


// ----------------------------------------------------------------------

export default function Router() {
    return useRoutes([
        // SET INDEX PAGE WITH SKIP HOME PAGE
        // {
        //   path: '/',
        //   element: <Navigate to={PATH_AFTER_LOGIN} replace />,
        // },

        // ----------------------------------------------------------------------

        // SET INDEX PAGE WITH HOME PAGE
        // {
        //   path: '/',
        //   element: (
        //       <AuthClassicLayout>
        //         <HomePage />
        //       </AuthClassicLayout>
        //     // <MainLayout>
        //     //   <Outlet />
        //     // </MainLayout>
        //   ),
        //   // children: [{ element: <HomePage />, index: true }],
        // },

        // Auth routes
        ...authRoutes,
        // ...authDemoRoutes,

        // Dashboard routes
        ...dashboardRoutes,

        // Main routes
        ...mainRoutes,

        // Components routes
        ...componentsRoutes,

        // No match 404
        {path: '*', element: <Navigate to="/404" replace/>},
    ]);
}
