import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';
import Image from 'src/components/image';

// ----------------------------------------------------------------------

const icon = (name) => (
    <SvgColor src={`/assets/icon/navbar/pro/${name}.svg`} sx={{ width: 1, height: 1 }}/>
    // OR
    // <Iconify icon="fluent:mail-24-filled" />
    // https://icon-sets.iconify.design/solar/
    // https://www.streamlinehq.com/icons
);

const ICONS = {
    // analytics: icon('ic_analytics'),
    dashboard: icon('ic_dashboard'),
    orders: icon('ic_orders'),
    shipments: icon('ic_shipments'),
    packing: icon('ic_packing'),
    inventory: icon('ic_inventory'),
    warehouse: icon('ic_warehouse'),
    stores: icon('ic_stores'),
    wallet: icon('ic_wallet'),
    settings: icon('ic_settings'),
    shipping_rules: icon('ic_shipping_rules'),
    address_book: icon('ic_address_book'),
    carrier_accounts: icon('ic_carrier_accounts'),
    user: icon('ic_user'),
};

// ----------------------------------------------------------------------

export function useNavData() {
    const { translate } = useLocales();

    const data = useMemo(
        () => [
            // OVERVIEW
            // ----------------------------------------------------------------------
            {
                // subheader: translate('overview'),
                items: [
                    // { title: translate('app'), path: paths.dashboard.root, icon: ICONS.dashboard },
                    // { title: translate('ecommerce'), path: paths.dashboard.general.ecommerce, icon: ICONS.ecommerce },
                    // { title: translate('analytics'), path: paths.dashboard.general.analytics, icon: ICONS.analytics },
                    // { title: translate('banking'), path: paths.dashboard.general.banking, icon: ICONS.banking },
                    // { title: translate('booking'), path: paths.dashboard.general.booking, icon: ICONS.booking },
                    // { title: translate('file'), path: paths.dashboard.general.file, icon: ICONS.file },
                    { title: translate('dashboard'), path: paths.dashboard.root, icon: ICONS.dashboard },
                    {
                        title: translate('orders'),
                        path: paths.dashboard.orders.root,
                        icon: ICONS.orders,
                        // children: [
                        //     { title: translate('awaiting'), path: paths.dashboard.orders.awaiting },
                        //     { title: translate('hold'), path: paths.dashboard.orders.hold },
                        //     { title: translate('canceled'), path: paths.dashboard.orders.canceled },
                        //     { title: translate('shipped'), path: paths.dashboard.orders.shipped },
                        //     { title: translate('all'), path: paths.dashboard.orders.root },
                        // ],
                    },
                    { title: translate('shipments'), path: paths.dashboard.general.analytics, icon: ICONS.shipments },
                    { title: translate('analytics'), path: paths.dashboard.general.ecommerce, icon: ICONS.packing },
                    // { title: translate('packing'), path: paths.dashboard.general.banking, icon: ICONS.packing },
                    // {
                    //     title: translate('inventory'),
                    //     path: paths.dashboard.inventory.root,
                    //     icon: ICONS.inventory,
                    //     children: [
                    //         { title: translate('inventory'), path: paths.dashboard.inventory.root },
                    //         { title: translate('inbound & outbound'), path: paths.dashboard.inventory.process },
                    //         { title: translate('history'), path: paths.dashboard.inventory.history },
                    //     ],
                    // },
                    // {
                    //     title: translate('warehouse'),
                    //     path: paths.dashboard.warehouse.root,
                    //     icon: ICONS.warehouse,
                    //     children: [
                    //         { title: translate('warehouse'), path: paths.dashboard.warehouse.root },
                    //         { title: translate('bins'), path: paths.dashboard.warehouse.bins },
                    //         { title: translate('products'), path: paths.dashboard.warehouse.products },
                    //     ],
                    // },
                    // {
                    //     title: translate('store'),
                    //     path: paths.dashboard.store.root,
                    //     icon: ICONS.stores,
                    //     children: [
                    //         { title: translate('stores'), path: paths.dashboard.store.root },
                    //         { title: translate('items'), path: paths.dashboard.store.items },
                    //     ],
                    // },
                    // {
                    //     title: translate('wallet'),
                    //     path: paths.dashboard.wallet.root,
                    //     icon: ICONS.wallet,
                    //     children: [
                    //         { title: translate('payments'), path: paths.dashboard.wallet.root },
                    //         { title: translate('transactions'), path: paths.dashboard.wallet.transaction },
                    //     ],
                    // },
                    {
                        title: translate('settings'),
                        path: paths.dashboard.settings.root,
                        icon: ICONS.settings,
                        children: [
                            { title: translate('account'), path: paths.dashboard.settings.account },
                            // { title: translate('company'), path: paths.dashboard.settings.company },
                            // { title: translate('printer'), path: paths.dashboard.settings.printer },
                            // { title: translate('documentation'), path: paths.dashboard.settings.documentation },
                            { title: translate('address_book'), path: paths.dashboard.settings.addressBook },
                            { title: translate('integration'), path: paths.dashboard.settings.integration },
                            { title: translate('automation'), path: paths.dashboard.settings.automation },
                            { title: translate('shipping_rule'), path: paths.dashboard.settings.shippingRules, info:'PRO' },
                            { title: translate('branding'), path: paths.dashboard.settings.branding, info:'PRO' },
                        ],
                    },
                    // {
                    //     title: translate('shipping_rules'),
                    //     path: paths.dashboard.shippingRules,
                    //     icon: ICONS.shipping_rules
                    // },
                    // { title: translate('address_book'), path: paths.dashboard.addressBook, icon: ICONS.address_book },
                    // {
                    //     title: translate('carrier_accounts'),
                    //     path: paths.dashboard.carrier_accounts,
                    //     icon: ICONS.carrier_accounts
                    // },
                    // { title: translate('user'), path: paths.dashboard.user, icon: ICONS.user },
                ],
            },

            // MANAGEMENT
            // ----------------------------------------------------------------------
            // {
            //   subheader: translate('management'),
            //   items: [
            //     // USER
            //     {
            //       title: translate('user'),
            //       path: paths.dashboard.user.root,
            //       icon: ICONS.user,
            //       children: [
            //         { title: translate('profile'), path: paths.dashboard.user.root },
            //         { title: translate('cards'), path: paths.dashboard.user.cards },
            //         { title: translate('list'), path: paths.dashboard.user.list },
            //         { title: translate('create'), path: paths.dashboard.user.new },
            //         { title: translate('edit'), path: paths.dashboard.user.demo.edit },
            //         { title: translate('account'), path: paths.dashboard.user.account },
            //       ],
            //     },
            //
            //     // PRODUCT
            //     {
            //       title: translate('product'),
            //       path: paths.dashboard.product.root,
            //       icon: ICONS.product,
            //       children: [
            //         { title: translate('list'), path: paths.dashboard.product.root },
            //         { title: translate('details'), path: paths.dashboard.product.demo.details },
            //         { title: translate('create'), path: paths.dashboard.product.new },
            //         { title: translate('edit'), path: paths.dashboard.product.demo.edit },
            //       ],
            //     },
            //
            //     // ORDER
            //     {
            //       title: translate('order'),
            //       path: paths.dashboard.order.root,
            //       icon: ICONS.order,
            //       children: [
            //         { title: translate('list'), path: paths.dashboard.order.root },
            //         { title: translate('details'), path: paths.dashboard.order.demo.details },
            //       ],
            //     },
            //
            //     // INVOICE
            //     {
            //       title: translate('invoice'),
            //       path: paths.dashboard.invoice.root,
            //       icon: ICONS.invoice,
            //       children: [
            //         { title: translate('list'), path: paths.dashboard.invoice.root },
            //         { title: translate('details'), path: paths.dashboard.invoice.demo.details },
            //         { title: translate('create'), path: paths.dashboard.invoice.new },
            //         { title: translate('edit'), path: paths.dashboard.invoice.demo.edit },
            //       ],
            //     },
            //
            //     // BLOG
            //     {
            //       title: translate('blog'),
            //       path: paths.dashboard.post.root,
            //       icon: ICONS.blog,
            //       children: [
            //         { title: translate('list'), path: paths.dashboard.post.root },
            //         { title: translate('details'), path: paths.dashboard.post.demo.details },
            //         { title: translate('create'), path: paths.dashboard.post.new },
            //         { title: translate('edit'), path: paths.dashboard.post.demo.edit },
            //       ],
            //     },
            //
            //     // JOB
            //     {
            //       title: translate('job'),
            //       path: paths.dashboard.job.root,
            //       icon: ICONS.job,
            //       children: [
            //         { title: translate('list'), path: paths.dashboard.job.root },
            //         { title: translate('details'), path: paths.dashboard.job.demo.details },
            //         { title: translate('create'), path: paths.dashboard.job.new },
            //         { title: translate('edit'), path: paths.dashboard.job.demo.edit },
            //       ],
            //     },
            //
            //     // TOUR
            //     {
            //       title: translate('tour'),
            //       path: paths.dashboard.tour.root,
            //       icon: ICONS.tour,
            //       children: [
            //         { title: translate('list'), path: paths.dashboard.tour.root },
            //         { title: translate('details'), path: paths.dashboard.tour.demo.details },
            //         { title: translate('create'), path: paths.dashboard.tour.new },
            //         { title: translate('edit'), path: paths.dashboard.tour.demo.edit },
            //       ],
            //     },
            //
            //     // FILE MANAGER
            //     {
            //       title: translate('file_manager'),
            //       path: paths.dashboard.fileManager,
            //       icon: ICONS.folder,
            //     },
            //
            //     // MAIL
            //     {
            //       title: translate('mail'),
            //       path: paths.dashboard.mail,
            //       icon: ICONS.mail,
            //       info: <Label color="error">+32</Label>,
            //     },
            //
            //     // CHAT
            //     {
            //       title: translate('chat'),
            //       path: paths.dashboard.chat,
            //       icon: ICONS.chat,
            //     },
            //
            //     // CALENDAR
            //     {
            //       title: translate('calendar'),
            //       path: paths.dashboard.calendar,
            //       icon: ICONS.calendar,
            //     },
            //
            //     // KANBAN
            //     {
            //       title: translate('kanban'),
            //       path: paths.dashboard.kanban,
            //       icon: ICONS.kanban,
            //     },
            //   ],
            // },
            //
            // // DEMO MENU STATES
            // {
            //   subheader: translate(translate('other_cases')),
            //   items: [
            //     {
            //       // default roles : All roles can see this entry.
            //       // roles: ['user'] Only users can see this item.
            //       // roles: ['admin'] Only admin can see this item.
            //       // roles: ['admin', 'manager'] Only admin/manager can see this item.
            //       // Reference from 'src/guards/RoleBasedGuard'.
            //       title: translate('item_by_roles'),
            //       path: paths.dashboard.permission,
            //       icon: ICONS.lock,
            //       roles: ['admin', 'manager'],
            //       caption: translate('only_admin_can_see_this_item'),
            //     },
            //     {
            //       title: translate('menu_level'),
            //       path: '#/dashboard/menu_level',
            //       icon: ICONS.menuItem,
            //       children: [
            //         {
            //           title: translate('menu_level_1a'),
            //           path: '#/dashboard/menu_level/menu_level_1a',
            //         },
            //         {
            //           title: translate('menu_level_1b'),
            //           path: '#/dashboard/menu_level/menu_level_1b',
            //           children: [
            //             {
            //               title: translate('menu_level_2a'),
            //               path: '#/dashboard/menu_level/menu_level_1b/menu_level_2a',
            //             },
            //             {
            //               title: translate('menu_level_2b'),
            //               path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b',
            //               children: [
            //                 {
            //                   title: translate('menu_level_3a'),
            //                   path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b/menu_level_3a',
            //                 },
            //                 {
            //                   title: translate('menu_level_3b'),
            //                   path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b/menu_level_3b',
            //                 },
            //               ],
            //             },
            //           ],
            //         },
            //       ],
            //     },
            //     {
            //       title: translate('item_disabled'),
            //       path: '#disabled',
            //       icon: ICONS.disabled,
            //       disabled: true,
            //     },
            //     {
            //       title: translate('item_label'),
            //       path: '#label',
            //       icon: ICONS.label,
            //       info: (
            //         <Label color="info" startIcon={<Iconify icon="solar:bell-bing-bold-duotone" />}>
            //           NEW
            //         </Label>
            //       ),
            //     },
            //     {
            //       title: translate('item_caption'),
            //       path: '#caption',
            //       icon: ICONS.menuItem,
            //       caption:
            //         'Quisque malesuada placerat nisl. In hac habitasse platea dictumst. Cras id dui. Pellentesque commodo eros a enim. Morbi mollis tellus ac sapien.',
            //     },
            //     {
            //       title: translate('item_external_link'),
            //       path: 'https://www.google.com/',
            //       icon: ICONS.external,
            //     },
            //     {
            //       title: translate('blank'),
            //       path: paths.dashboard.blank,
            //       icon: ICONS.blank,
            //     },
            //   ],
            // },
        ],
        [translate]
    );

    return data;
}
