import {lazy} from 'react';
import {Outlet} from 'react-router-dom';
// auth
import {GuestGuard} from 'src/auth/guard';
// layouts
import CompactLayout from 'src/layouts/compact';
import AuthClassicLayout from 'src/layouts/auth/classic';
import AuthModernLayout from "../../layouts/auth/modern";

// ----------------------------------------------------------------------

// // AMPLIFY
// const AmplifyLoginPage = lazy(() => import('src/pages/auth/amplify/login'));
// const AmplifyRegisterPage = lazy(() => import('src/pages/auth/amplify/register'));
// const AmplifyVerifyPage = lazy(() => import('src/pages/auth/amplify/verify'));
// const AmplifyNewPasswordPage = lazy(() => import('src/pages/auth/amplify/new-password'));
// const AmplifyForgotPasswordPage = lazy(() => import('src/pages/auth/amplify/forgot-password'));

// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const JwtRegisterPage = lazy(() => import('src/pages/auth/jwt/register'));
const JwtForgotPasswordPage = lazy(() => import('src/pages/auth/jwt/forgot-password'));
const NewPasswordPage = lazy(() => import('src/pages/auth/jwt/new-password'));
const VerifiedPage = lazy(() => import('src/pages/auth/jwt/verified'));
// // FIREBASE
// const FirebaseLoginPage = lazy(() => import('src/pages/auth/firebase/login'));
// const FirebaseRegisterPage = lazy(() => import('src/pages/auth/firebase/register'));
// const FirebaseVerifyPage = lazy(() => import('src/pages/auth/firebase/verify'));
// const FirebaseForgotPasswordPage = lazy(() => import('src/pages/auth/firebase/forgot-password'));
//
// // AUTH0
// const Auth0LoginPage = lazy(() => import('src/pages/auth/auth0/login'));
// const Auth0Callback = lazy(() => import('src/pages/auth/auth0/callback'));

// ----------------------------------------------------------------------

// const authAmplify = {
//   path: 'amplify',
//   element: (
//     <GuestGuard>
//       <Outlet />
//     </GuestGuard>
//   ),
//   children: [
//     {
//       path: 'login',
//       element: (
//         <AuthClassicLayout>
//           <AmplifyLoginPage />
//         </AuthClassicLayout>
//       ),
//     },
//     {
//       path: 'register',
//       element: (
//         <AuthClassicLayout title="Manage the job more effectively with Minimal">
//           <AmplifyRegisterPage />
//         </AuthClassicLayout>
//       ),
//     },
//     {
//       element: (
//         <CompactLayout>
//           <Outlet />
//         </CompactLayout>
//       ),
//       children: [
//         { path: 'verify', element: <AmplifyVerifyPage /> },
//         { path: 'new-password', element: <AmplifyNewPasswordPage /> },
//         { path: 'forgot-password', element: <AmplifyForgotPasswordPage /> },
//       ],
//     },
//   ],
// };

const authJwt = {
    // path: 'jwt',
    path: '',
    element: (
        <GuestGuard>
            <Outlet/>
        </GuestGuard>
    ),
    children: [
        {
            path: 'login',
            element: (
                <AuthModernLayout>
                    <JwtLoginPage/>
                </AuthModernLayout>
            ),
        },
        {
            path: 'register',
            element: (
                <AuthModernLayout title="Manage the job more effectively with Minimal">
                    <JwtRegisterPage/>
                </AuthModernLayout>
            ),
        },
        {
            path: 'forgot-password',
            element: (
                <AuthModernLayout>
                    <JwtForgotPasswordPage/>
                </AuthModernLayout>
            ),
        },
        {
            path: 'reset_password',
            element: (
                <AuthModernLayout>
                    <NewPasswordPage/>
                </AuthModernLayout>
            ),
        },
        {
          path:'verified_email',
          element: (
              <VerifiedPage/>
          ),
        },
    ],
};

// const authFirebase = {
//   path: 'firebase',
//   element: (
//     <GuestGuard>
//       <Outlet />
//     </GuestGuard>
//   ),
//   children: [
//     {
//       path: 'login',
//       element: (
//         <AuthClassicLayout>
//           <FirebaseLoginPage />
//         </AuthClassicLayout>
//       ),
//     },
//     {
//       path: 'register',
//       element: (
//         <AuthClassicLayout title="Manage the job more effectively with Minimal">
//           <FirebaseRegisterPage />
//         </AuthClassicLayout>
//       ),
//     },
//     {
//       element: (
//         <CompactLayout>
//           <Outlet />
//         </CompactLayout>
//       ),
//       children: [
//         { path: 'verify', element: <FirebaseVerifyPage /> },
//         { path: 'forgot-password', element: <FirebaseForgotPasswordPage /> },
//       ],
//     },
//   ],
// };

// const authAuth0 = {
//   path: 'auth0',
//   children: [
//     {
//       path: 'login',
//       element: (
//         <GuestGuard>
//           <AuthClassicLayout>
//             <Auth0LoginPage />
//           </AuthClassicLayout>
//         </GuestGuard>
//       ),
//     },
//     {
//       path: 'callback',
//       element: <Auth0Callback />,
//     },
//   ],
// };

export const authRoutes = [
    {
        // path: 'auth',
        // children: [authAmplify, authJwt, authFirebase, authAuth0],
        path: '/',
        children: [authJwt],
    },
];
