import { createSlice } from '@reduxjs/toolkit';
// api
import axios, { API_ENDPOINTS } from "../../utils/axios";
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    binsList: [],
    binFilter: {
        warehouse_id: 'all',
        type: 'all',
        bin_number: '',
        barcode: '',
        keyword: '',
        searchCategory: 'Barcode',
        page: 0,
        page_size: 25,
        total: 0,
    },
    sidebarFilter: {
        zone: '',
        aisle: '',
        bay: '',
        shelf: '',
        sequence: '',
    },
    // product: {
    //     id: null,
    //     company_id: null,
    //     sku: null,
    //     barcode: null,
    //     thumbnail: null,
    //     title: null,
    //     price: null,
    //     length: null,
    //     width: null,
    //     height: null,
    //     weight: null,
    //     weight_lb: null,
    //     weight_oz: null,
    //     style: null,
    //     color: null,
    //     size: null,
    //     hs_tariff_number: null,
    //     origin_country: null,
    //     create_time: null,
    // },
};

const slice = createSlice({
    name: 'bin',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET ITEM LIST
        getBinsSuccess(state, action) {
            state.isLoading = false;
            state.binList = action.payload;
        },

        // UPDATE ITEM LIST FILTER
        updateBinListFilterSuccess(state, action) {
            state.binFilter = {
                ...state.binFilter,
                ...action.payload,
            };
        },

        // RESET ITEM
        // resetBin(state) {
        //     state.order = null;
        // },

        // MODIFY Item
        modifyBinSuccess(state) {
            state.isLoading = false;
        },

        // DELETE ITEM
        deleteBinSuccess(state, action) {
            state.isLoading = false;
            state.binList.bins = state.binList?.bins.filter((_bin) => !action.payload.includes(_bin.bin_id));
        },

        // UPDATE BIN SIDEBAR FILTER
        updateBinSidebarFilterSuccess(state, action) {
            state.sidebarFilter = {
                ...state.sidebarFilter,
                ...action.payload,
            };
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    resetBin,
} = slice.actions;

// ----------------------------------------------------------------------
const filterEmptyValues = (obj) => {
    const result = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
        if (obj[key] !== '' && obj[key] !== 'all') {
            result[key] = obj[key];
        }
    }
    if (result?.searchCategory) {
        switch (result?.searchCategory) {
            case 'Barcode':
                result.barcode = result.keyword;
                break;
            case 'Number':
                result.number = result.keyword;
                break;
            default:
                break;
        }
    }

    return result;
};

export function getBinList(filter) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(API_ENDPOINTS.warehouse.searchBin, {
                params: filterEmptyValues(filter),
            });

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getBinsSuccess(data?.data || []));
                dispatch(slice.actions.updateBinListFilterSuccess({
                    total: data?.data?.total || 0,
                }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateBinSidebarFilter(filter) {
    return () => {
        dispatch(slice.actions.updateBinSidebarFilterSuccess({
            zone: filter?.zone || '',
            aisle: filter?.aisle || '',
            bay: filter?.bay || '',
            shelf: filter?.shelf || '',
            sequence: filter?.sequence || '',
        }));
    };
}

// ----------------------------------------------------------------------

export function resetSidebarFilter() {
    return async () => {
        const defaultFilter = {
            zone: '',
            aisle: '',
            bay: '',
            shelf: '',
            sequence: '',
        };

        dispatch(slice.actions.updateBinSidebarFilterSuccess({ ...defaultFilter }));

        return defaultFilter;
    };
}

// ----------------------------------------------------------------------

export function addBin(detail) {
    return async (dispatchA, getState) => {
        dispatchA(slice.actions.startLoading(true));
        try {
            const response = await axios.post(API_ENDPOINTS.warehouse.createBin, detail);

            const { status, data } = response;

            if (status === 200) {
                dispatch(getBinList(getState().bin.binFilter));
                return true;
            }
            return false;
        } catch (error) {
            dispatchA(slice.actions.hasError(error));
            return false;
        }
    };
}

//---------------------------------------------------------------------------------------

export function modifyBin(detail) {
    return async (dispatchA, getState) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.warehouse.updateBin, detail);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.modifyBinSuccess());
                dispatch(getBinList(getState().bin.binFilter));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function deleteBins(ids) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.warehouse.deleteBin, {
                bin_ids: ids,
            });

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.deleteBinSuccess(ids));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}

// // ----------------------------------------------------------------------
//
// // export function getItemInfo(id) {
// //     return async () => {
// //         try {
// //             const response = await getItemAPI(id);
// //             const { status, data } = response;
// //
// //             if (status === 200 && data) {
// //                 dispatch(slice.actions.getItemsSuccess(data ?? null));
// //             }
// //         } catch (error) {
// //             dispatch(slice.actions.hasError(error));
// //         }
// //     }
// // }
//
// ----------------------------------------------------------------------

export function updateBinListFilter(filter) {
    return () => {
        dispatch(slice.actions.updateBinListFilterSuccess({
            warehouse_id: filter?.warehouse_id || 'all',
            type: filter?.type || 'all',
            bin_number: filter?.bin_number || '',
            barcode: filter?.barcode || '',
            keyword: filter?.keyword || '',
            searchCategory: filter?.searchCategory || '',
            page: filter?.page || 0,
            page_size: filter?.page_size || 25,
            total: filter?.total || 0,
        }));
    };
}

// ----------------------------------------------------------------------

export function resetBinFilter() {
    return async () => {
        const defaultFilter = {
            warehouse_id: 'all',
            type: 'all',
            bin_number: '',
            barcode: '',
            keyword: '',
            searchCategory: 'Barcode',
            page: 0,
            page_size: 25,
            total: 0,
        };

        dispatch(slice.actions.updateBinListFilterSuccess({ ...defaultFilter }));

        return defaultFilter;
    };
}

// ----------------------------------------------------------------------
