import { createSlice } from '@reduxjs/toolkit';
// api
// import { voidLabel as voidLabelAPI } from "../../api/shipment";
// utils
// import { getAppointedDate } from "../../utils/getFilterDate";
//
import { filterEmptyValues } from 'src/redux/slices/products';
import { dispatch } from '../store';
import axios, { API_ENDPOINTS } from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    awaitingNumber: 0,
    order: null,
    orderList: [],
    tagList: [],
    sidebarFilter: {
        invoice_number: '',
        batch_number: '',
        packing_slip_number: '',
        picking_list_number: '',
        client_name: '',
        client_phone: '',
        product_sku: '',
        bin_number: '',
        item_sku: '',
        tracking_code: '',
        from_date: '',
        to_date: '',
    },
    filter: {
        warehouse_ids: [],
        store_ids: [],
        user_ids: [],
        tag_ids: [],
        sort_field: 'order_date',
        is_asc: false,
        status: 'all',
        keyword: '',
        page: 0,
        page_size: 5,
        total: 0,
    },
    orderAwaitingList: [],
    awaitingFilter: {
        warehouse_ids: [],
        store_ids: [],
        user_ids: [],
        tag_ids: [],
        sort_field: 'order_date',
        is_asc: false,
        status: 'awaiting',
        keyword: '',
        page: 0,
        page_size: 5,
        total: 0,
    },
    orderShippedList: [],
    shippedFilter: {
        warehouse_ids: [],
        store_ids: [],
        user_ids: [],
        tag_ids: [],
        sort_field: 'order_date',
        is_asc: false,
        status: 'shipped',
        keyword: '',
        page: 0,
        page_size: 5,
        total: 0,
    },
    orderCancelledList: [],
    cancelledFilter: {
        warehouse_ids: [],
        store_ids: [],
        user_ids: [],
        tag_ids: [],
        sort_field: 'order_date',
        is_asc: false,
        status: 'canceled',
        keyword: '',
        page: 0,
        page_size: 5,
        total: 0,
    },
    holdFilter: {
        warehouse_ids: [],
        store_ids: [],
        user_ids: [],
        tag_ids: [],
        sort_field: 'order_date',
        is_asc: false,
        status: 'hold',
        keyword: '',
        page: 0,
        page_size: 5,
        total: 0,
    },
    orderHoldList: [],
    combineList: [],
    pickingList: [],
};

const slice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET ORDER
        getOrderSuccess(state, action) {
            state.isLoading = false;
            state.order = action.payload;
        },

        // ADD ORDER
        addOrderSuccess(state) {
            state.isLoading = false;
        },

        // MODIFY ORDER
        modifyOrderSuccess(state) {
            state.isLoading = false;
        },

        // DELETE ORDER
        deleteOrderSuccess(state) {
            state.isLoading = false;
        },

        // UPDATE ORDER SIDEBAR FILTER
        updateOrderSidebarFilterSuccess(state, action) {
            state.sidebarFilter = {
                ...state.sidebarFilter,
                ...action.payload,
            };
        },

        // MODIFY AWAITING ORDER LIST
        modifyAwaitingOrderSuccess(state, action) {
            // const obj = action.payload;
            const modifiedOrderList = state.orderAwaitingList.map((_order) => {
                if (action.payload?.id === _order.id) {
                    _order = action.payload;
                }

                return _order;
            });

            state.isLoading = false;
            state.orderAwaitingList = modifiedOrderList;
        },

        // MODIFY ALL ORDER LIST
        modifyAllOrderSuccess(state, action) {
            // const obj = action.payload;
            const modifiedAllOrderList = state.orderList.map((_order) => {
                if (action.payload?.id === _order.id) {
                    _order = action.payload;
                }

                return _order;
            });

            state.isLoading = false;
            state.orderList = modifiedAllOrderList;
        },

        // GET ORDER LIST
        getOrdersSuccess(state, action) {
            state.isLoading = false;
            state.orderList = action.payload;
        },

        // UPDATE ORDER LIST FILTER
        updateOrderListFilterSuccess(state, action) {
            state.filter = {
                ...state.filter,
                ...action.payload,
            };
        },

        // GET ORDER AWAITING LIST
        getOrdersAwaitingSuccess(state, action) {
            state.isLoading = false;
            state.orderAwaitingList = action.payload;
        },

        // UPDATE ORDER AWAITING LIST FILTER
        updateOrderAwaitingListFilterSuccess(state, action) {
            state.awaitingFilter = {
                ...state.awaitingFilter,
                ...action.payload,
            };
        },

        // GET ORDER SHIPPED LIST
        getOrdersShippedSuccess(state, action) {
            state.isLoading = false;
            state.orderShippedList = action.payload;
        },

        // UPDATE ORDER SHIPPED LIST FILTER
        updateOrderShippedListFilterSuccess(state, action) {
            state.shippedFilter = {
                ...state.shippedFilter,
                ...action.payload,
            };
        },

        // GET ORDER CANCELED LIST
        getOrdersCancelledSuccess(state, action) {
            state.isLoading = false;
            state.orderCancelledList = action.payload;
        },

        // UPDATE ORDER CANCELED LIST FILTER
        updateOrderCancelledListFilterSuccess(state, action) {
            state.cancelledFilter = {
                ...state.cancelledFilter,
                ...action.payload,
            };
        },

        // GET ORDER HOLD LIST
        getOrdersHoldSuccess(state, action) {
            state.isLoading = false;
            state.orderHoldList = action.payload;
        },

        // UPDATE ORDER HOLD LIST FILTER
        updateOrderHoldListFilterSuccess(state, action) {
            state.holdFilter = {
                ...state.holdFilter,
                ...action.payload,
            };
        },

        // GET ORDER COMBINE LIST
        getOrdersCombineSuccess(state, action) {
            state.isLoading = false;
            state.combineList = action.payload;
        },

        // ADD ORDER COMBINE SUCCESS
        addOrdersCombineSuccess(state, action) {
            state.isLoading = false;
            state.combineList.splice(action.payload.numOfList, 1);
        },

        // GET ORDER PICKING LIST
        getOrdersPickingSuccess(state, action) {
            state.isLoading = false;
            state.pickingList = action.payload;
        },

        // GET ORDER HOLD FROM ORDER LIST
        modifyOrdersHoldSuccess(state, action) {
            state.isLoading = false;
            state.orderList = state.orderList.map((_order) => {
                if (_order.id === action.payload.order_ids[0]) {
                    _order.hold = action.payload.is_hold;
                }

                return _order;
            });
        },

        // GET ORDER HOLD FROM ORDER AWAITING LIST
        modifyOrdersAwaitingHoldSuccess(state, action) {
            state.isLoading = false;

            const modifiedOrderAwaitingList = state.orderAwaitingList.map((_order) => {
                if (_order.id === action.payload.order_ids[0]) {
                    _order.hold = action.payload.is_hold;
                }

                return _order;
            });

            state.orderAwaitingList = modifiedOrderAwaitingList;
        },

        // GET ORDER HOLD FROM ORDER SHIPPED LIST
        modifyOrdersShippedHoldSuccess(state, action) {
            state.isLoading = false;

            const modifiedOrderShippedList = state.orderShippedList.map((_order) => {
                if (_order.id === action.payload.order_ids[0]) {
                    _order.hold = action.payload.is_hold;
                }

                return _order;
            });

            state.orderShippedList = modifiedOrderShippedList;
        },

        // GET ORDER HOLD FROM ORDER CANCELED LIST
        modifyOrdersCancelledHoldSuccess(state, action) {
            state.isLoading = false;

            const modifiedOrderCancelledList = state.orderCancelledList.map((_order) => {
                if (_order.id === action.payload.order_ids[0]) {
                    _order.hold = action.payload.is_hold;
                }

                return _order;
            });

            state.orderCancelledList = modifiedOrderCancelledList;
        },

        getAwaitingNumberSuccess(state, action) {
            state.isLoading = false;
            state.awaitingNumber = action.payload;
        },

        actionSuccess(state) {
            state.isLoading = false;
        },

        // RESET ORDER
        resetOrder(state) {
            state.order = null;
        },

        // GET TAG LIST
        getTagListSuccess(state, action) {
            state.isLoading = false;
            state.tagList = action.payload;
        },

        modifyTagListSuccess(state, action) {
            state.isLoading = false;
            state.tagList = state.tagList.map((_tag) => {
                if (_tag.id === action.payload.id) {
                    _tag = action.payload;
                }

                return _tag;
            });
        },


    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    resetOrder,
    getOrderSuccess,
    modifyAwaitingOrderSuccess,
    modifyAllOrderSuccess,
    updateOrderAwaitingListFilterSuccess,
} = slice.actions;

//
const filterEmptyObjValues = (obj) => {
    const result = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
        if (obj[key] !== '' && obj[key] !== 'all' && !(Array.isArray(obj[key]) && obj[key].length === 0)) {
            result[key] = obj[key];
        }
    }
    return result;
};
// ----------------------------------------------------------------------

// export function getOrderInfo(id) {
//     return async () => {
//         try {
//             const response = await getOrderAPI(id);
//
//             const { status, data } = response;
//
//             if (status === 200) {
//                 dispatch(slice.actions.getOrderSuccess(data ?? null));
//             }
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }

// ----------------------------------------------------------------------

export function addOrder(detail) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const result = await axios.post(API_ENDPOINTS.orders.createOrder, detail);

            const { status, data } = result;

            if (status === 200) {
                dispatch(slice.actions.addOrderSuccess());
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}

// ----------------------------------------------------------------------
//
// export function modifyOrder(detail) {
//     return async () => {
//         dispatch(slice.actions.startLoading());
//         try {
//             const result = await modifyOrderAPI(detail);
//
//             const { status, data } = result;
//
//             if (status === 200 && data?.result === 'success') {
//                 dispatch(slice.actions.modifyOrderSuccess());
//                 return true;
//             }
//             return false;
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//
//             return false;
//         }
//     };
// }

// ----------------------------------------------------------------------

export function deleteOrder(ids) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const result = await axios.post(API_ENDPOINTS.orders.deleteOrder, {
                order_ids: ids,
            });

            const { status, data } = result;

            if (status === 200) {
                dispatch(slice.actions.deleteOrderSuccess());
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function modifyBatchOrderStatus(detail) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const result = await axios.post(API_ENDPOINTS.orders.bulkStatus, {
                ...detail,
            });

            const { status, data } = result;

            if (status === 200) {
                dispatch(slice.actions.actionSuccess());
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function modifyBatchOrderHold(detail) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const result = await axios.post(API_ENDPOINTS.orders.bulkHold, {
                ...detail,
            });

            const { status, data } = result;

            if (status === 200) {
                // if (page === 'all') {
                //     dispatch(slice.actions.modifyOrdersHoldSuccess(detail));
                // } else if (page === 'awaiting') {
                //     dispatch(slice.actions.modifyOrdersAwaitingHoldSuccess(detail));
                // } else if (page === 'shipped') {
                //     dispatch(slice.actions.modifyOrdersShippedHoldSuccess(detail));
                // } else if (page === 'canceled') {
                //     dispatch(slice.actions.modifyOrdersCancelledHoldSuccess(detail));
                // }
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function modifyBatchOrderWarehouse(detail) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const result = await axios.post(API_ENDPOINTS.orders.bulkWarehouse, {
                ...detail,
            });

            const { status, data } = result;

            if (status === 200) {
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function modifyBatchOrderUser(detail) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const result = await axios.post(API_ENDPOINTS.orders.bulkUser, {
                ...detail,
            });

            const { status, data } = result;

            if (status === 200) {
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}

// ----------------------------------------------------------------------

// export function getAwaitingNumber() {
//     return async () => {
//         dispatch(slice.actions.startLoading());
//         try {
//             const result = await getOrderAwaitingNumberAAPI();
//             // const result = await getOrderAwaitingNumberBAPI();
//
//             const { status, data } = result;
//
//             if (status === 200) {
//                 dispatch(slice.actions.getAwaitingNumberSuccess(data?.awaitint_number || 0));
//                 // dispatch(slice.actions.getAwaitingNumberSuccess(data?.awaiting || 0));
//             }
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }

// ----------------------------------------------------------------------

export function getOrderList(filter) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.orders.searchOrder, filterEmptyObjValues(filter));

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getOrdersSuccess(data?.data || []));
                dispatch(slice.actions.updateOrderListFilterSuccess({
                    total: data?.data?.total || 0,
                    page_size: filter?.page_size || 5,
                    page: filter?.page || 0,
                }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateOrderListFilter(filter) {
    return () => {
        dispatch(slice.actions.updateOrderListFilterSuccess({
            warehouse_ids: filter?.warehouse_ids || [],
            store_ids: filter?.store_ids || [],
            user_ids: filter?.user_ids || [],
            tag_ids: filter?.tag_ids || [],
            sort_field: filter?.sort_field || '',
            status: filter?.status || 'awaiting',
            keyword: filter?.keyword || '',
            page: filter?.page || 0,
            page_size: filter?.page_size || 5,
            total: filter?.total || 0,
        }));
    };
}

// ----------------------------------------------------------------------

export function resetFilter() {
    return async () => {
        const defaultFilter = {
            warehouse_ids: [],
            store_ids: [],
            user_ids: [],
            tag_ids: [],
            sort_field: 'order_date',
            status: 'all',
            keyword: '',
            is_asc: false,
            page: 0,
            page_size: 5,
            total: 0,
        };

        dispatch(slice.actions.updateOrderListFilterSuccess({ ...defaultFilter }));

        return defaultFilter;
    };
}

// ----------------------------------------------------------------------

export function getOrderAwaitingList(filter) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.orders.searchOrder, filterEmptyObjValues(filter));

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getOrdersAwaitingSuccess(data?.data || []));
                dispatch(slice.actions.updateOrderAwaitingListFilterSuccess({
                    total: data?.data?.total || 0,
                    page_size: filter?.page_size || 5,
                    page: filter?.page || 0,
                }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateOrderAwaitingListFilter(filter) {
    return () => {
        dispatch(slice.actions.updateOrderAwaitingListFilterSuccess({
            warehouse_ids: filter?.warehouse_ids || [],
            store_ids: filter?.store_ids || [],
            user_ids: filter?.user_ids || [],
            tag_ids: filter?.tag_ids || [],
            sort_field: filter?.sort_field || '',
            status: filter?.status || 'awaiting',
            keyword: filter?.keyword || '',
            page: filter?.page || 0,
            page_size: filter?.page_size || 5,
            total: filter?.total || 0,
        }));
    };
}

// ----------------------------------------------------------------------

export function resetAwaitingFilter() {
    return async () => {
        const defaultFilter = {
            warehouse_ids: [],
            store_ids: [],
            user_ids: [],
            tag_ids: [],
            sort_field: 'order_date',
            status: 'awaiting',
            is_asc: false,
            keyword: '',
            page: 0,
            page_size: 5,
            total: 0,
        };

        dispatch(slice.actions.updateOrderAwaitingListFilterSuccess({ ...defaultFilter }));

        return defaultFilter;
    };
}

// ----------------------------------------------------------------------

export function getOrderHoldList(filter) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.orders.searchOrder, filterEmptyObjValues(filter));

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getOrdersHoldSuccess(data?.data || []));
                dispatch(slice.actions.updateOrderHoldListFilterSuccess({
                    total: data?.data?.total || 0,
                    page_size: filter?.page_size || 5,
                    page: filter?.page || 0,
                }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateOrderHoldListFilter(filter) {
    return () => {
        dispatch(slice.actions.updateOrderHoldListFilterSuccess({
            warehouse_ids: filter?.warehouse_ids || [],
            store_ids: filter?.store_ids || [],
            user_ids: filter?.user_ids || [],
            tag_ids: filter?.tag_ids || [],
            sort_field: filter?.sort_field || '',
            status: filter?.status || 'hold',
            keyword: filter?.keyword || '',
            page: filter?.page || 0,
            page_size: filter?.page_size || 5,
            total: filter?.total || 0,
        }));
    };
}

// ----------------------------------------------------------------------

export function resetHoldFilter() {
    return async () => {
        const defaultFilter = {
            warehouse_ids: [],
            store_ids: [],
            user_ids: [],
            tag_ids: [],
            sort_field: 'order_date',
            status: 'hold',
            is_asc: false,
            keyword: '',
            page: 0,
            page_size: 5,
            total: 0,
        };

        dispatch(slice.actions.updateOrderHoldListFilterSuccess({ ...defaultFilter }));

        return defaultFilter;
    };
}

// ----------------------------------------------------------------------

export function getOrderShippedList(filter) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.orders.searchOrder, filterEmptyObjValues(filter));

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getOrdersShippedSuccess(data?.data || []));
                dispatch(slice.actions.updateOrderShippedListFilterSuccess({
                    total: data?.data?.total || 0,
                    page_size: filter?.page_size || 5,
                    page: filter?.page || 0,
                }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateOrderShippedListFilter(filter) {
    return () => {
        dispatch(slice.actions.updateOrderShippedListFilterSuccess({
            warehouse_ids: filter?.warehouse_ids || [],
            store_ids: filter?.store_ids || [],
            user_ids: filter?.user_ids || [],
            tag_ids: filter?.tag_ids || [],
            sort_field: filter?.sort_field || '',
            status: filter?.status || 'shipped',
            keyword: filter?.keyword || '',
            page: filter?.page || 0,
            page_size: filter?.page_size || 5,
            total: filter?.total || 0,
            is_asc: filter?.is_asc || false,
        }));
    };
}

// ----------------------------------------------------------------------

export function resetShippedFilter() {
    return async () => {
        const defaultFilter = {
            warehouse_ids: [],
            store_ids: [],
            user_ids: [],
            tag_ids: [],
            sort_field: 'order_date',
            is_asc: false,
            status: 'shipped',
            keyword: '',
            page: 0,
            page_size: 5,
            total: 0,
        };

        dispatch(slice.actions.updateOrderShippedListFilterSuccess({ ...defaultFilter }));

        return defaultFilter;
    };
}

// ----------------------------------------------------------------------

export function getOrderCancelledList(filter) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.orders.searchOrder, filterEmptyObjValues(filter));

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getOrdersCancelledSuccess(data?.data || []));
                dispatch(slice.actions.updateOrderCancelledListFilterSuccess({
                    total: data?.data?.total || 0,
                    page_size: filter?.page_size || 5,
                    page: filter?.page || 0,
                }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateOrderCanceledListFilter(filter) {
    return () => {
        dispatch(slice.actions.updateOrderCancelledListFilterSuccess({
            warehouse_ids: filter?.warehouse_ids || [],
            store_ids: filter?.store_ids || [],
            user_ids: filter?.user_ids || [],
            tag_ids: filter?.tag_ids || [],
            sort_field: filter?.sort_field || '',
            status: filter?.status || 'canceled',
            keyword: filter?.keyword || '',
            page: filter?.page || 0,
            page_size: filter?.page_size || 5,
            total: filter?.total || 0,
            is_asc: filter?.is_asc || false,
        }));
    };
}

// ----------------------------------------------------------------------

export function resetCancelledFilter() {
    return async () => {
        const defaultFilter = {
            warehouse_ids: [],
            store_ids: [],
            user_ids: [],
            tag_ids: [],
            sort_field: 'order_date',
            is_asc: false,
            status: 'canceled',
            keyword: '',
            page: 0,
            page_size: 5,
            total: 0,
        };

        dispatch(slice.actions.updateOrderCancelledListFilterSuccess({ ...defaultFilter }));

        return defaultFilter;
    };
}

// ----------------------------------------------------------------------

export function updateOrderSidebarFilter(filter) {
    return () => {
        dispatch(slice.actions.updateOrderSidebarFilterSuccess({
            invoice_number: filter?.invoice_number || '',
            batch_number: filter?.batch_number || '',
            packing_slip_number: filter?.packing_slip_number || '',
            picking_list_number: filter?.picking_list_number || '',
            client_name: filter?.client_name || '',
            client_phone: filter?.client_phone || '',
            product_sku: filter?.product_sku || '',
            bin_number: filter?.bin_number || '',
            item_sku: filter?.item_sku || '',
            tracking_code: filter?.tracking_code || '',
            from_date: filter?.from_date || '',
            to_date: filter?.to_date || '',
        }));
    };
}

// ----------------------------------------------------------------------

export function resetSidebarFilter() {
    return async () => {
        const defaultFilter = {
            invoice_number: '',
            batch_number: '',
            packing_slip_number: '',
            picking_list_number: '',
            client_name: '',
            client_phone: '',
            product_sku: '',
            bin_number: '',
            item_sku: '',
            tracking_code: '',
            from_date: '',
            to_date: '',
        };

        dispatch(slice.actions.updateOrderSidebarFilterSuccess({ ...defaultFilter }));

        return defaultFilter;
    };
}

// ----------------------------------------------------------------------

export function getCombineList() {
    return async () => {
        // dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(API_ENDPOINTS.orders.combineFindAll);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getOrdersCombineSuccess(data?.data || []));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function addOrderCombine(list, numOfList) {
    return async () => {
        // dispatch(slice.actions.startLoading());
        try {
            const result = await axios.post(API_ENDPOINTS.orders.combineOrder, list);

            const { status, data } = result;

            if (status === 200) {
                // dispatch(slice.actions.addOrdersCombineSuccess(numOfList));

                return data?.data || '';
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function getPickingList() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(API_ENDPOINTS.orders.allPickingList);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getOrdersPickingSuccess(data?.data || []));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function addPicking(list) {
    return async () => {
        try {
            const response = await axios.post(API_ENDPOINTS.orders.addPickingList, list);

            const { status, data } = response;

            if (status === 200) {
                dispatch(getPickingList());
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}

// ----------------------------------------------------------------------

// export function modifyPicking(detail) {
//     return async () => {
//         try {
//             const response = await modifyPickingListOrderAPI(detail);
//
//             const { status, data } = response;
//
//             if (status === 200 && data?.result === 'success') {
//                 dispatch(getPickingList());
//             }
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }

// ----------------------------------------------------------------------

export function deletePicking(list) {
    return async () => {
        try {
            const response = await axios.post(API_ENDPOINTS.orders.releasePickingList, list);

            const { status, data } = response;

            if (status === 200) {
                dispatch(getPickingList());

                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}

// ----------------------------------------------------------------------

// export function deletePickingOrder(detail) {
//     return async () => {
//         try {
//             const response = await deletePickingListOrderAPI(detail);
//
//             const { status, data } = response;
//
//             return status === 200 && data?.result === 'success';
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//
//             return false;
//         }
//     };
// }

// ----------------------------------------------------------------------

// export function voidLabel(id) {
//     return async () => {
//         dispatch(slice.actions.startLoading(true));
//         try {
//             const response = await voidLabelAPI(id);
//
//             const { status, data } = response;
//
//             if (status === 200 && data?.result === 'success') {
//                 dispatch(slice.actions.actionSuccess());
//                 return true;
//             }
//             return false;
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//
//             return false;
//         }
//     };
// }

// ----------------------------------------------------------------------

export function getTagList() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(API_ENDPOINTS.orders.allTags);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getTagListSuccess(data?.data || []));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function modifyTagList(detail) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const result = await axios.post(API_ENDPOINTS.orders.manageTags, detail);

            const { status, data } = result;

            if (status === 200) {
                dispatch(slice.actions.modifyTagListSuccess(detail));
                dispatch(getTagList());
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function bulkUpdateTags(detail) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const result = await axios.post(API_ENDPOINTS.orders.updateOrderTags, detail);

            const { status, data } = result;

            if (status === 200) {
                // dispatch(getOrderAwaitingList());
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function addOrderShipments(detail) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const result = await axios.post(API_ENDPOINTS.orders.manageOrderShipments, detail);

            const { status, data } = result;

            if (status === 200) {
                dispatch(slice.actions.actionSuccess());
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function getRate(detail) {
    return async () => {
        // dispatch(slice.actions.startLoading());
        try {
            const result = await axios.post(API_ENDPOINTS.orders.getRate, detail);

            const { status, data } = result;

            if (status === 200) {
                // dispatch(slice.actions.actionSuccess());
                return data?.data;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function buyLabel(request) {
    return async () => {
        dispatch(slice.actions.startLoading(true));
        try {
            const response = await axios.post(API_ENDPOINTS.orders.buyRate, request);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.actionSuccess());
                return true;
            }
            dispatch(slice.actions.startLoading(false));
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function bulkHoldOrder(detail) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const result = await axios.post(API_ENDPOINTS.orders.bulkHold, detail);

            const { status, data } = result;

            if (status === 200) {
                dispatch(slice.actions.actionSuccess());
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}

// ----------------------------------------------------------------------
