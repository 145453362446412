import { createSlice } from '@reduxjs/toolkit';
import axios, { API_ENDPOINTS } from "../../utils/axios";
import { dispatch } from '../store';



// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    tasks: [],
    taskFilter: {
        // status: 'all',
        page: 0,
        page_size: 5,
        // read_status: false,
        // total: 0,
        // sort: 'desc',
    },
    totalUnRead: 0,
};

const slice = createSlice({
    name: 'task',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state, action) {
            state.isLoading = action.payload;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET & UPDATE NOTIFICATION LIST
        getTasksSuccess(state, action) {
            state.isLoading = false;
            state.tasks = action.payload;
        },

        // UPDATE NOTIFICATION LIST
        updateTaskList(state, action) {
            state.isLoading = false;
            action.payload.tasks.forEach((_task) => {
                state.tasks.tasks = [...state.tasks.tasks, _task];
            });
        },

        // UPDATE NOTIFICATION FILTER
        updateTasksFilterSuccess(state, action) {
            state.taskFilter = {
                ...state.taskFilter,
                ...action.payload
            }
        },

        // GET TOTAL UNREAD MESSAGE NUMBER
        getTaskUnreadCountSuccess(state, action) {
            state.totalUnRead = action.payload;
        },

        // UPDATE NOTIFICATION STATUS
        updateTaskStatus(state, action) {
            const taskId = action.payload;

            // const modifiedTasks = state.tasks.map((_task) => {
            //     if (_task.id === taskId) {
            //         _task.is_read = 1;
            //     }
            //     return _task;
            // });
            //
            // state.tasks = modifiedTasks;
        },
    }
});


// Reducer
export default slice.reducer;

// Actions
export const { updateTaskStatus } = slice.actions;

// ----------------------------------------------------------------------

export function getTaskList(filter, update) {
    return async () => {
        try {
            const response = await axios.get(API_ENDPOINTS.tasks.query,  {
                params: filter,
            });

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.updateTasksFilterSuccess({
                    // status: filter?.status || 'all',
                    // total: data?.total || 0,
                    perPage: filter?.per_page || 5,
                    page: filter?.page || 0
                }));
                if (update) {
                    dispatch(slice.actions.updateTaskList(data?.data || []));
                } else {
                    dispatch(slice.actions.getTasksSuccess(data?.data || []));
                }
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function getUnreadTaskNum() {
    return async () => {
        try {
            const response = await axios.get(API_ENDPOINTS.tasks.query, {
                params: {
                    read_status: 0,
                    page: 0,
                    page_size: 1,
                },
            });

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getTaskUnreadCountSuccess(data?.data?.total || 0));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function markTasksAsRead(ids) {
    return async () => {
        try {
            const response = await axios.post(API_ENDPOINTS.tasks.clearUnRead, {
                task_ids: ids,
            });

            const { status, data } = response;

            if (status === 200 && data) {
                const filter = {
                    page_size: 3,
                    page: 0,
                    // read_status: 0,
                }
                dispatch(getTaskList(filter, false));
                dispatch(getUnreadTaskNum());
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function markAllAsRead() {
    return async () => {
        try {
            const response = await axios.post(API_ENDPOINTS.tasks.clearAll);

            const { status, data } = response;

            if (status === 200) {
                dispatch(getUnreadTaskNum());
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
