import { createSlice } from '@reduxjs/toolkit';
// api
// import {
//     getCompanyPermission as getCompanyPermissionAPI,
// } from "../../api/permission";
//
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    permissionList: [],
};

const slice = createSlice({
    name: 'permission',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET PERMISSION
        getPermissionListSuccess(state, action) {
            const tempList = (action.payload || []).filter(_item => _item.code !== 'usps');
            // const tempList = (action.payload || []).filter(_item => _item.code !== "carrier_referral");
            //
            // tempList.map(_itemA => {
            //     if (_itemA.code === 'account') {
            //         const tempItemA = _itemA;
            //         tempItemA.children = (_itemA?.children ?? []).filter(_itemB => _itemB.code !== 'account.user');
            //
            //         tempItemA.children.map(_itemB => {
            //             if (_itemB.code === 'account.billing') {
            //                 const tempItemB = _itemB;
            //                 tempItemB.children = (_itemB?.children ?? []).filter(_itemC => _itemC.code !== 'account.billing.bank_account');
            //
            //                 return tempItemB;
            //             }
            //             return _itemB;
            //         });
            //         return tempItemA;
            //     }
            //     return _itemA;
            // });

            state.isLoading = false;
            state.permissionList = tempList;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
// export const {  } = slice.actions;

// ----------------------------------------------------------------------

export function getPermissionList() {
    return async () => {
        try {
            const response = await axios.get(API_ENDPOINTS.permission.allPermission);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getPermissionListSuccess(data?.data ?? []));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
