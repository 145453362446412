import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong'),
);

export default axiosInstance;

export const API_ENDPOINTS = {
    chat: '/api/chat',
    kanban: '/api/kanban',
    calendar: '/api/calendar',
    auth: {
        me: '/api/auth/me',
        login: '/auth/login',
        register: '/auth/register',
        logout: '/auth/logout',
        forgotPassword: '/auth/forgot_password',
        resetPassword: '/auth/reset_password',
        resendEmail: '/auth/resend_activate_email',
        activeUser: '/auth/activate',
        verifyRecaptcha: '/auth/recaptcha/verify',
        permission: '/auth/permissions',
    },
    settings: {
        accountInfo: '/settings/account/info',
        warehouses: '/settings/company/warehouses',
        company: '/settings/company/info',
        invoice: '/settings/documentation/invoice/info',
        pickingList: '/settings/documentation/picking_list/info',
        packingSlip: '/settings/documentation/packing_slip/info',
        avatar: '/settings/account/avatar/update',
        updateAccount: `/settings/account/profile/update`,
        resetPassword: `/settings/account/profile/reset_password`,
        updateCompanyLogo: `/settings/company/logo/update`,
        resetApiToken: `/settings/company/reset_api_token`,
        updateCompany: `/settings/company/info/update`,
        updateInvoice: `/settings/documentation/invoice/update`,
        updatePickingList: `/settings/documentation/picking_list/update`,
        updatePackingSlip: `/settings/documentation/packing_slip/update`,
    },
    automation: {
        savePrinter: '/automation/config/printer_setting/save',
        getPrinter: '/automation/config/printer_setting/get',
        saveInsurance: '/automation/config/insurance_setting/save',
        getInsurance: '/automation/config/insurance_setting/get',
        saveInternational: '/automation/config/international_setting/save',
        getInternational: '/automation/config/international_setting/get',
    },
    warehouse: {
        warehouses: '/warehouse/all',
        createWarehouse: '/warehouse/create',
        updateWarehouse: '/warehouse/update',
        // verifyAddress: '/address/addresses/verify',
        verifyAddress: '/address/validate',
        deleteWarehouse: '/warehouse/delete',
        getAllStates: '/warehouse/states/all',
        searchProduct: '/warehouse/product/search',
        createProduct: '/warehouse/product/create',
        updateProduct: '/warehouse/product/update',
        deleteProduct: '/warehouse/product/delete',
        uploadProductThumbnail: '/warehouse/product/upload/thumbnail',
        queryProductBins: '/warehouse/product/bins/query',
        updateProductBins: '/warehouse/product/bins/update',

        // bins
        searchBin: '/warehouse/bin/search',
        createBin: '/warehouse/bin/create',
        updateBin: '/warehouse/bin/update',
        deleteBin: '/warehouse/bin/delete',
    },
    address: {
        addAddressBooks: '/address/create',
        deleteAddressBooks: '/address/delete',
        editAddressBooks: '/address/edit',
        getAddressBooks: '/address/query',
        markAsDefault: '/address/mark_as_default',
    },
    tasks: {
        info: '/task/info',
        query: '/task/query',
        exportProduct: '/task/export_product',
        exportBin: '/task/export_bin',
        exportInventory: '/task/export_transfer',
        exportInventoryHistory: '/task/export_history',
        importBin: '/task/import_bin',
        importProduct: '/task/import_product',
        importNewInventory: '/task/import_new_inventory',
        importFullInventory: '/task/import_full_inventory',
        exportRebate: '/task/export_rebate',
        exportTransaction: '/task/export_transaction',
        importShippingRule: '/task/import_shipping_rules',
        exportShippingRule: '/task/export_shippingRules',
        clearUnRead: '/task/clear_tasks',
        clearAll: '/task/clear_tasks_all',
        importOrder: '/task/import_orders',
        exportOrder: '/task/export_order',
    },
    carriers: {
        carrierAccounts: '/carrier_account/all',
        allCarriers: '/carrier_account/all_carriers',
        serviceLevels: '/carrier_account/service_level_candidates',
        predefinedPackages: '/carrier_account/predefined_package_candidates',
        createAccount: '/carrier_account/create',
        deleteAccount: '/carrier_account/delete',
        editAccount: '/carrier_account/edit',
        updateBillAccount: '/carrier_account/update_bill_account',
        upsDapLicense: '/carrier_account/ups_dap_license',
        upsDapNotifiedStatus: '/carrier_account/ups_dap_notified_status',
        allBillAccounts: '/carrier_account/all_bill_accounts',
    },
    store: {
        stores: '/store/query_all',
        preStore: '/store/pre_url',
        createStore: '/store/create',
        confirmStore: '/store/confirm_redirect',
        finishStore: '/store/finish_auth',
        editStore: '/store/edit',
        deleteStore: '/store/delete',
        storeStatus: '/store/set_active_status',
        reAuthStore: '/store/reauth',
        // items
        searchItem: '/item/search',
        createItem: '/item/create',
        editItem: '/item/edit',
        deleteItem: '/item/delete',
        updateItemProductMatch: '/item/update_item_product_match',
        deleteAllItemProductMatch: '/item/delete_all_item_product_match',
    },
    wallet: {
        // balance
        balance: '/wallet_account/balance',
        // payments
        payments: '/payment_method/all',
        addACH: '/payment_method/add_ach',
        addCard: '/payment_method/add_card',
        editPayment: '/payment_method/edit',
        verifyPayment: '/payment_method/verify',
        defaultPayment: '/payment_method/make_default',
        deletePayment: '/payment_method/delete',
        // transactions
        searchTransaction: '/transaction/search',
        // rebate
        searchRebate: '/rebate/search',
        redeemRebate: '/rebate/redeem',
        // recharge
        searchRecharge: '/recharge/search',
        invoice: '/recharge/charge/invoice',
        rechargeCardACH: '/recharge/charge/card',
        rechargeAlipay: '/recharge/charge/alipay',
        uploadImage: '/recharge/offline_charge/upload_image',
        rechargeOffline: '/recharge/charge/offline',
        // auto funding
        autoFunding: '/auto_funding/all',
        createAutoFunding: '/auto_funding/create',
        editAutoFunding: '/auto_funding/edit',
        deleteAutoFunding: '/auto_funding/delete',
    },
    inventory: {
        processInventory: '/inventory/process',
        searchInventory: '/inventory/transfer/search',
        transferInventory: '/inventory/transfer/update',
        historyInventory: '/inventory/history/list',
    },
    user: {
        searchUser: '/user/list',
        addUser: '/user/add',
        editUser: '/user/edit',
        deleteUser: '/user/delete',
        activateUser: '/user/activate',
        getCompanyType: '/user/getCompanyType',
        updateCompanyType: '/user/updateType',
    },
    plan: {
        getList: '/fee/plan/list',
        bind: '/fee/plan/bind',
        update: '/fee/plan/change',
        getDetail: '/fee/plan/detail',
        hasTrial: '/fee/plan/has_trialed',
    },
    permission: {
        allPermission: '/permission/all',
    },
    shippingRules: {
        searchShippingRules: '/shippingRules/list',
        addShippingRules: '/shippingRules/add',
        editShippingRules: '/shippingRules/edit',
        deleteShippingRules: '/shippingRules/delete',
        packageType: '/packageType/all',
        getByKey: '/shippingRules/getByKey',
    },
    packages: {
        searchAllPackages: '/package/query',
        createPackage: '/package/create',
        editPackage: '/package/edit',
        deletePackage: '/package/delete',
        makeDefault: '/package/mark_as_default',
        searchPredefinedPackages: '/package/query_predefined_packages',
        getDimensionUnit: `/automation/config/packages_setting/get`,
        editDimensionUnit: '/automation/config/packages_setting/save',
    },
    orders: {
        searchOrder: '/order/search',
        scanOrder: '/order/scan',
        createOrder: '/order/create',
        editOrder: '/order/edit',
        deleteOrder: '/order/delete',
        allTags: '/order/tags/all',
        manageTags: '/order/tags/manage',
        updateOrderTags: '/order/tags/update_order',
        allBatchNumber: '/order/batch_number/all',
        createBatchNumber: '/order/batch_number/create',
        updateOrderBatchNumber: '/order/batch_number/update_order',
        releaseOrderBatchNumber: '/order/batch_number/release',
        bulkHold: '/order/bulk_update/hold',
        bulkStatus: '/order/bulk_update/status',
        bulkWarehouse: '/order/bulk_update/warehouse',
        bulkUser: '/order/bulk_update/user',
        allPickingList: '/order/picking_list/all',
        createPickingList: '/order/picking_list/create',
        addPickingList: '/order/picking_list/add',
        releasePickingList: '/order/picking_list/release',
        bulkDimension: '/order/bulk_update/dimension',
        bulkWeight: '/order/bulk_update/weight',
        bulkPackage: '/order/bulk_update/package',
        // addShipment: '/shipment/order/create',
        manageOrderShipments: '/shipment/manage_order_shipments',
        getRate: '/rate/list',
        buyRate: '/rate/buy',
        combineFindAll: '/order/combine/find_all',
        // releaseOrderList: '/order/combine/release/find_all',
        combineOrder: '/order/combine',
        // releaseCombineOrder: '/order/combine/release',
    },
    shipments: {
        syncPackage: '/shipment/sync/package',
    },
};
