// ----------------------------------------------------------------------

export default function Stack(theme) {
    return {
        MuiStack: {
            defaultProps: {
                useFlexGap: true,
            },
        },
    };
}
