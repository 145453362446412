import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

// SETUP COLORS
const GREY = {
    0: '#FFFFFF',
    100: '#F9FAFB',
    200: '#F4F6F8',
    300: '#DFE3E8',
    400: '#C4CDD5',
    500: '#919EAB',
    600: '#909090',
    700: '#454F5B',
    800: '#292929',
    900: '#161C24',
};

const PRIMARY = {
    lighter: '#C8FAD6',
    light: '#5BE49B',
    // main: '#00A76F',
    main: '#2C6CD2',
    dark: '#2C6CD2',
    darker: '#004B50',
    contrastText: '#FFFFFF',
};

const SECONDARY = {
    lighter: '#EFD6FF',
    light: '#C684FF',
    main: '#8D1BFF',
    dark: '#00AB55',
    darker: '#27097A',
    contrastText: '#FFFFFF',
};

const INFO = {
    lighter: '#D0F2FF',
    light: '#74CAFF',
    main: '#2C6CD2',
    dark: '#0C53B7',
    darker: '#04297A',
    contrastText: '#FFFFFF',
};

const SUCCESS = {
    lighter: '#E3FFF1',
    light: '#83F1BA',
    main: '#00AB55',
    dark: '#0D8348',
    darker: '#0B5932',
    contrastText: '#ffffff',
};

const WARNING = {
    lighter: '#FFF7CD',
    light: '#FFE16A',
    main: '#FFC107',
    dark: '#B78103',
    darker: '#7A4F01',
    contrastText: GREY[800],
};

const ERROR = {
    lighter: '#FFE7D9',
    light: '#FFA48D',
    main: '#FF4842',
    dark: '#B72136',
    darker: '#7A0C2E',
    contrastText: '#FFFFFF',
};

const BASIC = {
    lighter: '#909090',
    light: '#909090',
    main: '#909090',
    dark: '#909090',
    darker: '#909090',
    contrastText: '#909090',
};

const CHART_COLORS = {
    violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
    blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
    green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
    yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
    red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const COMMON = {
    common: {
        black: '#000000',
        white: '#FFFFFF',
        text1: '#292929',
        text2: '#909090',
        line: '#D8DDE1',
        gray: '#F6F6F6',
        grayWhite: '#FBFBFB',
    },
    primary: PRIMARY,
    secondary: SECONDARY,
    info: INFO,
    success: SUCCESS,
    warning: WARNING,
    error: ERROR,
    basic: BASIC,
    grey: GREY,
    chart: CHART_COLORS,
    divider: alpha(GREY[500], 0.2),
    action: {
        hover: alpha(GREY[500], 0.08),
        selected: alpha(GREY[500], 0.16),
        disabled: alpha(GREY[500], 0.8),
        disabledBackground: alpha(GREY[500], 0.24),
        focus: alpha(GREY[500], 0.24),
        hoverOpacity: 0.08,
        disabledOpacity: 0.48,
    },
};

export function palette(mode) {
    const light = {
        ...COMMON,
        mode: 'light',
        text: {
            primary: GREY[800],
            secondary: GREY[600],
            disabled: GREY[500],
            selected: '#00743A',
        },
        background: {
            paper: '#FFFFFF',
            default: '#FFFFFF',
            neutral: GREY[200],
        },
        action: {
            ...COMMON.action,
            active: GREY[600],
        },
    };

    const dark = {
        ...COMMON,
        mode: 'dark',
        text: {
            primary: '#FFFFFF',
            secondary: GREY[500],
            disabled: GREY[600],
            selected: '#00743A',
        },
        background: {
            paper: GREY[800],
            default: GREY[900],
            neutral: alpha(GREY[500], 0.12),
        },
        action: {
            ...COMMON.action,
            active: GREY[500],
        },
    };

    return mode === 'light' ? light : dark;
}
