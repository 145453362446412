import {useTranslation} from 'react-i18next';
import {useCallback} from 'react';
// utils
import {localStorageGetItem} from 'src/utils/storage-available';
// components
import {useSettingsContext} from 'src/components/settings';
//
import {allLangs, defaultLang} from './config-lang';
import { setSessionLang } from "../auth/context/jwt/utils";

// ----------------------------------------------------------------------

export default function useLocales() {
    const {i18n, t: translate} = useTranslation();

    const settings = useSettingsContext();

    const langStorage = localStorageGetItem('i18nextLng');

    const currentLang = allLangs.find((lang) => lang.value === langStorage) || defaultLang;

    const onChangeLang = useCallback(
        (newlang) => {
            i18n.changeLanguage(newlang);
            settings.onChangeDirectionByLang(newlang);
            setSessionLang(newlang);
        },
        [i18n, settings]
    );

    const translateEn = useCallback(
        (translationKey) => translate(translationKey, { lng: 'en' }),
        [translate]
    );

    return {
        allLangs,
        translate,
        translateEn,
        currentLang,
        onChangeLang,
    };
}
