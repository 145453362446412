import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import axios, { API_ENDPOINTS } from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    balance: '0',
    rebate: '0',
    balanceIncreaseTimes: '0',
    transactionList: [],
    filter: {
        transaction_type: 'all',
        // searchYear: `${new Date().getFullYear()}`,
        start_time: '',
        end_time: '',
        tracking_no: '',
        keyword: '',
        // sort: 'desc',
        page_size: 5,
        page: 0,
        // rowsPerPage: 5,
        // total: 0,
    },
    zelleList: [],
    zelleFilter: {
        offlineType: 'ZELLE',
        offlineStatus: 'all',
        sort: 'desc',
        page: 0,
        rowsPerPage: 5,
    },
    // ---------------------------
    methodList: [],
    // ---------------------------
    chargeHistoryList: [],
    chargeHistoryFilter: {
        start_time: '',
        end_time: '',
        page: 0,
        page_size: 5,
    },
    // ---------------------------
    rebateList: [],
    rebateFilter: {
        start_time: '',
        end_time: '',
        tracking_no: '',
        page: 0,
        page_size: 5,
    },
    // ---------------------------
    autoFundList: [],
    // ---------------------------
    isOpenModal: false,
    isOpenRechargeModal: false,
    formatMethods: null,
};

const filterEmptyValues = (obj) => {
    const result = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
        if (obj[key] !== '' && obj[key] !== 'all') {
            result[key] = obj[key];
        }
    }
    return result;
};

const slice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        clearError(state) {
            state.error = null;
        },

        // GET BALANCE
        getBalanceSuccess(state, action) {
            state.isLoading = false;
            state.balance = action.payload;
        },

        // GET REBATE BALANCE
        getRebateBalance(state, action) {
            state.isLoading = false;
            state.rebate = action.payload;
        },

        // GET BALANCE INCREASE TIMES
        getBalanceIncreaseTimesSuccess(state, action) {
            state.balanceIncreaseTimes = action.payload;
        },

        // GET PAYMENT METHODS
        getMethodsSuccess(state, action) {
            state.isLoading = false;
            state.methodList = action.payload;

            // FOR ADD PAYMENT METHOD && MODIFIED PAYMENT METHOD
            state.isOpenModal = false;
        },

        // DELETE PAYMENT METHOD
        deleteMethodSuccess(state, action) {
            const methodId = action.payload;
            const deletedMethodList = state.methodList.filter((method) => method.id !== methodId);

            state.isLoading = false;
            state.methodList = deletedMethodList;
        },

        // MAKE DEFAULT PAYMENT METHOD
        makeDefaultMethodSuccess(state, action) {
            const methodId = action.payload;
            const modifiedMethodList = state.methodList.map((_method) => {
                _method.is_default = _method.id === methodId;

                return _method;
            });

            state.isLoading = false;
            state.methodList = modifiedMethodList;
        },

        // RECHARGE BALANCE
        rechargeSuccess(state, action) {
            state.isLoading = false;
            state.isOpenRechargeModal = false;
        },

        // GET TRANSACTION LIST
        getTransactionsSuccess(state, action) {
            state.isLoading = false;
            state.transactionList = action.payload;
        },

        // UPDATE TRANSACTION LIST FILTER
        updateTransactionListFilterSuccess(state, action) {
            state.filter = {
                ...state.filter,
                ...action.payload,
            };
        },

        // GET ZELLE LIST
        getZelleSuccess(state, action) {
            state.isLoading = false;
            state.zelleList = action.payload;
        },

        // UPDATE ZELLE LIST FILTER
        updateZelleListFilterSuccess(state, action) {
            state.zelleFilter = {
                ...state.zelleFilter,
                ...action.payload,
            };
        },

        // OPEN MODAL
        openModal(state) {
            state.isOpenModal = true;
        },

        // CLOSE MODAL
        closeModal(state) {
            state.isOpenModal = false;
        },

        // OPEN RECHARGE MODAL
        openRechargeModal(state) {
            state.isOpenRechargeModal = true;
        },

        // CLOSE RECHARGE MODAL
        closeRechargeModal(state) {
            state.isOpenRechargeModal = false;
        },

        // GET FORMAT PAYMENT METHODS
        getFormatMethodsSuccess(state, action) {
            state.isLoading = false;
            state.formatMethods = action.payload;
        },

        // GET REBATE
        getRebateSuccess(state, action) {
            state.isLoading = false;
            state.rebate = action.payload;
        },

        // GET REBATE METHODS
        getRebatesSuccess(state, action) {
            state.isLoading = false;
            state.rebateList = action.payload;
        },

        // UPDATE REBATE LIST FILTER
        updateRebateListFilterSuccess(state, action) {
            state.rebateFilter = {
                ...state.rebateFilter,
                ...action.payload,
            };
        },

        // RESET FILTER
        resetFilter(state) {
            state.rebateFilter = {
                start_time: '',
                end_time: '',
                tracking_no: '',
                page: 0,
                page_size: 5,
            };
        },

        // GET CHARGE HISTORY
        getChargeHistorySuccess(state, action) {
            state.isLoading = false;
            state.chargeHistoryList = action.payload;
        },

        // UPDATE CHARGE HISTORY LIST FILTER
        updateChargeHistoryFilterSuccess(state, action) {
            state.chargeHistoryFilter = {
                ...state.chargeHistoryFilter,
                ...action.payload,
            };
        },

        // RESET FILTER
        resetChargeHistoryFilter(state) {
            state.chargeHistoryFilter = {
                start_time: '',
                end_time: '',
                page: 0,
                page_size: 5,
            };
        },

        // GET AUTO FUND
        getAutoFundSuccess(state, action) {
            state.isLoading = false;
            state.autoFundList = action.payload;
        },

        // DELETE AUTO FUND
        deleteAutoFundSuccess(state, action) {
            const autoFundId = action.payload;
            const deletedAutoFundList = state.autoFundList.filter((autoFund) => autoFund.id !== autoFundId);

            state.isLoading = false;
            state.autoFundList = deletedAutoFundList;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { clearError } = slice.actions;

// ----------------------------------------------------------------------

export function getBalance() {
    return async () => {
        try {
            const response = await axios.get(API_ENDPOINTS.wallet.balance);

            const { status, data } = response;
            if (status === 200) {
                dispatch(slice.actions.getBalanceSuccess(`${data?.data?.account_balance ?? '0'}`));
                dispatch(slice.actions.getRebateBalance(`${data?.data?.rebate_balance ?? '0'}`));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

// export function getBalanceIncreaseTimes() {
//     return async () => {
//         try {
//             const response = await getBalanceIncreaseTimesAPI();
//
//             const { status, data } = response;
//
//             if (status === 200) {
//                 dispatch(slice.actions.getBalanceIncreaseTimesSuccess(data?.balance_increase_times ?? '0'));
//             }
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }

// ----------------------------------------------------------------------

export function getMethodList() {
    return async () => {
        try {
            const response = await axios.get(API_ENDPOINTS.wallet.payments);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getMethodsSuccess(data?.data || []));
                dispatch(slice.actions.getFormatMethodsSuccess(standardizeMethodList(data?.data || [])));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

// export function getFormatMethodList() {
//     return async () => {
//         try {
//             const response = await getPaymentMethodListAPI();
//
//             const { status, data } = response;
//
//             if (status === 200) {
//                 dispatch(slice.actions.getFormatMethodsSuccess(standardizeMethodList(data?.data || [])));
//             }
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }

// ----------------------------------------------------------------------

export function addCreditCard(detail) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.wallet.addCard, detail);

            const { status, data } = response;

            if (status === 200) {
                await dispatch(getMethodList());

                return data?.data.account_id;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function addACH(detail) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.wallet.addACH, detail);

            const { status, data } = response;

            if (status === 200) {
                await dispatch(getMethodList());

                return data?.data.account_id;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function modifyMethod(detail) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.wallet.editPayment, detail);

            const { status, data } = response;

            if (status === 200) {
                dispatch(getMethodList());

                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function verifyMethod(detail) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.wallet.verifyPayment, detail);

            const { status, data } = response;

            await dispatch(getMethodList());

            return status === 200;
        } catch (error) {
            console.log(error);
            await dispatch(getMethodList());

            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function deleteMethod(id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.wallet.deletePayment, {
                account_id: id,
            });

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.deleteMethodSuccess(id));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function makeDefaultMethod(id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.wallet.defaultPayment, {
                account_id: id,
            });

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.makeDefaultMethodSuccess(id));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function rechargeBalance(detail) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.wallet.rechargeCardACH, detail);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.rechargeSuccess());
                // dispatch(slice.actions.getBalanceSuccess(`${data?.data?.balance ?? '0'}`));

                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function rechargeBalanceByThirdParty(detail) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.wallet.rechargeAlipay, detail);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.rechargeSuccess());

                if (data?.data?.cashier_url) {
                    window.open(data?.data?.cashier_url, '_blank');
                }
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function rechargeBalanceByZelle(detail) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.wallet.rechargeOffline, detail);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.rechargeSuccess());

                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function getTransactionList(filter) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(API_ENDPOINTS.wallet.searchTransaction, {
                params: filterEmptyValues(filter),
            });

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getTransactionsSuccess(data?.data || []));
                // dispatch(slice.actions.updateTransactionListFilterSuccess({
                //     total: data?.data?.total || 0,
                //     // rowsPerPage: data?.per_page || 5,
                // }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateTransactionListFilter(filter) {
    return () => {
        dispatch(slice.actions.updateTransactionListFilterSuccess({
            transaction_type: filter?.transaction_type || 'all',
            start_time: filter?.start_time || '',
            end_time: filter?.end_time || '',
            tracking_no: filter?.tracking_no || '',
            keyword: filter?.keyword || '',
            page_size: filter?.page_size || 5,
            page: filter?.page || 0,

            // searchYear: filter?.searchYear || `${new Date().getFullYear()}`,
            // sort: filter?.sort || 'desc',
            // page: filter?.page || 0,
            // rowsPerPage: filter?.rowsPerPage || 5,
            // total: filter?.total || 0,
        }));
    };
}

// ----------------------------------------------------------------------
//
// export function getZelleList(filter) {
//     return async () => {
//         dispatch(slice.actions.startLoading());
//         try {
//             const response = await getZelleListAPI(filter);
//
//             const { status, data } = response;
//
//             if (status === 200) {
//                 dispatch(slice.actions.getZelleSuccess(data?.data || []));
//                 dispatch(slice.actions.updateZelleListFilterSuccess({
//                     total: data?.total || 0,
//                     rowsPerPage: data?.per_page || 5,
//                 }));
//             }
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }
//
// // ----------------------------------------------------------------------
//
// export function updateZelleListFilter(filter) {
//     return () => {
//         dispatch(slice.actions.updateZelleListFilterSuccess({
//             offlineStatus: filter?.offlineStatus || 'all',
//             sort: filter?.sort || 'desc',
//             page: filter?.page || 0,
//             rowsPerPage: filter?.rowsPerPage || 5,
//         }));
//     };
// }
//
// ----------------------------------------------------------------------

function standardizeMethodList(methodList) {
    let countFailed = 0;
    const resultArr = [];
    let payByCard = false;
    let defaultVerifiedCard = null;
    let selectedMethod = null;

    methodList.forEach(method => {
        switch (method.status) {
            case 'VERIFIED':
                payByCard = true;
                if (method.is_default) {
                    defaultVerifiedCard = method;
                }
                resultArr.unshift(method);
                break;
            case 'NEW':
                resultArr.push(method);
                break;
            case 'VERIFICATION_FAILED':
                countFailed += 1;
                break;
            default:
                break;
        }
    });

    if (resultArr.length > 0) {
        selectedMethod = defaultVerifiedCard ? defaultVerifiedCard.id : resultArr[0].id;
    }

    return {
        methods: resultArr,
        payByCard,
        selectedMethod,
        unableToAdd: countFailed > 1 && resultArr.length <= 0,
    };
}

// ----------------------------------------------------------------------
//
// export function getTransactionInvoice(detail) {
//     return async () => {
//         dispatch(slice.actions.startLoading());
//         try {
//             const response = await getInvoiceAPI(detail);
//
//             const { status, data } = response;
//
//             // if (status === 200) {
//             //
//             // }
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }
//
// ----------------------------------------------------------------------

export function resetTransactionFilter() {
    return async () => {
        const defaultFilter = {
            transaction_type: 'all',
            // searchYear: `${new Date().getFullYear()}`,
            start_time: '',
            end_time: '',
            tracking_no: '',
            keyword: '',
            // sort: 'desc',
            page_size: 5,
            page: 0,
        };

        dispatch(slice.actions.updateTransactionListFilterSuccess({ ...defaultFilter }));

        return defaultFilter;
    };
}

// ----------------------------------------------------------------------
//
// export function resetZelleFilter() {
//     return async () => {
//         const defaultFilter = {
//             offlineStatus: 'all',
//             sort: 'desc',
//             page: 0,
//             rowsPerPage: 5,
//         };
//
//         dispatch(slice.actions.updateZelleListFilterSuccess({ ...defaultFilter }));
//
//         return defaultFilter;
//     };
// }
// ----------------------------------------------------------------------

// export function getRebate() {
//     return async () => {
//         try {
//             const response = await axios();
//
//             const { status, data } = response;
//
//             if (status === 200) {
//                 dispatch(slice.actions.getRebateSuccess(data?.rebate ?? '0'));
//             }
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }

// ----------------------------------------------------------------------

export function getRebateList(filter) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(API_ENDPOINTS.wallet.searchRebate, {
                params: filterEmptyValues(filter),
            });

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getRebatesSuccess(data?.data || []));
                // dispatch(slice.actions.updateRebateListFilterSuccess({
                //     total: data?.total || 0,
                //     rowsPerPage: data?.per_page || 5,
                // }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateRebateListFilter(filter) {
    return () => {
        dispatch(slice.actions.updateRebateListFilterSuccess({
            start_time: filter?.start_time || '',
            end_time: filter?.end_time || '',
            tracking_no: filter?.tracking_no || '',
            page: filter?.page || 0,
            page_size: filter?.page_size || 5,
        }));
    };
}

// ----------------------------------------------------------------------

export function resetRebateFilter() {
    return async () => {
        const defaultFilter = {
            start_time: '',
            end_time: '',
            tracking_no: '',
            keyword: '',
            page_size: 5,
            page: 0,
        };

        dispatch(slice.actions.updateRebateListFilterSuccess({ ...defaultFilter }));

        return defaultFilter;
    };
}

// ----------------------------------------------------------------------

export function redeemRebate() {
    return async (dispatchA, getState) => {
        try {
            const response = await axios.post(API_ENDPOINTS.wallet.redeemRebate);

            const { status, data } = response;

            if (status === 200) {
                await dispatch(getBalance());
                await dispatch(getRebateList(getState().rebateFilter));
                return data?.data.redeem_amount;
            }
            return false;
        } catch (error) {
            dispatchA(slice.actions.hasError(error));
            return false;
        }
    };

}

// ----------------------------------------------------------------------

export function getChargeHistoryList(filter) {
    return async (dispatchA, getState) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(API_ENDPOINTS.wallet.searchRecharge, {
                params: filterEmptyValues(filter || getState().chargeHistoryFilter),
            });

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getChargeHistorySuccess(data?.data || []));
                // dispatch(slice.actions.updateChargeHistoryFilterSuccess({
                //     total: data?.total || 0,
                //     rowsPerPage: data?.per_page || 5,
                // }));
            }
        } catch (error) {
            dispatchA(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateChargeHistoryListFilter(filter) {
    return () => {
        dispatch(slice.actions.updateChargeHistoryFilterSuccess({
            ...filter,
            page: filter?.page || 0,
            page_size: filter?.page_size || 5,
        }));
    };
}

// ----------------------------------------------------------------------

export function resetChargeHistoryFilter() {
    return async () => {
        const defaultFilter = {
            start_time: '',
            end_time: '',
            page_size: 5,
            page: 0,
        };

        dispatch(slice.actions.updateChargeHistoryFilterSuccess({ ...defaultFilter }));

        return defaultFilter;
    };
}

// ----------------------------------------------------------------------

export function getAutoFundList(filter) {
    return async () => {
        try {
            const response = await axios.get(API_ENDPOINTS.wallet.autoFunding);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getAutoFundSuccess(data?.data || []));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function addAutoFundList(detail) {
    return async () => {
        try {
            const response = await axios.post(API_ENDPOINTS.wallet.createAutoFunding, detail);

            const { status, data } = response;

            if (status === 200) {
                await dispatch(getAutoFundList());
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function deleteAutoFundList(id) {
    return async () => {
        try {
            const response = await axios.post(API_ENDPOINTS.wallet.deleteAutoFunding, {
                auto_funding_config_id: id,
            });

            const { status } = response;

            if (status === 200) {
                dispatch(slice.actions.deleteAutoFundSuccess(id));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function modifyAutoFundList(detail) {
    return async () => {
        try {
            const response = await axios.post(API_ENDPOINTS.wallet.editAutoFunding, detail);

            const { status } = response;

            if (status === 200) {
                await dispatch(getAutoFundList());
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}
