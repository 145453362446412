import { createSlice } from '@reduxjs/toolkit';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { filterEmptyValues } from 'src/redux/slices/products';
import { dispatch } from '../store';
// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    shippingRuleList: [],
    shippingRuleFilter: {
        total: 0,
        page: 0,
        page_size: 5,
        keyword: '',
        product_sku: '',
    },
    isOpenModal: false,
};

const slice = createSlice({
    name: 'shippingRule',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET SHIPPING RULE LIST SUCCESS
        getShippingRulesSuccess(state, action) {
            state.isLoading = false;
            state.shippingRuleList = action.payload;
        },

        // UPDATE SHIPPING RULE LIST FILTER
        updateShippingRuleFilterSuccess(state, action) {
            state.shippingRuleFilter = {
                ...state.shippingRuleFilter,
                ...action.payload,
            };
        },

        // DELETE ADDRESS
        deleteShippingRuleSuccess(state, action) {
            state.isLoading = false;
            state.shippingRuleList.shipping_rules_data = state.shippingRuleList.shipping_rules_data.filter((_shippingRule) => `${_shippingRule.id}` !== `${action.payload}`) || [];
        },

        // OPEN NEW EDIT MODAL
        openModal(state) {
            state.isOpenModal = true;
        },

        // CLOSE NEW EDIT MODAL
        closeModal(state) {
            state.isOpenModal = false;
        },

        // RESET STATES
        resetShippingRules(state) {
            state.isOpenModal = false;
        },
    },
});

export default slice.reducer;

export const { openModal, closeModal, resetShippingRules } = slice.actions;

// ----------------------------------------------------------------------

export function getShippingRuleList(filter) {
    return async () => {
        try {
            const response = await axios.get(API_ENDPOINTS.shippingRules.searchShippingRules, {
                params: filterEmptyValues(filter),
            });

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getShippingRulesSuccess(data?.data ?? []));
                dispatch(slice.actions.updateShippingRuleFilterSuccess({
                    total: data?.data?.total || 0,
                    // page_size: data?.data?.shipping_rules?.per_page || 5,
                    // page: data?.current_page || 0,
                }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateShippingRuleListFilter(filter) {
    return () => {
        dispatch(slice.actions.updateShippingRuleFilterSuccess({
            page: filter?.page || 0,
            page_size: filter?.page_size || 5,
            total: filter?.total || 0,
            keyword: filter?.keyword || '',
            product_sku: filter?.product_sku || '',
        }));
    };
}

// ----------------------------------------------------------------------

export function addShippingRule(detail) {
    return async (dispatchA, getState) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.shippingRules.addShippingRules, detail);

            const { status, data } = response;

            if (status === 200) {
                dispatch(getShippingRuleList(getState().shippingRule.shippingRuleFilter));
                dispatch(slice.actions.closeModal());
                return true;
            }
            return false;
        } catch (error) {
            dispatchA(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function modifyShippingRule(detail) {
    return async (dispatchA, getState) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.shippingRules.editShippingRules, detail);

            const { status, data } = response;

            if (status === 200) {
                dispatch(getShippingRuleList(getState().shippingRule.shippingRuleFilter));
                return true;
            }
            return false;
        } catch (error) {
            dispatchA(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function resetShippingRuleFilter() {
    return async () => {
        const defaultFilter = {
            total: 0,
            page_size: 5,
            page: 0,
            keyword: '',
            product_sku: '',
        };

        dispatch(slice.actions.updateShippingRuleFilterSuccess({ ...defaultFilter }));

        return defaultFilter;
    };
}

// ----------------------------------------------------------------------

export function deleteShippingRule(id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.shippingRules.deleteShippingRules, {
                id,
            });

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.deleteShippingRuleSuccess(id));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}
