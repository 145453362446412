import { createSlice } from '@reduxjs/toolkit';
// utils
import axios, { API_ENDPOINTS } from "../../utils/axios";
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
    storeLocation: null,
    isLoading: false,
    error: null,
    optionStoreList: [],
    storeList: [],
    authSuccess: null,
    connectStoreList: null,
    isOpenAddModal: false,
    isOpenConnectModal: false,
};

const slice = createSlice({
    name: 'store',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // SET LOCATION
        setRequestedLocationSuccess(state, action) {
            state.storeLocation = action.payload;
        },

        // RESET LOCATION
        resetRequestedLocation(state) {
            state.storeLocation = null;
        },

        // GET OPTION STORES
        getOptionStoresSuccess(state, action) {
            state.isLoading = false;
            state.optionStoreList = action.payload;
        },

        // GET STORES
        getStoresSuccess(state, action) {
            state.isLoading = false;
            state.storeList = action.payload;
        },

        // ADD STORE
        addStoreSuccess(state) {
            state.isLoading = false;
            state.isOpenAddModal = false;
            state.isOpenConnectModal = false;
        },

        // MODIFY STORE
        modifyStoreSuccess(state) {
            state.isLoading = false;
            state.isOpenAddModal = false;
        },

        // DELETE STORE
        deleteStoreSuccess(state) {
            state.isLoading = false;
        },

        // UPDATE AUTH SUCCESS
        updateAuthSuccess(state, action) {
            state.authSuccess = action.payload;
        },

        // GET CONNECT STORE LIST SUCCESS
        getConnectStoresSuccess(state, action) {
            state.connectStoreList = action.payload;
        },

        // OPEN ADD MODAL
        openAddModal(state) {
            state.isOpenAddModal = true;
        },

        closeAddModal(state) {
            state.isOpenAddModal = false;
        },

        // OPEN CONNECT MODAL
        openConnectModal(state) {
            state.isOpenConnectModal = true;
        },

        closeConnectModal(state) {
            state.isOpenConnectModal = false;
        },

        // RESET STORE
        resetStore(state) {
            state.isLoading = false;
            state.isOpenAddModal = false;
            state.isOpenConnectModal = false;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { updateAuthSuccess, openAddModal, closeAddModal, resetStore, openConnectModal, closeConnectModal } = slice.actions;

// ----------------------------------------------------------------------

export function setStoreLocation(url) {
    return () => dispatch(slice.actions.setRequestedLocationSuccess(url));
}

// ----------------------------------------------------------------------

export function resetStoreLocation() {
    return () => dispatch(slice.actions.resetRequestedLocation());
}

// ----------------------------------------------------------------------

// export function getOptionStoreList() {
//     return async () => {
//         try {
//             const response = await getOptionStoreListAPI();
//
//             const { status, data } = response;
//
//             if (status === 200) {
//                 dispatch(slice.actions.getOptionStoresSuccess(data ?? []));
//             }
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }

// ----------------------------------------------------------------------

export function getStoreList() {
    return async () => {
        try {
            const response = await axios.get(API_ENDPOINTS.store.stores);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getStoresSuccess(data?.data ?? []));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function preStore(request) {
    return async () => {
        dispatch(slice.actions.startLoading());

        try {
            const response = await axios.get(API_ENDPOINTS.store.preStore, {
                params: {
                    platform: request?.platform ?? "",
                },
            });
            const { status, data } = response;

            if (status === 200 && data?.code === "ok") {
                if (data?.data) {
                    localStorage.setItem('ssAuthStore', JSON.stringify(request));
                    window.location.assign(data.data);
                }
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function addStore(request) {
    return async () => {
        dispatch(slice.actions.startLoading());

        try {
            const response = await axios.post(API_ENDPOINTS.store.createStore, request);

            const { status, data } = response;

            if (status === 200 && data?.code === "ok") {
                if (data?.data) {
                    localStorage.setItem('ssAuthStore', JSON.stringify(request));
                    window.location.assign(data.data);
                }
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function modifyStore(store) {
    return async () => {
        dispatch(slice.actions.startLoading());

        try {
            const response = await axios.post(API_ENDPOINTS.store.editStore, store);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.modifyStoreSuccess());
                dispatch(getStoreList()).then(null);
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function deleteStore(id) {
    return async () => {
        dispatch(slice.actions.startLoading());

        try {
            const response = await axios.post(API_ENDPOINTS.store.deleteStore, {
                store_id: id,
            });

            const { status, data } = response;

            if (status === 200) {
                dispatch(getStoreList()).then(null);
                dispatch(slice.actions.deleteStoreSuccess(id));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function reAuthStore(request) {
    return async () => {
        dispatch(slice.actions.startLoading());

        try {
            const response = await axios.post(API_ENDPOINTS.store.reAuthStore, request);

            const { status, data } = response;

            if (status === 200 && data?.code === "ok") {
                if (data?.data) {
                    localStorage.setItem('ssAuthStore', JSON.stringify(request));
                    window.location.assign(data.data);
                }
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


// ----------------------------------------------------------------------

// export function getConnectStores() {
//     return async () => {
//         try {
//             const response = await getConnectStoreListAPI();
//             const { status, data } = response;
//
//             if (status === 200 && data) {
//                 dispatch(slice.actions.getConnectStoresSuccess(data ?? null));
//             }
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }

// ----------------------------------------------------------------------

// export function addStoreNew(request) {
//     return async () => {
//         try {
//             const response = await getStoreAuthVerifyAPI(request);
//             const { status, data } = response;
//
//             if (status === 200 && data) {
//                 dispatch(getStoreList());
//             }
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }
