export const CARRIER_LIST = [
    {
        code: 'UPS',
        name: 'UPS',
        carrierCode: 'UPS',
        image: '/assets/image/carriers/logo/ups.svg',
        bgImage: '/assets/image/carriers/logo/ups.svg',
        bgColor: '#292929',
        hoverColor: '#292929',
        credentials: [
            {
                label: 'Account Number',
                value: 'account_number',
                name: 'accountNumber',
                type: 'input',
            },
            // {
            //     label: 'Password',
            //     value: 'password',
            //     name: 'password',
            //     type: 'input',
            // },
            // {
            //     label: 'Access License Number',
            //     value: 'access_license_number',
            //     name: 'accessLicenseNumber',
            //     type: 'input',
            // },
            // {
            //     label: 'Username',
            //     value: 'username',
            //     name: 'username',
            //     type: 'input',
            // },
        ],
        options: [
            {
                label: 'Surepost Endorsement',
                value: 'surepost_endorsement',
                name: 'surepostEndorsement',
                type: 'select',
                arr: [
                    {
                        name: 'Carrier Leave If No Response',
                        value: 'CARRIER_LEAVE_IF_NO_RESPONSE'
                    },
                    {
                        name: 'Return Service',
                        value: 'RETURN_SERVICE'
                    },
                    {
                        name: 'Forwarding Service',
                        value: 'FORWARDING_SERVICE'
                    },
                    {
                        name: 'Address Correction',
                        value: 'ADDRESS_CORRECTION'
                    },
                    {
                        name: 'Change Service',
                        value: 'CHANGE_SERVICE'
                    },
                ]
            },
            {
                label: 'Send emails to recipient when shipments are shipped, delivered, or an exception occurs.',
                value: 'quantum_notification',
                name: 'quantumNotification',
                type: 'checkbox',
            },
        ],
    },
    {
        code: 'FEDEX',
        name: 'FedEx',
        carrierCode: 'FEDEX',
        image: '/assets/image/carriers/logo/fedex.svg',
        bgImage: '/assets/image/carriers/logo/fedex.svg',
        bgColor: '#411A7F',
        hoverColor: '#411A7F',
        credentials: [
            {
                label: 'Meter Number',
                value: 'meter_number',
                name: 'meterNumber',
                type: 'input',
            },
        ],
        options: [
            {
                label: 'Dropoff Type',
                value: 'dropoff_type',
                name: 'dropoffType',
                type: 'input',
            },
            {
                label: 'Hub ID',
                value: 'hub_id',
                name: 'hubId',
                type: 'input',
            },
            {
                label: 'Ancillary Endorsement Type',
                value: 'ancillary_endorsement_type',
                name: 'ancillaryEndorsementType',
                type: 'input',
            },
        ],
    },
    {
        code: 'DHL-EXPRESS',
        name: 'DHL Express',
        carrierCode: 'DHL_EXPRESS',
        image: '/assets/image/carriers/logo/dhl_express.svg',
        bgImage: '/assets/image/carriers/logo/dhl_express.svg',
        bgColor: '#FECB00',
        hoverColor: '#FECB00',
        credentials: [
            {
                label: 'Import Account Number',
                value: 'import_account_number',
                name: 'importAccountNumber',
                type: 'input',
            },
            {
                label: 'Export Account Number',
                value: 'export_account_number',
                name: 'exportAccountNumber',
                type: 'input',
            },
            {
                label: 'Site ID',
                value: 'site_id',
                name: 'siteId',
                type: 'input',
            },
            {
                label: 'Password',
                value: 'password',
                name: 'password',
                type: 'input',
            },
        ],
        options: [],
    },
    {
        code: 'DHL-ECOMMERCE',
        name: 'DHL Ecommerce',
        carrierCode: 'DHL_ECOMMERCE',
        image: '/assets/image/carriers/logo/dhl_ecommerce.svg',
        bgImage: '/assets/image/carriers/logo/dhl_ecommerce.svg',
        bgColor: '#FECB00',
        hoverColor: '#FECB00',
        credentials: [
            {
                label: 'Pickup Account Number',
                value: 'pickup_account_number',
                name: 'pickupAccountNumber',
                type: 'input',
            },
            {
                label: 'Client ID',
                value: 'client_id',
                name: 'clientId',
                type: 'input',
            },
            {
                label: 'Client Secret',
                value: 'client_secret',
                name: 'clientSecret',
                type: 'input',
            },
        ],
        options: [
            {
                label: 'Facility Code',
                value: 'facility_code',
                name: 'facilityCode',
                type: 'input',
            },
        ],
    },
    {
        code: 'ONTRAC',
        name: 'Ontrac',
        carrierCode: 'ONTRAC',
        image: '/assets/image/carriers/logo/ontrac.svg',
        bgImage: '/assets/image/carriers/logo/ontrac.svg',
        bgColor: '#121212',
        hoverColor: '#121212',
        credentials: [
            {
                label: 'Account Number',
                value: 'account_number',
                name: 'accountNumber',
                type: 'input',
            },
            {
                label: 'Password',
                value: 'password',
                name: 'password',
                type: 'input',
            },
        ],
        options: [],
    },
];
