import { createSlice } from '@reduxjs/toolkit';
// api
import axios, { API_ENDPOINTS } from "../../utils/axios";

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    addressList: [],
    addressFilter: {
        address_type: 'all',
        keyword: '',
        search_category: 'Name',
        name: '',
        phone: '',
        company_name: '',
        page: 1,
        page_size: 50,
        address_category: 'ship_from',
        order_by_direction: 'desc'
    },
    isOpenModal: false,
};

const slice = createSlice({
    name: 'address',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET ADDRESSES
        getAddressesSuccess(state, action) {
            state.isLoading = false;
            state.addressList = action.payload;

            // // FOR ADD ADDRESS && MODIFIED ADDRESS
            state.isOpenModal = false;
        },

        // UPDATE ADDRESS LIST FILTER
        updateAddressListFilterSuccess(state, action) {
            state.addressFilter = {
                ...state.addressFilter,
                ...action.payload,
            };
        },

        // VALIDATE SUCCESS
        validateAddressSuccess(state) {
            state.isLoading = false;
        },

        // OPEN MODAL
        openModal(state) {
            state.isOpenModal = true;
        },

        // CLOSE MODAL
        closeModal(state) {
            state.isOpenModal = false;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal } = slice.actions;

// ----------------------------------------------------------------------

export const filterAddressEmptyValues = (obj) => {
    const result = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
        if (obj[key] !== '' && obj[key] !== 'all') {
            result[key] = obj[key];
        }
    }

    // result.name = result.keyword ? result.keyword : '';
    // result.phone = result.keyword ? result.keyword : '';

    if (result?.search_category) {
        switch (result?.search_category) {
            case 'Name':
                result.name = result.keyword ? result.keyword : '';
                break;
            case 'Phone':
                result.phone = result.keyword ? result.keyword : '';
                break;
            default:
                break;
        }
    }
    delete result.search_category
    delete result.keyword

    return result;
};

// ----------------------------------------------------------------------

export function getAddressList(filter) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());

        try {
            const response = await axios.get(API_ENDPOINTS.address.getAddressBooks, {
                params: filterAddressEmptyValues(filter),
            });

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getAddressesSuccess(data?.data?.address_book_data_list ?? []));
                dispatch(slice.actions.updateAddressListFilterSuccess({
                    total: data?.data?.total || 0,
                }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateAddressListFilter(filter) {
    return (dispatch) => {
        dispatch(slice.actions.updateAddressListFilterSuccess({
            address_type: filter?.address_type || 'all',
            keyword: filter?.keyword || '',
            search_category: filter?.search_category || 'Name',
            page: filter?.page || 1,
            page_size: filter?.page_size || 20,
        }));
    };
}

// ----------------------------------------------------------------------

export function resetAddressFilter(category) {
    return async (dispatch) => {
        const defaultFilter = {
            address_type: 'all',
            keyword: '',
            search_category: 'Name',
            name: '',
            phone: '',
            page: 1,
            address_category: category,
            page_size: 50,
        };

        dispatch(slice.actions.updateAddressListFilterSuccess({ ...defaultFilter }));

        return defaultFilter;
    };
}

// ----------------------------------------------------------------------

export function validateAddress(detail) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());

        try {
            const response = await axios.post(API_ENDPOINTS.warehouse.verifyAddress, {
                street: detail?.street || '',
                street2: detail?.street2 || '',
                city: detail?.city || '',
                state: detail?.state || '',
                country: detail?.country || '',
                zip: detail?.zip_code || '',
            });

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.validateAddressSuccess());
                return data?.data;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function addAddress(detail) {
    return async (dispatch, getState) => {
        dispatch(slice.actions.startLoading());

        try {
            const response = await axios.post(API_ENDPOINTS.address.addAddressBooks, detail);

            const { status, data } = response;

            if (status === 200 && data?.code === "ok") {
                dispatch(getAddressList({
                    ...getState().address.addressFilter,
                    address_category: detail.address_category
                }));
                return true;
            }

            dispatch(slice.actions.hasError(data));
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function modifyAddress(detail) {
    return async (dispatch, getState) => {
        dispatch(slice.actions.startLoading());

        try {
            const response = await axios.post(API_ENDPOINTS.address.editAddressBooks, detail);

            const { status, data } = response;

            if (status === 200 && data?.code === "ok") {
                dispatch(getAddressList({
                    ...getState().address.addressFilter,
                    address_category: detail.address_category
                }));
                return true;
            }

            dispatch(slice.actions.hasError(data));
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function deleteAddress(Id, category) {
    return async (dispatch, getState) => {
        dispatch(slice.actions.startLoading());

        try {
            const response = await axios.post(API_ENDPOINTS.address.deleteAddressBooks, {
                address_id: Id,
            });

            const { status, data } = response;

            if (status === 200 && data?.code === "ok") {
                dispatch(getAddressList({
                    ...getState().address.addressFilter,
                    address_category: category
                }));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function markAsDefault(Id, category) {
    return async (dispatch, getState) => {
        dispatch(slice.actions.startLoading());

        try {
            const response = await axios.post(API_ENDPOINTS.address.markAsDefault, {
                address_id: Id
            });

            const { status, data } = response;

            if (status === 200 && data?.code === "ok") {
                dispatch(getAddressList({
                    ...getState().address.addressFilter,
                    address_category: category
                }));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}
