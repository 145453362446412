import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
    const fm = newFormat || 'dd MMM yyyy';

    return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date, newFormat) {
    const fm = newFormat || 'dd MMM yyyy p';

    return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
    return date ? getTime(new Date(date)) : '';
}

export function fDateTimeSuffixE(date) {
    return format(new Date(date), 'MM/dd/yyyy');
}

export function fDateTimeSuffixS(date) {
    return format(new Date(date), 'MM/dd/yyyy p');
}

export function fToNow(date) {
    return date
        ? formatDistanceToNow(new Date(date), {
            addSuffix: true,
        })
        : '';
}

export function fDateOrderCombine(date) {
    return format(new Date(date), 'MM/dd');
}

export function fDateStore(date) {
    return format(new Date(date), 'MM/dd/yyyy');
}

export function fDateTimeStore(date) {
    return format(new Date(date), 'MM/dd/yyyy p');
}

export function fDateCarrier(date) {
    return format(new Date(date), 'yyyyMMdd');
}
