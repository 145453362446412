import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PaymentComplete from 'src/pages/dashboard/wallet/PaymentComplete';

const RedirectToPayment = () => {
    const location = useLocation();

    const { search } = location;
    const params = search ? search.split('?')[1] : null;
    const paramsArr = params ? params.split('&') : [];

    const request = {};

    paramsArr.forEach((p) => {
        const pArr = p.split('=');
        const key = pArr.length === 2 ? pArr[0] : null;
        const value = pArr.length === 2 ? pArr[1] : null;
        if (key && value) {
            request[key] = value;
        }
    });

    return <PaymentComplete status={request.status} />;

    // if (request.status === 'success') {
    //     return <PaymentComplete />;
    //     // return <Navigate to="/payments/" replace />;
    // }
    //
    // return <Navigate to="/payments/transactions" replace />;
};

export default RedirectToPayment;
