import * as React from "react";
import PropTypes from "prop-types";
// @mui
import {
    Box,
    Button,
    Dialog,
    Divider,
    List,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    Typography
} from "@mui/material";
// hooks
import { useLocales } from "../../locales";
// redux
import { useDispatch, useSelector } from "../../redux/store";
// components
import Scrollbar from "../../components/scrollbar";
import Image from "../../components/image";
import { TableNoData } from "../../components/table";

// ----------------------------------------------------------------------

ErrorDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    errors: PropTypes.array,
}

export default function ErrorDialog({ open, onClose, errors }) {
    const { translate } = useLocales();

    const dispatch = useDispatch();

    const { tasks, taskFilter } = useSelector(({ task }) => task);

    // const { notifications, totalUnRead, notificationFilter } = useSelector(({ notification }) => notification);

    return (
        <Dialog fullWidth
                maxWidth="sm"
                open={open}
                onClose={onClose}
                PaperProps={{
                    style: {
                        borderRadius: 8,
                        maxHeight: '70%',
                    }
                }}
        >
            <Stack width={1}
                   direction="row"
                   justifyContent="space-between"
                   alignItems="center"
                   sx={{ px: 2.5, pt: 5, pb: 3.75, backgroundColor:'#2C6CD2' }}
            >
                <Typography variant="h2" color="#fff">
                    {`${translate('task_6')}`}
                </Typography>
            </Stack>

            <Scrollbar>
                <Table sx={{minWidth: 800}}>
                    <TableHead>
                        <TableRow>
                            <TableCell><Typography>{`${translate('task_7')}`}</Typography></TableCell>
                            <TableCell><Typography>{`${translate('task_8')}`}</Typography></TableCell>
                            <TableCell><Typography>{`${translate('task_9')}`}</Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {errors?.length > 0 && errors.map(e => (
                                <TableRow>
                                    <TableCell><Typography>{e?.row_no}</Typography></TableCell>
                                    <TableCell><Typography>{e?.err_title}</Typography></TableCell>
                                    <TableCell><Typography>{e?.err_msg}</Typography></TableCell>
                                </TableRow>
                        ))}

                        <TableNoData notFound={errors === null}/>
                    </TableBody>
                </Table>
            </Scrollbar>
        </Dialog>
    )
}
