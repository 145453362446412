// @mui
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import ListItemText from '@mui/material/ListItemText';
// routes
import { RouterLink } from 'src/routes/components';
//
import Iconify from '../../iconify';
//
import { StyledItem, StyledIcon, StyledDotIcon } from './styles';

// ----------------------------------------------------------------------

export default function NavItem({ item, open, depth, active, config, externalLink, ...other }) {
    const { title, path, icon, info, children, disabled, caption, roles } = item;

    const subItem = depth !== 1;

    const renderContent = (
        <StyledItem
            disableGutters
            disabled={disabled}
            active={active}
            depth={depth}
            config={config}
            {...other}
        >
            <>
                {icon && <StyledIcon size={config.iconSize}>{icon}</StyledIcon>}

                {subItem && (
                    <StyledIcon size={config.iconSize}>
                        {/* <StyledDotIcon active={active}/> */}
                    </StyledIcon>
                )}
            </>

            {!(config.hiddenLabel && !subItem) && (
                <ListItemText
                    primary={title}
                    secondary={
                        caption ? (
                            <Tooltip title={caption} placement="top-start">
                                <span>{caption}</span>
                            </Tooltip>
                        ) : null
                    }
                    primaryTypographyProps={{
                        noWrap: true,
                        textTransform: 'capitalize',
                        typography: icon ? "body1" : 'body1',
                        fontWeight: "inherit",
                    }}
                    secondaryTypographyProps={{
                        noWrap: true,
                        component: 'span',
                        typography: 'body1',
                        color: 'text.disabled',
                    }}
                />
            )}

            {info && (
                <Typography variant="caption" textTransform="capitalize" display="inline-block" ml={0.5} px={0.75} sx={{ color: '#105E37', lineHeight: '20px', bgcolor: '#00AB55', borderRadius: '8px' }}>
                    {info}
                </Typography>
            )}

            {!!children && (
                <Iconify
                    width={16}
                    icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
                    sx={{ ml: 1, flexShrink: 0 }}
                />
            )}
        </StyledItem>
    );

    // Hidden item by role
    if (roles && !roles.includes(`${config.currentRole}`)) {
        return null;
    }

    // External link
    if (externalLink)
        return (
            <Link
                href={path}
                target="_blank"
                rel="noopener"
                underline="none"
                color="inherit"
                sx={{
                    ...(disabled && {
                        cursor: 'default',
                    }),
                }}
            >
                {renderContent}
            </Link>
        );

    // Has child
    if (children) {
        return renderContent;
    }

    // Default
    return (
        <Link
            component={RouterLink}
            href={path}
            underline="none"
            color="inherit"
            sx={{
                ...(disabled && {
                    cursor: 'default',
                }),
            }}
        >
            {renderContent}
        </Link>
    );
}

NavItem.propTypes = {
    active: PropTypes.bool,
    config: PropTypes.object,
    depth: PropTypes.number,
    externalLink: PropTypes.bool,
    item: PropTypes.object,
    open: PropTypes.bool,
};
