import { createSlice } from '@reduxjs/toolkit';
// api
import axios, { API_ENDPOINTS } from "../../utils/axios";
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    showTutorial: false,
    productList: [],
    inventoryList: [],
    inventoryHistoryList: [],
    productFilter: {
        warehouse_id: 'all',
        bin_number: '',
        sku: '',
        barcode: '',
        title: '',
        keyword: '',
        searchCategory: 'Barcode',
        // sort: 'desc',
        page: 0,
        page_size: 25,
        // rowsPerPage: 25,
        total: 0,
    },
    inventoryFilter: {
        warehouse_id: 'all',
        bin_number: '',
        sku: '',
        title: '',
        keyword: '',
        searchCategory: 'SKU',
        page: 0,
        page_size: 25,
        total: 0,
    },
    inventoryHistoryFilter: {
        warehouse_id: 'all',
        keyword: '',
        bin_number: '',
        sku: '',
        user_name: '',
        searchCategory: 'SKU',
        page: 0,
        page_size: 25,
    },
    product: {
        id: null,
        company_id: null,
        sku: null,
        barcode: null,
        thumbnail: null,
        title: null,
        price: null,
        length: null,
        width: null,
        height: null,
        weight: null,
        weight_lb: null,
        weight_oz: null,
        style: null,
        color: null,
        size: null,
        hs_tariff_number: null,
        origin_country: null,
        create_time: null,
    },
};

const slice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET ITEM LIST
        getProductsSuccess(state, action) {
            state.isLoading = false;
            state.productList = action.payload;
        },

        // UPDATE ITEM LIST FILTER
        updateProductListFilterSuccess(state, action) {
            state.productFilter = {
                ...state.productFilter,
                ...action.payload,
            };
        },

        // RESET ITEM
        // resetProduct(state) {
        //     state.order = null;
        // },

        // MODIFY Item
        modifyProductSuccess(state) {
            state.isLoading = false;
        },

        // MODIFY PRODUCT
        modifyProductInfoSuccess(state, action) {

            const modifiedProductList = state.productList?.products.map((_product) => {
                if (action.payload?.products?.product_id === _product.product_id) {
                    _product = action.payload?.products;
                }

                return _product;
            });

            state.isLoading = false;
            state.productList = {
                ...state.productList,
                products: modifiedProductList,
            };
        },

        // DELETE ITEM
        deleteProductSuccess(state, action) {
            state.isLoading = false;
            state.productList.products = state.productList?.products.filter((_product) => !action.payload.includes(_product.product_id));
        },

        // GET INVENTORY LIST
        getInventorySuccess(state, action) {
            state.isLoading = false;
            state.inventoryList = action.payload;
        },

        // UPDATE INVENTORY FILTER
        updateInventoryListFilterSuccess(state, action) {
            state.inventoryFilter = {
                ...state.inventoryFilter,
                ...action.payload,
            };
        },

        // TRANSFER INVENTORY
        transferInventorySuccess(state) {
            state.isLoading = false;
        },

        // GET INVENTORY HISTORY LIST
        getInventoryHistorySuccess(state, action) {
            state.isLoading = false;
            state.inventoryHistoryList = action.payload;
        },

        // UPDATE INVENTORY HISTORY FILTER
        updateInventoryHistoryListFilterSuccess(state, action) {
            state.inventoryHistoryFilter = {
                ...state.inventoryHistoryFilter,
                ...action.payload,
            };
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    resetProduct,
    modifyProductInfoSuccess,
} = slice.actions;

// ----------------------------------------------------------------------
export const filterEmptyValues = (obj) => {
    const result = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
        if (obj[key] !== '' && obj[key] !== 'all') {
            result[key] = obj[key];
        }
    }
    if (result?.searchCategory) {
        switch (result?.searchCategory) {
            case 'SKU':
                result.sku = result.keyword;
                break;
            case 'Barcode':
                result.barcode = result.keyword;
                break;
            case 'Title':
                result.title = result.keyword;
                break;
            case 'Bin Number':
                result.bin_number = result.keyword;
                break;
            default:
                break;
        }
    }

    return result;
};

export function getProductList(filter) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(API_ENDPOINTS.warehouse.searchProduct, {
                params: filterEmptyValues(filter),
            });

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getProductsSuccess(data?.data || []));
                dispatch(slice.actions.updateProductListFilterSuccess({
                    total: data?.total || 0,
                    // rowsPerPage: data?.per_page || 25,
                }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function addProduct(detail) {
    return async (dispatchA, getState) => {
        dispatchA(slice.actions.startLoading(true));
        try {
            const response = await axios.post(API_ENDPOINTS.warehouse.createProduct, detail);

            const { status, data } = response;

            if (status === 200) {
                dispatch(getProductList(getState().products.productFilter));
                return true;
            }
            return false;
        } catch (error) {
            dispatchA(slice.actions.hasError(error));
            return false;
        }
    };
}

//---------------------------------------------------------------------------------------

export function modifyProduct(detail) {
    return async (dispatchA, getState) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.warehouse.updateProduct, detail);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.modifyProductSuccess());
                dispatch(getProductList(getState().products.productFilter));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function deleteProducts(ids) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.warehouse.deleteProduct, {
                product_ids:ids,
            });

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.deleteProductSuccess(ids));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}

// // ----------------------------------------------------------------------
//
// // export function getItemInfo(id) {
// //     return async () => {
// //         try {
// //             const response = await getItemAPI(id);
// //             const { status, data } = response;
// //
// //             if (status === 200 && data) {
// //                 dispatch(slice.actions.getItemsSuccess(data ?? null));
// //             }
// //         } catch (error) {
// //             dispatch(slice.actions.hasError(error));
// //         }
// //     }
// // }
//
// ----------------------------------------------------------------------

export function updateProductListFilter(filter) {
    return () => {
        dispatch(slice.actions.updateProductListFilterSuccess({
            warehouse_id: filter?.warehouse_id || 'all',
            bin_number: filter?.bin_number || '',
            sku: filter?.sku || '',
            barcode: filter?.barcode || '',
            title: filter?.title || '',
            keyword: filter?.keyword || '',
            searchCategory: filter?.searchCategory || '',
            page: filter?.page || 0,
            page_size: filter?.page_size || 25,
            // sort: filter?.sort || 'desc',
            total: filter?.total || 0,
        }));
    };
}

// ----------------------------------------------------------------------

export function resetProductFilter() {
    return async () => {
        const defaultFilter = {
            warehouse_id: 'all',
            bin_number: '',
            sku: '',
            barcode: '',
            title: '',
            keyword: '',
            searchCategory: 'Barcode',
            // sort: 'desc',
            page: 0,
            page_size: 25,
            // rowsPerPage: 25,
            total: 0,
        };

        dispatch(slice.actions.updateProductListFilterSuccess({ ...defaultFilter }));

        return defaultFilter;
    };
}

// ----------------------------------------------------------------------

export function getInventoryList(filter) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(API_ENDPOINTS.inventory.searchInventory, {
                params: filterEmptyValues(filter),
            });

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getInventorySuccess(data?.data || []));
                // dispatch(slice.actions.updateProductListFilterSuccess({
                //     total: data?.total || 0,
                //     // rowsPerPage: data?.per_page || 25,
                // }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function processInventory(detail) {
    return async (dispatchA, getState) => {
        dispatchA(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.inventory.processInventory, filterEmptyValues(detail));

            const { status, data } = response;

            if (status === 200) {
                dispatch(getInventoryHistoryList(getState().products.inventoryHistoryFilter))
                return data?.data;
            }
            return false;
        } catch (error) {
            dispatchA(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function resetInventoryFilter() {
    return async () => {
        const defaultFilter = {
            warehouse_id: 'all',
            bin_number: '',
            sku: '',
            title: '',
            keyword: '',
            searchCategory: 'SKU',
            page: 0,
            page_size: 25,
            total: 0,
        };

        dispatch(slice.actions.updateInventoryListFilterSuccess({ ...defaultFilter }));

        return defaultFilter;
    };
}

// ----------------------------------------------------------------------

export function updateInventoryListFilter(filter) {
    return () => {
        dispatch(slice.actions.updateInventoryListFilterSuccess({
            warehouse_id: filter?.warehouse_id || 'all',
            bin_number: filter?.bin_number || '',
            sku: filter?.sku || '',
            title: filter?.title || '',
            keyword: filter?.keyword || '',
            searchCategory: filter?.searchCategory || '',
            page: filter?.page || 0,
            page_size: filter?.page_size || 25,
            total: filter?.total || 0,
        }));
    };
}

// ----------------------------------------------------------------------

export function getInventoryHistoryList(filter) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(API_ENDPOINTS.inventory.historyInventory, {
                params: filterEmptyValues(filter),
            });

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getInventoryHistorySuccess(data?.data || []));
                // dispatch(slice.actions.updateProductListFilterSuccess({
                //     total: data?.total || 0,
                //     // rowsPerPage: data?.per_page || 25,
                // }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateInventoryHistoryListFilter(filter) {
    return () => {
        dispatch(slice.actions.updateInventoryHistoryListFilterSuccess({
            warehouse_id: filter?.warehouse_id || 'all',
            bin_number: filter?.bin_number || '',
            sku: filter?.sku || '',
            user_name: filter?.user_name || '',
            searchCategory: filter?.searchCategory || '',
            keyword: filter?.keyword || '',
            page: filter?.page || 0,
            page_size: filter?.page_size || 25,
        }));
    };
}

// ----------------------------------------------------------------------

export function resetInventoryHistoryFilter() {
    return async () => {
        const defaultFilter = {
            warehouse_id: 'all',
            bin_number: '',
            sku: '',
            user_name: '',
            keyword: '',
            searchCategory: 'SKU',
            page: 0,
            page_size: 25,
        };

        dispatch(slice.actions.updateInventoryHistoryListFilterSuccess({ ...defaultFilter }));

        return defaultFilter;
    };
}

// ----------------------------------------------------------------------

export function transferInventory(details) {
    return async (dispatchA, getState) => {
        dispatchA(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.inventory.transferInventory, details);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.transferInventorySuccess());
                dispatch(getInventoryList(getState().products.inventoryFilter));
                return true;
                // dispatch(slice.actions.getInventorySuccess(data?.data || []));
            }
            return false;
        } catch (error) {
            dispatchA(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------
