import Slider from "react-slick";
// @mui
import PropTypes from 'prop-types';
import {Box, Card, Stack, Container, Typography} from '@mui/material';
// hooks
import {useResponsive} from 'src/hooks/use-responsive';
// components
import Logo from 'src/components/logo';
import {LanguagePopover} from "../_common";

// ----------------------------------------------------------------------

export default function AuthModernLayout({children, image}) {
    const upMd = useResponsive('up', 'md');

    const settings = {
        dots: true,
        arrows: false,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        fade: true,
    };

    const renderContent = (
        <Stack
            sx={{
                width: 1,
                mx: 'auto',
                maxWidth: 480,
                height: '100vh',
                px: {xs: 2, md: 3.75},
                py: {xs: 2, md: 3.75},
            }}
        >
            <Stack direction="row" justifyContent="space-between">
                <Logo/>
                {/* <LanguagePopover hasArrow/> */}
            </Stack>

            <Card
                sx={{
                    py: {xs: 5, md: 0},
                    px: {xs: 3, md: 0},
                    height: '100%',
                    boxShadow: {md: 'none'},
                    overflow: {md: 'unset'},
                }}
            >
                {children}
            </Card>
        </Stack>
    );

    const renderSection = (
        <Stack flexGrow={1} sx={{position: 'relative'}}>
            {/* <Box */}
            {/*   component="img" */}
            {/*   alt="auth" */}
            {/*   src={image || '/assets/background/overlay_3.jpg'} */}
            {/*   sx={{ */}
            {/*     top: 16, */}
            {/*     left: 16, */}
            {/*     objectFit: 'cover', */}
            {/*     position: 'absolute', */}
            {/*     width: 'calc(100% - 32px)', */}
            {/*     height: 'calc(100% - 32px)', */}
            {/*   }} */}
            {/* /> */}
        </Stack>
    );

    return (
        <Stack>
            <Stack
                component="main"
                direction="row"
                sx={{
                    minHeight: '100vh',
                    background: '#fff',
                    // position: 'relative',
                    // '&:before': {
                    //   width: 1,
                    //   height: 1,
                    //   zIndex: -1,
                    //   content: "''",
                    //   position: 'absolute',
                    //   backgroundSize: 'cover',
                    //   opacity: { xs: 0.24, md: 0 },
                    //   backgroundRepeat: 'no-repeat',
                    //   backgroundPosition: 'center center',
                    //   backgroundImage: 'url(/assets/background/overlay_4.jpg)',
                    // },
                    position: 'absolute',
                    left: '0',
                    top: '0',
                    zIndex: '1',
                    maxWidth: '480px',
                    width: {xs: '100%', sm: '100%'}
                }}
            >
                {renderContent}

                {upMd && renderSection}
            </Stack>
            {/* background slider */}
            <Stack
                sx={{
                    '& .slick-slider': {
                        overflow: 'hidden', // Prevent overflow of the slider container
                    },
                    '& .slick-slide>div': {height: '100vh'},
                    '& .slick-dots': {
                        bottom: "45px",
                        left: 'clamp(0px, 38vw, 480px)',
                        width: '-webkit-fill-available',
                        maxWidth: 'calc(100% - 480px)',
                        'li.slick-active button:before': {color: '#fff'},
                        'li button:before': {color: '#fff'}
                    },
                }}
            >
                <Slider {...settings}>
                    <Container
                        maxWidth={false} sx={{
                        position: 'relative',
                        display: 'flex',
                        p: {xs: 0},
                        minHeight: '100vh',
                        background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.0), 80%, rgba(0, 0, 0, 1)), url(https://shipsaving.s3.us-west-2.amazonaws.com/resources/public/assets/image/auth/banner_a.jpg) center / cover no-repeat ',
                    }}
                    >
                        <Box sx={{
                            position: 'absolute',
                            bottom: '88px',
                            left: 'clamp(0px, 38vw, 480px)',
                            width: '-webkit-fill-available',
                            maxWidth: 'calc(100% - 480px)',
                        }}>
                            <Typography
                                sx={{
                                    color: '#fff',
                                    lineHeight: '1.5',
                                    fontStyle: 'italic',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    margin: 'auto',
                                    maxWidth: {sm: '660px', xxl: '900px'},
                                    fontSize: {xs: '2em', sm: '2.6em', xxl: '3em'},
                                }}
                            > &quot;Whenever I ship I love going to ShipSaving, I have used it for three years, and it
                                saved me a lot of time. It is very fast and convenient, it helps give me the price so
                                quickly.&quot;</Typography>
                            <Typography sx={{color: '#fff', textAlign: 'center', mt: 3}}>Retail / Owner</Typography>
                        </Box>
                    </Container>

                    <Container
                        maxWidth={false} sx={{
                        position: 'relative',
                        display: 'flex',
                        p: {xs: 0},
                        minHeight: '100vh',
                        background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.0), 80%, rgba(0, 0, 0, 1)), url(https://shipsaving.s3.us-west-2.amazonaws.com/resources/public/assets/image/auth/banner_b.jpg) center / cover no-repeat',
                    }}
                    >
                        <Box sx={{
                            position: 'absolute',
                            bottom: '88px',
                            left: 'clamp(0px, 38vw, 480px)',
                            width: '-webkit-fill-available',
                            maxWidth: 'calc(100% - 480px)',
                        }}>
                            <Typography
                                sx={{
                                    color: '#fff',
                                    lineHeight: '1.5',
                                    fontStyle: 'italic',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    margin: 'auto',
                                    maxWidth: {sm: '660px', xxl: '900px'},
                                    fontSize: {xs: '2em', sm: '2.6em', xxl: '3em'},
                                }}
                            >&quot;Easy to use, B2C integration, brings great convenience. I recommend this to everyone
                                and think everyone should use it. It saves you time and money. Customer service is also
                                very helpful.&quot;</Typography>
                            <Typography sx={{color: '#fff', textAlign: 'center', mt: 3}}>Wholesale / Shipping
                                Department</Typography>
                        </Box>
                    </Container>

                    <Container
                        maxWidth={false} sx={{
                        position: 'relative',
                        display: 'flex',
                        p: {xs: 0},
                        minHeight: '100vh',
                        background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.0), 80%, rgba(0, 0, 0, 1)), url(https://shipsaving.s3.us-west-2.amazonaws.com/resources/public/assets/image/auth/banner_c.jpg) center / cover no-repeat',
                    }}
                    >
                        <Box sx={{
                            position: 'absolute',
                            bottom: '88px',
                            left: 'clamp(0px, 38vw, 480px)',
                            width: '-webkit-fill-available',
                            maxWidth: 'calc(100% - 480px)',
                        }}>
                            <Typography
                                sx={{
                                    color: '#fff',
                                    lineHeight: '1.5',
                                    fontStyle: 'italic',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    margin: 'auto',
                                    maxWidth: {sm: '660px', xxl: '900px'},
                                    fontSize: {xs: '2em', sm: '2.6em', xxl: '3em'},
                                }}
                            >&quot;Excellent UI interface. Minimalistic and functional. Responsive customer service -
                                strong execution. Since I switched to ShipSaving I eliminated the need for any other
                                logistics providers.&quot;</Typography>
                            <Typography sx={{color: '#fff', textAlign: 'center', mt: 3}}>Information Technology and
                                Services / CTO</Typography>
                        </Box>
                    </Container>
                </Slider>
            </Stack>
        </Stack>
    );
}

AuthModernLayout.propTypes = {
    children: PropTypes.node,
    image: PropTypes.string,
};
