import * as React from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
// @mui
import { Box, Button, Dialog, Divider, List, Stack, Typography } from "@mui/material";
// hooks
import { useLocales } from "../../locales";
// redux
import {useDispatch, useSelector} from "../../redux/store";
import { getTaskList } from "../../redux/slices/task";
// components
import Scrollbar from "../../components/scrollbar";
import Image from "../../components/image";
//
import TaskItem from "../../layouts/_common/task-popover/task-item";

// ----------------------------------------------------------------------

TaskDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
}

export default function TaskDialog({ open, onClose }) {
    const { translate } = useLocales();

    const dispatch = useDispatch();

    const { tasks, taskFilter } = useSelector(({ task }) => task);

    // const { notifications, totalUnRead, notificationFilter } = useSelector(({ notification }) => notification);

    // const handleFilterChanged = (status) => {
    //     if (notificationFilter?.status !== status) {
    //         const filter = {
    //             status,
    //             perPage: notificationFilter?.perPage || 5,
    //             page: 0,
    //         }
    //         dispatch(getNotificationList(filter, false));
    //     }
    // }

    const handleUpdateTasks = () => {
        const filter = {
            page_size: taskFilter?.page_size || 5,
            /* eslint-disable-next-line no-unsafe-optional-chaining */
            page: taskFilter?.page + 1 || 0,
        }
        dispatch(getTaskList(filter, true));
    }

    // const handleMarkAllAsRead = () => {
    //     dispatch(markNotificationsAsRead([]));
    // };

    return (
        <Dialog fullWidth
                maxWidth="sm"
                open={open}
                onClose={onClose}
                PaperProps={{
                    style: {
                        borderRadius: 8,
                        maxHeight: '70%',
                    }
                }}
        >
            <Stack width={1}
                   direction="row"
                   justifyContent="space-between"
                   alignItems="center"
                   sx={{ px: 2.5, pt: 5, pb: 3.75, backgroundColor:'#2C6CD2' }}
            >
                <Typography variant="h2" color="#fff">
                    {`${translate('task_1')}`}
                    <Typography variant="body1" color="#fff">
                        {`${translate('task_4')}`}
                    </Typography>
                </Typography>

                {/* <Stack direction="row" spacing={0.5}> */}
                {/*     {(() => { */}
                {/*         let count = 0; */}
                {/*         notifications.forEach((_notification, index) => { */}
                {/*             if (index < 3 && !_notification.is_readed) { */}
                {/*                 count += 1; */}
                {/*             } */}
                {/*         }); */}

                {/*         if (count > 0) { */}
                {/*             return ( */}
                {/*                 <Button */}
                {/*                     sx={{ color: '#fff', mr: '-8px' }} */}
                {/*                     onClick={handleMarkAllAsRead} */}
                {/*                     disabled={count <= 0} */}
                {/*                 > */}
                {/*                     {`${translate('notifications_2')}`} */}
                {/*                 </Button> */}
                {/*             ) */}
                {/*         } */}

                {/*         return null; */}
                {/*     })()} */}
                {/* </Stack> */}
            </Stack>
            <Scrollbar>
                <Stack>
                    {tasks.tasks?.length > 0 ?
                        <>
                            <List disablePadding>
                                {(tasks?.tasks || []).map((task) => (
                                    <TaskItem key={task?.task_id_str} task={task} />
                                ))}
                            </List>
                            {tasks?.total > ((taskFilter?.page_size ?? 5) * ((taskFilter?.page ?? 0) + 1)) &&
                                <>
                                    <Divider sx={{ borderStyle: 'solid' }}/>

                                    <Box sx={{ p: 1 }}>
                                        <Button fullWidth disableRipple sx={{ color: '#292929' }} onClick={handleUpdateTasks}>
                                            {`${translate('task_5')}`}
                                        </Button>
                                    </Box>
                                </>
                            }
                        </>
                        :
                        <Box justifyContent="center" alignItems="center" sx={{ py: 6.25, px: 2, textAlign: 'center' }}>
                            <Image src='/assets/icon/navbar/ic_message.svg' alt='message' sx={{ width: "32px", height: '24px', mx: 'auto', mb: 1.75 }}/>
                            <Typography variant="body1" sx={{ color: '#9D9D9D', mb: 0.5 }}>{`${translate('dialog_notifications_4')}`}</Typography>
                        </Box>
                    }
                </Stack>
            </Scrollbar>
        </Dialog>
    )
}
