import PropTypes from 'prop-types'; // @mui
import Box from '@mui/material/Box';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import { useLocales } from "../../locales";

// ----------------------------------------------------------------------

const visuallyHidden = {
    border: 0,
    margin: -1,
    padding: 0,
    width: '1px',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    clip: 'rect(0 0 0 0)',
};

// ----------------------------------------------------------------------

export default function TableHeadCustom({
                                            order,
                                            orderBy,
                                            rowCount = 0,
                                            headLabel,
                                            numSelected = 0,
                                            onSort,
                                            onSelectAllRows,
                                            sx,
                                        }) {
    const { translate } = useLocales();
    return (
        <TableHead sx={sx}>
            <TableRow>
                {onSelectAllRows && (
                    <TableCell padding="checkbox" sx={{ '&:first-of-type': { pl: '2px', py: '2px' } }}>
                        <Checkbox
                            indeterminate={!!numSelected && numSelected < rowCount}
                            checked={!!rowCount && numSelected === rowCount}
                            onChange={(event) => onSelectAllRows(event.target.checked)}
                            sx={{ mx: '4px', p: '4px' }}
                        />
                    </TableCell>
                )}

                {headLabel.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align || 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{
                            width: headCell.width,
                            minWidth: headCell.minWidth,
                            maxWidth: headCell.maxWidth,
                            textTransform: 'uppercase',
                            // fontWeight: light ? 400 : 500,
                            color: '#292929',
                            bgcolor: '#FBFBFB',
                            borderTop: '1px solid #E6E6E6',
                            borderBottomColor: '#E6E6E6',
                            whiteSpace: 'nowrap',
                            padding: '10px 15px',
                        }}
                    >
                        {onSort ? (
                            <TableSortLabel
                                hideSortIcon
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={() => onSort(headCell.id)}
                                sx={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }}
                            >
                                {headCell.label ? `${translate(`${headCell.label}`)}` : ''}

                                {orderBy === headCell.id ? (
                                    <Box sx={{...visuallyHidden}}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        ) : (
                            <>{headCell.label ? `${translate(`${headCell.label}`)}` : ''}</>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

TableHeadCustom.propTypes = {
    sx: PropTypes.object,
    onSort: PropTypes.func,
    orderBy: PropTypes.string,
    headLabel: PropTypes.array,
    rowCount: PropTypes.number,
    numSelected: PropTypes.number,
    onSelectAllRows: PropTypes.func,
    order: PropTypes.oneOf(['asc', 'desc']),
};
