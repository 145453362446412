import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';
import axios, {API_ENDPOINTS} from "../../utils/axios";

const initialState = {
    isLoading: false,
    error: null,
    labelPackingSlip: [],
    insurance: [],
    international: [],
};

const slice = createSlice({
    name: 'automation',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state, action) {
            state.isLoading = action.payload;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET PRINT SETTINGS SUCCESS
        getPrintSuccess(state, action) {
            state.isLoading = false;
            state.labelPackingSlip = action.payload;
        },

        // GET INSURANCE SUCCESS
        getInsuranceSuccess(state, action) {
            state.isLoading = false;
            state.insurance = action.payload;
        },

        // GET INTERNATIONAL SUCCESS
        getInternationalSuccess(state, action) {
            state.isLoading = false;
            state.international = action.payload;
        },

        // UPDATE INVOICE SUCCESS
        updatePrintSuccess(state, action) {
            state.isLoading = false;
            state.labelPackingSlip = {
                ...state.labelPackingSlip,
                ...action.payload,
            }
        },

        // UPDATE INSURANCE SUCCESS
        updateInsuranceSuccess(state, action) {
            state.isLoading = false;
            state.insurance = {
                ...state.insurance,
                ...action.payload,
            }
        },

        // UPDATE INTERNATIONAL SUCCESS
        updateInternationalSuccess(state, action) {
            state.isLoading = false;
            state.international = {
                ...state.international,
                ...action.payload,
            }
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getPrint() {
    return async () => {
        dispatch(slice.actions.startLoading(true));
        try {
            const response = await axios.get(API_ENDPOINTS.automation.getPrinter);
            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getInvoiceSuccess(data?.data ?? null));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function modifyPrint(detail) {
    return async () => {
        try {
            const response = await axios.post(API_ENDPOINTS.automation.savePrinter, detail);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.updatePrintSuccess(detail));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

export function getInsuranceList() {
    return async () => {
        dispatch(slice.actions.startLoading(true));
        try {
            const response = await axios.get(API_ENDPOINTS.automation.getInsurance);
            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getInsuranceSuccess(data?.data ?? null));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function modifyInsuranceList(detail) {
    return async () => {
        try {
            const response = await axios.post(API_ENDPOINTS.automation.saveInsurance, detail);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.updateInsuranceSuccess(detail));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

export function getInternational() {
    return async () => {
        dispatch(slice.actions.startLoading(true));
        try {
            const response = await axios.get(API_ENDPOINTS.automation.getInternational);
            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getInternationalSuccess(data?.data ?? null));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function modifyInternational(detail) {
    return async () => {
        try {
            const response = await axios.post(API_ENDPOINTS.automation.saveInternational, detail);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.updateInternationalSuccess(detail));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}
