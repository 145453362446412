// utils
import { paramCase } from 'src/utils/change-case';
// import { _id, _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------

// const MOCK_ID = _id[1];
//
// const MOCK_TITLE = _postTitles[2];

const ROOTS = {
    // AUTH: '/auth',
    AUTH: '',
    // AUTH_DEMO: '/auth-demo',
    DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
    shipsavingTerms: 'https://www.shipsaving.com/en/terms',
    comingSoon: '/coming-soon',
    maintenance: '/maintenance',
    pricing: '/pricing',
    payment: '/payment',
    about: '/about-us',
    contact: '/contact-us',
    faqs: '/faqs',
    page403: '/403',
    page404: '/404',
    page500: '/500',
    components: '/components',
    docs: 'https://docs.minimals.cc',
    changelog: 'https://docs.minimals.cc/changelog',
    zoneUI: 'https://mui.com/store/items/zone-landing-page/',
    minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
    freeUI: 'https://mui.com/store/items/minimal-dashboard-free/',
    figma:
        'https://www.figma.com/file/kAYnYYdib0aQPNKZpgJT6J/%5BPreview%5D-Minimal-Web.v5.0.0?type=design&node-id=0%3A1&t=Al4jScQq97Aly0Mn-1',
    // AUTH
    auth: {
        amplify: {
            login: `${ROOTS.AUTH}/amplify/login`,
            verify: `${ROOTS.AUTH}/amplify/verify`,
            register: `${ROOTS.AUTH}/amplify/register`,
            newPassword: `${ROOTS.AUTH}/amplify/new-password`,
            forgotPassword: `${ROOTS.AUTH}/amplify/forgot-password`,
        },
        jwt: {
            login: `${ROOTS.AUTH}/login`,
            register: `${ROOTS.AUTH}/register`,
            resetPassword: `${ROOTS.AUTH}/forgot-password`,
            newPassword: `${ROOTS.AUTH}/reset_password`,
            verified: `${ROOTS.AUTH}/verified_email`,
        },
        firebase: {
            login: `${ROOTS.AUTH}/firebase/login`,
            verify: `${ROOTS.AUTH}/firebase/verify`,
            register: `${ROOTS.AUTH}/firebase/register`,
            forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
        },
        auth0: {
            login: `${ROOTS.AUTH}/auth0/login`,
        },
    },
    authDemo: {
        classic: {
            login: `${ROOTS.AUTH_DEMO}/classic/login`,
            register: `${ROOTS.AUTH_DEMO}/classic/register`,
            forgotPassword: `${ROOTS.AUTH_DEMO}/classic/forgot-password`,
            newPassword: `${ROOTS.AUTH_DEMO}/classic/new-password`,
            verify: `${ROOTS.AUTH_DEMO}/classic/verify`,
        },
        modern: {
            login: `${ROOTS.AUTH_DEMO}/modern/login`,
            register: `${ROOTS.AUTH_DEMO}/modern/register`,
            forgotPassword: `${ROOTS.AUTH_DEMO}/modern/forgot-password`,
            newPassword: `${ROOTS.AUTH_DEMO}/modern/new-password`,
            verify: `${ROOTS.AUTH_DEMO}/modern/verify`,
        },
    },
    // External
    external: {
        mainSite: 'https://www.shipsaving.com',
        tutorials: 'https://www.youtube.com/watch?v=ifA_BJwWLjs&list=PLu8E_4Z-kU0hL0Ibx9oZwNZcAPXcoFYwJ',
        shipsavingFeatures: 'https://www.shipsaving.com/en/features',
        shipsavingTerms: 'https://www.shipsaving.com/en/terms',
        shipsavingPartners: 'https://www.shipsaving.com/en/partners',
        upsGuide: 'https://www.canva.com/design/DAEe23d4zL0/dX-urCesPJjNKd2qrh6lgQ/view?utm_content=DAEe23d4zL0&utm_campaign=designshare&utm_medium=link&utm_source=sharebutton',
        mps: 'https://www.shipsaving.com/blog/what-is-multi-piece-shipment',
        dhlPickup: 'https://mydhl.express.dhl/us/en/schedule-pickup.html#/schedule-pickup#label-reference',
        ontracPickup: 'https://www.ontrac.com/schedulepickup.asp',
        uspsScanForm: 'https://www.shipsaving.com/blog/5-reasons-why-you-must-use-usps-scan-form',
        fedexPickup: 'https://www.fedex.com/en-us/shipping/schedule-manage-pickups.html',
        upsPickup: 'https://wwwapps.ups.com/pickup/schedule?loc=en_US',
    },
    // DASHBOARD
    dashboard: {
        root: ROOTS.DASHBOARD,
        mail: `${ROOTS.DASHBOARD}/mail`,
        chat: `${ROOTS.DASHBOARD}/chat`,
        blank: `${ROOTS.DASHBOARD}/blank`,
        kanban: `${ROOTS.DASHBOARD}/kanban`,
        calendar: `${ROOTS.DASHBOARD}/calendar`,
        fileManager: `${ROOTS.DASHBOARD}/file-manager`,
        permission: `${ROOTS.DASHBOARD}/permission`,
        general: {
            app: `${ROOTS.DASHBOARD}/app`,
            ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
            analytics: `${ROOTS.DASHBOARD}/analytics`,
            banking: `${ROOTS.DASHBOARD}/banking`,
            booking: `${ROOTS.DASHBOARD}/booking`,
            file: `${ROOTS.DASHBOARD}/file`,
        },
        // user: {
        //     root: `${ROOTS.DASHBOARD}/user`,
        //     new: `${ROOTS.DASHBOARD}/user/new`,
        //     list: `${ROOTS.DASHBOARD}/user/list`,
        //     cards: `${ROOTS.DASHBOARD}/user/cards`,
        //     profile: `${ROOTS.DASHBOARD}/user/profile`,
        //     account: `${ROOTS.DASHBOARD}/user/account`,
        //     edit: (id) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
        //     demo: {
        //         edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
        //     },
        // },
        orders: {
            root: `/orders`,
            awaiting: `/orders/awaiting`,
            hold: `/orders/hold`,
            canceled: `/orders/canceled`,
            shipped: `/orders/shipped`,
        },
        inventory: {
            root: `/inventory`,
            process: `/inventory/process`,
            history: `/inventory/history`,
        },
        warehouse: {
            root: `/warehouse`,
            bins: `/warehouse/bins`,
            products: `/warehouse/products`,
        },
        stores: {
            install: (platform) => `/stores/${platform}/install`,
            authorized: (platform) => `/stores/${platform}/authorized`,
        },
        wallet: {
            root: `/payments`,
            transaction: `/payments/transactions`,
            rebate: `/payments/rebate`,
            funds: `/payments/funds`,
            // zelle: `/payments/zellehistory`,
        },
        settings: {
            root: `/settings`,
            account: `/settings/account`,
            company: `/settings/company`,
            printer: `/settings/printer`,
            documentation: `/settings/documentation`,
            addressBook: `/settings/address-book`,
            integration: `/settings/integration`,
            automation: `/settings/automation`,
            shippingRules: `settings/shipping-rules`,
            branding: `/settings/branding`,
        },
        shippingRules: `/shipping-rules`,
        addressBook: `/address-book`,
        user: `/user`,
        carrier_accounts: `/carrier-accounts`,
    },
};

// ----------------------------------------------------------------------

export const PATH_EXTERNAL = {
    mainSite: 'https://www.shipsaving.com',
    tutorials: 'https://www.youtube.com/watch?v=ifA_BJwWLjs&list=PLu8E_4Z-kU0hL0Ibx9oZwNZcAPXcoFYwJ',
    shipsavingFeatures: 'https://www.shipsaving.com/en/features',
    shipsavingTerms: 'https://www.shipsaving.com/en/terms',
    shipsavingPartners: 'https://www.shipsaving.com/en/partners',
    upsGuide: 'https://www.canva.com/design/DAEe23d4zL0/dX-urCesPJjNKd2qrh6lgQ/view?utm_content=DAEe23d4zL0&utm_campaign=designshare&utm_medium=link&utm_source=sharebutton',
    mps: 'https://www.shipsaving.com/blog/what-is-multi-piece-shipment',
    dhlPickup: 'https://mydhl.express.dhl/us/en/schedule-pickup.html#/schedule-pickup#label-reference',
    ontracPickup: 'https://www.ontrac.com/schedulepickup.asp',
    uspsScanForm: 'https://www.shipsaving.com/blog/5-reasons-why-you-must-use-usps-scan-form',
    fedexPickup: 'https://www.fedex.com/en-us/shipping/schedule-manage-pickups.html',
    upsPickup: 'https://wwwapps.ups.com/pickup/schedule?loc=en_US',
    shipsavingBlogShippingGuideGeneralDangerousAndProhibitedItems: 'https://www.shipsaving.com/blog/shipping-guide-general-dangerous-and-prohibited-items',
};
