import { createSlice } from '@reduxjs/toolkit';
// api
// import {
//     getItemList as getItemListAPI,
//     getItem as getItemAPI,
//     addItem as addItemAPI,
//     modifyItem as modifyItemAPI,
//     deleteItem as deleteItemAPI,
// } from "../../api/item";
//
import { dispatch } from '../store';
import axios, { API_ENDPOINTS } from '../../utils/axios';
import { filterEmptyValues } from './products';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    showTutorial: false,
    itemList: [],
    itemFilter: {
        store_id: 'all',
        sku: '',
        title: '',
        keyword: '',
        searchCategory: 'SKU',
        // sort: 'desc',
        page: 0,
        page_size: 25,
        // rowsPerPage: 25,
        total: 0,
    },
    item: {
        id: null,
        store_id: null,
        title: null,
        sku: null,
        thumbnails: null,
        price: null,
        quantity: null,
        length: null,
        width: null,
        height: null,
        weight: null,
        hs_tariff_number: null,
        origin_country: null,
        style: null,
        color: null,
        size: null,
    },
};

const slice = createSlice({
    name: 'item',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET ITEM LIST
        getItemsSuccess(state, action) {
            state.isLoading = false;
            state.itemList = action.payload;
        },

        // UPDATE ITEM LIST FILTER
        updateItemListFilterSuccess(state, action) {
            state.itemFilter = {
                ...state.itemFilter,
                ...action.payload,
            };
        },

        // RESET ITEM
        resetItem(state) {
            state.order = null;
        },

        // MODIFY Item
        modifyItemSuccess(state) {
            state.isLoading = false;
        },

        // MODIFY ITEM INFO
        modifyItemInfoSuccess(state, action) {

            const modifiedItemList = state.itemList?.items.map((_item) => {
                if (action.payload?.items?.item_id === _item.item_id) {
                    _item = action.payload?.items;
                }

                return _item;
            });

            state.isLoading = false;
            state.itemList = {
                ...state.itemList,
                items: modifiedItemList,
            };
        },

        // DELETE ITEM
        deleteItemSuccess(state, action) {
            state.isLoading = false;
            state.itemList.items = state.itemList?.items.filter((_item) => !action.payload.include(_item.item_id));
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    resetItem,
    modifyItemInfoSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getItemList(filter) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(API_ENDPOINTS.store.searchItem, {
                params: filterEmptyValues(filter),
            });

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.getItemsSuccess(data?.data || []));
                dispatch(slice.actions.updateItemListFilterSuccess({
                    total: data?.total || 0,
                    // rowsPerPage: data?.per_page || 25,
                }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function addItem(detail) {
    return async (dispatchA, getState) => {
        dispatch(slice.actions.startLoading(true));
        try {
            const response = await axios.post(API_ENDPOINTS.store.createItem, detail);

            const { status, data } = response;

            if (status === 200) {
                dispatch(getItemList(getState().item.itemFilter));
                return true;
            }
            return false;
        } catch (error) {
            dispatchA(slice.actions.hasError(error));
            return false;
        }
    };
}

//---------------------------------------------------------------------------------------

export function modifyItem(detail) {
    return async (dispatchA, getState) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.store.editItem, detail);

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.modifyItemSuccess());
                dispatch(getItemList(getState().item.itemFilter));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function deleteItems(ids) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(API_ENDPOINTS.store.deleteItem, {
                item_ids: ids,
            });

            const { status, data } = response;

            if (status === 200) {
                dispatch(slice.actions.deleteItemSuccess(ids));
                return true;
            }
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));

            return false;
        }
    };
}

// ----------------------------------------------------------------------

// export function getItemInfo(id) {
//     return async () => {
//         try {
//             const response = await getItemAPI(id);
//             const { status, data } = response;
//
//             if (status === 200 && data) {
//                 dispatch(slice.actions.getItemsSuccess(data ?? null));
//             }
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     }
// }

// ----------------------------------------------------------------------

export function updateItemListFilter(filter) {
    return () => {
        dispatch(slice.actions.updateItemListFilterSuccess({
            store_id: filter?.store_id || 'all',
            keyword: filter?.keyword || '',
            sort: filter?.sort || 'desc',
            page: filter?.page || 0,
            rowsPerPage: filter?.rowsPerPage || 25,
            total: filter?.total || 0,
        }));
    };
}

// ----------------------------------------------------------------------

export function resetItemFilter() {
    return async () => {
        const defaultFilter = {
            store_id: 'all',
            sku: '',
            title: '',
            keyword: '',
            searchCategory: 'SKU',
            // sort: 'desc',
            page: 0,
            page_size: 25,
            // rowsPerPage: 25,
            total: 0,
        };

        dispatch(slice.actions.updateItemListFilterSuccess({ ...defaultFilter }));

        return defaultFilter;
    };
}
